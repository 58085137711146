<template>
    <div class="page">
        <el-radio-group v-model="curSelectedData" :class="'my-radio'">
            <el-row :gutter="20">
                <el-col :span="8" v-for="(item, index) in data" :key="index">
                    <div class="personItem">
                        <div class="title">
                            <el-radio :label="item.id" size="large">{{ item.name }}</el-radio>
                            <div>
                                <el-button type="primary" link :icon="EditPen" @click="editPerson(item)"
                                    style="font-size: 16px;">
                                </el-button>
                                <el-button type="danger" link :icon="Delete" @click="delPerson(item)"
                                    style="font-size: 16px;">
                                </el-button>
                            </div>
                        </div>
                        <div class="content">
                            <div class="alias">
                                <el-text truncated>
                                    别名：{{ item.alias }}
                                </el-text>
                            </div>
                            <div class="tags">
                                <el-text truncated>
                                    标签：{{ item.tags.map(item => item.label).join(",") }}
                                </el-text>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </el-radio-group>
    </div>
</template>
<style src="./style.css" scoped></style>
<script setup>
import { ref, inject, reactive, onMounted, nextTick } from "vue"
import { ElMessage, ElMessageBox, ElLoading } from 'element-plus'
import { EditPen, Delete } from '@element-plus/icons-vue'
const myFetch = inject('myFetch')
const getUserId = inject('getUserId')

const emits = defineEmits(['editPerson']);

// 角色数据
const data = ref([])
// 当前选择的角色的 id
const curSelectedData = ref()
// 标签数据
const tabData = ref([]);
// 根据标签id 获取标签数据
function getTagbyId(tagId) {
    return tabData.value.reduce((pre, cur) => pre.concat(cur.tags), []).find(item => item.value == tagId)
}


// 编辑角色
function editPerson(item) {
    emits('editPerson', item);
}

// 刷新数据
async function refresh(defaultVale) {
    // data.value = [
    //     {
    //         id: "0",
    //         name: "安以甜",
    //         alias: "以甜,Lisa",
    //         tags: [
    //             { label: "少女", value: "shao-nv" },
    //             { label: "少男", value: "shao-nan" },
    //         ]
    //     }, 
    // ];
    const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    const { code, msg, data: resData } = await myFetch(`noveltweet/role_list?page=1&pagesize=999&user_id=${getUserId()}`, {}, {});
    loading.close();
    if (code === 2000) {
        data.value = resData.list.map(item => ({
            ...item,
            id: item["role_id"] + '',
            name: item["role_name"],
            alias: item["role_alias_name"],
            tags: item["tag_id_list"]?.split(",")?.map?.(id => ({
                label: getTagbyId(id)?.label,
                value: id
            })),
        }))
        if (defaultVale) {
            // 让角色默认选择
            curSelectedData.value = defaultVale;
            // curSelectedData.value = data.value.find((item) => `${item["id"]}` == `${defaultVale}`);
            // await nextTick()
        }
    } else {
        ElMessage.error(msg);
    }
}

async function delPerson(item) {
    ElMessageBox.alert('您确认要删除该角色？删除后将无法恢复', '删除角色', {
        // if you want to disable its autofocus
        // autofocus: false,
        confirmButtonText: '删除',
        callback: async (action) => {
            if (action === "cancel") return;
            const loading = ElLoading.service({
                lock: true,
                text: 'Loading',
                background: 'rgba(0, 0, 0, 0.7)',
            })
            const { code, msg } = await myFetch(`noveltweet/role_del`, {
                role_id: item.id,
                user_id: getUserId()
            }, {}, "DELETE");
            loading.close();
            if (code === 2000) {
                ElMessage.success("删除成功");
                refresh();
            } else {
                ElMessage.error(msg);
            }
        },
    })

}

// 刷新标签数据
async function refreshTags() {
    // tabData.value = [
    //     {
    //         value: "0",
    //         label: "人物身份",
    //         tags: [
    //             { label: "少女", value: "shao-nv" },
    //             { label: "少男", value: "shao-nan" },
    //         ]
    //     }
    // ];

    const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    const { code, msg, data } = await myFetch("noveltweet/character_tag_list");
    loading.close()
    if (code === 2000) {
        tabData.value = data.list.map(item => ({
            label: item["title"],
            value: item["id"],
            tags: item["tag_list"].map(item => ({
                label: item["title"],
                value: item["id"] + '',
            }))
        }))
    } else {
        ElMessage.error(msg);
    }
}

onMounted(() => {
    (async () => {
        await refreshTags();
        await nextTick();
        await refresh();
    })()
})

const getValues = async () => {
    return new Promise((resolve) => {
        resolve(data.value.find((item) => item["id"] == curSelectedData.value))
    })
}

const setValues = async (personId) => { 
    curSelectedData.value = personId+'';
}
defineExpose({ refresh, getValues, setValues })
</script>
