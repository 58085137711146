<template>
    <div class="page page--image-manage lycontainer" :class="{'ly-is-full':isFull}">
        <div class="flex-1">
            <el-tabs 
                class="tabs tabs--top-nav"
                type="border-card" 
                v-model="activeTopNavTab" 
            >
                <el-tab-pane
                    class="tab-pane tab-pane--goods"
                    name="goods"
                    label="模型训练"
                >
                    <template #label>
                    <span class="flex items-center">
                        <el-icon><PriceTag /></el-icon>
                        <span>模型训练</span>
                    </span>
                    </template>
                    <el-container
                        class="container container--goods"
                    >
                        <el-aside
                            class="aside flex flex-col"
                            width="240px">
                            <div class="flex flex-col mb-2">
                                <h5
                                    class="flex flex-row justify-between items-center"
                                    @click="onShowGoodsImageMain"
                                >
                                    <span class="flex flex-row items-center justify-between">
                                        <el-icon size="16px">
                                            <Collection />
                                        </el-icon>
                                        <i class="ml-1">模型训练管理</i>
                                    </span>
                                </h5>
                                <p class="mt-2">
                                    <el-text type="info">
                                        简单几步，快速生成商业效果的训练模型。
                                    </el-text>
                                </p>
                            </div>
                            <div class="">
                                <el-button
                                    round 
                                    plain
                                    type="primary"
                                    class="w-full"
                                    @click="onNewGoodsImageTask"
                                >
                                    新建任务
                                </el-button>
                            </div>
                            <div class="mt-4 mb-1 pb-6">
                                <el-scrollbar
                                >
                                    <ul class="list">
                                        <li
                                            class="list-item list-item--task"
                                            v-for="(task, index) in userTaskList"
                                            :key="index"
                                            :class="{'active': goodsImageTaskCurrentId == task.id}"
                                            @click="onShowGoodsImageCurrentTask(task.id, task)"
                                        >
                                            <div class="item-thumb">
                                                <el-image
                                                    :src="task.thumb_url"
                                                    loading="lazy"
                                                    fit="cover"
                                                    style="width: 48px;height:48px"
                                                ></el-image>
                                            </div>
                                            <div class="item-content">
                                                <div class="title">{{  task.name }}</div>
                                                <div class="subtitle">{{  task.status_text?'('+task.status_text+')':'' }}</div>
                                            </div>
                                            <div class="item-actions">

                                            </div>
                                        </li>
                                    </ul>
                                    <div
                                        class="more-director text-center"
                                        v-if="hasMoreUserTaskList"
                                    >
                                        <el-button
                                            round
                                            plain
                                            @click="onMoreUserTaskList"
                                        >更多历史任务</el-button>
                                    </div>
                                </el-scrollbar>
                            </div>
                        </el-aside>
                        <el-main
                            class="main"
                        >
                            <article
                                class="section--main"
                                v-if="isShowGoodsImageMain"
                            >
                                <el-tabs
                                    v-model="activeMainNavTab"
                                    class="tabs "
                                >
                                    <el-tab-pane
                                        label="收藏"
                                        name="collect"
                                        class="pb-6"
                                    >
                                        <div class="flex justify-start items-center">
                                            <el-empty
                                                description="暂无收藏训练"
                                                v-if="userCollectList.length == 0"
                                            >
                                            </el-empty>
                                        </div>
                                        <div
                                            class="flex flex-row flex-wrap"
                                            v-if="userCollectList.length > 0"
                                        >
                                            <div
                                                class=""
                                                v-for="(collect,index) in userCollectList"
                                                :key="index"
                                            >
                                                <figure
                                                    class="task-image shadow p-1 mr-2 mb-2"
                                                    v-if="collect.is_collected"
                                                >
                                                    <div class="image">
                                                        <el-image
                                                            fit="cover"
                                                            loading="lazy"
                                                            style="width: 128px;"
                                                            :src="collect.object_asset_url"
                                                            :preview-src-list="[collect.object_asset_url]"
                                                        ></el-image>
                                                    </div>
                                                    <div class="footer mt-1">
                                                        <div class="flex flex-row justify-between">
                                                            <div class="action"
                                                                @click="onCollectTaskImage(
                                                                    collect, 
                                                                    collect.is_collected, 
                                                                    collect.task_id, 
                                                                    collect.object_id, 
                                                                    collect.task_media_site, 
                                                                    collect.object_asset_url
                                                                )"
                                                            >
                                                                <el-icon>
                                                                    <StarFilled size="24px"></StarFilled>
                                                                </el-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </figure>
                                            </div>
                                        </div>
                                        <footer
                                            class="more-director text-center"
                                            v-if="hasMoreUserCollectList"
                                        >
                                            <el-button
                                                round
                                                plain
                                                @click="onMoreUserCollects"
                                            >更多收藏</el-button>
                                        </footer>
                                    </el-tab-pane>
                                    <!-- <el-tab-pane label="创意参考" name="reference">
                                        <div class="flex justify-start items-center">
                                            <el-empty
                                                description="暂无创意参考"
                                                v-if="galleryList.length == 0"
                                            >
                                            </el-empty>
                                        </div>
                                        <el-row :gutter="4">
                                            <el-col
                                                :span="6"
                                                :xs="24"
                                                :sm="12"
                                                v-for="(item,index) in galleryList"
                                                :key="index"
                                            >
                                                <el-image
                                                    :src="item.thumb_url"
                                                    fit="cover"
                                                    loading="lazy"
                                                    style="width: 128px;"
                                                    :preview-src-list="[item.image_url]"
                                                ></el-image>
                                            </el-col>
                                        </el-row>
                                    </el-tab-pane> -->
                                </el-tabs>
                            </article>

                            <article
                                class="section--current-goodsimage"
                                v-if="isShowCurrentGoodsImageTask && isCurrentGoodsImageTaskTodo"
                            >
                                <nav class="mb-1">
                                    <div
                                        class="flex flex-row items-center mb-1"
                                        @click="onEditTaskName"
                                    >
                                        <el-text
                                            class="mr-2"
                                            type="primary"
                                        >
                                            [{{ goodsImageTaskCurrent.name }}]-生成设置
                                        </el-text>
                                        <el-icon
                                            color="#9e9e9e"
                                        >
                                            <EditPen />
                                        </el-icon>
                                    </div>
                                    <el-divider
                                        class="my-2"
                                        border-style="dashed"
                                    ></el-divider>
                                    <el-steps :align-center="true">
                                        <el-step title="选择主体参考图">
                                            <template #description>
                                                <el-image
                                                    :src="goodsImageRefCurrent"
                                                    style="width: 96px;"
                                                    fit="contain"
                                                    v-if="goodsImageRefCurrent"
                                                ></el-image>
                                                <el-text
                                                    type="info"
                                                    v-if="!goodsImageRefCurrent"
                                                >等待选取</el-text>
                                            </template>
                                        </el-step>
                                        <el-step title="选择蒙版">
                                            <template #description>
                                                <el-image
                                                    :src="goodsImageMaskCurrent"
                                                    style="width: 96px;"
                                                    fit="contain"
                                                    v-if="goodsImageMaskCurrent"
                                                ></el-image>
                                                <el-text
                                                    type="info"
                                                    v-if="!goodsImageMaskCurrent"
                                                >等待选取</el-text>
                                            </template>
                                        </el-step>
                                        <el-step title="确认场景">
                                            <template #description>
                                                <el-tag size="default" v-if="goodsImageBuildOptions.prompt_cn">
                                                    {{ goodsImageBuildOptions.prompt_cn }}
                                                </el-tag>
                                                <el-tag size="default" v-if="goodsImageBuildOptions.prompt_en">
                                                    {{ goodsImageBuildOptions.prompt_en }}
                                                </el-tag>
                                                <el-tag size="default" v-if="goodsImageBuildOptions.medium_style">
                                                    {{ goodsImageBuildOptions.medium_style }}
                                                </el-tag>
                                                <el-tag size="default" v-if="goodsImageBuildOptions.composition_style">
                                                    {{ goodsImageBuildOptions.composition_style }}
                                                </el-tag>
                                                <el-tag size="default" v-if="goodsImageBuildOptions.paint_style">
                                                    {{ goodsImageBuildOptions.paint_style }}
                                                </el-tag>
                                                <el-tag size="default" v-if="goodsImageBuildOptions.scene_style">
                                                    {{ goodsImageBuildOptions.scene_style }}
                                                </el-tag>
                                                <el-tag size="default" v-if="goodsImageBuildOptions.location_style">
                                                    {{ goodsImageBuildOptions.location_style }}
                                                </el-tag>
                                                <el-tag size="default" v-if="goodsImageBuildOptions.person_style">
                                                    {{ goodsImageBuildOptions.person_style }}
                                                </el-tag>
                                                <el-tag size="default" v-if="goodsImageBuildOptions.face_style">
                                                    {{ goodsImageBuildOptions.face_style }}
                                                </el-tag>
                                                <el-tag size="default" v-if="goodsImageBuildOptions.tag_style">
                                                    {{ goodsImageBuildOptions.tag_style }}
                                                </el-tag>
                                            </template>
                                        </el-step>
                                        <el-step title="选择数量">
                                            <template #description>
                                                <el-tag 
                                                    type="danger"
                                                    size="default"
                                                    v-if="goodsImageBuildOptions.batch_size"
                                                >
                                                    {{ goodsImageBuildOptions.batch_size }}
                                                </el-tag>
                                        </template>
                                        </el-step>
                                        <el-step title="提交任务">
                                            <template #description>
                                                <el-text type="warning">
                                                    可以多次重复提交，生成更多的不同
                                                </el-text>
                                            </template>
                                        </el-step>
                                    </el-steps>
                                    <div class="flex justify-between p-1 mt-2 mb-2 bg-gray-200">
                                        <div>
                                            <el-button 
                                                plain
                                                round
                                                type="danger"
                                                @click="onSubmitGoodsImageTask('pretrain-model')"
                                            >
                                                生成训练
                                            </el-button>
                                        </div>
                                        <div>
                                            <el-button
                                                round
                                                plain
                                                type="success"
                                                @click="onDoneGoodsImageTask"
                                                v-if="isCurrentGoodsImageTaskTodo"
                                            >
                                                确认任务完成
                                            </el-button>
                                            <el-divider direction="vertical" border-style="dotted"></el-divider>
                                            <el-button
                                                round
                                                plain
                                                type="warning"
                                                @click="onDestroyGoodsImageTask"
                                            >
                                                删除任务
                                            </el-button>
                                        </div>
                                    </div>
                                    <div class="flex">
                                        <nav class="flex flex-col main-ref basis-72">
                                            <div class="ref-image">
                                                <h5 class="label">主体参考图</h5>
                                                <el-radio-group
                                                    v-model="goodsImageRefType"
                                                    >
                                                    <el-radio label="gallery">已有主体参考图</el-radio>
                                                    <el-radio label="upload">上传主体参考图</el-radio>
                                                </el-radio-group>                                                
                                            </div>
                                            <div
                                                class=""
                                                v-if="goodsImageRefType == 'gallery'">
                                                <el-scrollbar
                                                    max-height="100vh"
                                                >
                                                    <el-row :gutter="8">
                                                        <el-radio-group
                                                            v-model="goodsImageRefGalleryCurrent"
                                                        >
                                                        <el-column
                                                            :span="12"
                                                            v-for="(ref,index) in goodsImageRefList"
                                                            :key="index"
                                                        >
                                                            <figure class="mr-1 mt-1">
                                                                <el-image 
                                                                    :src="ref.thumb_url"
                                                                    :preview-src-list="[ref.thumb_url]"
                                                                    style="width: 136px;height:136px"
                                                                    class="rounded"
                                                                    fit="cover"
                                                                ></el-image>
                                                                <figcaption class="ml-1 flex items-center justify-between">
                                                                    <el-radio 
                                                                        :label="ref.thumb_url"
                                                                        data-id="ref.id"
                                                                        @change="onChangeGoodsImageRefGallery({'url': ref.thumb_url, 'id': ref.id})"
                                                                    >设为参考图</el-radio>
                                                                    <el-dropdown
                                                                        @command="onProcessGoodsImageRefActions"
                                                                    >
                                                                        <span class="el-dropdown-link">
                                                                            <el-icon class="el-icon--right">
                                                                                <MoreFilled />
                                                                            </el-icon>
                                                                        </span>
                                                                        <template #dropdown>
                                                                        <el-dropdown-menu>
                                                                            <el-dropdown-item command="drop">
                                                                                删除
                                                                            </el-dropdown-item>
                                                                        </el-dropdown-menu>
                                                                        </template>
                                                                    </el-dropdown>
                                                                </figcaption>
                                                            </figure>
                                                        </el-column>
                                                        </el-radio-group>
                                                    </el-row>
                                                </el-scrollbar>
                                            </div>
                                            <div
                                                class="flex justify-start items-center"
                                                v-if="goodsImageRefType == 'upload'"
                                            >
                                                <el-upload
                                                    ref="goodsImageRefUploaderRef"
                                                    class="my-2 flex-1 w-full uploader--ref"
                                                    list-type="picture-card"
                                                    :limit="1"
                                                    :auto-upload="false"
                                                    action="#"
                                                    :http-request="uploadGoodsImageRefToServer"
                                                    :before-upload="onBeforeUploadGoodsImage"
                                                    :on-success="onAfterUploadGoodsImageRef"
                                                    :on-exceed="onExceedGoodsImageRef"
                                                >
                                                    <template #trigger>
                                                        <div class="flex flex-col justify-center items-center w-full">
                                                            <el-icon>
                                                                <Plus />
                                                            </el-icon>
                                                        </div>
                                                    </template>
                                                    <el-divider border-style="dashed"></el-divider>
                                                    <el-button
                                                        plain
                                                        round
                                                        @click="onUploadGoodsImageRefToServer"
                                                    >
                                                        上传到服务器
                                                    </el-button>
                                                    <div class="el-upload__tip">只能上传jpg/png文件</div>
                                                </el-upload>
                                            </div>
                                        </nav>
                                        <main class="flex flex-1">
                                            <div class="flex pb-6">
                                                <aside class="flex flex-col px-1 main-gutter">
                                                    <div
                                                        class="gutter-switch flex flex-col justify-center items-center"
                                                        :class="{'active': goodsImageTaskCurrentMainOption == 'mask'}"
                                                        @click="onSwitchMainOption2Mask"
                                                    >
                                                        <h5>蒙版</h5>
                                                        <el-icon><PictureFilled /></el-icon>
                                                    </div>
                                                    <div
                                                        class="gutter-switch flex flex-col justify-center items-center"
                                                        :class="{'active': goodsImageTaskCurrentMainOption == 'scene'}"
                                                        @click="onSwitchMainOption2Scene"
                                                    >
                                                        <h5>场景</h5>
                                                        <el-icon><Place /></el-icon>
                                                    </div>
                                                    <div
                                                        class="gutter-switch flex flex-col justify-center items-center"
                                                        :class="{'active': goodsImageTaskCurrentMainOption == 'number'}"
                                                        @click="onSwitchMainOption2Number"
                                                    >
                                                        <h5>生成数量</h5>
                                                        <el-icon>
                                                            <DCaret></DCaret>
                                                        </el-icon>
                                                    </div>
                                                </aside>
                                                <div class="flex flex-1 pl-1 pb-6 main-options">
                                                    <div
                                                        class="option--mask flex flex-col"
                                                        v-if="goodsImageTaskCurrentMainOption == 'mask'"
                                                    >
                                                        <div class="">
                                                            <h5 class="label">
                                                                <el-text type="">主体蒙版</el-text>
                                                            </h5>
                                                            <el-divider type="dashed"></el-divider>
                                                            <el-radio-group
                                                                v-model="goodsImageMaskType"
                                                            >
                                                                <el-radio label="auto">获取自动蒙版</el-radio>
                                                                <el-radio label="upload">上传主体蒙版</el-radio>
                                                            </el-radio-group>
                                                        </div>
                                                        <div
                                                            class=""
                                                            v-if="goodsImageMaskType == 'auto'"
                                                        >
                                                            <el-form-item
                                                                label=""
                                                            >
                                                                <el-button
                                                                    class="ml-1 w-full"
                                                                    plain
                                                                    round
                                                                    type="danger"
                                                                    size="large"
                                                                    @click="onGetGoodsImageMaskAuto"
                                                                    :loading="goodsImageMaskProgressing"
                                                                >获取自动蒙版</el-button>
                                                            </el-form-item>
                                                            <el-form label-position="left">
                                                                <el-form-item
                                                                    label=""
                                                                >
                                                                    <template #label>
                                                                        <el-text type="info">
                                                                            获取类型
                                                                        </el-text>                                                            
                                                                    </template>
                                                                    <el-radio-group
                                                                        v-model="goodsImageMaskAutoGetType"
                                                                    >
                                                                        <el-radio label="mask">获取主体蒙版</el-radio>
                                                                        <el-radio label="extract">获取主体提取图</el-radio>
                                                                    </el-radio-group>
                                                                </el-form-item>
                                                            </el-form>
                                                            <div
                                                                class="mt-1 flex flex-col"
                                                                v-if="goodsImageMaskType == 'auto'"
                                                            >
                                                                <div class="">
                                                                    <el-checkbox 
                                                                        disabbled
                                                                        v-model="goodsImageMaskIsNeedFinetune">
                                                                        不够精细,需要精修（开发中）
                                                                    </el-checkbox>
                                                                </div>
                                                                <div
                                                                    class="mt-1"
                                                                    v-if="goodsImageMaskIsNeedFinetune"
                                                                >
                                                                    <el-radio-group
                                                                        v-model="goodsImageFinetuneType"
                                                                    >
                                                                        <el-radio label="ref">从主体参考图精修</el-radio>
                                                                        <el-radio label="auto-extract">从主体提取图精修</el-radio>
                                                                    </el-radio-group>
                                                                    <el-button
                                                                        class="ml-1"
                                                                        plain
                                                                        round
                                                                        @click="onStartFinetuneMask"
                                                                    >开始精修蒙版</el-button>
                                                                </div>
                                                            </div>
                                                            <figure
                                                                class="flex items-center justify-left"
                                                                v-if="!goodsImageMaskAuto"
                                                            >
                                                                <el-empty
                                                                    :image-size="96"
                                                                    description="等待生成"
                                                                ></el-empty>
                                                            </figure>
                                                            <figure
                                                                class="flex items-center justify-left"
                                                                v-if="goodsImageMaskAuto"
                                                            >
                                                                <el-image
                                                                    fit="cover"
                                                                    style="width: 136px;"
                                                                    :src="goodsImageMaskAuto"
                                                                    :preview-src-list="[goodsImageMaskAuto]"
                                                                >
                                                                </el-image>
                                                            </figure>
                                                        </div>
                                                        <div
                                                            class="flex justify-center items-center"
                                                            v-if="goodsImageMaskType == 'upload'"
                                                        >
                                                            <el-upload
                                                                ref="goodsImageMaskUploaderRef"
                                                                class="my-2 flex-1 w-full"
                                                                list-type="picture-card"
                                                                :auto-upload="false"
                                                                :limit="1"
                                                                action="#"
                                                                :http-request="uploadGoodsImageMaskToServer"
                                                                :on-success="onAfterUploadGoodsImageMask"
                                                                :on-exceed="onExceedGoodsImageMask"
                                                            >
                                                                <template #trigger>
                                                                    <div class="flex flex-col justify-center items-center w-full">
                                                                        <el-icon>
                                                                            <Plus />
                                                                        </el-icon>
                                                                    </div>
                                                                </template>
                                                                <el-divider border-style="dashed"></el-divider>
                                                                <el-button
                                                                    plain
                                                                    round
                                                                    @click="onUploadGoodsImageMaskToServer"
                                                                >
                                                                    上传到服务器
                                                                </el-button>
                                                                <div class="el-upload__tip">只能上传jpg/png文件</div>
                                                            </el-upload>
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="option--scene flex flex-col"
                                                        v-if="goodsImageTaskCurrentMainOption == 'scene'"
                                                    >
                                                        <el-tabs
                                                            v-model="activeNewNavTab"
                                                            class="mt-2 w-auto"
                                                        >
                                                            <el-tab-pane label="场景模板" name="stencil">
                                                                <el-form
                                                                    label-position="top"
                                                                    label-width="100px"
                                                                    :model="goodsImageBuildOptions"
                                                                >
                                                                    <el-form-item label="媒质类型">
                                                                        <div
                                                                            class="flex flex-row flex-wrap pl-6"
                                                                        >
                                                                            <el-radio-group
                                                                                v-model="goodsImageBuildOptions.medium_style"
                                                                            >
                                                                            <div
                                                                                class="flex flex-row flex-wrap"
                                                                                v-for="(stencil,index) in imageStencilMediumStyleList"
                                                                                :key="index"
                                                                            >
                                                                                <figure class="flex flex-col mr-1">
                                                                                    <el-image
                                                                                        :src="stencil.thumb_url"
                                                                                        style="width: 40px;height:40px"
                                                                                        :preview-src-list="[stencil.thumb_url]"
                                                                                        fit="cover"
                                                                                        loading="lazy"
                                                                                    ></el-image>
                                                                                    <el-radio
                                                                                        class="mt-1"
                                                                                        :label="stencil.name"
                                                                                        @change="onChangeImageStencilMediumStyle(stencil)"
                                                                                    >
                                                                                    {{  stencil.name }}
                                                                                    </el-radio>
                                                                                </figure>
                                                                            </div>
                                                                            </el-radio-group>
                                                                        </div>
                                                                    </el-form-item>
                                                                    <el-form-item label="构图类型">
                                                                        <div
                                                                            class="flex flex-row flex-wrap pl-6"
                                                                        >
                                                                            <el-radio-group
                                                                                v-model="goodsImageBuildOptions.composition_style"
                                                                            >
                                                                            <div
                                                                                class="flex flex-row flex-wrap"
                                                                                v-for="(stencil,index) in imageStencilCompositionStyleList"
                                                                                :key="index"
                                                                            >
                                                                                <figure class="flex flex-col mr-1">
                                                                                    <el-image
                                                                                        :src="stencil.thumb_url"
                                                                                        style="width: 40px;height:40px"
                                                                                        :preview-src-list="[stencil.thumb_url]"
                                                                                        fit="cover"
                                                                                        loading="lazy"
                                                                                    ></el-image>
                                                                                    <el-radio
                                                                                        class="mt-1"
                                                                                        :label="stencil.name"
                                                                                        @change="onChangeImageStencilCompositionStyle(stencil)"
                                                                                    >
                                                                                    {{  stencil.name }}
                                                                                    </el-radio>
                                                                                </figure>
                                                                            </div>
                                                                            </el-radio-group>
                                                                        </div>
                                                                    </el-form-item>
                                                                    <el-form-item label="画风类型">
                                                                        <div
                                                                            class="flex flex-row flex-wrap pl-6"
                                                                        >
                                                                            <el-radio-group
                                                                                v-model="goodsImageBuildOptions.paint_style"
                                                                            >
                                                                            <div
                                                                                class="flex flex-row flex-wrap"
                                                                                v-for="(stencil,index) in imageStencilPaintStyleList"
                                                                                :key="index"
                                                                            >
                                                                                <figure class="flex flex-col mr-1">
                                                                                    <el-image
                                                                                        :src="stencil.thumb_url"
                                                                                        style="width: 40px;height:40px"
                                                                                        :preview-src-list="[stencil.thumb_url]"
                                                                                        fit="cover"
                                                                                        loading="lazy"
                                                                                    ></el-image>
                                                                                    <el-radio
                                                                                        class="mt-1"
                                                                                        :label="stencil.name"
                                                                                        @change="onChangeImageStencilPaintStyle(stencil)"
                                                                                    >
                                                                                    {{  stencil.name }}
                                                                                    </el-radio>
                                                                                </figure>
                                                                            </div>
                                                                            </el-radio-group>
                                                                        </div>
                                                                    </el-form-item>
                                                                    <el-form-item label="场景/动作">
                                                                        <div
                                                                            class="flex flex-row flex-wrap pl-6"
                                                                        >
                                                                            <el-radio-group
                                                                                v-model="goodsImageBuildOptions.scene_style"
                                                                            >
                                                                            <div
                                                                                class="flex flex-row flex-wrap"
                                                                                v-for="(stencil,index) in imageStencilSceneStyleList"
                                                                                :key="index"
                                                                            >
                                                                                <figure class="flex flex-col mr-1">
                                                                                    <el-image
                                                                                        :src="stencil.thumb_url"
                                                                                        style="width: 40px;height:40px"
                                                                                        :preview-src-list="[stencil.thumb_url]"
                                                                                        fit="cover"
                                                                                        loading="lazy"
                                                                                    ></el-image>
                                                                                    <el-radio
                                                                                        class="mt-1"
                                                                                        :label="stencil.name"
                                                                                        @change="onChangeImageStencilSceneStyle(stencil)"
                                                                                    >
                                                                                    {{  stencil.name }}
                                                                                    </el-radio>
                                                                                </figure>
                                                                            </div>
                                                                            </el-radio-group>
                                                                        </div>
                                                                    </el-form-item>
                                                                    <el-form-item label="位置">
                                                                        <div
                                                                            class="flex flex-row flex-wrap pl-6"
                                                                        >
                                                                            <el-radio-group
                                                                                v-model="goodsImageBuildOptions.location_style"
                                                                            >
                                                                            <div
                                                                                class="flex flex-row flex-wrap"
                                                                                v-for="(stencil,index) in imageStencilLocationStyleList"
                                                                                :key="index"
                                                                            >
                                                                                <figure class="flex flex-col mr-1">
                                                                                    <el-image
                                                                                        :src="stencil.thumb_url"
                                                                                        style="width: 40px;height:40px"
                                                                                        :preview-src-list="[stencil.thumb_url]"
                                                                                        fit="cover"
                                                                                        loading="lazy"
                                                                                    ></el-image>
                                                                                    <el-radio
                                                                                        class="mt-1"
                                                                                        :label="stencil.name"
                                                                                        @change="onChangeImageStencilLocationStyle(stencil)"
                                                                                    >
                                                                                    {{  stencil.name }}
                                                                                    </el-radio>
                                                                                </figure>
                                                                            </div>
                                                                            </el-radio-group>
                                                                        </div>
                                                                    </el-form-item>
                                                                    <!-- <el-form-item label="人物类型">
                                                                        <div
                                                                            class="flex flex-row flex-wrap pl-6"
                                                                        >
                                                                            <el-radio-group
                                                                                v-model="goodsImageBuildOptions.person_style"
                                                                            >
                                                                            <div
                                                                                class="flex flex-row flex-wrap"
                                                                                v-for="(stencil,index) in imageStencilPersonStyleList"
                                                                                :key="index"
                                                                            >
                                                                                <figure class="flex flex-col mr-1">
                                                                                    <el-image
                                                                                        :src="stencil.thumb_url"
                                                                                        style="width: 40px;height:40px"
                                                                                        :preview-src-list="[stencil.thumb_url]"
                                                                                        fit="cover"
                                                                                        loading="lazy"
                                                                                    ></el-image>
                                                                                    <el-radio
                                                                                        class="mt-1"
                                                                                        :label="stencil.name"
                                                                                        @change="onChangeImageStencilPersonStyle(stencil)"
                                                                                    >
                                                                                    {{  stencil.name }}
                                                                                    </el-radio>
                                                                                </figure>
                                                                            </div>
                                                                            </el-radio-group>
                                                                        </div>
                                                                    </el-form-item> -->
                                                                    <!-- <el-form-item label="面部风格">
                                                                        <div
                                                                            class="flex flex-row flex-wrap pl-6"
                                                                        >
                                                                            <el-radio-group
                                                                                v-model="goodsImageBuildOptions.face_style"
                                                                            >
                                                                            <div
                                                                                class="flex flex-row flex-wrap"
                                                                                v-for="(stencil,index) in imageStencilFaceStyleList"
                                                                                :key="index"
                                                                            >
                                                                                <figure class="flex flex-col mr-1">
                                                                                    <el-image
                                                                                        :src="stencil.thumb_url"
                                                                                        style="width: 40px;height:40px"
                                                                                        :preview-src-list="[stencil.thumb_url]"
                                                                                        fit="cover"
                                                                                        loading="lazy"
                                                                                    ></el-image>
                                                                                    <el-radio
                                                                                        class="mt-1"
                                                                                        :label="stencil.name"
                                                                                        @change="onChangeImageStencilFaceStyle(stencil)"
                                                                                    >
                                                                                    {{  stencil.name }}
                                                                                    </el-radio>
                                                                                </figure>
                                                                            </div>
                                                                            </el-radio-group>
                                                                        </div>
                                                                    </el-form-item> -->
                                                                    <!-- <el-form-item label="标签">
                                                                    </el-form-item> -->
                                                                </el-form>
                                                            </el-tab-pane>
                                                            <el-tab-pane
                                                                label="场景/动作自定义"
                                                                name="custom"
                                                            >
                                                                <el-form
                                                                    label-position="left"
                                                                    label-width="100px"
                                                                >
                                                                    <el-form-item label="中文场景/动作说明">
                                                                        <el-input
                                                                            v-model="goodsImageBuildOptions.prompt_cn"
                                                                            type="textarea"
                                                                            :autosize="{minRows: 3, maxRows: 5}"
                                                                            placeholder="文字描述"
                                                                        ></el-input>
                                                                    </el-form-item>
                                                                    <el-form-item label="">
                                                                        <el-button
                                                                            plain
                                                                            round
                                                                            class="btn--translate"
                                                                            @click="onTranslateChinesePromptByAlibaba"
                                                                        >中译英</el-button>
                                                                    </el-form-item>                                        
                                                                    <el-form-item label="英文提示词（生成需要）">
                                                                        <el-input
                                                                            v-model="goodsImageBuildOptions.prompt_en"
                                                                            type="textarea"
                                                                            :autosize="{minRows: 3, maxRows: 5}"
                                                                            placeholder="文字描述"
                                                                        ></el-input>
                                                                    </el-form-item>                                        
                                                                </el-form>
                                                                <blockquote class="help-block mt-1">
                                                                    <el-text
                                                                        type="info"
                                                                        size="default"
                                                                    >
                                                                        {{ goodsImageBuildHelp }}
                                                                    </el-text>
                                                                </blockquote>
                                                            </el-tab-pane>
                                                        </el-tabs>
                                                    </div>
                                                    <div
                                                        class="option--number flex"
                                                        v-if="goodsImageTaskCurrentMainOption == 'number'"
                                                    >
                                                        <el-form label-position="top">
                                                            <h5
                                                                class="section-header pb-1 mb-1"
                                                            >
                                                                生成图片数量
                                                            </h5>
                                                            <el-form-item
                                                                label=""
                                                            >
                                                                <el-input-number
                                                                    :min="1"
                                                                    :max="4"
                                                                    placeholder="最多4张"
                                                                    v-model="goodsImageBuildOptions.batch_size"
                                                                ></el-input-number>
                                                            </el-form-item>
                                                        </el-form>
                                                    </div>
                                                </div>
                                            </div>
                                        </main>
                                    </div>
                                </nav>
                            </article>

                            <article
                                class="section--current-goodsimage"
                                v-if="isShowCurrentGoodsImageTask && !isCurrentGoodsImageTaskTodo"
                            >
                                <div class="top">
                                    <div class="flex flex-row items-center">
                                        <el-space :size="4">
                                            <div
                                                class="flex flex-row items-center mb-1"
                                                @click="onEditTaskName"
                                            >
                                                <el-text
                                                    class="mr-2"
                                                    type="primary"
                                                >
                                                    [{{ goodsImageTaskCurrent.task_name }}]
                                                </el-text>
                                                <el-icon
                                                    color="#9e9e9e"
                                                >
                                                    <EditPen />
                                                </el-icon>
                                            </div>
                                            <el-divider direction="vertical"></el-divider>
                                            <div
                                                class="flex flex-row items-center"
                                            >
                                                <span class="mr-1">任务ID: </span>
                                                <span>{{ goodsImageTaskCurrent.task_id }}</span>
                                            </div>
                                        </el-space>
                                        <div class="flex-1"></div>
                                        <div class="flex justify-end items-center self-end">
                                            <el-button
                                                plain
                                                round
                                                @click="onReDoingGoodsImageTask"
                                                v-if="!isCurrentGoodsImageTaskTodo"
                                            >
                                                再次执行任务
                                            </el-button>
                                            <el-divider
                                                direction="vertical"
                                                border-type="dotted"
                                            ></el-divider>

                                            <el-tooltip
                                                content="删除任务"
                                                placement="bottom"
                                            >
                                                <el-icon
                                                    @click="onDestroyGoodsImageTask"
                                                >
                                                    <DeleteFilled />
                                                </el-icon>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </div>
                                <el-divider border-style="dashed"></el-divider>
                                <div class="main mt-1 pb-6">
                                    <div class="main-header">
                                        <div class="header-left">
                                            <el-descriptions
                                                title="任务详情"
                                                direction="vertical"
                                                :column="2"
                                                size="large"
                                            >
                                                <el-descriptions-item label="创建时间">
                                                    {{  goodsImageTaskCurrent.task_date_created }}
                                                </el-descriptions-item>
                                                <el-descriptions-item label="完成时间">
                                                    {{  goodsImageTaskCurrent.task_date_done }}
                                                </el-descriptions-item>
                                                <el-descriptions-item label="任务提交最近设置">
                                                    <el-space
                                                        wrap
                                                        v-if="goodsImageTaskCurrentContextDescList"
                                                    >
                                                        <el-tag
                                                            v-for="(desc,index) in goodsImageTaskCurrentContextDescList"
                                                            :key="index"
                                                        >
                                                            {{  desc }}
                                                        </el-tag>
                                                    </el-space>
                                                </el-descriptions-item>
                                            </el-descriptions>
                                        </div>
                                    </div>
                                    <div class="main-body">
                                        <el-divider border-style="dashed"></el-divider>
                                        <el-row :gutter="4">
                                            <el-col
                                                :span="6"
                                                v-for="(image,index) in goodsImageTaskCurrentImageList"
                                                :key="index"
                                            >
                                                <figure class="task-image shadow p-1 mr-2 mb-2">
                                                    <div class="image">
                                                        <el-image
                                                            :src="image.asset_url"
                                                            style="width:128px;"
                                                            :preview-src-list="[image.asset_url]"
                                                            fit="cover"
                                                            loading="lazy"
                                                        ></el-image>
                                                    </div>
                                                    <div class="footer mt-1">
                                                        <div class="flex flex-row justify-between">
                                                            <div class="action"
                                                                @click="onCollectTaskImage(
                                                                    image,
                                                                    image.is_collected,
                                                                    image.task_id,
                                                                    image.id,
                                                                    image.task_media_site,
                                                                    image.asset_url
                                                                )"
                                                            >
                                                                <el-icon v-if="image.is_collected">
                                                                    <StarFilled size="24px"></StarFilled>
                                                                </el-icon>
                                                                <el-icon v-if="!image.is_collected">
                                                                    <Star size="16px"></Star>
                                                                </el-icon>
                                                            </div>
                                                            <div class="action"
                                                                @click="onDestroyTaskImage(image.id, image.task_id)"
                                                            >
                                                                <el-icon>
                                                                    <Delete></Delete>
                                                                </el-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </figure>
                                            </el-col>
                                        </el-row>
                                    </div>
                                </div>
                            </article>
                        </el-main>
                    </el-container>
                </el-tab-pane>
            </el-tabs>
        </div>

        <el-dialog
            v-model="isShowTaskEditDialog"
            title="任务名称编辑"
        >
            <el-form
                label-position="top"
            >
                <el-form-item label="任务名字">
                    <el-input v-model="taskNewName"></el-input>
                </el-form-item>
            </el-form>

            <template #footer>
                <el-button
                    plain
                    @click="onCancelTaskEdit"
                >
                    取消
                </el-button>
                <el-button
                    plain
                    @click="onConfirmTaskEdit"
                >
                    确定
                </el-button>
            </template>
        </el-dialog>

        <el-dialog
            v-model="isShowFinetuneDialog"
            title="精修蒙版图"
            class="dialog--finetune"
        >
            <nav class="flex items-center">
                <el-button
                    round 
                    plain
                    class="finetune-pen-switch"
                    :icon="Plus"
                    :class="{'active': finetunePenMode == 'include'}"
                    @click="onSetPickInclude"
                >
                    选择保留部分
                </el-button>
                <el-button
                    round 
                    plain
                    class="finetune-pen-switch"
                    :icon="Minus"
                    :class="{'active': finetunePenMode == 'exclude'}"
                    @click="onSetPickExclude"
                >
                    选择删除部分
                </el-button>
                <el-button
                    round 
                    plain
                    class="finetune-pen-switch"
                    :class="{'active': finetunePenMode == 'spondge'}"
                    :icon="MagicStick"
                    disabled
                    @click="onSetPickSpondge"
                >
                    手动涂抹
                </el-button>
                <el-button
                    round 
                    plain
                    class="finetune-pen-switch"
                    :class="{'active': finetunePenMode == 'spondge'}"
                    :icon="MagicStick"
                    @click="onPreviewFinetune"
                >
                    预览效果
                </el-button>
                <span class="flex-1"></span>
                <el-button
                    round 
                    plain
                    class="finetune-undo-btn"
                    :icon="RefreshLeft"
                    @click="onUndoFinetune"
                >
                    撤销
                </el-button>
                <el-button
                    round 
                    plain
                    class="finetune-reset-btn"
                    :icon="Refresh"
                    @click="onResetFinetune"
                >
                    重置精修
                </el-button>
            </nav>

            <main class="flex flex-1 mt-2">
                <div class="flex flex-1">
                    <aside 
                        class="finetune-stage flex flex-col flex-1"
                    >
                        <div
                            class="p-2 border-2 border-dashed border-indigo-600 rounded"
                        >
                            <v-stage
                                ref="finetuneStage"
                                class=""
                                :config="finetuneStageConfig"
                                @click="onPickFinetuneStage"
                            >
                                <v-layer
                                    ref="finetuneStageLayer"
                                    :config="finetuneStageLayerConfig"
                                >
                                    <v-image
                                        ref="finetuneStageLayerBgImage"
                                        :config="finetuneStageLayerBgImageConfig"
                                    >
                                    </v-image>
                                </v-layer>
                            </v-stage>
                        </div>
                    </aside>
                    <article class="finetune-preview-area flex flex-1 ml-2">
                        <div
                            class="flex-1 p-2 border-2 border-dashed border-indigo-600 rounded"
                        >
                            <div class="preview-band min-h-[60px]">
                                <h5 class="preview-band-header text-large">蒙版混合图</h5>
                                <p
                                    class="p-2 preview-band-body flex flex-row"
                                    v-if="finetunePreviewSegmentBlendedList"
                                >
                                    <el-space>
                                        <el-image
                                            :src="image"
                                            style="width: 80px;height:80px"
                                            v-for="(image,index) in finetunePreviewSegmentBlendedList"
                                            :key="index"
                                        ></el-image>
                                    </el-space>
                                </p>
                            </div>
                            <div class="preview-band min-h-[60px]">
                                <h5 class="text-lg font-bold preview-band-header">蒙版</h5>
                                <p
                                    class="p-2 preview-band-body flex flex-row"
                                    v-if="finetunePreviewSegmentMaskedList"
                                >
                                    <el-space>
                                        <el-image
                                            :src="image"
                                            style="width: 80px;height:80px"
                                            v-for="(image,index) in finetunePreviewSegmentMaskedList"
                                            :key="index"
                                        ></el-image>

                                    </el-space>
                                </p>
                            </div>
                            <div class="preview-band min-h-[60px]">
                                <h5 class="preview-band-header">蒙版对应图像</h5>
                                <p
                                    class="p-2 preview-band-body flex flex-row"
                                    v-if="finetunePreviewSegmentMaskList"
                                >
                                    <el-space>
                                        <el-image
                                            :src="image"
                                            style="width: 80px;height:80px"
                                            v-for="(image,index) in finetunePreviewSegmentMaskList"
                                            :key="index"
                                        ></el-image>
                                    </el-space>
                                </p>
                            </div>
                        </div>
                    </article>
                </div>
            </main>

            <template #footer>
                <el-button
                    plain
                    @click="onCancelFinetune"
                >
                    取消
                </el-button>
                <el-button
                    plain
                    @click="onConfirmFinetune"
                >
                    确定
                </el-button>
            </template>
        </el-dialog>

    </div>
</template>

<script setup>
    import { ref, reactive, nextTick, onMounted,onBeforeUnmount,getCurrentInstance} from 'vue'
    import { useRoute } from "vue-router";
    import { ElMessage, ElMessageBox, genFileId } from 'element-plus'
    // import Konva from 'konva'
    import { 
        getUserCollectList,createUserCollect,destroyUserCollect,
        getUserImageTaskList, 
        createImageTask, retriveImageTask, updateImageTask, destroyImageTask, queryImageTask,
        submitImageTask, getImageTaskProgress, getImageTaskResult, 
        getUserImageRefList, uploadGoodsImageRef,destroyGoodsImageRef,
        destroyGoodsImage,
        getGoodsImageMaskAuto, uploadGoodsImageMask, 
        getUserImagePretrainModelList, getImageStencilCategoryList, getImageStencilList,
        sendSegmentReq,
        translateByTencent, translateByAzure, translateByAlibaba } from '@/api/api'
    import {hasPermission,getTableHeight} from "@/utils/util";
    import { MEDIA_SITE_ROOT } from '@/config'
    
    const { proxy } = getCurrentInstance()

    const route = useRoute();

    let isFull = ref(false)

    function setFull(){
        isFull.value=!isFull.value
        window.dispatchEvent(new Event('resize'))
    }

    let scrowHight = ref(0)

    let activeTopNavTab = ref("goods")
    let activeMainNavTab = ref("collect")
    let activeNewNavTab = ref("stencil")
    let editableTopNavTabs = ref([])

    let userTaskList = ref([
    ])
    let userTaskListCurrentPage = ref(1)
    let userTaskListScrollHeight = ref('100%')
    let hasMoreUserTaskList = ref(false)

    let userCollectList = ref([])
    let userCollectListCurrentPage = ref([])
    let hasMoreUserCollectList = ref(false)
    let galleryList = ref([])

    let isShowGoodsImageMain = ref(true)
    let isCurrentGoodsImageTaskTodo = ref(true)
    let isShowCurrentGoodsImageTask = ref(false)
    let isShowUploadRef = ref(false)
    let isShowUploadMask = ref(false)

    let taskNewName = ref('')
    let isShowTaskEditDialog = ref(false)
    let isShowFinetuneDialog = ref(false)

    let pretrainModelType = ref('upload')
    let pretrainModelCurrentThumbUrl = ref('')
    let pretrainModelCurrentName = ref('')
    let pretrainModelCurrentId = ref('')

    let imagePretrainModelList = ref([])

    let imageStencilMediumStyleList = ref([])
    let imageStencilPaintStyleList = ref([])
    let imageStencilCompositionStyleList = ref([])
    let imageStencilSceneStyleList = ref([])
    let imageStencilLocationStyleList = ref([])
    let imageStencilPersonStyleList = ref([])
    let imageStencilFaceStyleList = ref([])
    let imageStencilTagList = ref([])

    let goodsImageTaskCurrentMainOption = ref('mask')
    let goodsImageBuildTaskResult = ref([])
    let goodsImageTaskCurrent = ref()
    let goodsImageTaskCurrentId = ref()
    let goodsImageTaskCurrentContextDescList = ref([])
    let goodsImageTaskCurrentImageList = ref([])

    let goodsImageRefType = ref('upload')
    let goodsImageRefCurrent = ref('')
    let goodsImageRefGalleryCurrent = ref('')
    let goodsImageRefGalleryCurrentId = ref('')
    let goodsImageRefList = ref([])
    let goodsImageRefUploaded = ref('')
    let goodsImageRefUploaderRef = ref()

    let goodsImageMaskType = ref('auto')
    let goodsImageMaskAuto = ref()
    let goodsImageMaskAutoGetType = ref('extract')
    let goodsImageMaskIsNeedFinetune = ref(true)
    let goodsImageMaskUploaded = ref()
    let goodsImageMaskUploaderRef = ref()

    let finetuneStageContainer = ref()
    let finetuneStage = ref()
    let finetuneStageConfig= ref({
        width: 300,
        height: 300,
    })
    let finetuneStageLayer = ref()
    let finetuneStageLayerConfig= ref({
    })
    let finetuneStageLayerBgImage = ref()
    let finetuneStageLayerBgImageConfig= ref({
        image: '',
        x: 0,
        y: 0,
        width: 300,
        height: 300,
    })

    let finetuneExpectedContainer = ref()
    let finetuneExpected = ref()
    let finetuneExpectedConfig= ref({
        width: 300,
        height: 300,
    })
    let finetuneExpectedLayerConfig= ref({
    })
    let finetuneExpectedImage= ref()
    let finetuneExpectedImageConfig= ref({
    })
    let finetuneExpectedInfo = ref('')
    let finetuneExpectedResult = ref('')

    let finetunePenMode = ref('include')
    let finetunePenMudgeWidth = ref(4)
    let finetunePositivePoints = ref([])
    let finetuneNegativePoints = ref([])

    let goodsImageFinetuneType = ref('auto-extract')
    let goodsImageFinetune = ref('')
    let goodsImageFinetuneWidth = ref(256)
    let goodsImageFinetuneHeight = ref(256)
    let goodsImageFinetuneWidthOrig = ref(256)
    let goodsImageFinetuneHeightOrig = ref(256)
    let goodsImageFinetuneScale = ref()
    let goodsImageFinetunePreview = ref('')

    let goodsImageBuildOptions = reactive({
        pretrain_model_id: '',
        pretrain_model_name: '',
        pretrain_model_thumb_url: '',
        prompt_cn: '',
        prompt_cn_negative: '',
        prompt_en: '',
        prompt_en_negative: '',
        batch_size: 4,
        medium_style: '',
        medium_style_id: '',
        paint_style: '',
        paint_style_id: '',
        scene_style: '',
        scene_style_id: '',
        location_style: '',
        location_style_id: '',
        composition_style: '',
        composition_style_id: '',
        person_style: '',
        person_style_id: '',
        face_style: '',
        face_style_id: '',
        tags_style: '',
        tags_style_id: '',
    })
    let goodsImageBuildHelp = ref(
        `
        描述你想要生成的图片内容，支持中英文，但是最终都要生成英文才能提交。
        `
    )

    let goodsImageMaskProgressing = ref(false)

    let goodsImageTaskProgressing = ref(false)
    let goodsImageTaskProgressValue = ref(0)
    let goodsImageTaskProgressInfo = ref('')
    let hasImageTaskProgressPreview = ref(false)
    let goodsImageTaskImagesPreview = ref([])
    let goodsImageTaskGeneratedImages = ref([])
    let goodsImageTaskTimerId = ref(-1)

    let goodsImageRefCanvas = ref()
    let goodsImageMaskCanvas = ref()

    let goodsImageRefStageConfig= ref({
        width: 300,
        height: 300,
    })
    
    let goodsImageRefLayerConfig= ref()
    let goodsImageMaskLayerConfig= ref()
    let goodsImageMaskImageConfig= ref({
        image: ''
    })

    const app = new App();

    function getTopNavTabs() {
        // platformsettingsSysconfig({limit:999,parent__isnull:true}).then(res=>{
        //     if(res.code == 2000){
        //         editableTopNavTabs.value = res.data.data
        //     }
        // })
        editableTopNavTabs.value = [
            {
                title: '训练',
                key: 'goods',
            },
        ]
    }
    function handleResize(){
        nextTick(() => {
            let tabSelectHeight = proxy.$refs.tableSelect?proxy.$refs.tableSelect.offsetHeight:0
            tabSelectHeight = isFull.value?tabSelectHeight + 90 :tabSelectHeight + 200
            scrowHight.value = getTableHeight(tabSelectHeight,false)
        })
    }

    onMounted(() => {
        getTopNavTabs()
        handleResize()
        window.addEventListener('resize',handleResize)
        getUserTasks()
        getUserCollects(1)
        getUserGoodsImageRefs()
        getUserImagePretainModels()
        getImageStencils('medium')
        getImageStencils('composition')
        getImageStencils('paint')
        getImageStencils('scene')
        getImageStencils('location')
        getImageStencils('person')
        getImageStencils('face')
        // getImageStencils('tag')

        // if (finetuneStageContainer.value) {
        //     app.mount(finetuneStageContainer.value);
        // }
    })
    onBeforeUnmount(()=>{
        window.removeEventListener('resize',handleResize)
    })

    defineExpose({
        setFull
    })

    function getUserCollects(page=userCollectListCurrentPage.value) {
        let params = {
            page: page,
        }
        getUserCollectList(params).then((res) => {
            let remList = res.data.data
            remList.forEach((el) => {
                el.is_collected = true
                el.task_id = el.task
            })
            let hasNext = res.data.has_next
            if (hasNext) {
                hasMoreUserCollectList.value = true
            } else {
                hasMoreUserCollectList.value = false
            }
            if (page == 1) {
                userCollectList.value = remList
            } else {
                userCollectList.value = userCollectList.value.concat(remList)
            }
            console.log(userCollectList)
        }).catch((err) => {
            console.log(err)
        })
    }

    function onMoreUserCollects() {
        userCollectListCurrentPage.value = parseInt(userCollectListCurrentPage.value) + 1
        getUserCollects(userCollectListCurrentPage.value)
    }

    function getTaskContextDescList(context) {
        let result = []
        if (context?.goodsImageBuildOptions?.pretrain_model_name) {
            result.push('预训练模型:' + context?.goodsImageBuildOptions?.pretrain_model_name)
        }
        if (context?.goodsImageBuildOptions?.prompt_cn) {
            result.push('中文提示词:' + context?.goodsImageBuildOptions?.prompt_cn)
        }
        if (context?.goodsImageBuildOptions?.prompt_en) {
            result.push('英文提示词:' + context?.goodsImageBuildOptions?.prompt_en)
        }
        if (context?.goodsImageBuildOptions?.medium_style) {
            result.push('媒质类型:' + context?.goodsImageBuildOptions?.medium_style)
        }
        if (context?.goodsImageBuildOptions?.composition_style) {
            result.push('构图类型:' + context?.goodsImageBuildOptions?.composition_style)
        }
        if (context?.goodsImageBuildOptions?.paint_style) {
            result.push('画风类型:' + context?.goodsImageBuildOptions?.paint_style)
        }
        if (context?.goodsImageBuildOptions?.scene_style) {
            result.push('动作/场景类型:' + context?.goodsImageBuildOptions?.scene_style)
        }
        if (context?.goodsImageBuildOptions?.location_style) {
            result.push('位置类型:' + context?.goodsImageBuildOptions?.location_style)
        }
        // if (context?.goodsImageBuildOptions?.person_style) {
        //     result.push('人物类型:' + context?.goodsImageBuildOptions?.person_style)
        // }
        // if (context?.goodsImageBuildOptions?.face_style_id) {
        //     result.push('面部类型:' + context?.goodsImageBuildOptions?.face_style_id)
        // }
        if (context?.goodsImageBuildOptions?.tags_style) {
            result.push('标签:' + context?.goodsImageBuildOptions?.tags_style)
        }
        return result
    }

    function reformatTask(dataOrig) {
        let result = {}
        let thumb_url = ''
        let taskImages = []
        let taskCurrentResult = []
        let taskContext = {}

        if (dataOrig?.assets && dataOrig?.assets.length > 0) {
            thumb_url = dataOrig.task_media_site + '' + dataOrig?.assets[0]?.asset_url
        }
        if (dataOrig) {
            let name = '任务-'+dataOrig.id
            if (dataOrig.task_name) {
                name = dataOrig.task_name
            }
            if (dataOrig.assets) {
                dataOrig.assets.forEach((el) => {
                    if (el.asset_url) {
                        el.asset_url = dataOrig.task_media_site + '' + el.asset_url
                        taskImages.push({
                            'id': el.id, 
                            'asset_url': el.asset_url, 
                            'is_collected': el.is_collected,
                            'task_id': dataOrig.id,
                            'task_media_site': dataOrig.task_media_site,
                            'asset_url': el.asset_url,
                        })
                    } else if (el?.fields?.asset_url) {
                        el.fields.asset_url = dataOrig.task_media_site + '' + el.fields.asset_url
                        taskImages.push({
                            'id': el.fields.id, 
                            'asset_url': el.fields.asset_url, 
                            'is_collected': el.fields.is_collected,
                            'task_id': dataOrig.id,
                            'task_media_site': dataOrig.task_media_site,
                            'asset_url': el.fiels.asset_url,
                        })
                    }
                })
            }
            if (dataOrig.task_context) {
                if (typeof(dataOrig.task_context) == 'string') {
                    taskContext = JSON.parse(dataOrig.task_context)
                } else {
                    taskContext = dataOrig.task_context
                }
            }
            if (dataOrig.task_result) {
                if (typeof(dataOrig.task_context) == 'string') {
                    taskCurrentResult = JSON.parse(dataOrig.task_result)
                } else {
                    taskCurrentResult = dataOrig.task_result
                }
            }
            result = {
                id: dataOrig.id,
                name: name,
                thumb_url: thumb_url,
                task_id: dataOrig.task_id,
                task_name: dataOrig.task_name,
                status: dataOrig.status,
                status_text: getStatusText(dataOrig.status),
                task_status: dataOrig.task_status,
                task_context: taskContext,
                task_date_created: dataOrig.task_date_created,
                task_date_done: dataOrig.task_date_done,
                task_args: dataOrig.task_args,
                task_successful: dataOrig.task_successful,
                task_media_site: dataOrig.task_media_site,
                task_images: taskImages,
                task_result: taskCurrentResult,
            }
        }
        return result
    }

    function getUserTasks(page = userTaskListCurrentPage.value, currentTaskId = null, currentTask = null) {
        let params = {
            page: page,
        }
        getUserImageTaskList(params).then((res) => {
            let remList = res.data.data
            let hasNext = res.data.has_next
            if (hasNext) {
                hasMoreUserTaskList.value = true
            } else {
                hasMoreUserTaskList.value = false
            }
            let transList = remList.map((el) => {
                return reformatTask(el)
            })
            if (page == 1) {
                userTaskList.value = transList
            } else {
                userTaskList.value = userTaskList.value.concat(transList)
            }
            if (currentTaskId && currentTask) {
                onShowGoodsImageCurrentTask(currentTaskId, currentTask)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    function onMoreUserTaskList() {
        userTaskListCurrentPage.value = parseInt(userTaskListCurrentPage.value) + 1
        let page = userTaskListCurrentPage.value
        getUserTasks(page)
    }

    function onChangeGoodsImageRefGallery(values) {
        goodsImageRefCurrent.value = values.url
        goodsImageRefGalleryCurrent.value = values.url
        goodsImageRefGalleryCurrentId.value = values.id
        goodsImageFinetuneType.value = 'ref'
    }

    function onProcessGoodsImageRefActions(command) {
        console.log(command)
        if (command == 'drop') {
            ElMessageBox.confirm('确认要删除这项参考图吗？', '提示',{}).then(() => {
                let params = {
                    id: goodsImageRefGalleryCurrentId.value
                }
                destroyGoodsImageRef(params).then((res) => {
                    getUserGoodsImageRefs()
                }).catch((err) => {
                    console.log(err)
                })
            }).catch(() => {

            })
        }
    }

    function getUserGoodsImageRefs() {
        let params = {
            page: 1,
        }
        getUserImageRefList(params).then((res) => {
            goodsImageRefList.value = res.data.data
        }).catch((err) => {
            console.log(err)
        })
    }

    function onChangePretrainModel(values) {
        pretrainModelCurrentThumbUrl.value = values.thumb_url
        pretrainModelCurrentName.value = values.name
        pretrainModelCurrentId.value = values.id
        goodsImageBuildOptions.pretrain_model_id = values.id
        goodsImageBuildOptions.pretrain_model_name = values.name
        goodsImageBuildOptions.pretrain_model_thumb_url = values.thumb_url
    }

    function getUserImagePretainModels() {
        let params = {
            page: 1,
        }
        getUserImagePretrainModelList(params).then((res) => {
            imagePretrainModelList.value = res.data.data
        }).catch((err) => {
            console.log(err)
        })
    }

    function getImageStencils(styleName) {
        let params = {
            page: 1,
        }
        if (styleName == 'medium') {
            params['stencil_category_id'] = 1
            getImageStencilList(params).then((res) => {
                imageStencilMediumStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'composition') {
            params['stencil_category_id'] = 2
            getImageStencilList(params).then((res) => {
                imageStencilCompositionStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'paint') {
            params['stencil_category_id'] = 3
            getImageStencilList(params).then((res) => {
                imageStencilPaintStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'scene') {
            params['stencil_category_id'] = 4
            getImageStencilList(params).then((res) => {
                imageStencilSceneStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'location') {
            params['stencil_category_id'] = 5
            getImageStencilList(params).then((res) => {
                imageStencilLocationStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'person') {
            params['stencil_category_id'] = 8
            getImageStencilList(params).then((res) => {
                imageStencilPersonStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'face') {
            params['stencil_category_id'] = 9
            getImageStencilList(params).then((res) => {
                imageStencilFaceStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'tag') {
            params['stencil_category_id'] = 10
            getImageStencilList(params).then((res) => {
                imageStencilTagList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        }
    }

    function onShowGoodsImageMain() {
        isShowGoodsImageMain.value = true
        isShowCurrentGoodsImageTask.value = false
        isCurrentGoodsImageTaskTodo.value = false
        userCollectListCurrentPage.value = 1
        getUserCollects(userCollectListCurrentPage.value)
    }

    function onNewGoodsImageTask() {
        isShowGoodsImageMain.value = false
        isShowCurrentGoodsImageTask.value = true
        isCurrentGoodsImageTaskTodo.value = true

        goodsImageTaskProgressing.value = false
        hasImageTaskProgressPreview.value = false
        goodsImageTaskImagesPreview.value = []

        goodsImageBuildOptions.pretrain_model_id = ''
        goodsImageBuildOptions.pretrain_model_name = ''
        goodsImageBuildOptions.pretrain_model_thumb_url = ''
        goodsImageBuildOptions.prompt_cn = ''
        goodsImageBuildOptions.prompt_cn_negative = ''
        goodsImageBuildOptions.prompt_en = ''
        goodsImageBuildOptions.prompt_en_negative = ''
        goodsImageBuildOptions.batch_size = 4
        goodsImageBuildOptions.medium_style = ''
        goodsImageBuildOptions.medium_style_id = ''
        goodsImageBuildOptions.paint_style = ''
        goodsImageBuildOptions.paint_style_id = ''
        goodsImageBuildOptions.scene_style = ''
        goodsImageBuildOptions.scene_style_id = ''
        goodsImageBuildOptions.location_style = ''
        goodsImageBuildOptions.location_style_id = ''
        goodsImageBuildOptions.composition_style = ''
        goodsImageBuildOptions.composition_style_id = ''
        goodsImageBuildOptions.person_style = ''
        goodsImageBuildOptions.person_style_id = ''
        goodsImageBuildOptions.face_style = ''
        goodsImageBuildOptions.face_style_id = ''
        goodsImageBuildOptions.tag_style = ''
        goodsImageBuildOptions.tag_style_id = ''

        createImageTask().then((res) => {
            let newTaskId = res.data.data.id
            let newTask = res.data.data
            getUserTasks(1, newTaskId, newTask)
        }).catch((err) => {
            console.log(err)
        })
    }

    function onShowGoodsImageCurrentTask(taskId, task) {
        goodsImageTaskCurrentId.value = taskId
        goodsImageTaskCurrent.value = task
        if (task.task_images) {
            goodsImageTaskCurrentImageList.value = task.task_images
        }
        goodsImageTaskGeneratedImages.value = task.task_result
        
        if (task.task_context) {
            let ctxPretrainModelCurrentThumbUrl = ''
            let ctxPretrainModelCurrentName = ''
            let ctxPretrainModelCurrentId = ''
            let ctxPretrainModelCurrent = {}
            let ctxGoodsImageBuildOptions = {}
            let context = task.task_context
            if (typeof(context) == 'string') {
                context = JSON.parse(context)
            }
            ctxPretrainModelCurrentThumbUrl = context.goodsImageBuildOptions.pretrain_model_thumb_url
            ctxPretrainModelCurrentName = context.goodsImageBuildOptions.pretrain_model_name
            ctxPretrainModelCurrentId = context.goodsImageBuildOptions.pretrain_model_id
            ctxGoodsImageBuildOptions = context.goodsImageBuildOptions

            pretrainModelCurrentThumbUrl.value = ctxPretrainModelCurrentThumbUrl
            pretrainModelCurrentName.value = ctxPretrainModelCurrentName
            pretrainModelCurrentId.value = ctxPretrainModelCurrentId
            goodsImageBuildOptions.pretrain_model_id = ctxGoodsImageBuildOptions.pretrain_model_id
            goodsImageBuildOptions.pretrain_model_name = ctxGoodsImageBuildOptions.pretrain_model_name
            goodsImageBuildOptions.pretrain_model_thumb_url = ctxGoodsImageBuildOptions.pretrain_model_thumb_url
            goodsImageBuildOptions.batch_size = ctxGoodsImageBuildOptions.batch_size
            goodsImageBuildOptions.prompt_cn = ctxGoodsImageBuildOptions.prompt_cn
            goodsImageBuildOptions.prompt_en = ctxGoodsImageBuildOptions.prompt_en
            goodsImageBuildOptions.prompt_en_negative = ctxGoodsImageBuildOptions.prompt_en_negative
            goodsImageBuildOptions.medium_style = ctxGoodsImageBuildOptions.medium_style
            goodsImageBuildOptions.medium_style_id = ctxGoodsImageBuildOptions.medium_style_id
            goodsImageBuildOptions.composition_style = ctxGoodsImageBuildOptions.composition_style
            goodsImageBuildOptions.composition_style_id = ctxGoodsImageBuildOptions.composition_style_id
            goodsImageBuildOptions.paint_style = ctxGoodsImageBuildOptions.paint_style
            goodsImageBuildOptions.paint_style_id = ctxGoodsImageBuildOptions.paint_style_id
            goodsImageBuildOptions.scene_style = ctxGoodsImageBuildOptions.scene_style
            goodsImageBuildOptions.scene_style_id = ctxGoodsImageBuildOptions.scene_style_id
            goodsImageBuildOptions.person_style = ctxGoodsImageBuildOptions.person_style
            goodsImageBuildOptions.person_style_id = ctxGoodsImageBuildOptions.person_style_id
            goodsImageBuildOptions.face_style = ctxGoodsImageBuildOptions.face_style
            goodsImageBuildOptions.face_style_id = ctxGoodsImageBuildOptions.face_style_id
            goodsImageBuildOptions.tags_style = ctxGoodsImageBuildOptions.tags_style
            goodsImageBuildOptions.tags_style_id = ctxGoodsImageBuildOptions.tags_style_id

            goodsImageTaskCurrentContextDescList.value = getTaskContextDescList(task.task_context)
        } else {
            pretrainModelCurrentThumbUrl.value = ''
            pretrainModelCurrentName.value = ''
            pretrainModelCurrentId.value = ''

            goodsImageBuildOptions.pretrain_model_id = ''
            goodsImageBuildOptions.pretrain_model_name = ''
            goodsImageBuildOptions.pretrain_model_thumb_url = ''
            goodsImageBuildOptions.batch_size = 4
            goodsImageBuildOptions.prompt_cn = ''
            goodsImageBuildOptions.prompt_en = ''
            goodsImageBuildOptions.prompt_en_negative = ''
            goodsImageBuildOptions.medium_style = ''
            goodsImageBuildOptions.medium_style_id = ''
            goodsImageBuildOptions.composition_style = ''
            goodsImageBuildOptions.composition_style_id = ''
            goodsImageBuildOptions.paint_style = ''
            goodsImageBuildOptions.paint_style_id = ''
            goodsImageBuildOptions.scene_style = ''
            goodsImageBuildOptions.scene_style_id = ''
            goodsImageBuildOptions.person_style = ''
            goodsImageBuildOptions.person_style_id = ''
            goodsImageBuildOptions.face_style = ''
            goodsImageBuildOptions.face_style_id = ''
            goodsImageBuildOptions.tags_style = ''
            goodsImageBuildOptions.tags_style_id = ''

        }

        isShowGoodsImageMain.value = false
        isShowCurrentGoodsImageTask.value = true
        isCurrentGoodsImageTaskTodo.value = task.task_successful != 1
    }

    function onSwitchMainOption2Mask() {
        goodsImageTaskCurrentMainOption.value = 'mask'
    }
    function onSwitchMainOption2Model() {
        goodsImageTaskCurrentMainOption.value = 'model'
    }
    function onSwitchMainOption2Scene() {
        goodsImageTaskCurrentMainOption.value = 'scene'
    }
    function onSwitchMainOption2Number() {
        goodsImageTaskCurrentMainOption.value = 'number'
    }

    function onTranslateChinesePromptByTencent() {
        let params = {
            text: goodsImageBuildOptions.prompt,
            source: 'zh',
            to: 'en',
        }
        translateByTencent(params).then((res) => {
            let translatedText = res.data.data[0]
            goodsImageBuildOptions.prompt_en = translatedText
        }).catch((err) => {
            console.log(err)
        })
    }

    function onTranslateChinesePromptByAzure() {
        let params = {
            text: goodsImageBuildOptions.prompt,
            source: 'zh',
            to: 'en',
        }
        translateByAzure(params).then((res) => {
            let translatedText = res.data.data[0]
            goodsImageBuildOptions.prompt_en = translatedText
        }).catch((err) => {
            console.log(err)
        })
    }

    function onTranslateChinesePromptByAlibaba() {
        let params = {
            text: goodsImageBuildOptions.prompt_cn,
            source: 'zh',
            to: 'en',
        }
        translateByAlibaba(params).then((res) => {
            let translatedText = res.data.data[0]
            goodsImageBuildOptions.prompt_en = translatedText
        }).catch((err) => {
            console.log(err)
        })
    }

    function onSubmitGoodsImageTask(type) {
        let task_id = ''
        let pretrain_model_id = ''
        let pretrain_model_name = ''
        let pretrain_model_thumb_url = ''
        let prompt_cn = ''
        let prompt_cn_negative = ''
        let prompt_en = ''
        let prompt_en_negative = ''
        let generat_number = 1
        let stencil_ids = ''
        let medium_style = ''
        let medium_style_id = ''
        let composition_style = ''
        let composition_style_id = ''
        let paint_style = ''
        let paint_style_id = ''
        let scene_style = ''
        let scene_style_id = ''
        let location_style = ''
        let location_style_id = ''
        let person_style = ''
        let person_style_id = ''
        let face_style = ''
        let face_style_id = ''
        let tags_style = ''
        let tags_style_id = ''
        let image_ref = ''
        let image_mask = ''
        let image_mask_type = ''
        let image_build_type = ''

        let checkItems = {
            'prompt_en': '英文提示词还没有设置',
            'medium_style_id': '媒质类型还没有设置',
            'composition_style_id': '构图类型还没有设置',
            'paint_style_id': '画风类型还没有设置',
            'scene_style_id': '场景/动作类型还没有设置',
            'location_style_id': '位置类型还没有设置',
            // 'person_style_id': '人物类型还没有设置',
            // 'face_style_id': '面部类型还没有设置',
            // 'tags_style_id': '标签还没有设置',
        }
        let invalidItems = []

        if (!goodsImageBuildOptions['pretrain_model_id']) {
            let checkResultMessage = '预训练模型还没有选择'
            ElMessageBox.alert(checkResultMessage, '提示', {
                callback: () => {
                    return
                }
            })
        } else {
            for (let itemKey in checkItems) {
                if (!goodsImageBuildOptions[itemKey]) {
                    invalidItems.push(
                        checkItems[itemKey]
                    )
                }
            }
            // if (invalidItems.length == checkItems.keys.length) {
            if (invalidItems.length == 6) {
                let checkResultMessage = '[' + invalidItems.join(',\n') + '];几项中需要选择其中一项!'
                ElMessageBox.alert(checkResultMessage, '提示', {
                    callback: () => {
                        return
                    }
                })
            } else {
                pretrain_model_id = goodsImageBuildOptions.pretrain_model_id
                pretrain_model_name = goodsImageBuildOptions.pretrain_model_name
                pretrain_model_thumb_url = goodsImageBuildOptions.pretrain_model_thumb_url
                prompt_cn = goodsImageBuildOptions.prompt_cn
                prompt_cn_negative = goodsImageBuildOptions.prompt_cn_negative
                prompt_en = goodsImageBuildOptions.prompt_en
                prompt_en_negative = goodsImageBuildOptions.prompt_en_negative
                generat_number = goodsImageBuildOptions.batch_size
                medium_style_id = goodsImageBuildOptions.medium_style_id
                medium_style = goodsImageBuildOptions.medium_style
                composition_style_id = goodsImageBuildOptions.composition_style_id
                composition_style = goodsImageBuildOptions.composition_style
                paint_style_id = goodsImageBuildOptions.paint_style_id
                paint_style = goodsImageBuildOptions.paint_style
                scene_style_id = goodsImageBuildOptions.scene_style_id
                scene_style = goodsImageBuildOptions.scene_style
                location_style_id = goodsImageBuildOptions.location_style_id
                location_style = goodsImageBuildOptions.location_style
                person_style_id = goodsImageBuildOptions.person_style_id
                person_style = goodsImageBuildOptions.person_style
                face_style_id = goodsImageBuildOptions.face_style_id
                face_style = goodsImageBuildOptions.face_style
                image_build_type = type
                stencil_ids = medium_style_id + ',' + composition_style_id + ',' + paint_style_id + ',' + scene_style_id + ',' + location_style_id + ',' + person_style_id + ',' + face_style_id

                task_id = goodsImageTaskCurrentId.value
                let params = {
                    task_id: task_id,
                    pretrain_model_id: pretrain_model_id,
                    pretrain_model_name: pretrain_model_name,
                    pretrain_model_thumb_url: pretrain_model_thumb_url,
                    medium_style_id: medium_style_id,
                    medium_style: medium_style,
                    composition_style_id: composition_style_id,
                    composition_style: composition_style,
                    paint_style_id: paint_style_id,
                    paint_style: paint_style,
                    scene_style_id: scene_style_id,
                    scene_style: scene_style,
                    location_style_id: location_style_id,
                    location_style: location_style,
                    person_style_id: person_style_id,
                    person_style: person_style,
                    face_style_id: face_style_id,
                    face_style: face_style,
                    tags_style_id: tags_style_id,
                    tags_style: tags_style,
                    stencil_ids: stencil_ids,
                    batch_size: generat_number,
                    prompt_cn: prompt_cn,
                    prompt_cn_negative: prompt_cn_negative,
                    prompt_en: prompt_en,
                    prompt_en_negative: prompt_en_negative,
                    image_build_type: image_build_type,
                }


                hasImageTaskProgressPreview.value = false
                goodsImageTaskImagesPreview.value = []
                goodsImageTaskProgressing.value = false
                goodsImageTaskProgressValue.value = 0
                goodsImageTaskProgressInfo.value = ''
                goodsImageTaskGeneratedImages.value = []
                goodsImageBuildTaskResult.value = []

                submitImageTask(params).then((res) => {
                    goodsImageTaskProgressing.value = true
                    goodsImageTaskGeneratedImages.value = []
                    let task_id = res.data.data.id
                    let task_task_id = res.data.data.task_id
                    let task_successful = res.data.data.task_successful
                    let task_current_media_site = res.data.data.task_media_site
                    localStorage.setItem('current_task_id', task_id)
                    localStorage.setItem('current_task_task_id', task_task_id)
                    if (task_successful != 1) {
                        goodsImageTaskTimerId.value = setInterval(() => {
                            let current_task_id = localStorage.getItem('current_task_id')
                            let taskParams = {
                                task_id: current_task_id,
                            }
                            getImageTaskProgress(taskParams).then((progressRes) => {   
                                task_current_media_site = progressRes.data.data.progress_current_media_site
                                // let progress_current_id = progressRes.data.data.progress_current_id
                                // let progress_current_status = progressRes.data.data.progress_current_status
                                let progress_current_successful = progressRes.data.data.progress_current_successful
                                let progress_current_result = progressRes.data.data.progress_current_result
                                let progress_current_value = progressRes.data.data.progress_current_value
                                let progress_current_info = progressRes.data.data.progress_current_info
                                let progress_current_images = progressRes.data.data.progress_current_images

                                goodsImageTaskProgressInfo.value = progress_current_info
                                goodsImageTaskProgressValue.value = progress_current_value
                                if (progress_current_images.length > 0) {
                                    hasImageTaskProgressPreview.value = true
                                }
                                goodsImageTaskImagesPreview.value = progress_current_images

                                if (progress_current_successful) {
                                    goodsImageTaskProgressing.value =false
                                    let current_task_task_id = localStorage.getItem('current_task_task_id')
                                    let taskParams = {
                                        task_id: current_task_task_id
                                    }
                                    getImageTaskResult(taskParams).then((res) => {
                                        let task_images_result = res.data.data.images
                                        goodsImageTaskProgressing.value = false
                                        goodsImageTaskGeneratedImages.value = task_images_result
                                    }).catch((err) => {
                                        console.log(err)
                                    })
                                    clearInterval(goodsImageTaskTimerId.value)
                                } else {
                                    goodsImageTaskGeneratedImages.value = progress_current_images
                                }
                            }).catch((err) => {
                                console.log(err)
                            })
                        }, 1000)
                    } else {
                        goodsImageTaskProgressing.value =false
                        let current_task_id = localStorage.getItem('current_task_id')
                        let taskParams = {
                            id: current_task_id
                        }
                        getImageTaskResult(taskParams).then((res) => {
                            let task_result = res.data.data.result
                            let taskImageUrls = task_result.image_urls
                            let taskImageRealUrls = []
                            let media_site_base_url = task_current_media_site
                            taskImageUrls.forEach((el) => {
                                taskImageRealUrls.push(media_site_base_url + el)
                            })
                            goodsImageTaskGeneratedImages.value = taskImageRealUrls
                        }).catch((err) => {
                            console.log(err)
                        })
                    }
                }).catch((err) => {
                    console.log(err)
                })
            }
        }
    }

    function onUpdateGoodsImageTask() {
        let currentContext = {
            pretrainModelCurrentThumbUrl: pretrainModelCurrentThumbUrl.value,
            goodsImageBuildOptions: goodsImageBuildOptions,
        }
        let params = {
            id: goodsImageTaskCurrentId.value,
            context: currentContext,
        }
        updateImageTask(params).then(() => {
            ElMessage('保存完成')
        }).catch((err) => {
            console.log(err)
        })
    }

    function onQueryGoodsImageTask() {
        let params = {
            task_id: goodsImageTaskCurrent.value['task_id']
        }
        let images = []
        goodsImageBuildTaskResult.value = []
        queryImageTask(params).then((res) => {
            images = res.data.data.images
            goodsImageBuildTaskResult.value = images
        }).catch((err) => {
            console.log(err)
        })
    }

    function onDoneGoodsImageTask() {
        let params = {
            id: goodsImageTaskCurrentId.value,
            status: 'SUCCESS',
            successful: 1,
        }
        updateImageTask(params).then((res) => {
            isCurrentGoodsImageTaskTodo.value = false
            retriveImageTask(params).then((res) => {
                goodsImageTaskCurrent.value = reformatTask(res.data.data)
                nextTick(() => {
                    let newUserTaskList = []
                    userTaskList.value.forEach((el) => {
                        if (el.id == goodsImageTaskCurrentId.value) {
                            el = goodsImageTaskCurrent.value
                        }
                        newUserTaskList.push(el)
                    })
                    userTaskList.value = newUserTaskList
                    onShowGoodsImageCurrentTask(
                        goodsImageTaskCurrentId.value,
                        goodsImageTaskCurrent.value
                    )
                })
            }).catch((err) => {
                console.log(err)
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    function onReDoingGoodsImageTask() {
        let params = {
            id: goodsImageTaskCurrentId.value,
            status: 'STARTED',
            successful: -1,
        }
        updateImageTask(params).then((res) => {
            isCurrentGoodsImageTaskTodo.value = true
            goodsImageTaskCurrent.value = reformatTask(res.data.data)
            userTaskList.value.forEach((el) => {
                nextTick(() => {
                    let newUserTaskList = []
                    userTaskList.value.forEach((el) => {
                        if (el.id == goodsImageTaskCurrentId.value) {
                            el = goodsImageTaskCurrent.value
                        }
                        newUserTaskList.push(el)
                    })
                    userTaskList.value = newUserTaskList
                    onShowGoodsImageCurrentTask(
                        goodsImageTaskCurrentId.value,
                        goodsImageTaskCurrent.value
                    )
                })
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    function onDestroyGoodsImageTask() {
        ElMessageBox.confirm('确认要删除[' + goodsImageTaskCurrent.value.name + ']吗？','提示',
            {
                type: 'danger',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                callback: (action) => {
                    console.log('after callback')
                    let params = {
                        id: goodsImageTaskCurrentId.value
                    }
                    destroyImageTask(params).then(() => {
                        getUserTasks(1)
                        onShowGoodsImageMain()
                    }).catch(() => {

                    })
                }
            }
        )
    }

    function onEditTaskName() {
        if (goodsImageTaskCurrent.value.task_name) {
            taskNewName.value = goodsImageTaskCurrent.value.task_name
        } else {
            taskNewName.value = goodsImageTaskCurrent.value.name
        }
        isShowTaskEditDialog.value = true
    }

    function onCancelTaskEdit() {
        isShowTaskEditDialog.value = false
    }

    function onConfirmTaskEdit() {
        isShowTaskEditDialog.value = false
        let params = {
            id: goodsImageTaskCurrentId.value,
            name: taskNewName.value
        }
        updateImageTask(params).then((res) => {
            goodsImageTaskCurrent.value = reformatTask(res.data.data)
            taskNewName.value = ''
            userTaskList.value.forEach((el) => {
                let id = el.id
                let currentCompareId = goodsImageTaskCurrent.value.id
                if (id == currentCompareId) {
                    el.name = goodsImageTaskCurrent.value.task_name
                }
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    function getStatusText(status) {
        let result = ''
        let statusTextDict = {
            'PENDING': '待执行',
            'STARTED': '执行中',
            'SUCCESS': '完成',
            'RETRY': '重试',
            'FAILURE': '失败',
        }
        if (statusTextDict.hasOwnProperty(status?.toUpperCase())) {
            result = statusTextDict[status?.toUpperCase()]
        } else {
            result = '状态未知'
        }
        return result
    }

    function onChangeImageStencilMediumStyle(stencil) {
        goodsImageBuildOptions.medium_style = stencil.name
        goodsImageBuildOptions.medium_style_id = stencil.id
    }

    function onChangeImageStencilCompositionStyle(stencil) {
        goodsImageBuildOptions.composition_style = stencil.name
        goodsImageBuildOptions.composition_style_id = stencil.id
    }

    function onChangeImageStencilPaintStyle(stencil) {
        goodsImageBuildOptions.paint_style = stencil.name
        goodsImageBuildOptions.paint_style_id = stencil.id
    }

    function onChangeImageStencilSceneStyle(stencil) {
        goodsImageBuildOptions.scene_style = stencil.name
        goodsImageBuildOptions.scene_style_id = stencil.id
    }

    function onChangeImageStencilLocationStyle(stencil) {
        goodsImageBuildOptions.location_style = stencil.name
        goodsImageBuildOptions.location_style_id = stencil.id
    }

    function onChangeImageStencilPersonStyle(stencil) {
        goodsImageBuildOptions.person_style = stencil.name
        goodsImageBuildOptions.person_style_id = stencil.id
    }

    function onChangeImageStencilFaceStyle(stencil) {
        goodsImageBuildOptions.face_style = stencil.name
        goodsImageBuildOptions.face_style_id = stencil.id
    }

    function onChangeImageStencilTagsStyle(stencil) {
        goodsImageBuildOptions.tag_style = stencil.name
        goodsImageBuildOptions.tag_style_id = stencil.id
    }

    function onCollectTaskImage(image, is_collected, task_id, id, task_media_site, asset_url) {
        console.log(image)
        let params = {
            id: id,
            task_id: task_id,
            task_media_site: task_media_site,
            asset_url: asset_url,
        }

        if (!is_collected) {
            createUserCollect(params).then((res) => {
                console.log(res)
                image.is_collected = true
                let message = res.msg
                ElMessage({
                    message: message,
                    type: 'success',
                })
            }).catch((err) => {
                let message = res.msg
                ElMessage({
                    message: message,
                    type: 'error',
                })
                console.log(err)
            })
        } else if (is_collected) {
            destroyUserCollect(params).then((res) => {
                image.is_collected = false
                let message = res.msg
                ElMessage({
                    message: message,
                    type: 'warning'
                })
            }).catch((err) => {
                let message = res.msg
                ElMessage({
                    message: message,
                    type: 'error'
                })
                console.log(err)
            })
        }
    }

    function onDestroyTaskImage(id, task_id) {
        ElMessageBox.confirm('确认要删除这张图吗？', '危险提示',{
            type: 'danger',
            callback: (action) => {
                let params = {
                    id: id,
                    task_id: task_id,
                }
                destroyGoodsImage(params).then((res) => {
                    let taskParams = {
                        id: goodsImageTaskCurrentId.value,
                    }
                    retriveImageTask(taskParams).then((res) => {
                        goodsImageTaskCurrent.value = reformatTask(res.data.data)
                        onShowGoodsImageCurrentTask(
                            goodsImageTaskCurrentId.value,
                            goodsImageTaskCurrent.value)
                    }).catch((err) => {
                        console.log(err)
                    })
                    
                }).catch((err) => {
                    console.log(err)
                })
            }
        })
    }

    function onGetGoodsImageMaskAuto() {
        if (goodsImageRefType.value == 'gallery') {
            if (!goodsImageRefGalleryCurrent.value) {
                ElMessageBox.alert('请先选择主体参考图','提示', {
                    callback: (action) => {
                        return
                    }
                })
            }
        } else if (goodsImageRefType.value == 'upload') {
            if (!goodsImageRefUploaded.value) {
                ElMessageBox.alert('请先上传主体参考图','提示', {
                    callback: (action) => {
                        return
                    }
                })
            }
        }
        let input_image = ''
        let return_mask = ''
        let input_image_type = goodsImageRefType.value
        if (input_image_type == 'upload') {
            input_image = goodsImageRefUploaded.value
        } else {
            input_image = goodsImageRefGalleryCurrent.value
        }
        return_mask = goodsImageMaskAutoGetType.value == 'mask' ? 1: 0
        let params = {
            input_image: input_image,
            return_mask: return_mask,
        }
        goodsImageMaskProgressing.value = true
        getGoodsImageMaskAuto(params).then((res) => {
            goodsImageMaskProgressing.value = false
            goodsImageMaskAuto.value = res.data.data.mask_image
            if (goodsImageMaskAutoGetType.value == 'mask') {
                goodsImageMaskCurrent.value = res.data.data.mask_image
            }
            goodsImageFinetuneType.value = 'auto-extract'
        }).catch((err) => {
            console.log(err)
        })
    }

    function onStartFinetuneMask() {
        isShowFinetuneDialog.value = true
        if (goodsImageFinetuneType.value == 'ref') {
            if (goodsImageRefType.value == 'gallery') {
                goodsImageFinetune.value = goodsImageRefGalleryCurrent.value
            } else if (goodsImageRefType.value == 'upload') {
                goodsImageFinetune.value = goodsImageRefUploaded.value
            }
        } else if (goodsImageFinetuneType.value == 'auto-extract') {
            if (goodsImageMaskType.value == 'auto') {
                if (goodsImageMaskAutoGetType.value == 'extract') {
                    goodsImageFinetune.value = goodsImageMaskAuto.value
                }
            }
        }
        if (goodsImageFinetune.value) {
            let imageObj = new Image()
            imageObj.src = goodsImageFinetune.value
            console.log(goodsImageFinetune.value)
            imageObj.onload = () => {
                goodsImageFinetuneWidthOrig.value =imageObj.width
                goodsImageFinetuneHeightOrig.value =imageObj.height
                let willWidth = 256
                let willHeight = 256
                goodsImageFinetuneScale.value = Math.ceil(imageObj.width / 256)
                willHeight = imageObj.height / goodsImageFinetuneScale.value
                goodsImageFinetuneWidth.value = willWidth
                goodsImageFinetuneHeight.value =willHeight
                console.log(goodsImageFinetuneWidth.value)
                console.log(goodsImageFinetuneHeight.value)
                finetuneStage.value.getNode().width(goodsImageFinetuneWidth.value)
                finetuneStage.value.getNode().height(goodsImageFinetuneHeight.value)
                finetuneStageLayerBgImage.value.getNode().image(imageObj)
                finetuneStageLayerBgImage.value.getNode().width(goodsImageFinetuneWidth.value)
                finetuneStageLayerBgImage.value.getNode().height(goodsImageFinetuneHeight.value)
            }
        }
    }

    function uploadGoodsImageRefToServer(params) {
        console.log(params)
        uploadGoodsImageRef(params).then((res) => {
            if(res.code == 2000) {
                goodsImageRefUploaded.value = res.data.data[0]
                goodsImageRefCurrent.value = res.data.data[0]
                getUserGoodsImageRefs()
            } else {
                console.log(res)
                // vm.$message.warning(res.msg)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    function onUploadGoodsImageRefToServer() {
        goodsImageRefUploaderRef.value.submit()
    }

    function uploadGoodsImageMaskToServer(params) {
        uploadGoodsImageMask(params).then((res) => {
            if(res.code == 2000) {
                goodsImageMaskUploaded.value = res.data.data[0]
                goodsImageMaskCurrent.value = res.data.data[0]
            } else {
                console.log(res)
                // vm.$message.warning(res.msg)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    function onUploadGoodsImageMaskToServer() {
        goodsImageMaskUploaderRef.value.submit()
    }

    function onBeforeUploadGoodsImage(file) {
        // const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        // if (!isJPG) {
        //     this.$message.error('图片只能是 JPG/PNG 格式!');
        //     return false
        // }
        // isJPG = True
        // return isJPG;
    }

    function onAfterUploadGoodsImageRef(response,file,fileList) {
        getUserGoodsImageRefs()
        console.log(response)
        console.log(file)
        console.log(fileList)
    }

    function onAfterUploadGoodsImageMask(response,file,fileList) {
        console.log(response)
        console.log(file)
        console.log(fileList)
    }

    function onExceedGoodsImageRef(fileList, uploadFileList) {
        goodsImageRefUploaderRef.value.clearFiles()
        const file = fileList[0]
        file.uid = genFileId()
        goodsImageRefUploaderRef.value.handleStart(file)
    }

    function onExceedGoodsImageMask(fileList, uploadFileList) {
        goodsImageMaskUploaderRef.value.clearFiles()
        const file = fileList[0]
        file.uid = genFileId()
        goodsImageMaskUploaderRef.value.handleStart(file)
    }

    function onSetPickInclude() {
        finetunePenMode.value = 'include'
    }

    function onSetPickExclude() {
        finetunePenMode.value = 'exclude'
    }

    function onSetPickSpondge() {
        finetunePenMode.value = 'spondge'
    }

    function onSetPickSpondgeWidth() {
        finetunePenMode.value = 'include'
    }

    function onPreviewFinetune() {
        let sam_positive_points = JSON.parse(JSON.stringify(finetunePositivePoints.value))
        let sam_negative_points = JSON.parse(JSON.stringify(finetuneNegativePoints.value))
        sam_positive_points = sam_positive_points.map((el) => {
            console.log(el)
            return [el[0] / goodsImageFinetuneScale.value ,el[1] / goodsImageFinetuneScale.value ]
        })
        sam_negative_points = sam_negative_points.map((el) => {
            return [el[0] / goodsImageFinetuneScale.value ,el[1] / goodsImageFinetuneScale.value ]
        })
        let params = {
            "input_image": goodsImageFinetune.value,
            "sam_positive_points": sam_positive_points,
            "sam_negative_points": sam_negative_points,
        }
        sendSegmentReq(params).then((res) => {
            finetunePreviewSegmentMaskList.value = res.data.data.mask_images
            finetunePreviewSegmentMaskedList.value = res.data.data.masked_images
            finetunePreviewSegmentBlendedList.value = res.data.data.blended_images
        }).catch((err) => {
            console.log(err)
        })
    }

    function onUndoFinetune() {
    }

    function onResetFinetune() {
        finetunePositivePoints.value = [[]]
        finetuneNegativePoints.value = [[]]
    }

    function onCancelFinetune() {
        isShowFinetuneDialog.value = false
    }

    function onConfirmFinetune() {
        isShowFinetuneDialog.value = false
    }

    function onPickFinetuneStage(evt) {
        const curPos = finetuneStage.value.getNode().getPointerPosition();
        const x = curPos.x.toFixed(2)
        const y = curPos.y.toFixed(2)
        const point = [x,y]
        let hasPoint = false
        // let star = new Konva.Star({
        //     x: x,
        //     y: y,
        //     numPoints: 5,
        //     innerRadius: 3,
        //     outerRadius: 6,
        //     fill: 'blue',
        //     stroke: 'black',
        //     strokeWidth: 1
        // })
        // if (finetunePenMode.value == 'include') {
        //     star.fill('blue')
        //     finetunePositivePoints.value.forEach((el,index) => {
        //         if (x == el.x && y == el.y) {
        //             hasPoint = true
        //         }             
        //     })
        //     if (!hasPoint) {
        //         finetunePositivePoints.value.push(point)
        //     }
        // } else if (finetunePenMode.value == 'exclude') {
        //     star.fill('red')
        //     finetunePositivePoints.value.forEach((el,index) => {
        //         if (x == el.x && y == el.y) {
        //             hasPoint = true
        //         }             
        //     })
        //     if (!hasPoint) {
        //         finetuneNegativePoints.value.push(point)
        //     }
        // }
        // if (!hasPoint) {
        //     finetuneStageLayer.value.getNode().add(star)
        // }
    }

</script>

<style scoped lang="scss">
    ::v-deep(.el-tabs__content){
        // overflow:hidden 会导致 分页下拉框超出部分会被.el-tabs__content隐藏
        overflow: visible;
    }
    .page {
        position: relative;
        .aside {
            position: relative;
            .list {
                position: relative;
                display: flex;
                flex-direction: column;
                .list-item {
                    display: flex;
                    flex-direction: row;
                    background-color: #fff;
                    color: #424242;
                    margin-bottom: 4px;
                    &.active {
                        background-color: #eee;
                        color: #333;
                    }
                    .item-thumb {
                        width: 48px;
                        height: 48px;
                        border-radius: 50%;
                        margin-right: 4px;
                    }
                    .item-content {
                        display: flex;
                        flex-direction: row;
                    }
                    .item-actions {

                    }
                }
            }
        }
        .main {
            position: relative;
            .section--main {
                position: relative;
            }
            .section--new-goodsimage {
                position: relative;
                .footer--task-create {
                    background-color: #fafafa;
                    opacity: 0.7;
                    color: #212121;
                }                
            }
            .section--current-goodsimage {
                position: relative;
            }

            .main-gutter {
                border-left: 1px dashed #eee;
                border-right: 1px dashed #eee;
            }

            .gutter-switch {
                border: 1px solid #f5f5f5;
                border-radius: 4px;
                padding: 4px;
                &.active {
                    background-color: #f5f5f5;
                    color: #212121;
                }
            }

            .flag-icon {
                color: #212121;
                &.flag-icon--fin {
                    color: #000;
                }
            }
            .flag-label {
                margin-left: 4px;
                margin-right: 4px;
                &.flag-label--fin {
                    color: #000;
                }
            }
        }
        .section-header {
            border-bottom: 1px dashed #eee;
        }
        .dlg--finetune {
            .finetune-pen-switch {
                background-color: #fff;
                color: #757575;
                &.active {
                    background-color: #eee;
                    color: #424242;
                }
            }
        }
    }
</style>