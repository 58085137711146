
<template>
    <div class="page page--video-manage lycontainer" :class="{'ly-is-full':isFull}">
        <!-- <div>
            <el-empty
                description="敬请期待"
            >
            </el-empty>
        </div> -->

        <div class="header">
			<div class="left">
				<div @click="modelChange(item.value)" :class="'item ' + (item.value === modelACtive ? 'active' : '')"
					v-for="item in modelOptions" :key="item.value">
					<div class="icon"> </div>
					<div class="label">
						{{ item.label }}
					</div>
					<div class="sjx"></div>
				</div>
			</div>
			<div class="right">
				<div class="content">
					<div class="text">上传图片后计算<span>预计消耗算力
							<el-tooltip class="box-item" effect="dark"
								content="算力消耗与图片数量、单张训练次数、训练轮数有关；基于基础算法XL的训练任务算力消耗为基础算法1.5的6倍" placement="top">
								<el-icon style="cursor: pointer;">
									<InfoFilled />
								</el-icon>
							</el-tooltip>
						</span></div>
					<div class="startBtn" @click="startFn">开始训练</div>
				</div>
			</div>
		</div>
		<div class="body">
			<div class="left">
				<div class="titleContent">
					<div class="noContent">
						<div class="no">1</div>
						<div class="title">参数设置</div>
					</div>
					<div class="rightDesc">
						<span>专业参数</span>
						<el-icon>
							<FullScreen />
						</el-icon>
					</div>
				</div>
				<div class="formContent">

					<div class="formItem">
						<div class="label">使用底模</div>
						<div class="input" style="display: flex;align-items: center;">
							<el-select v-model="value1" style="flex: 1;" size="large">
								<el-option v-for="item in diMoOptions" :key="item.value" :label="item.label"
									:value="item.value" />
							</el-select>
							<el-button :icon="Refresh" circle style="margin-left:6px;" />
						</div>
					</div>

					<div class="formItem">
						<div class="label">单张次数 <span style="padding-left: 8px; color: #999;">Repeat</span></div>
						<div class="input" style="padding-left: 8px;">
							<el-slider v-model="value2" show-input size="large" input-size="small"
								:show-input-controls="false" :min="1" />
						</div>
					</div>

					<div class="formItem">
						<div class="label">循环轮次 <span style="padding-left: 8px; color: #999;">Epoch</span></div>
						<div class="input" style="padding-left: 8px;">
							<el-slider v-model="value3" show-input size="large" input-size="small"
								:show-input-controls="false" :min="1" />
						</div>
					</div>


					<div class="formItem">
						<div class="input">
							<el-input-number size="large" v-model="value4" style="width: 100%;text-align: left;" disabled
								:controls="false" placeholder="总步数: 上传图片后计算" />
						</div>
					</div>
					<div class="formItem">
						<div class="label">
							模型效果预览提示词
							<span
								style="color: #999; font-weight: 400; font-size: 12px;float: right;">训练中会根据提示词生成实时样图</span>
						</div>
						<div class="input">
							<el-input v-model="value5" :rows="6" type="textarea" placeholder="训练过程中会根据提示词实时生成样图" />
						</div>
					</div>
				</div>
			</div>
			<div class="right">
				<div class="titleContent">
					<div class="noContent">
						<div class="no">2</div>
						<div class="title">图片打标/裁剪 <span>共 <span style="color: #3162ff">{{ fileList.length }}</span>
								张</span></div>
					</div>
					<div class="rightDesc">
						<el-popover placement="bottom-end" :width="600" trigger="hover" effect="dark">
							<template #reference>
								示例
							</template>
							<template v-slot:default>
								<div style="padding: 0px 12px;box-sizing: border-box;background-color: #303133;">
									<img src="./imgs/1.png" style="width: 100%;" alt="">
								</div>
							</template>
						</el-popover>
					</div>
				</div>
				<div class="formContent">
					<div class="contnet" style="text-align: center;">
						<el-upload v-model:file-list="fileList" class="upload-demo"
							action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" multiple
							:on-preview="handlePreview" :on-success="uploadSuccess">
							<a style="color: #3162ff;text-decoration: underline;">点击上传图片</a>
							<template #tip>
								<div class="el-upload__tip">
									最多添加200张图片，支持png/jpg/jpeg
								</div>
							</template>
						</el-upload>
						<div style="width: 50%;">
							<el-divider>
								<span>或</span>
							</el-divider>
						</div>
						<el-upload v-model:file-list="fileList2" class="upload-demo"
							action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" multiple
							:on-preview="handlePreview">
							<a style="color: #3162ff;text-decoration: underline;">上传已有数据集</a>
							<template #tip>
								<div class="el-upload__tip">
									请保证图片和打标文件一一对应
								</div>
							</template>
						</el-upload>
					</div>
					<div class="bottom">
						<div class="formItem">
							<div class="label">裁剪方式</div>
							<div class="input" style="display: flex;align-items: center;">
								<el-select v-model="jianChaiFangShi" style="flex: 1;" size="large">
									<el-option v-for="item in jianChaiFangShiOptions" :key="item.value" :label="item.label"
										:value="item.value" />
								</el-select>
							</div>
						</div>

						<div class="formItem">
							<div class="label">裁剪尺寸</div>
							<div class="input" style="display: flex;align-items: center;">
								<el-select v-model="jianChaiChiChun" style="flex: 1;" size="large">
									<el-option v-for="item in jianChaiChiChunOptions" :key="item.value" :label="item.label"
										:value="item.value" />
								</el-select>
							</div>
						</div>

						<div class="formItem">
							<div class="label">打标算法</div>
							<div class="input" style="display: flex;align-items: center;">
								<el-select v-model="daBiaoSuanFa" style="flex: 1;" size="large">
									<el-option v-for="item in daBiaoSuanFaOptions" :key="item.value" :label="item.label"
										:value="item.value" />
								</el-select>
							</div>
						</div>
						<div class="formItem">
							<div class="label">打标阈值</div>
							<div class="input" style="display: flex;align-items: center;">
								<el-input-number size="large" controls-position="right" v-model="daBiaoYuZhi"
									style="width: 100%;text-align: left;" />
							</div>
						</div>

						<div class="formItem" style="flex-basis: 35%;">
							<div class="label">模型触发词</div>
							<div class="input" style="display: flex;align-items: center;">
								<el-input placeholder="请输入..." size="large" v-model="chuFaCi"
									style="width: 100%;text-align: left;" />
							</div>
						</div>
						<div class="formItem button">
							<el-button style="width: 100%;" type="primary" size="large" @click="daBiaoFn">剪裁/打标</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>  
 
<script setup>

import { ref } from "vue";
import { Refresh } from '@element-plus/icons-vue' 

let isFull = ref(false)

function setFull(){
    isFull.value=!isFull.value
    window.dispatchEvent(new Event('resize'))
}

const modelACtive = ref("Option1")
const modelOptions = ref([
	{
		value: 'Option1',
		label: '自定义',
	},
	{
		value: 'Option2',
		label: 'XL',
	},
	{
		value: 'Option3',
		label: '人像',
	},
	{
		value: 'Option4',
		label: 'ACG',
	},
	{
		value: 'Option5',
		label: '画风',
	}
])
const diMoOptions = ref([
	{
		value: 'Option1',
		label: '第一种算法',
	},
	{
		value: 'Option2',
		label: '天才算法',
	},
	{
		value: 'Option3',
		label: '大连算法',
	},
	{
		value: 'Option4',
		label: '北京算法',
	}
])

const jianChaiFangShi = ref('1');
const jianChaiFangShiOptions = ref([
	{
		value: '1',
		label: '1',
	},
	{
		value: '2',
		label: '2',
	}
])

const jianChaiChiChun = ref('1');
const jianChaiChiChunOptions = ref([
	{
		value: '1',
		label: '1',
	},
	{
		value: '2',
		label: '2',
	}
])
const daBiaoSuanFa = ref('1');
const daBiaoSuanFaOptions = ref([
	{
		value: '1',
		label: '1',
	},
	{
		value: '2',
		label: '2',
	}
])
const chuFaCi = ref();
const daBiaoYuZhi = ref(1);


const fileList = ref([
	{
		name: 'element-plus-logo.svg',
		url: 'https://element-plus.org/images/element-plus-logo.svg',
	},
	{
		name: 'element-plus-logo2.svg',
		url: 'https://element-plus.org/images/element-plus-logo.svg',
	},
]) 
const fileList2 = ref([]) 


const handlePreview = (uploadFile) => {
	console.log(uploadFile)
}

function uploadSuccess(arg){
	console.log(arg)
}

const value1 = ref('Option1')
const value2 = ref(1)
const value3 = ref(1)
const value4 = ref()
const value5 = ref('')


/**
 * 顶部模型切换
*/
function modelChange(value) {
	modelACtive.value = value;
	if(value === "Option1"){
		value1.value = "Option1"
	}
	
	if(value === "Option2"){
		value1.value = "Option2"
	}
	
	if(value === "Option3"){
		value1.value = "Option3"
	}
	if(value === "Option4"){
		value1.value = "Option4"
	}
	if(value === "Option5"){
		value1.value = "Option5"
	}
}

/**
 * 剪裁打标
*/
function daBiaoFn() {
	console.log('剪裁打标', {
		jianChaiFangShi: jianChaiFangShi.value,
		jianChaiChiChun: jianChaiChiChun.value,
		daBiaoSuanFa: daBiaoSuanFa.value,
		daBiaoYuZhi: daBiaoYuZhi.value,
		chuFaCi: chuFaCi.value,
	})
}

/**
 * 开始训练
*/
function startFn() {
	console.log('开始训练', {
		modelACtive: modelACtive.value,
		value1: value1.value,
		value2: value2.value,
		value3: value3.value,
		value4: value4.value,
		value5: value5.value,
	})
}
</script>


<style scoped src="./style.css"></style>
<style>
.formItem .input .el-slider__input {
	width: 50px;
}

.formItem .input .el-slider__runway.show-input {
	margin-right: 16px;
}
</style>