<template>
   <div :class="{'ly-is-full':isFull}">
  <div class="examine-tontent" >
    <!-- 表格数据展示 -->
    <el-table :data="examineTableData" style="width: 100%;" border  v-loading="loadingExamine" :height="'calc('+(tableHeight)+'px)'">
      <el-table-column type="index" width="60" align="center" label="序号" :index="Nindex"/>
      <el-table-column min-width="20" prop="task_id" label="任务ID"/>
      <el-table-column  prop="cover_img_url" label="产出视频" min-width="30">
        <template #default="scope">
         <div class="examine-image" @click="onExamineImage(scope.row)">
          <el-image :src="scope.row.cover_img_url" fit="cover" loading="lazy"></el-image>
          <el-icon  color="#f5f5f5"  size="32px" ><VideoPlay /></el-icon>
         </div>
        </template>
      </el-table-column>
      <el-table-column min-width="20" label="是否审核通过" prop="sh_status">
                <template #default="scope">
                    <el-tag v-if="scope.row.sh_status == 1" type="success">已通过</el-tag>
                    <el-tag v-else type="danger" >未通过</el-tag>
                </template>
      </el-table-column>
    <el-table-column min-width="50" prop="create_time" label="创建时间" ></el-table-column>
    <el-table-column label="操作" fixed="right" width="180">
                <template #header>
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div>操作</div>
                        <div @click="setFull">
                            <el-tooltip content="全屏" placement="bottom">
                                <el-icon ><full-screen /></el-icon>
                            </el-tooltip>
                        </div>
                    </div>
                </template>
                <template #default="scope">                               
                    <span class="table-operate-btn" @click="onExamineEdit(scope.row,'pass')">通过</span>
                    <span class="table-operate-btn" @click="onExamineEdit(scope.row,'nopass')">不通过</span>
                </template>
        </el-table-column>
  </el-table>  
  <!-- 弹框播放视频 -->
  <el-dialog v-model="examineDialogVisible"  title="视频播放" width="50%" center destroy-on-close >
  <video ref="video" class="w-[100%] h-[100%]" :poster="videoCoverImgUrl" :src="videoCoverUrl" controls>  </video>
  </el-dialog>
  <!-- 分页展示 -->
  <div class="examine-pagination">
    <el-pagination
      :current-page="examinePages.page"
      :page-size="examinePages.pagesize"
      :page-sizes="[10,20,50,100]"
      layout="total, sizes, prev, pager, next, jumper"
      :total="examinePages.total"
      @size-change="onEexamineSizeChange"
      @current-change="onEexamineCurrentChange"
    />
  </div>
 
  </div>
</div>
</template>

<script setup>
import { onMounted, ref,reactive ,onUnmounted,nextTick} from 'vue'
import {getShList,getShState}from '@/api/train'
import {getTableHeight} from "@/utils/util";
//element-puls引入
import { ElMessage,ElMessageBox } from 'element-plus'
// 节点
const tableSelect = ref()
//设置
const isFull = ref(false)
//高度
const tableHeight = ref(600)
//加载
const loadingExamine = ref(false)
//视频播放背景图
const videoCoverImgUrl = ref('')
//视频播放地址
const videoCoverUrl = ref('')
//弹框是否
const examineDialogVisible = ref(false)
//数据列表
// let examineTableData = reactive([])
const examineTableData = ref([])
//列表传参
let examinePages = reactive({page:1,pagesize:10,total:0})
/*事件*/

//点击图片事件
const onExamineImage = (row)=>{
  examineDialogVisible.value = true
  videoCoverImgUrl.value = row.cover_img_url
  videoCoverUrl.value = row.video_url
}
//通过和不通过事件
const onExamineEdit = async(row,info)=>{
   if(info === "pass"){
    ElMessageBox.confirm('您确定要审核通过选中的内容？','提示',
    {confirmButtonText: '确定', cancelButtonText: '取消',type: 'warning',}
  ).then(async() => {
    let passObj = {"task_id":row.task_id,"sh_type":1}
    const res = await getShState(passObj)
    if(res.code !== 2000)return ElMessage.error(res.msg)
      ElMessage.success(res.msg)
      examineList()
    console.log(res);
    }).catch(() => { ElMessage.info('已取消审核通过')})
   }else{
    ElMessageBox.confirm('您确定要审核通过选中的内容？','提示',
    {confirmButtonText: '确定', cancelButtonText: '取消',type: 'warning',}
  ).then(async() => {
    let nopassObj = {"task_id":row.task_id,"sh_type":2}
    const res = await getShState(nopassObj)
    if(res.code !== 2000)return ElMessage.error(res.msg)
      ElMessage.success(res.msg)
      examineList()
    console.log(res);
    }).catch(() => { ElMessage.info('已取消审核不通过')})
   }
}
//改变每页显示的条目数
const onEexamineSizeChange = (size)=>{
  examinePages.pagesize = size
  examineList()
}
//改变页码事件
const onEexamineCurrentChange =(page)=>{
    examinePages.page = page
  examineList()
}
//获取序号
const  Nindex = (index)=> {
      // 当前页数 - 1 * 每页数据条数 + 1
      const page = examinePages.page // 当前页码
      const pagesize = examinePages.pagesize // 每页条数
      return index + 1 + (page - 1) * pagesize
    }
//请求
onMounted(()=>{
  //请求数据列表
  examineList()
  //设置
  // 监听页面宽度变化搜索框的高度
  window.addEventListener('resize', listenResize);
            nextTick(() => {
              getTheTableHeight()
            })
})
//获取数据列表事件
const examineList = async()=>{
  loadingExamine.value = true
  let pagesObj = {"page":examinePages.page,"pagesize":examinePages.pagesize}
  const res = await getShList(pagesObj)
  if(res.code !== 2000)return ElMessage.error(res.msg)
    console.log(res);
  loadingExamine.value = false
  examineTableData.value = res.data.list
  examinePages.total = res.data.total
    // console.log(res.data);
    // console.log(res.data.list);
    // console.log(examineTableData);
}
// 计算搜索栏的高度
const listenResize = ()=>{
                nextTick(() => {
                    getTheTableHeight()
                })
            }
const  getTheTableHeight = ()=>{
                let tabSelectHeight = tableSelect?tableSelect.offsetHeight:0
                tabSelectHeight = isFull.value?tabSelectHeight - 110:tabSelectHeight
                tableHeight.value =  getTableHeight(tabSelectHeight)
            }
//设置
const setFull = ()=>{
                isFull.value = !isFull.value
                window.dispatchEvent(new Event('resize'))
            }
  onUnmounted(()=>{
 // 页面销毁，去掉监听事件
    window.removeEventListener("resize", listenResize);
 })
</script>
<style lang="scss" scoped>
.examine-tontent{

  .el-pagination{
    margin-top: 20px;
    justify-content: center;
  }
  .examine-image{
    width: 100px;
    height: 75px;
    position: relative;
    .el-image{
      width: 100%;
    }
    .el-icon{
      z-index: 1;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); 
    }
  }
}
</style>