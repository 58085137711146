<template>
  <div :class="{'ly-is-full':isFull}">
      <div class="tableSelect" ref="tableSelect">
          <el-form
              :inline="true" 
              :model="formInline"
              label-position="left"
          >
              <!-- <el-form-item label="搜索关键字：">
                  <el-input size="default" v-model.trim="formInline.search" maxlength="60"  clearable placeholder="任务名称" @change="search" style="width:200px"></el-input>
              </el-form-item> -->
              <el-form-item label="审核状态：">
                  <el-select
                      v-model="formInline.audit_status"
                      placeholder="请选择"
                      clearable
                      @change="search"
                      size="default"
                      style="width:100px"
                  >
                      <el-option
                          v-for="item in statusList"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label=""><el-button  @click="search" type="primary" icon="Search">查询</el-button></el-form-item>
              <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
          </el-form>
      </div>
      <el-table
          :height="'calc('+(tableHeight)+'px)'"
          border
          :data="tableData"
          ref="tableref"
          v-loading="loadingPage"
          style="width: 100%"
      >
          <el-table-column type="index" width="60" align="center" label="序号">
              <template #default="scope">
                  <span v-text="getIndex(scope.$index)"></span>
              </template>
          </el-table-column>
          <el-table-column min-width="90" prop="task" label="任务ID"></el-table-column>
          <el-table-column min-width="90" prop="task_history" label="历史子任务ID"></el-table-column>
          <el-table-column min-width="120" prop="asset_thumb_url" label="产出视频">
             <template #default="scope">
                  <figure
                      class="task-video shadow-none rounded p-1 ml-2 mr-2 mb-2"
                      @click="onShowVideo({
                          asset_url: scope.row.asset_url,
                          asset_thumb_medium_url: scope.row.asset_thumb_medium_url,
                      })"
                  >
                      <div class="video relative">
                          <el-image
                              fit="cover"
                              loading="lazy"
                              class="rounded"
                              style="width: 96px;height: 96px"
                              :src="scope.row.asset_thumb_url"
                          >
                              <template #placeholder>
                                  <el-icon><CameraFilled /></el-icon>
                              </template>
                              <template #error>
                                  <el-icon size="96px"><Picture /></el-icon>
                              </template>
                          </el-image>
                          <div class="absolute top-[40%] right-[50%] shadow">
                              <el-icon
                                  color="#f5f5f5"
                                  size="32px"
                              ><VideoPlay /></el-icon>
                          </div>
                      </div>
                  </figure>
             </template>
         </el-table-column>
          <!-- <el-table-column min-width="180" prop="task_content" show-overflow-tooltip label="产出视频">
              <template #default="scope">
                  <div v-html="customEllipsis(scope.row.task_content)" class="ellipsis"></div>
             </template>
          </el-table-column> -->
          <el-table-column min-width="90" label="是否审核通过">
              <template #default="scope">
                  <el-tag v-if="scope.row.audit_status == 1">已通过</el-tag>
                  <el-tag v-else type="danger">未通过</el-tag>
              </template>
          </el-table-column>
          <el-table-column min-width="150" prop="create_datetime" label="创建时间"></el-table-column>
          <el-table-column min-width="150" prop="update_datetime" label="更新时间"></el-table-column>
          <el-table-column label="操作" fixed="right" width="180">
              <template #header>
                  <div style="display: flex;justify-content: space-between;align-items: center;">
                      <div>操作</div>
                      <div @click="setFull">
                          <el-tooltip content="全屏" placement="bottom">
                              <el-icon ><full-screen /></el-icon>
                          </el-tooltip>
                      </div>
                  </div>
              </template>
              <template #default="scope">
                  <span class="table-operate-btn" @click="handleEdit(scope.row,'accept')">通过</span>
                  <span class="table-operate-btn" @click="handleEdit(scope.row,'reject')">不通过</span>
              </template>
          </el-table-column>
      </el-table>
      <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>

      <el-dialog
          v-model="isShowVideoDialog"
          class="flex flex-col justifify-center items-center"
          title="视频观看"
      >
          <video
              ref="video"
              class="w-[400px] h-[266px]"
              :poster="videoCurrentThumbUrl"
              :src="videoCurrentUrl"
              controls
          >
          </video>
      </el-dialog>

  </div>
</template>
<script>
  import Pagination from "@/components/Pagination";
  import {dateFormats,getTableHeight} from "@/utils/util";
  import {getAuditTaskList,acceptAuditTask,rejectAuditTask} from '@/api/api'
  export default {
      components:{
          Pagination,
      },
      name:'auditManage',
      data() {
          return {
              isFull:false,
              tableHeight:500,
              loadingPage:false,
              formInline:{
                  page: 1,
                  limit: 10,
                  page_size: 10,
                  search: '',
                  audit_status: '',
              },
              pageparm: {
                  page: 1,
                  limit: 10,
                  page_size: 10,
                  total: 0
              },
              statusList:[
                  {id:1,name:'通过'},
                  {id:0,name:'未通过'},
              ],
              tableData:[],
              videoCurrentUrl: '',
              videoCurrentThumbUrl: '',
              isShowVideoDialog: false,
          }
      },
      methods:{
          // 表格序列号
          getIndex($index) {
              // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
              return (this.pageparm.page-1)*this.pageparm.limit + $index +1
          },
          setFull(){
              this.isFull=!this.isFull
              window.dispatchEvent(new Event('resize'))
          },
          //当渲染的文字超出10字后显示省略号
          customEllipsis(value) {
              value = value.replace(/<.*?>/ig,"")       //把v-html的格式标签替换掉
              if(!value) return ""
              if (value.length > 10) {
                  return value.slice(0, 10) + "..."
              }
              return value
          },
          changeStatus(row) {
              // console.log(row,'row----')
          },
          handleEdit(row,flag) {
              let vm = this
              if(flag=='accept') {
                  vm.$confirm('您确定要审核通过选中的内容？',{
                      closeOnClickModal:false
                  }).then(res=>{
                      acceptAuditTask({id:row.id}).then(res=>{
                          if(res.code == 2000) {
                              vm.$message.success(res.msg)
                              vm.search()
                          } else {
                              vm.$message.warning(res.msg)
                          }
                      })
                  }).catch(()=>{

                  })
              }
              else if(flag=='reject') {
                  vm.$confirm('您确定要不通过选中的内容？',{
                      closeOnClickModal:false
                  }).then(res=>{
                      rejectAuditTask({id:row.id}).then(res=>{
                          if(res.code == 2000) {
                              vm.$message.success(res.msg)
                              vm.search()
                          } else {
                              vm.$message.warning(res.msg)
                          }
                      })
                  }).catch(()=>{

                  })
              }
              else if(flag=="reset"){
                  this.formInline = {
                      search: '',
                      page:1,
                      limit: 10,
                      page_size: 10,
                  }
                  this.pageparm={
                      page: 1,
                      limit: 10,
                      page_size: 10,
                      total: 0,
                  }
                  this.search()
              }
          },

          callFather(parm) {
              this.formInline.page = parm.page
              this.formInline.limit = parm.limit
              this.formInline.page_size = parm.page_size
              this.getData()
          },
          search() {
              this.formInline.page = 1
              this.formInline.limit = 10
              this.formInline.page_size = 10
              this.getData()
          },
          //获取列表
          async getData(){
              this.loadingPage = true
              getAuditTaskList(this.formInline).then(res => {
                   this.loadingPage = false
                   if(res.code ==2000) {
                       this.tableData = res.data.data
                       this.pageparm.page = res.data.page;
                       this.pageparm.limit = res.data.limit;
                       this.pageparm.page_size = res.data.page_size;
                       this.pageparm.total = res.data.total;

                   }
               })
          },
          // 计算搜索栏的高度
          listenResize() {
              this.$nextTick(() => {
                  this.getTheTableHeight()
              })
          },
          getTheTableHeight(){
              let tabSelectHeight = this.$refs.tableSelect?this.$refs.tableSelect.offsetHeight:0
              tabSelectHeight = this.isFull?tabSelectHeight - 110:tabSelectHeight
              this.tableHeight =  getTableHeight(tabSelectHeight)
          },
          onShowVideo(videoObj) {
              console.log(videoObj)
              this.videoCurrentUrl = videoObj.asset_url
              this.videoCurrentThumbUrl = videoObj.asset_thumb_medium_url

              this.isShowVideoDialog = true
          },
      },
      created() {
          this.getData()
      },
      mounted() {
          // 监听页面宽度变化搜索框的高度
          window.addEventListener('resize', this.listenResize);
          this.$nextTick(() => {
            this.getTheTableHeight()
          })
      },
      unmounted() {
            // 页面销毁，去掉监听事件
          window.removeEventListener("resize", this.listenResize);
      },
  }
</script>