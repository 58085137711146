import MyStorage from "./storage" 
/** 
 * apiName
 * opts.verifyToken
 * opts.headers
 */
import { API_URL } from '@/config/index'
    // console.log(API_URL);
//引入token
import {getStorage} from '@/utils/util'
let uploadToken = getStorage('logintoken')
let uploadHeaders = 'JWT ' + uploadToken
const MyFetch = (apiName, body = {}, opts = {}, method = "GET") => {
    // const { uploadHeaders } = window.configs;
    return new Promise((resolve) => {
        // 是否验证 token
        // const verifyToken = opts.verifyToken === false ? false : true;
        // const diyHeaders = opts.headers;

        // const loginInfo = MyStorage.getItem("loginInfo");
        // let access_token = loginInfo ? loginInfo.data["access_token"] : undefined;
        // if (!access_token && verifyToken) {
        //     resolve({
        //         success: false,
        //         code: "902",
        //         message: "没有登录信息，请登录！",
        //     })
        //     return;
        // }     
        const myInit = {
            // method: "POST",
            method,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=utf-8",
                "X-Requested-With": "XMLHttpRequest",
                // Authorization: `Bearer ${access_token}`,
                Authorization:uploadHeaders
            },
            mode: "cors",
            cache: "default",
            body: method === "GET" ? null : JSON.stringify(body),
        }; 
        const apiAddress = apiName.indexOf("http") !== -1 ? apiName : `${API_URL}${apiName}`;
        fetch(apiAddress, myInit)
            .then((response) => {
                const ct = response.headers.get("content-type"); 
                if (ct === "application/json; charset=utf-8" || ct === "application/json;charset=UTF-8" || ct === "application/json") {
                    return response.json();
                } else {
                    console.error(response.json())
                    return {
                        success: false,
                        code: "800",
                        message: "请求出错，请检查",
                    };
                }
            })
            .then(async (result) => {
                resolve({ ...result });
                // 为了避免用户要执行的请求动作失效如果返回3007必须在这块拦截而且刷新token后再次执行重复动作并且resolve
                // const tokenMiddleRes = await tokenMiddle(result);
                // if (tokenMiddleRes === "1") {
                //     resolve(result);
                // } else if (tokenMiddleRes === "2") {
                //     resolve({ ...needreLoginData });
                // } else if (tokenMiddleRes === "3") {
                //     // 重新执行失败的请求
                //     const reFeetchRes = await MyFetch(fnArg);
                //     if (reFeetchRes.success) {
                //         resolve({ ...reFeetchRes });
                //     } else {
                //         if (reFeetchRes.code === "-1") {
                //             notification.error({ message: "系统遇到问题，请联系运维人员", description: reFeetchRes.message, duration: 3 });
                //         } else {
                //             notification.warn({ message: "提示", description: reFeetchRes.message, duration: 3 });
                //         }
                //     }
                // }
            })
            .catch((error) => { 
                console.error(error);
                resolve({
                    success: false, 
                    message: "请求错误:"+error,
                });
            });

    });
};

export default MyFetch;