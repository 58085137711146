<template>
    <div class="pageGen">
        <!-- <el-dialog v-model="drawIng" align-center width="750px" class="my-modal" :close-on-click-modal="false"
            :close-on-press-escape="false" @close="modalClose">
            <template #header>
                <div style="height: 22px;"></div>
            </template>  
            <div class="modal-content">
                <div class="title">生成中…</div>
                <div class="desc">
                    <p>分镜绘图正在处理中，预计10-15分钟完成，可稍后到 个人中心 - 我的创作 - 全部，继续查看编辑您的创作</p>
                </div>
                <div class="btns">
                    <el-button style="margin-left: 12px;" type="primary" size="large" @click="toMyChuangZuo">
                        我的创作
                    </el-button>
                </div>
            </div>
        </el-dialog> -->
        <el-dialog title="生成中…" v-model="drawIng" align-center width="550px" class="my-modal" :close-on-click-modal="false"
            :close-on-press-escape="false" @close="modalClose">
            <div class="modal-content">
                <div class="desc">
                    <p>分镜绘图正在处理中，预计10-15分钟完成， <br/>可稍后到 个人中心 - 我的创作 - 全部，继续查看编辑您的创作</p>
                </div>
            </div> 
            <template #footer>
                <span class="dialog-footer">
                    <el-button style="margin-left: 12px;" type="primary" size="large" @click="toMyChuangZuo">
                        我的创作
                    </el-button>
                </span>
            </template>
        </el-dialog>

        <!-- <el-dialog v-model="drawIng2" align-center width="750px" class="my-modal" :close-on-click-modal="false"
            :close-on-press-escape="false" @close="modalClose">

            <template #header>
                <div style="height: 22px;"></div>
            </template>
            <div class="modal-content">
                <div class="title">视频生成中…</div>
                <div class="desc">视频正在制作中，预计15分左右完成，可稍后到 个人中心 - 我的创作 - 全部 内容中查看您的视频创作</div>
                <div class="btns">
                    <el-button style="margin-left: 12px;" type="primary" size="large" @click="toMyChuangZuo">
                        我的创作
                    </el-button>
                </div>
            </div>
        </el-dialog> -->

        <el-dialog title="视频生成中…" v-model="drawIng2" align-center width="750px" class="my-modal" :close-on-click-modal="false"
            :close-on-press-escape="false" @close="modalClose">
            <div class="modal-content">
                <div class="desc">
                    <p>分镜绘图正在处理中，预计10-15分钟完成，
                        <br/>可稍后到 个人中心 - 我的创作 - 全部，继续查看编辑您的创作</p>
                </div>
            </div> 
            <template #footer>
                <span class="dialog-footer">
                    <el-button style="margin-left: 12px;" type="primary" size="large" @click="toMyChuangZuo">
                        我的创作
                    </el-button>
                </span>
            </template>
        </el-dialog>

        <div class="tabelContainer">
            <el-table :data="tableData" style="width: 100%" :border="true">
                <el-table-column label="镜号" type="index" width="60" align="center" />
                <el-table-column prop="desc" label="原文描述" />
                <el-table-column prop="screenKeyword" label="画面关键词">
                    <template #default="scope">
                        <el-input v-model="scope.row.screenKeyword" type="textarea" placeholder="请输入..."
                            @change="screenKeywordChange($event, scope.row)" :autosize="{ minRows: 2, maxRows: 4 }" />
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="status" label="生成进度" align="center" :width="120" /> -->
                <el-table-column prop="imgs" label="可选绘图" align="center" :width="140">
                    <template #default="scope">
                        <el-image style="width: 60px; height: 60px" :preview-teleported="true" :src="scope.row['img_url']"
                            :preview-src-list="[scope.row['img_url']]" fit="cover">
                        </el-image>
                        <div style="cursor: pointer;color: #2d8cf0;" @click="selectImg(scope.row)">
                            选择绘本（{{ scope.row.history_img_num }}）</div>

                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="120" align="center">
                    <template #default="scope">
                        <el-button link type="primary" :icon="Failed" @click.prevent="reDraw(scope.row, scope.$index)">
                            重绘本镜
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>


    <el-dialog title="绘图" v-model="selectImgShow" align-center width="700px" class="my-modal" :close-on-click-modal="false">
        <div class="img-select">
            <div class="preview">
                <img :src="selectedImgData.url" alt="">
            </div>
            <div class="list">
                <div :class="['item', selectedImgData.index === item.index ? 'active' : '']" v-for="item in imgsData"
                    :key="item.url" @click="selectedImgData = item">
                    <img :src="item.url" alt="">
                </div>
            </div>
        </div>

        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="selectImgOk">
                    确定
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<style src="./style.css" scoped></style>
<script setup>
import { ref, inject, onMounted, nextTick, onUnmounted } from "vue"
import { ElMessage, ElLoading } from 'element-plus'
import { Failed } from '@element-plus/icons-vue'
// import { useRouter } from "vue-router"
import router from '@/router'
const emits = defineEmits(["hideBtns", 'setStep'])

const myFetch = inject('myFetch')
const getUserId = inject('getUserId')
// const router = useRouter()


const refreshTimer = ref('');

// 是否正在绘制
const drawIng = ref(false);
// 当前是否正在合成视频
const isEndLoading = ref(false);
const drawIng2 = ref(false);

// 是否是自动关闭弹出
const isAutoClose = ref(false);

// 表格数据
const tableData = ref([])
const paramsData = ref({})

// 是否显示选择绘本
const selectImgShow = ref(false)
const selectedImgData = ref({
    // index: 0,
    // url: "https://cdn.pixabay.com/photo/2023/07/29/17/36/fly-8157417_1280.jpg"
})
const imgsData = ref([
    // {
    //     index: 0,
    //     url: "https://cdn.pixabay.com/photo/2023/07/29/17/36/fly-8157417_1280.jpg"
    // },
    // {
    //     index: 2,
    //     url: "https://cdn.pixabay.com/photo/2023/04/22/10/28/sheep-7943526_640.jpg"
    // },
    // {
    //     index: 3,
    //     url: "https://cdn.pixabay.com/photo/2023/12/05/16/57/dog-8432098_640.jpg"
    // },
    // {
    //     index: 4,
    //     url: "https://cdn.pixabay.com/photo/2023/07/29/17/36/fly-8157417_1280.jpg"
    // },
    // {
    //     index: 5,
    //     url: "https://cdn.pixabay.com/photo/2023/04/22/10/28/sheep-7943526_640.jpg"
    // },
])
const curRowData = ref({})

// 去创造中心
function toMyChuangZuo() {
    router.push({
        path: '/personalMyCreation',
    });
}

// 重绘
async function reDraw(rowData) {
    if (!rowData.screenKeyword) {
        ElMessage.error("画面关键词必填！");
        return;
    }
    // console.log('重绘本镜', rowData)
    const loading = ElLoading.service({
        lock: true,
        text: '正在生成',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    const { code, msg } = await myFetch("noveltweet/article_generation", {
        // ...rowData, 
        "user_id": getUserId(),
        "task_id": rowData.task_id,
        "process_id": rowData.process_id,
        "prompt": rowData.screenKeyword
    }, {}, "POST");
    loading.close();
    if (code === 2000) {
        ElMessage.success("重绘成功");
        refresh(paramsData.value)
    } else {
        ElMessage.error(msg);
    }
}

// 关键词编辑
async function screenKeywordChange(value, rowData) {
    // const { success, msg, data } = await myFetch("apiName", { ...rowData });
    // if (success) {
    //     ElMessage.success(msg);
    // } else {
    //     ElMessage.error(msg);
    // }
}

// 刷新正在绘制的标识
async function refreshDrawIng() {
    drawIng.value = false;
    // drawIng.value = true;
    // drawIng.value && emits("hideBtns")

    // const { success, msg, data } = await myFetch(apiName, {});
    // if (success) {  
    // drawIng.value = data;
    // drawIng.value && emits("hideBtns")
    // } else {
    //     ElMessage.error(msg);
    // }
}

// 刷新列表数据
async function refresh(params) {
    // tableData.value = [
    //     {
    //         "task_id": "1",
    //         "process_id": "1",
    //         desc: "1-男人急促的呼吸声在耳边萦绕，安以甜知道自己又做梦了。",
    //         screenKeyword: "holle hi",
    //         status: "已完成",
    //         imgs: [
    //             "https://cdn.pixabay.com/photo/2023/07/29/17/36/fly-8157417_1280.jpg",
    //             "https://cdn.pixabay.com/photo/2023/04/22/10/28/sheep-7943526_640.jpg",
    //             "https://cdn.pixabay.com/photo/2023/12/05/16/57/dog-8432098_640.jpg",
    //         ]
    //     },
    // ];

    paramsData.value = params;
    const { code, msg, data } = await myFetch(`noveltweet/task_info?task_id=${params?.task_id}`);
    if (code === 2000) {
        if (data.is_automatic + '' === "2" && data.state + '' === "2") {
            isAutoClose.value = true;
            drawIng.value = false;
            drawIng2.value = false;
            tableData.value = data.describe_image.map(item => ({
                ...item,
                task_id: data.task_id,
                desc: item["original"],
                screenKeyword: item['ai_describe'],
                status: "已完成",
                imgs: [
                    item['img_url'],
                ]
            }))
        } else if (data.is_automatic + '' === "1" && data.state + '' === "4") {
            isAutoClose.value = true;
            drawIng.value = false;
            drawIng2.value = false;
            // 跳转到“我的创作”页面
            toMyChuangZuo();
        } else {

            // drawIng.value = true;
            // clearTimeout(refreshTimer.value);
            // refreshTimer.value = setTimeout(() => {
            //     isAutoClose.value = true;
            //     drawIng.value = false;
            // }, 3000)

            // 分镜绘图正在处理中
            if (isEndLoading.value) {
                drawIng2.value = true;
            } else {
                drawIng.value = true;
            }
            clearTimeout(refreshTimer.value);
            refreshTimer.value = setTimeout(() => {
                refresh(paramsData.value)
            }, 30000)
        }

    } else { 
        drawIng.value = false;
        drawIng2.value = false;

        ElMessage.error(msg);
    }
}

// 选择绘本按钮点击
async function selectImg(rowData) {
    const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    // 请求图片数据
    const { code, msg, data } = await myFetch(`noveltweet/article_history_generation?task_id=${paramsData.value["task_id"]}&process_id=${rowData.process_id}`);
    loading.close();
    if (code === 2000) {
        curRowData.value = rowData;
        imgsData.value = data.history_img_urls.map((item, index) => {
            return {
                index,
                task_id: data["task_id"],
                process_id: data["process_id"],
                url: item,
            }
        });
        selectedImgData.value = imgsData.value[0];
        // 显示
        selectImgShow.value = true;
    } else {
        ElMessage.error(msg);
    }
}

// 选择绘本后的确认
async function selectImgOk() {
    const { code, msg } = await myFetch(`noveltweet/article_upload_image`, {
        task_id: selectedImgData.value.task_id,
        process_id: selectedImgData.value.process_id,
        image: selectedImgData.value.url,
    }, {}, "POST");
    if (code === 2000) {
        refresh(paramsData.value);
        selectImgShow.value = false;
    } else {
        ElMessage.error(msg);
    }
}

function modalClose() {
    !isAutoClose.value && emits("setStep", 0)
}

onMounted(() => {
    // 测试情况下直接刷新
    // refresh({ task_id: 1, process_id: 1 });
    // refreshDrawIng();
})

onUnmounted(() => {
    clearTimeout(refreshTimer.value)
})

defineExpose({
    refresh, refreshDrawIng,
    endLoading: () => {
        isEndLoading.value = true;
        drawIng2.value = true;
    }
})

</script>

<style>

.my-modal .el-dialog__footer{
    padding-bottom: 12px;
 }
 </style>