<template>
     <div class="page page--video-manage lycontainer" :class="{'ly-is-full':isFull}">
        <!-- 0 -->
        <div class="page">
        <div class="xiaoShuo">
            <div class="title">
                <div class="text">小说漫剪</div>

                <el-radio-group v-model="xiaoShuoTabId" @change="xiaoShuoTabChange">
                    <el-radio-button label="1">全部</el-radio-button>
                    <el-radio-button label="2">进行中</el-radio-button>
                </el-radio-group>
            </div>
            <div style="font-size: 12px;padding-top: 12px;padding-left: 12px; color: #777;">
                ！完成的内容请在30天下载到本地，30天后系统会清空内容，清空后内容将无法找回</div>

            <div class="content" v-if="!showNoData">
                <div class="item" v-for="(item, index) in listDataByXS" :key="index">
                    <div class="imgContainer" @click="playVideo(item)">
                        <img v-if="item.img" :src="item.img" alt="img">
                        <!-- 播放背景 -->
                        <div class="bg" v-if="item.state == 4">
                            <el-icon>
                                <VideoPlay />
                            </el-icon>
                        </div>
                    </div>
                    <div class="infoContainer">
                        <div class="infoItem">
                            <span>&nbsp;&nbsp; <span class="label">任务ID：</span>{{ item.task_id }}</span>
                            <span @click="copyText(item.task_id)" style="float: right"><el-icon>
                                    <DocumentCopy />
                                </el-icon></span>
                        </div>
                        <div class="infoItem"><span class="label">创建时间：</span>{{
                            moment(item.createTime).format('YYYY-MM-DD HH: mm')
                        }}</div>
                        <div class="infoItem"><span class="label">任务状态：</span>{{ item.status }}</div>
                        <div class="infoItem" style="text-align: right;height: 32px;">
                            <el-button type="primary" :icon="EditPen" @click="edit(item)"
                                v-if="item.is_automatic == 2 && item.state == 2">继续编辑</el-button>
                            <el-button type="danger" :icon="Delete" circle @click="deleteData(item)"
                                v-if="item.state == 4" />
                            <el-button type="primary" :icon="Download" circle @click="download(item)"
                                v-if="item.state == 4" />
                            <!-- <a :href="item.video" :download="item.task_id" style="color: #2d8cf0;margin-left: 12px;">点击下载</a> -->
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="showNoData">
                <el-empty description="暂无数据~">
                    <el-button type="primary" link @click="refresh">刷新数据</el-button>
                </el-empty>
            </div>
            <!-- 分页 -->
            <div class="my-pageing"
                style=" display: flex; justify-content: flex-end; position: absolute;bottom: 0px;right: 0px; background: #fff; left: 0px;">
                <el-pagination background layout="prev, pager, next" :total="pageing.total_page"
                    :current-page="pageing.current_page" @current-change="pageing.pageChange" style="margin: 12px;" />
            </div>
        </div>
   
   
    </div>

    <!-- 视频播放弹出 -->
    <el-dialog destroy-on-close v-model="videoShow" align-center width="800px" class="my-modal"
        :close-on-click-modal="false">
        <template #header>
            <span>视频预览</span> - {{ videoData.task_id }}
        </template>
        <div class="modal-content">
            <video :src="videoData.video" controls autoplay></video>
        </div>
    </el-dialog>

</div>

</template>



<script setup>
// import { useRouter } from "vue-router"
import router from '../../router'
import { ref, inject, onMounted } from "vue"
import { ElMessage, ElLoading, ElMessageBox } from 'element-plus'
import { DocumentCopy, Download, Delete, EditPen } from '@element-plus/icons-vue'

// 0
let isFull = ref(false)
import moment from "moment"
const emits = defineEmits(["hideBtns"])

const myFetch = inject('myFetch')
const getUserId = inject('getUserId')
const showNoData = ref(true);
// const route = useRouter()

function edit(item) {
    // 跳转到小说页面
    router.push({
        path: "/vaGoodsVideoManage",
        query: {
            step: "2",
            task_id: item.task_id
        }
    })
}

// 小说漫剪数据
const listDataByXS = ref([]);
// 小说慢件 tab id
const xiaoShuoTabId = ref("1");
function xiaoShuoTabChange() {
    pageing.value.current_page = 1;
    // console.log(xiaoShuoTabId.value)
    refresh();
}

// 视频播放弹出窗是否出现
const videoShow = ref(false);
// 视频地址
const videoData = ref();
function playVideo(item) {
    videoData.value = item;
    if (!videoData.value.video) {
        ElMessage.error("视频还未生成，请稍后重试。")
        return;
    }
    videoShow.value = true;
}

// 刷新数据
async function refresh() {
    // showNoData.value = false;
    // listDataByXS.value = [
    //     {
    //         is_automatic: 2,
    //         state: 4,
    //         id: "001",
    //         taskId: "11111",
    //         createTime: 1234567894561,
    //         status: "进行中（完成）",
    //         img: "https://cdn.pixabay.com/photo/2023/07/29/17/36/fly-8157417_1280.jpg",
    //         video: "https://vjs.zencdn.net/v/oceans.mp4"
    //     },
    // ];
    // return;

    const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    // const { code, msg, data } = await myFetch(`noveltweet/task_list?page=${pageing.value.current_page}&pagesize=${pageing.value.page_size}&data_type=${xiaoShuoTabId.value}&user_id=${getUserId()}`);
    const { code, msg, data } = await myFetch(`noveltweet/task_list?page=${pageing.value.current_page}&pagesize=${pageing.value.page_size}&data_type=${xiaoShuoTabId.value}&user_id=${getUserId()}`);
    loading.close(data);
    if (code === 2000) {
        if (!data.list) {
            showNoData.value = true;
            return;
        }

        // 总数
        pageing.value.total_page = data.total_page * pageing.value.page_size;

        showNoData.value = false;
        listDataByXS.value = data.list.map((item, index) => {
            return {
                ...item,
                createTime: item.create_time,
                status: item.state_info,
                img: item.cover_img_url,
                video: item.video_url
            }
        });
    } else {
        ElMessage.error(msg);
    }
}

async function deleteData(item) {

    ElMessageBox.alert('您确认要删除该视频？删除后将无法恢复', '删除视频', {
        // if you want to disable its autofocus
        // autofocus: false,
        confirmButtonText: '删除',
        callback: async (action) => {
            if (action === "cancel") return;
            const loading = ElLoading.service({
                lock: true,
                text: 'Loading',
                background: 'rgba(0, 0, 0, 0.7)',
            })

            // 请求图片数据
            const { code, msg } = await myFetch(`noveltweet/task_delete`, {
                "task_id": item["task_id"],
                "user_id": getUserId()
            }, {}, "DELETE");
            loading.close();
            if (code === 2000) {
                refresh();
            } else {
                ElMessage.error(msg);
            }
        },
    })


}
function download(item) {
    window.open(item.video, "_block")
}

// 分页相关数据 
const pageing = ref({
    total_page: 0,
    current_page: 1,
    page_size: 10,
    pageChange: (value) => {
        // console.log('value', value)
        pageing.value.current_page = value;
        refresh();
    }
})

onMounted(() => {
    refresh();
})


function copyText(text) {
    navigator.clipboard.writeText(text).then(function () {
        ElMessage.success("已复制到粘贴板！")
    }, function () {
        ElMessage.error("复制失败")
    })
}

</script>
<style scoped lang="scss">
.page{
    width: 100%;
    height: 100%;
}

.xiaoShuo{
    padding-bottom: 100px; 
    position: relative;
}
.xiaoShuo .title{
    /* background-color: #fff; */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: space-between; */
    padding: 8px 8px;
    /* box-shadow: 1px 1px 3px #ccc; */
    /* border-radius: 8px; */
}
.xiaoShuo .title .text{
    font-weight: 600;
    padding: 12px 0px;
}

.xiaoShuo .content{ 
    display: flex;
    flex-wrap: wrap; 
}
.xiaoShuo .item{
    width: 380px; 
    background-color: #fff;
    margin-left: 12px;
    margin-top: 12px;
    box-sizing: border-box;
    cursor: pointer;
    box-shadow: 1px 1px 3px #ccc;
    border-radius: 8px;
    transition: 0.3s;

    display: flex;
    align-items: center;
    overflow: hidden;
}

.xiaoShuo .item:hover{
    transition: 0.3s;
    box-shadow: 3px 3px 5px #ccc;
}

.xiaoShuo .item .imgContainer{
    flex-basis: 100px;
    height: 100%;
    /* padding-right: 12px; */
    box-sizing: border-box;
    position: relative;
}
.xiaoShuo .item .imgContainer img{
    width: 120px;
    height: 100%;
    object-fit: cover;
}
.xiaoShuo .item  .imgContainer .bg{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    background: #d6d5d5b9;
    text-align: center;
    font-size: 52px;
    color: #00a7da;
    line-height: 140px; 
}

.xiaoShuo .item .infoContainer{
    padding: 6px 12px;
    box-sizing: border-box;
    flex: 1;
}
.xiaoShuo .item .infoContainer .infoItem{
    font-size: 14px;
    color: #575656;
    line-height: 30px;
}

.xiaoShuo .item .infoContainer .infoItem .label{
    font-weight: 600;
}


.modal-content video{
    width: 100%;
}

</style>