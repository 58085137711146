<template>
    <div class="page">
        <el-form label-position="top" ref="ruleFormRef" :model="formValues" :rules="rules" label-width="120px"
            class="my-form">

            <el-row :gutter="12">
                <el-col :span="12">
                    <el-form-item label="角色姓名" prop="role_name" col="2">
                        <el-input v-model="formValues.role_name" maxlength="20" placeholder="请输入" show-word-limit
                            type="text" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="角色别名" prop="alias_name">
                        <el-input v-model="formValues.alias_name" maxlength="50" placeholder="多别名用逗号隔开" show-word-limit
                            type="text" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-form-item>
                <div><span style="color: red;">*</span> 人物特征（<span :style="{ color: selectedTags?.length > 10 ? 'red' : '' }">{{ selectedTags?.length
                }}/10</span>）
                </div>
                <el-tabs v-model="tabActiveName" class="select-tabs">
                    <el-tab-pane class="select-tabs-pane" :label="item.label" :name="index + ''"
                        v-for="(item, index) in tabData">
                        <el-tag class="tag" size="large" effect="dark" v-for="item in getTagsbyTabId(item.value)"
                            @click="tagClick(item)" :type="tagIsSelected(item) ? '' : 'info'">
                            {{ item.label }}
                        </el-tag>
                    </el-tab-pane>
                </el-tabs>
                <div>
                    已选标签：
                    <span v-for="(item, index) in selectedTags" :key="item.id">
                        {{ index === 0 ? "" : "、" }}
                        {{ item.label }}
                    </span>
                </div>
            </el-form-item>
            <el-form-item label="补充描述" prop="custom_tag">
                <el-input v-model="formValues.custom_tag" maxlength="100" placeholder="补充以上标签中没有的角色特点" show-word-limit
                    type="textarea" />
            </el-form-item>


        </el-form>
    </div>
</template>
<style src="./style.css" scoped></style>
<script setup>
import { ref, inject, reactive, onMounted } from "vue"
import { ElMessage, ElLoading } from 'element-plus'
const myFetch = inject('myFetch')

const ruleFormRef = ref()
const formValues = ref({
    role_name: "",
    alias_name: "", 
})

const rules = reactive({
    role_name: [
        { required: true, message: '必填项' },
    ],  
})

// 人物特征选择相关变量
const tabActiveName = ref("0"); // 当前tab页面id
const selectedTags = ref([]); // 已经选择的 tag
// 标签数据
const tabData = ref([]);

// 根据标签页面 value 获取标签下面的 tags 数据
function getTagsbyTabId(tabId) {
    return tabData.value.find(item => item.value === tabId)?.tags || []
}

// 根据标签id 获取标签数据
function getTagbyId(tagId) {
    return tabData.value.reduce((pre, cur) => pre.concat(cur.tags), []).find(item => item.value == tagId)
}

// 判断标签是否被选中
function tagIsSelected(tagData) {
    return selectedTags.value.find(item => item.value == tagData.value);
}

// 点击 tag
function tagClick(tagData) {
    if (selectedTags.value?.length >= 10) {
        ElMessage.error("已经超出最大可选数量了");
        return;
    }
    if (tagIsSelected(tagData)) {
        // 说明已经选择了，需要删除
        selectedTags.value = selectedTags.value.filter(item => item.value != tagData.value);
    } else {
        // 未选中，需要改为选择状态
        selectedTags.value.push(tagData)
    }
}

// 刷新标签数据
async function refresh() {
    // tabData.value = [
    //     {
    //         value: "0",
    //         label: "人物身份",
    //         tags: [
    //             { label: "少女", value: "shao-nv" },
    //             { label: "少男", value: "shao-nan" },
    //         ]
    //     }
    // ];

    const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    const { code, msg, data } = await myFetch("noveltweet/character_tag_list");
    loading.close()
    if (code === 2000) {
        tabData.value = data.list.map(item => ({
            label: item["title"],
            value: item["id"],
            tags: item["tag_list"].map(item => ({
                label: item["title"],
                value: item["id"] + '',
            }))
        }))
    } else {
        ElMessage.error(msg);
    }
}

onMounted(() => {
    refresh();
})

const getValues = async () => {
    return new Promise((resolve) => {
        ruleFormRef.value.validate((valid, fields) => {
            if (valid) {
                if(!selectedTags.value?.length){
                    ElMessage.error("请选择人物特征！");
                    return;
                }
                resolve({
                    ...formValues.value,
                    tag_id_list: selectedTags.value.map(item => item["value"]).join(",")
                })
            } else {
                console.log('error submit!', fields)
            }
        })
    })
}

const setValues = async (values) => {
    formValues.value = {
        ...values
    }
    selectedTags.value = values["tags"];
    // selectedTags.value = formValues.value["tag_id_list"]?.split?.(",")?.map(id => {
    //     // console.log('----', id, getTagbyId(id))
    //     return {
    //         label: getTagbyId(id)?.label,
    //         value: getTagbyId(id)?.value
    //     }
    // });
}
defineExpose({ getValues, setValues })
</script>
