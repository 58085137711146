<template>
    <div class="page page--video-manage lycontainer" :class="{'ly-is-full':isFull}">
        <div class="flex-1">
            <el-tabs 
                class="tabs tabs--top-nav"
                type="border-card" 
                v-model="activeTopNavTab" 
            >
                <el-tab-pane
                    class="tab-pane tab-pane--goods"
                    name="goods"
                    label="短视频"
                >
                    <template #label>
                    <span class="label flex items-center">
                        <el-icon><PriceTag /></el-icon>
                        <span>短视频</span>
                    </span>
                    </template>
                    <el-container
                        class="container container--goods -mt-2"
                    >
                        <el-aside
                            class="aside aside--task-nav flex flex-col pr-2"
                            width="320px">
                            <div
                                class="flex flex-col mt-2 mb-2"
                                @click="onShowGoodsVideoMain"
                            >
                                <h5
                                    class="flex flex-row justify-between items-center"
                                >
                                    <span class="flex flex-row items-center justify-between">
                                        <el-icon size="16px">
                                            <Collection />
                                        </el-icon>
                                        <i class="ml-1 not-italic ">短视频管理-通用版</i>
                                    </span>
                                    <el-tooltip
                                        content="查看我的收藏列表"
                                        placement="left-start"
                                    >
                                        <el-icon>
                                            <CollectionTag size="32px"></CollectionTag>
                                        </el-icon>
                                    </el-tooltip>
                                </h5>
                                <p class="mt-2">
                                    <el-text type="info">
                                        简单几步，快速生成商业效果短视频
                                    </el-text>
                                </p>
                            </div>
                            <div class="border-t-dashed border-t-2 mt-1 pt-1">
                                <el-button
                                    round 
                                    plain
                                    type="primary"
                                    size="large"
                                    class="w-full"
                                    @click="onNewGoodsVideoTask"
                                >
                                    <i class="not-italic text-large font-bold">新建任务</i>
                                </el-button>
                            </div>
                            <div class="mt-4 mb-1 pb-6">
                                <el-scrollbar
                                    style="height: calc(100vh - 300px)"
                                >
                                    <ul class="list">
                                        <li
                                            class="flex items-center list-item list-item--task h-14 mb-4 pl-2 pr-2 rounded"
                                            v-for="(task, index) in userTaskList"
                                            :key="index"
                                            :class="{'active': goodsVideoTaskCurrentId == task.id}"
                                            @click="onShowGoodsVideoCurrentTask(task.id, task)"
                                        >
                                            <div class="item-thumb">
                                                <el-image
                                                    :src="task.thumb_url"
                                                    loading="lazy"
                                                    fit="cover"
                                                    style="width: 40px;height:40px"
                                                    class="mr-2 rounded"
                                                >
                                                    <template #placeholder>
                                                        <el-icon><CameraFilled /></el-icon>
                                                    </template>
                                                </el-image>
                                            </div>
                                            <div class="item-content flex items-center">
                                                <div class="title text-sm">{{  task.name }}</div>
                                                <div class="subtitle text-sm">{{  task.status_text?'('+task.status_text+')':'' }}</div>
                                            </div>
                                            <div class="item-actions">

                                            </div>
                                        </li>
                                    </ul>
                                    <div
                                        class="more-director text-center mt-4"
                                    >
                                        <el-button
                                            round
                                            plain
                                            @click="onMoreUserTaskList"
                                            v-if="hasMoreUserTaskList"
                                        >更多历史任务</el-button>
                                        <el-button
                                            disabled
                                            round
                                            plain
                                            v-if="!hasMoreUserTaskList"
                                        >已经到底了</el-button>
                                    </div>
                                </el-scrollbar>
                            </div>
                        </el-aside>
                        <el-main
                            class="main bg-neutral-100 pt-0 pb-2 -mr-[15]"
                        >
                            <article
                                class="section--main mb-4"
                                v-if="isShowGoodsVideoMain"
                            >
                                <el-tabs
                                    v-model="activeMainNavTab"
                                    class="tabs "
                                >
                                    <el-tab-pane
                                        name="collect"
                                        class="pb-6 flex flex-col"
                                    >
                                        <template #label>
                                            <el-text type="info" size="default" class="font-medium text-base neutral-700">我收藏的短视频</el-text>
                                        </template>
                                        <div
                                            class="label flex justify-start items-center"
                                            v-if="userVideoCollectList && userVideoCollectList.length == 0"
                                        >
                                            <el-empty
                                                description="暂无收藏短视频"
                                            >
                                            </el-empty>
                                        </div>
                                        <div
                                            class="flex flex-row flex-wrap"
                                            v-if="userVideoCollectList && userVideoCollectList.length > 0"
                                        >
                                            <div
                                                class="flex"
                                                v-for="(collect,index) in userVideoCollectList"
                                                :key="index"
                                            >
                                                <figure
                                                    class="task-video shadow-none rounded p-1 ml-2 mr-2 mb-2"
                                                    v-if="collect.is_collected"
                                                >
                                                    <div class="video">
                                                        <el-image
                                                            fit="cover"
                                                            loading="lazy"
                                                            class="rounded-xl"
                                                            style="width: 252px;"
                                                            :src="collect.object_asset_url"
                                                            :preview-src-list="[collect.object_asset_url]"
                                                        >
                                                            <template #placeholder>
                                                                <el-icon><CameraFilled /></el-icon>
                                                            </template>
                                                        </el-image>
                                                    </div>
                                                    <div class="footer mt-1">
                                                        <div class="flex flex-row justify-between">
                                                            <div class="action"
                                                                @click="onCollectTaskVideo(
                                                                    collect, 
                                                                    collect.is_collected, 
                                                                    collect.task_id, 
                                                                    collect.object_id, 
                                                                    collect.task_media_site, 
                                                                    collect.object_asset_url
                                                                )"
                                                            >
                                                                <el-icon>
                                                                    <StarFilled size="24px"></StarFilled>
                                                                </el-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </figure>
                                            </div>
                                        </div>
                                        <footer
                                            class="more-director text-center"
                                            v-if="userVideoCollectList && userVideoCollectList.length > 0"
                                        >
                                            <el-button
                                                round
                                                plain
                                                @click="onMoreUserCollects"
                                                v-if="hasMoreUserCollectList"
                                            >更多收藏</el-button>
                                            <el-text
                                                class="info"
                                                v-if="!hasMoreUserCollectList"
                                            >
                                                没有更多了
                                            </el-text>
                                        </footer>
                                    </el-tab-pane>
                                </el-tabs>
                            </article>

                            <article
                                class="section--current-task-doing bg-neutral-100 pb-16"
                                v-if="isShowCurrentGoodsVideoTask && isCurrentGoodsVideoTaskTodo"
                            >
                                <nav class="mb-2 mt-2">
                                    <el-steps :align-center="true"
                                        :active="goodsVideoTaskCurrentStep"
                                    >
                                        <el-step title="选择主题">
                                            <template #description>
                                                <div
                                                    class="flex flex-col items-center justify-center"
                                                >
                                                    <el-image
                                                        :src="subjectCurrentThumbUrl"
                                                        style="width: 96px;"
                                                        loading="lazy"
                                                        fit="contain"
                                                        :preview-src-list="[subjectCurrentThumbUrl]"
                                                        v-if="subjectCurrentThumbUrl"
                                                    >
                                                        <template #placeholder>
                                                            <el-icon><CameraFilled /></el-icon>
                                                        </template>
                                                    </el-image>
                                                    <el-text
                                                        type="info"
                                                        v-if="subjectCurrentName"
                                                    >{{ subjectCurrentName }}</el-text>
                                                    <el-text
                                                        type="info"
                                                        v-if="!subjectCurrentName"
                                                    >等待选取</el-text>
                                                </div>
                                            </template>
                                        </el-step>
                                        <el-step title="选择训练模型">
                                            <template #description>
                                                <div
                                                    class="flex flex-col items-center justify-center"
                                                >
                                                    <el-image
                                                        :src="pretrainModelCurrentThumbUrl"
                                                        style="width: 96px;"
                                                        loading="lazy"
                                                        fit="contain"
                                                        :preview-src-list="[pretrainModelCurrentThumbUrl]"
                                                        v-if="pretrainModelCurrentThumbUrl"
                                                    >
                                                        <template #placeholder>
                                                            <el-icon><CameraFilled /></el-icon>
                                                        </template>
                                                    </el-image>
                                                    <el-text
                                                        type="info"
                                                        v-if="pretrainModelCurrentName"
                                                    >{{ pretrainModelCurrentName }}</el-text>
                                                    <el-text
                                                        type="info"
                                                        v-if="!pretrainModelCurrentName"
                                                    >等待选取</el-text>
                                                </div>
                                            </template>
                                        </el-step>
                                        <el-step title="选择场景">
                                            <template #description>
                                                <el-space wrap>
                                                    <el-text
                                                        type="info"
                                                        line-clamp="2"
                                                        class="p-1 border border-dashed rounded"
                                                        v-if="goodsVideoBuildOptions.prompt_cn"
                                                    >
                                                        {{ goodsVideoBuildOptions.prompt_cn }}
                                                    </el-text>
                                                    <el-text
                                                        type="info"
                                                        line-clamp="2"
                                                        class="p-1 border border-dashed rounded"
                                                        v-if="goodsVideoBuildOptions.prompt_en"
                                                    >
                                                        {{ goodsVideoBuildOptions.prompt_en }}
                                                    </el-text>
                                                    <el-tag size="default" v-if="goodsVideoBuildOptions.medium_style">
                                                        {{ goodsVideoBuildOptions.medium_style }}
                                                    </el-tag>
                                                    <el-tag size="default" v-if="goodsVideoBuildOptions.composition_style">
                                                        {{ goodsVideoBuildOptions.composition_style }}
                                                    </el-tag>
                                                    <el-tag size="default" v-if="goodsVideoBuildOptions.paint_style">
                                                        {{ goodsVideoBuildOptions.paint_style }}
                                                    </el-tag>
                                                    <el-tag size="default" v-if="goodsVideoBuildOptions.scene_style">
                                                        {{ goodsVideoBuildOptions.scene_style }}
                                                    </el-tag>
                                                    <el-tag size="default" v-if="goodsVideoBuildOptions.location_style">
                                                        {{ goodsVideoBuildOptions.location_style }}
                                                    </el-tag>
                                                    <el-tag size="default" v-if="goodsVideoBuildOptions.person_style">
                                                        {{ goodsVideoBuildOptions.person_style }}
                                                    </el-tag>
                                                    <el-tag size="default" v-if="goodsVideoBuildOptions.face_style">
                                                        {{ goodsVideoBuildOptions.face_style }}
                                                    </el-tag>
                                                    <el-tag size="default" v-if="goodsVideoBuildOptions.tag_style">
                                                        {{ goodsVideoBuildOptions.tag_style }}
                                                    </el-tag>

                                                </el-space>
                                            </template>
                                        </el-step>
                                        <el-step title="提交任务">
                                            <template #description>
                                            </template>
                                        </el-step>
                                    </el-steps>
                                </nav>
                                <div class="flex pb-6 mt-4">
                                    <aside class="flex flex-col justify-start items-center pl-1 pr-2 pt-2 pb-8 main-gutter border-0 border-dashed border-neutral-700">
                                        <div
                                            class="gutter-switch p-2 rounded border-r border border-dashed  border-neutral-500 mb-2 flex flex-col justify-center items-center"
                                            :class="{'active': goodsVideoTaskCurrentMainOption == 'subject'}"
                                            @click="onSwitchMainOption2Subject"
                                        >
                                            <h5 class="text-large font-bold">选择主题</h5>
                                            <el-icon><Place /></el-icon>
                                        </div>
                                        <i class="not-italic flex flex-col justify-center items-center w-full mt-1 mb-2">
                                            <el-icon color="#737373"><Bottom /></el-icon>
                                        </i>
                                        <div
                                            class="gutter-switch p-2 rounded border-r border border-dashed  border-neutral-500 mb-2 flex flex-col justify-center items-center"
                                            :class="{'active': goodsVideoTaskCurrentMainOption == 'model'}"
                                            @click="onSwitchMainOption2Model"
                                        >
                                            <h5 class="text-large font-bold">选择训练模型</h5>
                                            <el-icon><Place /></el-icon>
                                        </div>
                                        <i class="not-italic flex flex-col justify-center items-center w-full mt-1 mb-2">
                                            <el-icon color="#737373"><Bottom /></el-icon>
                                        </i>
                                        <div
                                            class="gutter-switch p-2 rounded border-r border border-dashed  border-neutral-500 mb-2 flex flex-col justify-center items-center"
                                            :class="{'active': goodsVideoTaskCurrentMainOption == 'scene'}"
                                            @click="onSwitchMainOption2Scene"
                                        >
                                            <h5 class="text-large font-bold">选择场景</h5>
                                            <el-icon><Place /></el-icon>
                                        </div>
                                        <i class="not-italic flex flex-col justify-center items-center w-full mt-1 mb-2">
                                            <el-icon color="#737373"><Bottom /></el-icon>
                                        </i>
                                        <div
                                            class="gutter-switch p-2 rounded border-r border border-dashed  border-neutral-500 mb-2 flex flex-col justify-center items-center"
                                            :class="{'active': goodsVideoTaskCurrentMainOption == 'status'}"
                                            @click="onSwitchMainOption2Status"
                                        >
                                            <h5 class="text-large font-bold">生成状态</h5>
                                            <el-icon>
                                                <DCaret></DCaret>
                                            </el-icon>
                                        </div>
                                    </aside>
                                    <div class="flex flex-1 pl-2 pb-6 main-options">
                                        <div
                                            class="option--number flex flex-col pt-2"
                                            v-if="goodsVideoTaskCurrentMainOption == 'subject'"
                                        >
                                            <h5
                                                class="section-header text-large font-bold mb-4"
                                            >
                                                当前主题
                                            </h5>
                                            <div
                                                class="flex flex-wrap"
                                            >
                                                <el-radio-group
                                                    v-model="subjectCurrentThumbUrl"
                                                >
                                                <figure
                                                    class="mr-1 mt-1 flex flex-col"
                                                    v-for="(subject,index) in videoSubjectList"
                                                    :key="index"
                                                >
                                                    <el-image 
                                                        :src="subject.thumb_url"
                                                        :preview-src-list="[subject.thumb_url]"
                                                        style="width: 136px;height:136px"
                                                        class="rounded"
                                                        fit="cover"
                                                        loading="lazy"
                                                        @click="onChangeSubject({
                                                                'thumb_url': subject.thumb_url, 
                                                                'id': subject.id, 
                                                                'name': subject.name,
                                                                'subject': subject})"
                                                    >
                                                        <template #placeholder>
                                                            <el-icon><CameraFilled /></el-icon>
                                                        </template>
                                                    </el-image>

                                                    <el-text
                                                        type="info"    
                                                        class="mt-1"
                                                        @click="onChangeSubject({
                                                                'thumb_url': subject.thumb_url, 
                                                                'id': subject.id, 
                                                                'name': subject.name,
                                                                'subject': subject})"
                                                    >
                                                        {{ subject.name }}
                                                    </el-text>
                                                    <figcaption class="ml-1 flex items-center justify-between">
                                                        <el-radio 
                                                            :label="subject.thumb_url"
                                                            data-id="subject.id"
                                                            @change="onChangeSubject({
                                                                'thumb_url': subject.thumb_url, 
                                                                'id': subject.id, 
                                                                'name': subject.name,
                                                                'subject': subject})"
                                                        >使用这个主题</el-radio>
                                                    </figcaption>
                                                </figure>
                                                </el-radio-group>
                                            </div>
                                        </div>
                                        <div
                                            class="option--number flex flex-col pt-2"
                                            v-if="goodsVideoTaskCurrentMainOption == 'model'"
                                        >
                                            <h5
                                                class="section-header text-large font-bold mb-4"
                                            >
                                                当前用户预训练模型
                                            </h5>
                                            <div
                                                class="flex flex-wrap"
                                            >
                                                <el-radio-group
                                                    v-model="pretrainModelCurrentThumbUrl"
                                                >
                                                <figure
                                                    class="mr-1 mt-1 flex flex-col"
                                                    v-for="(model,index) in videoPretrainModelList"
                                                    :key="index"
                                                >
                                                    <el-image 
                                                        :src="model.thumb_url"
                                                        :preview-src-list="[model.thumb_url]"
                                                        style="width: 136px;height:136px"
                                                        class="rounded"
                                                        fit="cover"
                                                        loading="lazy"
                                                        @click="onChangePretrainModel({
                                                                'thumb_url': model.thumb_url, 
                                                                'id': model.id, 
                                                                'name': model.name,
                                                                'model': model})"
                                                    >
                                                        <template #placeholder>
                                                            <el-icon><CameraFilled /></el-icon>
                                                        </template>
                                                    </el-image>
                                                    <el-text
                                                        type="info"    
                                                        class="mt-1"
                                                        @click="onChangePretrainModel({
                                                                'thumb_url': model.thumb_url, 
                                                                'id': model.id, 
                                                                'name': model.name,
                                                                'model': model})"
                                                    >
                                                        {{ model.name }}
                                                    </el-text>
                                                    <figcaption class="ml-1 flex items-center justify-between">
                                                        <el-radio 
                                                            :label="model.thumb_url"
                                                            data-id="model.id"
                                                            @change="onChangePretrainModel({
                                                                'thumb_url': model.thumb_url, 
                                                                'id': model.id, 
                                                                'name': model.name,
                                                                'model': model})"
                                                        >使用这个模型</el-radio>
                                                    </figcaption>
                                                </figure>
                                                </el-radio-group>
                                            </div>
                                        </div>
                                        <div
                                            class="option--scene flex flex-col pt-2"
                                            v-if="goodsVideoTaskCurrentMainOption == 'scene'"
                                        >
                                            <el-tabs
                                                v-model="activeNewNavTab"
                                                class="mt-0 w-auto"
                                            >
                                                <el-tab-pane
                                                    name="stencil">
                                                    <template #label>
                                                        <el-text type="info" size="default" class="font-medium text-base neutral-700">场景模板</el-text>
                                                    </template>
                                                    <el-form
                                                        label-position="top"
                                                        label-width="100px"
                                                        :model="goodsVideoBuildOptions"
                                                    >
                                                        <el-form-item label="">
                                                            <template #label>
                                                                <el-text type="info" size="default" class="font-medium text-base neutral-700">媒质类型</el-text>
                                                            </template>
                                                            <div
                                                                class="flex flex-row flex-wrap pl-6"
                                                            >
                                                                <el-radio-group
                                                                    v-model="goodsVideoBuildOptions.medium_style"
                                                                >
                                                                <div
                                                                    class="flex flex-row flex-wrap"
                                                                    v-for="(stencil,index) in videoStencilMediumStyleList"
                                                                    :key="index"
                                                                >
                                                                    <figure class="flex flex-col mr-1">
                                                                        <el-image
                                                                            :src="stencil.thumb_url"
                                                                            style="width: 40px;height:40px"
                                                                            :preview-src-list="[stencil.thumb_url]"
                                                                            fit="cover"
                                                                            loading="lazy"
                                                                            @click="onChangeVideoStencilMediumStyle(stencil)"
                                                                            >
                                                                            <template #placeholder>
                                                                                <el-icon><CameraFilled /></el-icon>
                                                                            </template>
                                                                        </el-image>
                                                                        <el-radio
                                                                            class="mt-1"
                                                                            :label="stencil.name"
                                                                            @change="onChangeVideoStencilMediumStyle(stencil)"
                                                                        >
                                                                        {{  stencil.name }}
                                                                        </el-radio>
                                                                    </figure>
                                                                </div>
                                                                </el-radio-group>
                                                            </div>
                                                        </el-form-item>
                                                        <el-form-item label="">
                                                            <template #label>
                                                                <el-text type="info" size="default" class="font-medium text-base neutral-700">构图类型</el-text>
                                                            </template>
                                                            <div
                                                                class="flex flex-row flex-wrap pl-6"
                                                            >
                                                                <el-radio-group
                                                                    v-model="goodsVideoBuildOptions.composition_style"
                                                                >
                                                                <div
                                                                    class="flex flex-row flex-wrap"
                                                                    v-for="(stencil,index) in videoStencilCompositionStyleList"
                                                                    :key="index"
                                                                >
                                                                    <figure class="flex flex-col mr-1">
                                                                        <el-image
                                                                            :src="stencil.thumb_url"
                                                                            style="width: 40px;height:40px"
                                                                            :preview-src-list="[stencil.thumb_url]"
                                                                            fit="cover"
                                                                            loading="lazy"
                                                                            @click="onChangeVideoStencilCompositionStyle(stencil)"
                                                                        >
                                                                            <template #placeholder>
                                                                                <el-icon><CameraFilled /></el-icon>
                                                                            </template>
                                                                        </el-image>
                                                                        <el-radio
                                                                            class="mt-1"
                                                                            :label="stencil.name"
                                                                            @change="onChangeVideoStencilCompositionStyle(stencil)"
                                                                        >
                                                                        {{  stencil.name }}
                                                                        </el-radio>
                                                                    </figure>
                                                                </div>
                                                                </el-radio-group>
                                                            </div>
                                                        </el-form-item>
                                                        <el-form-item label="">
                                                            <template #label>
                                                                <el-text type="info" size="default" class="font-medium text-base neutral-700">场景/动作</el-text>
                                                            </template>
                                                            <div
                                                                class="flex flex-row flex-wrap pl-6"
                                                            >
                                                                <el-radio-group
                                                                    v-model="goodsVideoBuildOptions.scene_style"
                                                                >
                                                                <div
                                                                    class="flex flex-row flex-wrap"
                                                                    v-for="(stencil,index) in videoStencilSceneStyleList"
                                                                    :key="index"
                                                                >
                                                                    <figure class="flex flex-col mr-1">
                                                                        <el-image
                                                                            :src="stencil.thumb_url"
                                                                            style="width: 40px;height:40px"
                                                                            :preview-src-list="[stencil.thumb_url]"
                                                                            fit="cover"
                                                                            loading="lazy"
                                                                            @click="onChangeVideoStencilSceneStyle(stencil)"
                                                                        >
                                                                            <template #placeholder>
                                                                                <el-icon><CameraFilled /></el-icon>
                                                                            </template>
                                                                        </el-image>
                                                                        <el-radio
                                                                            class="mt-1"
                                                                            :label="stencil.name"
                                                                            @change="onChangeVideoStencilSceneStyle(stencil)"
                                                                        >
                                                                        {{  stencil.name }}
                                                                        </el-radio>
                                                                    </figure>
                                                                </div>
                                                                </el-radio-group>
                                                            </div>
                                                        </el-form-item>
                                                        <!--
                                                        <el-form-item label="">
                                                            <template #label>
                                                                <el-text type="info" size="default" class="font-medium text-base neutral-700">画风类型</el-text>
                                                            </template>
                                                            <div
                                                                class="flex flex-row flex-wrap pl-6"
                                                            >
                                                                <el-radio-group
                                                                    v-model="goodsVideoBuildOptions.paint_style"
                                                                >
                                                                <div
                                                                    class="flex flex-row flex-wrap"
                                                                    v-for="(stencil,index) in videoStencilPaintStyleList"
                                                                    :key="index"
                                                                >
                                                                    <figure class="flex flex-col mr-1">
                                                                        <el-image
                                                                            :src="stencil.thumb_url"
                                                                            style="width: 40px;height:40px"
                                                                            :preview-src-list="[stencil.thumb_url]"
                                                                            fit="cover"
                                                                            loading="lazy"
                                                                        ></el-image>
                                                                        <el-radio
                                                                            class="mt-1"
                                                                            :label="stencil.name"
                                                                            @change="onChangeVideoStencilPaintStyle(stencil)"
                                                                        >
                                                                        {{  stencil.name }}
                                                                        </el-radio>
                                                                    </figure>
                                                                </div>
                                                                </el-radio-group>
                                                            </div>
                                                        </el-form-item> -->
                                                        <el-form-item label="">
                                                            <template #label>
                                                                <el-text type="info" size="default" class="font-medium text-base neutral-700">位置</el-text>
                                                            </template>
                                                            <div
                                                                class="flex flex-row flex-wrap pl-6"
                                                            >
                                                                <el-radio-group
                                                                    v-model="goodsVideoBuildOptions.location_style"
                                                                >
                                                                <div
                                                                    class="flex flex-row flex-wrap"
                                                                    v-for="(stencil,index) in videoStencilLocationStyleList"
                                                                    :key="index"
                                                                >
                                                                    <figure class="flex flex-col mr-1">
                                                                        <el-image
                                                                            :src="stencil.thumb_url"
                                                                            style="width: 40px;height:40px"
                                                                            :preview-src-list="[stencil.thumb_url]"
                                                                            fit="cover"
                                                                            loading="lazy"
                                                                        >
                                                                            <template #placeholder>
                                                                                <el-icon><CameraFilled /></el-icon>
                                                                            </template>
                                                                        </el-image>
                                                                        <el-radio
                                                                            class="mt-1"
                                                                            :label="stencil.name"
                                                                            @change="onChangeVideoStencilLocationStyle(stencil)"
                                                                        >
                                                                        {{  stencil.name }}
                                                                        </el-radio>
                                                                    </figure>
                                                                </div>
                                                                </el-radio-group>
                                                            </div>
                                                        </el-form-item>
                                                        <!--
                                                        <el-form-item label="">
                                                            <template #label>
                                                                <el-text type="info" size="default" class="font-medium text-base neutral-700">人物类型</el-text>
                                                            </template>
                                                            <div
                                                                class="flex flex-row flex-wrap pl-6"
                                                            >
                                                                <el-radio-group
                                                                    v-model="goodsVideoBuildOptions.person_style"
                                                                >
                                                                <div
                                                                    class="flex flex-row flex-wrap"
                                                                    v-for="(stencil,index) in videoStencilPersonStyleList"
                                                                    :key="index"
                                                                >
                                                                    <figure class="flex flex-col mr-1">
                                                                        <el-image
                                                                            :src="stencil.thumb_url"
                                                                            style="width: 40px;height:40px"
                                                                            :preview-src-list="[stencil.thumb_url]"
                                                                            fit="cover"
                                                                            loading="lazy"
                                                                        ></el-image>
                                                                        <el-radio
                                                                            class="mt-1"
                                                                            :label="stencil.name"
                                                                            @change="onChangeVideoStencilPersonStyle(stencil)"
                                                                        >
                                                                        {{  stencil.name }}
                                                                        </el-radio>
                                                                    </figure>
                                                                </div>
                                                                </el-radio-group>
                                                            </div>
                                                        </el-form-item> -->
                                                        <!--
                                                        <el-form-item label="">
                                                            <template #label>
                                                                <el-text type="info" size="default" class="font-medium text-base neutral-700">面部风格</el-text>
                                                            </template>
                                                            <div
                                                                class="flex flex-row flex-wrap pl-6"
                                                            >
                                                                <el-radio-group
                                                                    v-model="goodsVideoBuildOptions.face_style"
                                                                >
                                                                <div
                                                                    class="flex flex-row flex-wrap"
                                                                    v-for="(stencil,index) in videoStencilFaceStyleList"
                                                                    :key="index"
                                                                >
                                                                    <figure class="flex flex-col mr-1">
                                                                        <el-image
                                                                            :src="stencil.thumb_url"
                                                                            style="width: 40px;height:40px"
                                                                            :preview-src-list="[stencil.thumb_url]"
                                                                            fit="cover"
                                                                            loading="lazy"
                                                                        ></el-image>
                                                                        <el-radio
                                                                            class="mt-1"
                                                                            :label="stencil.name"
                                                                            @change="onChangeVideoStencilFaceStyle(stencil)"
                                                                        >
                                                                        {{  stencil.name }}
                                                                        </el-radio>
                                                                    </figure>
                                                                </div>
                                                                </el-radio-group>
                                                            </div>
                                                        </el-form-item> -->
                                                        <!--
                                                        <el-form-item label="">
                                                            <template #label>
                                                                <el-text type="info" size="default" class="font-medium text-base neutral-700">标签</el-text>
                                                            </template>
                                                        </el-form-item> -->
                                                    </el-form>
                                                </el-tab-pane>
                                                <el-tab-pane
                                                    name="custom"
                                                >
                                                    <template #label>
                                                        <el-text type="info" size="default" class="font-medium text-base neutral-700">场景/动作自定义</el-text>
                                                    </template>
                                                    <el-form
                                                        label-position="left"
                                                        label-width="100px"
                                                    >
                                                        <el-form-item label="">
                                                            <template #label>
                                                                <el-text type="info" size="default" class="font-medium text-base neutral-700">中文场景/动作说明</el-text>
                                                            </template>
                                                            <el-input
                                                                v-model="goodsVideoBuildOptions.prompt_cn"
                                                                type="textarea"
                                                                :autosize="{minRows: 3, maxRows: 5}"
                                                                placeholder="文字描述"
                                                            ></el-input>
                                                        </el-form-item>
                                                        <el-form-item label="">
                                                            <el-button
                                                                plain
                                                                round
                                                                class="btn--translate"
                                                                @click="onTranslateChinesePromptByAlibaba"
                                                            >中译英</el-button>
                                                        </el-form-item>                                        
                                                        <el-form-item label="">
                                                            <template #label>
                                                                <el-text type="info" size="default" class="font-medium text-base neutral-700">英文提示词（生成需要）</el-text>
                                                            </template>
                                                            <el-input
                                                                v-model="goodsVideoBuildOptions.prompt_en"
                                                                type="textarea"
                                                                :autosize="{minRows: 3, maxRows: 5}"
                                                                placeholder="文字描述"
                                                            ></el-input>
                                                        </el-form-item>                                        
                                                    </el-form>
                                                    <blockquote class="help-block mt-1">
                                                        <el-text
                                                            type="info"
                                                            size="default"
                                                        >
                                                            {{ goodsVideoBuildHelp }}
                                                        </el-text>
                                                    </blockquote>
                                                </el-tab-pane>
                                            </el-tabs>
                                        </div>
                                        <div
                                            class="option--status flex flex-1 flex-col pt-2"
                                            v-if="goodsVideoTaskCurrentMainOption == 'status'"
                                        >
                                            <h5 class="text-large font-bold mb-4">
                                                生成状态
                                            </h5>
                                            <p class="ml-2 flex-1">
                                                <blockquote
                                                    class="px-1 py-1 flex flex-1 flex-col"
                                                    v-if="goodsVideoTaskProgressing"
                                                >
                                                    <el-space
                                                        wrap
                                                        v-if="hasVideoTaskProgressPreview && goodsVideoTaskVideosPreview.length > 0"
                                                    >
                                                        <el-image
                                                            :src="video"
                                                            style="width: 96px;"
                                                            loading="lazy"
                                                            fit="contain"
                                                            :preview-src-list="[video]"
                                                            v-for="(video,index) in goodsVideoTaskVideosPreview"
                                                            :key="index"
                                                        >
                                                            <template #placeholder>
                                                                <el-icon><CameraFilled /></el-icon>
                                                            </template>
                                                        </el-image>
                                                    </el-space>
                                                    <el-progress
                                                        class=""
                                                        :percentage="goodsVideoTaskProgressValue"
                                                    >
                                                    </el-progress>
                                                    <div class="progress-help-info">
                                                        {{ goodsVideoTaskProgressInfo }}
                                                    </div>
                                                </blockquote>
                                                <blockquote
                                                    class="px-1 py-1 flex flex-1 flex-col"
                                                    v-if="!goodsVideoTaskProgressing && goodsVideoTaskCurrentGeneratedVideos"
                                                >
                                                    <el-space>
                                                        <el-image
                                                            :src="video"
                                                            :preview-src-list="[video]"
                                                            style="width: 96px;"
                                                            loading="lazy"
                                                            fit="contain"
                                                            v-for="(video,index) in goodsVideoTaskCurrentGeneratedVideos"
                                                            :key="index"
                                                        >
                                                            <template #placeholder>
                                                                <el-icon><CameraFilled /></el-icon>
                                                            </template>
                                                        </el-image>
                                                    </el-space>
                                                </blockquote>
                                                <blockquote
                                                    class="px-1 py-1 flex flex-1 flex-col"
                                                    v-if="goodsVideoBuildTaskResult"
                                                >
                                                    <el-space>
                                                        <div class=""
                                                            v-for="(video,index) in goodsVideoBuildTaskResult"
                                                            :key="index"
                                                        >
                                                            <el-image
                                                                :src="video"
                                                                :preview-src-list="[video]"
                                                                loading="lazy"
                                                                style="width: 96px;"
                                                                fit="contain"
                                                            >
                                                                <template #placeholder>
                                                                    <el-icon><CameraFilled /></el-icon>
                                                                </template>
                                                            </el-image>
                                                        </div>
                                                    </el-space>
                                                </blockquote>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <footer
                                    class="footer--task-actions fixed bottom-2 p-2 mt-0 mb-0"
                                >
                                    <div class="flex justify-start items-center bg-neutral-500 w-[560px] py-1 px-4 mt-0 mb-0 rounded bg-neutral-200 text-neutral-200">
                                        <div
                                            class="flex flex-row flex-nowrap items-center mb-1"
                                        >
                                            <el-button 
                                                round
                                                plain
                                                type="danger"
                                                @click="onSubmitGoodsVideoTask('goods-video')"
                                            >
                                                提交任务
                                                <el-icon><Promotion /></el-icon>
                                            </el-button>
                                            <el-divider direction="vertical" border-style="dotted"></el-divider>
                                            <div
                                                class="flex flex-row flex-nowrap items-center"
                                                @click="onEditTaskName"
                                            >
                                                <el-text
                                                    class="mr-2 text-large font-bold"
                                                    type="info"
                                                >
                                                    {{ goodsVideoTaskCurrentName }}
                                                </el-text>
                                                <el-icon
                                                    color="#9e9e9e"
                                                >
                                                    <EditPen />
                                                </el-icon>
                                            </div>
                                        </div>
                                        <i class="not-italic flex-1"></i>
                                        <div
                                            class="flex flex-row flex-nowrap items-center"
                                        >
                                            <el-button
                                                round
                                                type="info"
                                                @click="onDoneGoodsVideoTask"
                                                v-if="isCurrentGoodsVideoTaskTodo"
                                            >
                                                <el-icon><Select /></el-icon>
                                                确认任务完成
                                            </el-button>
                                            <el-divider direction="vertical" border-style="dotted"></el-divider>
                                            <el-button
                                                round
                                                plain
                                                type="danger"
                                                @click="onDestroyGoodsVideoTask"
                                            >
                                                <el-icon><DeleteFilled /></el-icon>
                                                删除任务
                                            </el-button>
                                        </div>
                                    </div>
                                </footer>
                            </article>

                            <article
                                class="section--current-task-done bg-neutral-100"
                                v-if="isShowCurrentGoodsVideoTask && !isCurrentGoodsVideoTaskTodo"
                            >
                                <div class="top">
                                    <div class="flex flex-row mt-2 items-center">
                                        <el-space :size="4">
                                            <div
                                                class="flex flex-row items-center mb-1"
                                                @click="onEditTaskName"
                                            >
                                                <el-text
                                                    class="mr-2 text-lg font-medium text-neutral-700"
                                                    type="primary"
                                                >
                                                    {{ goodsVideoTaskCurrentName ? goodsVideoTaskCurrentName : goodsVideoTaskCurrentTaskName }}
                                                </el-text>
                                                <el-icon
                                                    color="#9e9e9e"
                                                >
                                                    <EditPen />
                                                </el-icon>
                                            </div>
                                            <el-divider direction="vertical"></el-divider>
                                            <p class="flex mx-2 items-center"
                                                @click="onToggleTaskMoreDetails"
                                            >
                                                <i class="not-italic mr-1 text-sm font-normal">详情</i>
                                                <el-icon size="14">
                                                    <ArrowDown v-if="!isShowTaskMoreDetails"></ArrowDown>
                                                    <ArrowUp v-if="isShowTaskMoreDetails"></ArrowUp>
                                                </el-icon>
                                            </p>
                                            <el-divider direction="vertical"></el-divider>
                                            <div
                                                class="flex flex-row items-center text-sm"
                                            >
                                                <span class="mr-1">任务ID: </span>
                                                <span>{{ goodsVideoTaskCurrent.task_id }}</span>
                                            </div>
                                        </el-space>
                                        <div class="flex-1"></div>
                                    </div>
                                </div>
                                <el-divider border-style="dashed"></el-divider>
                                <div class="flex flex-row mb-4 justify-between items-center">
                                    <h5 class="">
                                        <span class=""></span>
                                        <div class="">
                                            <i class="not-italic"></i>
                                            <i class="not-italic"></i>
                                        </div>
                                    </h5>
                                    <p class="">
                                        <el-button
                                            plain
                                            round
                                            @click="onReDoingGoodsVideoTask"
                                            v-if="!isCurrentGoodsVideoTaskTodo"
                                        >
                                            再次执行任务
                                        </el-button>
                                        <el-divider
                                            direction="vertical"
                                            border-type="dotted"
                                        ></el-divider>
                                        <el-tooltip
                                            content="删除任务"
                                        >
                                            <el-icon
                                                @click="onDestroyGoodsVideoTask"
                                            >
                                                <DeleteFilled />
                                            </el-icon>
                                        </el-tooltip>
                                    </p>
                                </div>
                                <div class="main mt-1 pb-6">
                                    <div class="main-header"
                                        v-if="isShowTaskMoreDetails"
                                    >
                                        <div class="header-left">
                                            <el-descriptions
                                                title="任务详情"
                                                direction="vertical"
                                                :column="2"
                                                size="large"
                                            >
                                                <el-descriptions-item
                                                    label="创建时间"
                                                    label-class-name="desc-label"
                                                    class-name="desc-content"
                                                >
                                                    {{  goodsVideoTaskCurrent.task_date_created }}
                                                </el-descriptions-item>
                                                <el-descriptions-item
                                                    label="完成时间"
                                                    label-class-name="desc-label"
                                                    class-name="desc-content"
                                                >
                                                    {{  goodsVideoTaskCurrent.task_date_done }}
                                                </el-descriptions-item>
                                                <el-descriptions-item
                                                    label="任务提交最近设置"
                                                    label-class-name="desc-label"
                                                    class-name="desc-content"
                                                >
                                                    <el-space
                                                        wrap
                                                        v-if="goodsVideoTaskCurrentContextDescList"
                                                    >
                                                        <el-tag
                                                            v-for="(desc,index) in goodsVideoTaskCurrentContextDescList"
                                                            :key="index"
                                                        >
                                                            {{  desc }}
                                                        </el-tag>
                                                    </el-space>
                                                </el-descriptions-item>
                                            </el-descriptions>
                                        </div>
                                        <el-divider border-style="dashed"></el-divider>
                                    </div>
                                    <div class="main-body">
                                        <!-- <h5
                                            class="mt-2 mb-2 text-large font-bold"
                                            type="info"
                                        >
                                            任务产出图一览
                                        </h5> -->
                                        <el-empty
                                            description="暂无完成短视频"
                                            v-if="goodsVideoTaskCurrentTotalVideoList.length == 0"
                                        >
                                        </el-empty>
                                        <div 
                                            class="flex flex-row flex-wrap pl-4"
                                            v-if="goodsVideoTaskCurrentTotalVideoList.length > 0"
                                        >
                                            <figure
                                                class="flex flex-col task-video shadow-none rounded p-1 ml-2 mr-2 mb-2"
                                                v-for="(video,index) in goodsVideoTaskCurrentTotalVideoList"
                                                :key="index"
                                            >
                                                <div class="video">
                                                    <el-image
                                                        :src="video.asset_url"
                                                        class="rounded-xl"
                                                        style="width:252px;"
                                                        :preview-src-list="[video.asset_url]"
                                                        fit="cover"
                                                        loading="lazy"
                                                    >
                                                        <template #placeholder>
                                                            <el-icon><CameraFilled /></el-icon>
                                                        </template>
                                                    </el-image>
                                                </div>
                                                <div class="footer mt-1">
                                                    <div class="flex flex-row justify-between">
                                                        <div class="action"
                                                            @click="onCollectTaskVideo(
                                                                video,
                                                                video.is_collected,
                                                                video.task_id,
                                                                video.id,
                                                                video.task_media_site,
                                                                video.asset_url
                                                            )"
                                                        >
                                                            <el-icon v-if="video.is_collected">
                                                                <StarFilled size="24px"></StarFilled>
                                                            </el-icon>
                                                            <el-icon v-if="!video.is_collected">
                                                                <Star size="16px"></Star>
                                                            </el-icon>
                                                        </div>
                                                        <div class="action"
                                                            @click="onDestroyTaskVideo(video.id, video.task_id)"
                                                        >
                                                            <el-icon>
                                                                <Delete></Delete>
                                                            </el-icon>
                                                        </div>
                                                    </div>
                                                </div>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </el-main>
                    </el-container>
                </el-tab-pane>
            </el-tabs>
        </div>

        <el-dialog
            v-model="isShowTaskEditDialog"
            title="任务名称编辑"
        >
            <el-form
                label-position="top"
            >
                <el-form-item label="">
                    <template #label>
                        <el-text type="info" size="default" class="font-medium text-base neutral-700">任务名字</el-text>
                    </template>
                    <el-input v-model="taskNewName"></el-input>
                </el-form-item>
            </el-form>

            <template #footer>
                <el-button
                    plain
                    @click="onCancelTaskEdit"
                >
                    取消
                </el-button>
                <el-button
                    plain
                    @click="onConfirmTaskEdit"
                >
                    确定
                </el-button>
            </template>
        </el-dialog>

    </div>
</template>

<script setup>
    import { ref, reactive, nextTick, onMounted,onBeforeUnmount,getCurrentInstance} from 'vue'
    import { useRoute } from "vue-router";
    import { ElMessage, ElMessageBox, genFileId } from 'element-plus'
    // import Konva from 'konva'
    import { 
        getUserVideoCollectList,createUserVideoCollect,destroyUserVideoCollect,
        getUserVideoTaskList, 
        createVideoTask, retriveVideoTask, updateVideoTask, destroyVideoTask, queryVideoTask,
        submitVideoTask, getVideoTaskProgress, getVideoTaskResult, 
        getUserVideoRefList, uploadGoodsVideoRef,destroyGoodsVideoRef,
        destroyGoodsVideo,
        getGoodsVideoMaskAuto, uploadGoodsVideoMask, 
        getUserVideoSubjectList, getUserVideoPretrainModelList, getVideoStencilCategoryList, getVideoStencilList,
        sendSegmentReq,
        translateByTencent, translateByAzure, translateByAlibaba } from '@/api/api'
    import {hasPermission,getTableHeight} from "@/utils/util";
    import { MEDIA_SITE_ROOT } from '@/config'
    
    const { proxy } = getCurrentInstance()

    const route = useRoute();

    let isFull = ref(false)
    let isShowTaskMoreDetails = ref(false)
    let footerNav = ref()

    function setFull(){
        isFull.value=!isFull.value
        window.dispatchEvent(new Event('resize'))
    }

    let scrowHight = ref(0)

    let activeTopNavTab = ref("goods")
    let activeMainNavTab = ref("collect")
    let activeNewNavTab = ref("stencil")
    let editableTopNavTabs = ref([])

    let userTaskList = ref([
    ])
    let userTaskListCurrentPage = ref(1)
    let userTaskListScrollHeight = ref('100%')
    let hasMoreUserTaskList = ref(false)

    let userVideoCollectList = ref([])
    let userVideoCollectListCurrentPage = ref(1)
    let hasMoreUserCollectList = ref(false)
    let galleryList = ref([])

    let isShowGoodsVideoMain = ref(true)
    let isCurrentGoodsVideoTaskTodo = ref(true)
    let isShowCurrentGoodsVideoTask = ref(false)
    let isShowUploadRef = ref(false)
    let isShowUploadMask = ref(false)

    let taskNewName = ref('')
    let isShowTaskEditDialog = ref(false)
    let isShowFinetuneDialog = ref(false)

    let subjectCurrentThumbUrl = ref('')
    let subjectCurrentName = ref('')
    let subjectCurrentId = ref('')

    let pretrainModelType = ref('upload')
    let pretrainModelCurrentThumbUrl = ref('')
    let pretrainModelCurrentName = ref('')
    let pretrainModelCurrentId = ref('')
    let goodsVideoRefGalleryCurrent = ref('')
    let goodsVideoRefList = ref([])
    let goodsVideoRefUploaded = ref('')
    let goodsVideoRefUploaderRef = ref()

    let videoSubjectList = ref([])
    let videoPretrainModelList = ref([])

    let videoStencilMediumStyleList = ref([])
    let videoStencilPaintStyleList = ref([])
    let videoStencilCompositionStyleList = ref([])
    let videoStencilSceneStyleList = ref([])
    let videoStencilLocationStyleList = ref([])
    let videoStencilPersonStyleList = ref([])
    let videoStencilFaceStyleList = ref([])
    let videoStencilTagList = ref([])

    let goodsVideoTaskCurrentMainOption = ref('subject')
    let goodsVideoBuildTaskResult = ref([])
    let goodsVideoTaskCurrent = ref()
    let goodsVideoTaskCurrentId = ref()
    let goodsVideoTaskCurrentContextDescList = ref([])
    let goodsVideoTaskCurrentTotalVideoList = ref([])
    let goodsVideoTaskCurrentName = ref()
    let goodsVideoTaskCurrentTaskName = ref()
    let goodsVideoTaskCurrentStep = ref(0)

    let goodsVideoMaskType = ref('auto')
    let goodsVideoMaskAuto = ref()
    let goodsVideoMaskAutoGetType = ref('extract')
    let goodsVideoMaskIsNeedFinetune = ref(true)
    let goodsVideoMaskUploaded = ref()
    let goodsVideoMaskUploaderRef = ref()

    let finetuneStageContainer = ref()
    let finetuneStage = ref()
    let finetuneStageConfig= ref({
        width: 300,
        height: 300,
    })
    let finetuneStageLayer = ref()
    let finetuneStageLayerConfig= ref({
    })
    let finetuneStageLayerBgVideo = ref()
    let finetuneStageLayerBgVideoConfig= ref({
        video: '',
        x: 0,
        y: 0,
        width: 300,
        height: 300,
    })

    let finetuneExpectedContainer = ref()
    let finetuneExpected = ref()
    let finetuneExpectedConfig= ref({
        width: 300,
        height: 300,
    })
    let finetuneExpectedLayerConfig= ref({
    })
    let finetuneExpectedVideo= ref()
    let finetuneExpectedVideoConfig= ref({
    })
    let finetuneExpectedInfo = ref('')
    let finetuneExpectedResult = ref('')

    let finetunePenMode = ref('include')
    let finetunePenMudgeWidth = ref(4)
    let finetunePositivePoints = ref([])
    let finetuneNegativePoints = ref([])

    let goodsVideoFinetuneType = ref('auto-extract')
    let goodsVideoFinetune = ref('')
    let goodsVideoFinetuneWidth = ref(256)
    let goodsVideoFinetuneHeight = ref(256)
    let goodsVideoFinetuneWidthOrig = ref(256)
    let goodsVideoFinetuneHeightOrig = ref(256)
    let goodsVideoFinetuneScale = ref()
    let goodsVideoFinetunePreview = ref('')

    let goodsVideoBuildOptions = reactive({
        subject_id: '',
        subject_name: '',
        subject_thumb_url: '',
        pretrain_model_id: '',
        pretrain_model_name: '',
        pretrain_model_thumb_url: '',
        prompt_cn: '',
        prompt_cn_negative: '',
        prompt_en: '',
        prompt_en_negative: '',
        batch_size: 4,
        medium_style: '',
        medium_style_id: '',
        paint_style: '',
        paint_style_id: '',
        scene_style: '',
        scene_style_id: '',
        location_style: '',
        location_style_id: '',
        composition_style: '',
        composition_style_id: '',
        person_style: '',
        person_style_id: '',
        face_style: '',
        face_style_id: '',
        tags_style: '',
        tags_style_id: '',
    })
    let goodsVideoBuildHelp = ref(
        `
        描述你想要生成的图片内容，支持中英文，但是最终都要生成英文才能提交。
        `
    )

    let goodsVideoMaskProgressing = ref(false)

    let goodsVideoTaskProgressing = ref(false)
    let goodsVideoTaskProgressValue = ref(0)
    let goodsVideoTaskProgressInfo = ref('')
    let hasVideoTaskProgressPreview = ref(false)
    let goodsVideoTaskVideosPreview = ref([])
    let goodsVideoTaskCurrentGeneratedVideos = ref([])
    let goodsVideoTaskTimerId = ref(-1)

    let goodsVideoRefCanvas = ref()
    let goodsVideoMaskCanvas = ref()

    let goodsVideoRefStageConfig= ref({
        width: 300,
        height: 300,
    })
    
    let goodsVideoRefLayerConfig= ref()
    let goodsVideoMaskLayerConfig= ref()
    let goodsVideoMaskVideoConfig= ref({
        video: ''
    })

    function getTopNavTabs() {
        // platformsettingsSysconfig({limit:999,parent__isnull:true}).then(res=>{
        //     if(res.code == 2000){
        //         editableTopNavTabs.value = res.data.data
        //     }
        // })
        editableTopNavTabs.value = [
            {
                title: '短视频',
                key: 'goods',
            },
        ]
    }
    function handleResize(){
        nextTick(() => {
            let tabSelectHeight = proxy.$refs.tableSelect?proxy.$refs.tableSelect.offsetHeight:0
            tabSelectHeight = isFull.value?tabSelectHeight + 90 :tabSelectHeight + 200
            scrowHight.value = getTableHeight(tabSelectHeight,false)
        })
    }

    onMounted(() => {
        getTopNavTabs()
        handleResize()
        window.addEventListener('resize',handleResize)

        getUserVideoTasks()
        getUserVideoCollects(1)
        // getUserGoodsVideoRefs()
        getUserVideoSubjects()
        getUserVideoPretainModels()
        getVideoStencils('medium')
        getVideoStencils('composition')
        getVideoStencils('paint')
        getVideoStencils('scene')
        getVideoStencils('location')
        getVideoStencils('person')
        getVideoStencils('face')
        // getVideoStencils('tag')

        // if (finetuneStageContainer.value) {
        //     app.mount(finetuneStageContainer.value);
        // }
    })
    onBeforeUnmount(()=>{
        window.removeEventListener('resize',handleResize)
    })

    defineExpose({
        setFull
    })

    function getUserVideoCollects(page = userVideoCollectListCurrentPage.value) {
        let params = {
            page: page,
            task_type: 'goods-video',
        }
        getUserVideoCollectList(params).then((res) => {
            let remList = res.data?.data
            remList && remList.forEach((el) => {
                el.is_collected = true
                el.task_id = el.task
            })
            let hasNext = res.data?.has_next
            if (hasNext) {
                hasMoreUserCollectList.value = true
            } else {
                hasMoreUserCollectList.value = false
            }
            if (page == 1) {
                userVideoCollectList.value = remList
            } else {
                userVideoCollectList.value = userVideoCollectList.value.concat(remList)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    function onMoreUserCollects() {
        userVideoCollectListCurrentPage.value = parseInt(userVideoCollectListCurrentPage.value) + 1
        getUserVideoCollects(userVideoCollectListCurrentPage.value)
    }

    function getTaskContextDescList(context) {
        let result = []
        if (context?.goodsVideoBuildOptions?.subject_name) {
            result.push('主题:' + context?.goodsVideoBuildOptions?.subject_name)
        }
        if (context?.goodsVideoBuildOptions?.pretrain_model_name) {
            result.push('预训练模型:' + context?.goodsVideoBuildOptions?.pretrain_model_name)
        }
        if (context?.goodsVideoBuildOptions?.prompt_cn) {
            result.push('中文提示词:' + context?.goodsVideoBuildOptions?.prompt_cn)
        }
        if (context?.goodsVideoBuildOptions?.prompt_en) {
            result.push('英文提示词:' + context?.goodsVideoBuildOptions?.prompt_en)
        }
        if (context?.goodsVideoBuildOptions?.medium_style) {
            result.push('媒质类型:' + context?.goodsVideoBuildOptions?.medium_style)
        }
        if (context?.goodsVideoBuildOptions?.composition_style) {
            result.push('构图类型:' + context?.goodsVideoBuildOptions?.composition_style)
        }
        // if (context?.goodsVideoBuildOptions?.paint_style) {
        //     result.push('画风类型:' + context?.goodsVideoBuildOptions?.paint_style)
        // }
        if (context?.goodsVideoBuildOptions?.scene_style) {
            result.push('动作/场景类型:' + context?.goodsVideoBuildOptions?.scene_style)
        }
        if (context?.goodsVideoBuildOptions?.location_style) {
            result.push('位置类型:' + context?.goodsVideoBuildOptions?.location_style)
        }
        // if (context?.goodsVideoBuildOptions?.person_style) {
        //     result.push('人物类型:' + context?.goodsVideoBuildOptions?.person_style)
        // }
        // if (context?.goodsVideoBuildOptions?.face_style_id) {
        //     result.push('面部类型:' + context?.goodsVideoBuildOptions?.face_style_id)
        // }
        if (context?.goodsVideoBuildOptions?.tags_style) {
            result.push('标签:' + context?.goodsVideoBuildOptions?.tags_style)
        }
        return result
    }

    function reformatTask(dataOrig) {
        let result = {}
        let thumb_url = ''
        let taskVideos = []
        let taskCurrentResult = []
        let taskContext = {}

        if (dataOrig?.assets && dataOrig?.assets.length > 0) {
            thumb_url = dataOrig.task_media_site + '' + dataOrig?.assets[0]?.asset_url
        }
        if (dataOrig) {
            let name = '任务-'+dataOrig.id
            if (dataOrig.task_name) {
                name = dataOrig.task_name
            }
            if (dataOrig.assets) {
                dataOrig.assets.forEach((el) => {
                    if (el.asset_url) {
                        el.asset_url = dataOrig.task_media_site + '' + el.asset_url
                        taskVideos.push({
                            'id': el.id, 
                            'asset_url': el.asset_url, 
                            'is_collected': el.is_collected,
                            'task_id': dataOrig.id,
                            'task_media_site': dataOrig.task_media_site,
                            'asset_url': el.asset_url,
                        })
                    } else if (el?.fields?.asset_url) {
                        el.fields.asset_url = dataOrig.task_media_site + '' + el.fields.asset_url
                        taskVideos.push({
                            'id': el.fields.id, 
                            'asset_url': el.fields.asset_url, 
                            'is_collected': el.fields.is_collected,
                            'task_id': dataOrig.id,
                            'task_media_site': dataOrig.task_media_site,
                            'asset_url': el.fiels.asset_url,
                        })
                    }
                })
            }
            if (dataOrig.task_context) {
                if (typeof(dataOrig.task_context) == 'string') {
                    taskContext = JSON.parse(dataOrig.task_context)
                } else {
                    taskContext = dataOrig.task_context
                }
            }
            if (dataOrig.task_result) {
                if (typeof(dataOrig.task_context) == 'string') {
                    taskCurrentResult = JSON.parse(dataOrig.task_result)
                } else {
                    taskCurrentResult = dataOrig.task_result
                }
            }
            result = {
                id: dataOrig.id,
                name: name,
                thumb_url: thumb_url,
                task_id: dataOrig.task_id,
                task_name: dataOrig.task_name,
                status: dataOrig.status,
                status_text: getStatusText(dataOrig.status),
                task_status: dataOrig.task_status,
                task_context: taskContext,
                task_date_created: dataOrig.task_date_created,
                task_date_done: dataOrig.task_date_done,
                task_args: dataOrig.task_args,
                task_successful: dataOrig.task_successful,
                task_media_site: dataOrig.task_media_site,
                task_videos: taskVideos,
                task_result: taskCurrentResult,
            }
        }
        return result
    }

    function getUserVideoTasks(page = userTaskListCurrentPage.value, currentTaskId = null, currentTask = null) {
        let params = {
            page: page,
            task_type: 'goods-video',
        }
        getUserVideoTaskList(params).then((res) => {
            let remList = res.data?.data
            let hasNext = res.data?.has_next
            if (hasNext) {
                hasMoreUserTaskList.value = true
            } else {
                hasMoreUserTaskList.value = false
            }
            let transList = []
            if (remList && remList.length > 0) {
                transList = remList.map((el) => {
                    return reformatTask(el)
                })
            }
            if (page == 1) {
                userTaskList.value = transList
            } else {
                userTaskList.value = userTaskList.value.concat(transList)
            }
            if (currentTaskId && currentTask) {
                onShowGoodsVideoCurrentTask(currentTaskId, currentTask)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    function onMoreUserTaskList() {
        userTaskListCurrentPage.value = parseInt(userTaskListCurrentPage.value) + 1
        let page = userTaskListCurrentPage.value
        getUserVideoTasks(page)
    }

    function onChangeSubject(values) {
        subjectCurrentThumbUrl.value = values.thumb_url
        subjectCurrentName.value = values.name
        subjectCurrentId.value = values.id
        goodsVideoBuildOptions.subject_id = values.id
        goodsVideoBuildOptions.subject_name = values.name
        goodsVideoBuildOptions.subject_thumb_url = values.thumb_url
        onSwitchMainOption2Model()
    }

    function onChangePretrainModel(values) {
        pretrainModelCurrentThumbUrl.value = values.thumb_url
        pretrainModelCurrentName.value = values.name
        pretrainModelCurrentId.value = values.id
        goodsVideoBuildOptions.pretrain_model_id = values.id
        goodsVideoBuildOptions.pretrain_model_name = values.name
        goodsVideoBuildOptions.pretrain_model_thumb_url = values.thumb_url
        onSwitchMainOption2Scene()
    }

    function onProcessGoodsVideoRefActions(command) {
        if (command == 'drop') {
            ElMessageBox.confirm('确认要删除这项参考图吗？', '提示',{}).then(() => {
                let params = {
                    id: goodsVideoRefGalleryCurrentId.value
                }
                destroyGoodsVideoRef(params).then((res) => {
                    getUserGoodsVideoRefs()
                }).catch((err) => {
                    console.log(err)
                })
            }).catch(() => {

            })
        }
    }

    function getUserGoodsVideoRefs() {
        let params = {
            page: 1,
        }
        // getUserVideoRefList(params).then((res) => {
        //     goodsVideoRefList.value = res.data.data
        // }).catch((err) => {
        //     console.log(err)
        // })
    }

    function getUserVideoSubjects() {
        let params = {
            page: 1,
        }
        getUserVideoSubjectList(params).then((res) => {
            videoSubjectList.value = res.data.data
        }).catch((err) => {
            console.log(err)
        })
    }

    function getUserVideoPretainModels() {
        let params = {
            page: 1,
        }
        getUserVideoPretrainModelList(params).then((res) => {
            videoPretrainModelList.value = res.data.data
        }).catch((err) => {
            console.log(err)
        })
    }

    function getVideoStencils(styleName) {
        let params = {
            page: 1,
        }
        if (styleName == 'medium') {
            params['stencil_category_id'] = 1
            getVideoStencilList(params).then((res) => {
                videoStencilMediumStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'composition') {
            params['stencil_category_id'] = 2
            getVideoStencilList(params).then((res) => {
                videoStencilCompositionStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'paint') {
            params['stencil_category_id'] = 3
            getVideoStencilList(params).then((res) => {
                videoStencilPaintStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'scene') {
            params['stencil_category_id'] = 4
            getVideoStencilList(params).then((res) => {
                videoStencilSceneStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'location') {
            params['stencil_category_id'] = 5
            getVideoStencilList(params).then((res) => {
                videoStencilLocationStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'person') {
            params['stencil_category_id'] = 8
            getVideoStencilList(params).then((res) => {
                videoStencilPersonStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'face') {
            params['stencil_category_id'] = 9
            getVideoStencilList(params).then((res) => {
                videoStencilFaceStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'tag') {
            params['stencil_category_id'] = 10
            getVideoStencilList(params).then((res) => {
                videoStencilTagList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        }
    }

    function onShowGoodsVideoMain() {
        isShowGoodsVideoMain.value = true
        isShowCurrentGoodsVideoTask.value = false
        isCurrentGoodsVideoTaskTodo.value = false
        userVideoCollectListCurrentPage.value = 1
        getUserVideoCollects(userVideoCollectListCurrentPage.value)
        goodsVideoTaskCurrentId.value = null
    }

    function onNewGoodsVideoTask() {
        isShowGoodsVideoMain.value = false
        isShowCurrentGoodsVideoTask.value = true
        isCurrentGoodsVideoTaskTodo.value = true

        goodsVideoTaskProgressing.value = false
        hasVideoTaskProgressPreview.value = false
        goodsVideoTaskVideosPreview.value = []

        goodsVideoBuildOptions.subject_id = ''
        goodsVideoBuildOptions.subject_name = ''
        goodsVideoBuildOptions.subject_thumb_url = ''
        goodsVideoBuildOptions.pretrain_model_id = ''
        goodsVideoBuildOptions.pretrain_model_name = ''
        goodsVideoBuildOptions.pretrain_model_thumb_url = ''
        goodsVideoBuildOptions.prompt_cn = ''
        goodsVideoBuildOptions.prompt_cn_negative = ''
        goodsVideoBuildOptions.prompt_en = ''
        goodsVideoBuildOptions.prompt_en_negative = ''
        goodsVideoBuildOptions.batch_size = 4
        goodsVideoBuildOptions.medium_style = ''
        goodsVideoBuildOptions.medium_style_id = ''
        goodsVideoBuildOptions.paint_style = ''
        goodsVideoBuildOptions.paint_style_id = ''
        goodsVideoBuildOptions.scene_style = ''
        goodsVideoBuildOptions.scene_style_id = ''
        goodsVideoBuildOptions.location_style = ''
        goodsVideoBuildOptions.location_style_id = ''
        goodsVideoBuildOptions.composition_style = ''
        goodsVideoBuildOptions.composition_style_id = ''
        goodsVideoBuildOptions.person_style = ''
        goodsVideoBuildOptions.person_style_id = ''
        goodsVideoBuildOptions.face_style = ''
        goodsVideoBuildOptions.face_style_id = ''
        goodsVideoBuildOptions.tags_style = ''
        goodsVideoBuildOptions.tags_style_id = ''

        let params = {
            task_type: 'goods-video',
        }
        createVideoTask(params).then((res) => {
            let newTaskId = res.data.data.id
            let newTask = res.data.data
            newTask = reformatTask(newTask)
            userTaskList.value.push(newTask)
            onShowGoodsVideoCurrentTask(newTaskId, newTask)
        }).catch((err) => {
            console.log(err)
        })
    }

    function onShowGoodsVideoCurrentTask(taskId, task) {
        isShowTaskMoreDetails.value = false
        goodsVideoTaskCurrentId.value = taskId
        goodsVideoTaskCurrent.value = task
        goodsVideoTaskCurrentName.value = task.name
        goodsVideoTaskCurrentTaskName.value = task.task_name
        if (task.task_videos) {
            goodsVideoTaskCurrentTotalVideoList.value = task.task_videos
        }
        goodsVideoTaskCurrentGeneratedVideos.value = task.task_result
        goodsVideoTaskCurrentMainOption.value = 'subject'
        goodsVideoTaskCurrentStep.value = 0

        isShowGoodsVideoMain.value = false
        isShowCurrentGoodsVideoTask.value = true
        isCurrentGoodsVideoTaskTodo.value = task.task_successful != 1

        
        let ctxPretrainModelCurrentThumbUrl = ''
        let ctxPretrainModelCurrentName = ''
        let ctxPretrainModelCurrentId = ''
        let ctxPretrainModelCurrent = {}
        let ctxGoodsVideoBuildOptions = {}
        let context = {}
        if (task.task_context) {
            if (typeof(context) == 'string') {
                context = JSON.parse(context)
            } else {
                context = task.task_context
            }
        }
        if (context.goodsVideoBuildOptions) {
            ctxSubjectCurrentThumbUrl = context.goodsVideoBuildOptions.subject_thumb_url
            ctxSubjectCurrentName = context.goodsVideoBuildOptions.subject_name
            ctxSubjectCurrentId = context.goodsVideoBuildOptions.subject_id

            ctxPretrainModelCurrentThumbUrl = context.goodsVideoBuildOptions.pretrain_model_thumb_url
            ctxPretrainModelCurrentName = context.goodsVideoBuildOptions.pretrain_model_name
            ctxPretrainModelCurrentId = context.goodsVideoBuildOptions.pretrain_model_id
            
            ctxGoodsVideoBuildOptions = context.goodsVideoBuildOptions

            subjectCurrentThumbUrl.value = ctxSubjectCurrentThumbUrl
            subjectCurrentName.value = ctxSubjectCurrentName
            pretrainModelCurrentThumbUrl.value = ctxPretrainModelCurrentThumbUrl
            pretrainModelCurrentName.value = ctxPretrainModelCurrentName
            pretrainModelCurrentId.value = ctxPretrainModelCurrentId
            goodsVideoBuildOptions.subject_id = ctxGoodsVideoBuildOptions.subject_id
            goodsVideoBuildOptions.subject_name = ctxGoodsVideoBuildOptions.subject_name
            goodsVideoBuildOptions.subject_thumb_url = ctxGoodsVideoBuildOptions.subject_thumb_url
            goodsVideoBuildOptions.pretrain_model_id = ctxGoodsVideoBuildOptions.pretrain_model_id
            goodsVideoBuildOptions.pretrain_model_name = ctxGoodsVideoBuildOptions.pretrain_model_name
            goodsVideoBuildOptions.pretrain_model_thumb_url = ctxGoodsVideoBuildOptions.pretrain_model_thumb_url
            goodsVideoBuildOptions.batch_size = ctxGoodsVideoBuildOptions.batch_size
            goodsVideoBuildOptions.prompt_cn = ctxGoodsVideoBuildOptions.prompt_cn
            goodsVideoBuildOptions.prompt_en = ctxGoodsVideoBuildOptions.prompt_en
            goodsVideoBuildOptions.prompt_en_negative = ctxGoodsVideoBuildOptions.prompt_en_negative
            goodsVideoBuildOptions.medium_style = ctxGoodsVideoBuildOptions.medium_style
            goodsVideoBuildOptions.medium_style_id = ctxGoodsVideoBuildOptions.medium_style_id
            goodsVideoBuildOptions.composition_style = ctxGoodsVideoBuildOptions.composition_style
            goodsVideoBuildOptions.composition_style_id = ctxGoodsVideoBuildOptions.composition_style_id
            goodsVideoBuildOptions.paint_style = ctxGoodsVideoBuildOptions.paint_style
            goodsVideoBuildOptions.paint_style_id = ctxGoodsVideoBuildOptions.paint_style_id
            goodsVideoBuildOptions.scene_style = ctxGoodsVideoBuildOptions.scene_style
            goodsVideoBuildOptions.scene_style_id = ctxGoodsVideoBuildOptions.scene_style_id
            goodsVideoBuildOptions.person_style = ctxGoodsVideoBuildOptions.person_style
            goodsVideoBuildOptions.person_style_id = ctxGoodsVideoBuildOptions.person_style_id
            goodsVideoBuildOptions.face_style = ctxGoodsVideoBuildOptions.face_style
            goodsVideoBuildOptions.face_style_id = ctxGoodsVideoBuildOptions.face_style_id
            goodsVideoBuildOptions.tags_style = ctxGoodsVideoBuildOptions.tags_style
            goodsVideoBuildOptions.tags_style_id = ctxGoodsVideoBuildOptions.tags_style_id

            goodsVideoTaskCurrentContextDescList.value = getTaskContextDescList(task.task_context)
        } else {
            pretrainModelCurrentThumbUrl.value = ''
            pretrainModelCurrentName.value = ''
            pretrainModelCurrentId.value = ''

            goodsVideoBuildOptions.subject_id = ''
            goodsVideoBuildOptions.subject_name = ''
            goodsVideoBuildOptions.subject_thumb_url = ''
            goodsVideoBuildOptions.pretrain_model_id = ''
            goodsVideoBuildOptions.pretrain_model_name = ''
            goodsVideoBuildOptions.pretrain_model_thumb_url = ''
            goodsVideoBuildOptions.batch_size = 4
            goodsVideoBuildOptions.prompt_cn = ''
            goodsVideoBuildOptions.prompt_en = ''
            goodsVideoBuildOptions.prompt_en_negative = ''
            goodsVideoBuildOptions.medium_style = ''
            goodsVideoBuildOptions.medium_style_id = ''
            goodsVideoBuildOptions.composition_style = ''
            goodsVideoBuildOptions.composition_style_id = ''
            goodsVideoBuildOptions.paint_style = ''
            goodsVideoBuildOptions.paint_style_id = ''
            goodsVideoBuildOptions.scene_style = ''
            goodsVideoBuildOptions.scene_style_id = ''
            goodsVideoBuildOptions.person_style = ''
            goodsVideoBuildOptions.person_style_id = ''
            goodsVideoBuildOptions.face_style = ''
            goodsVideoBuildOptions.face_style_id = ''
            goodsVideoBuildOptions.tags_style = ''
            goodsVideoBuildOptions.tags_style_id = ''
        }
    }

    function onSwitchMainOption2Mask() {
        goodsVideoTaskCurrentMainOption.value = 'mask'
    }
    function onSwitchMainOption2Subject() {
        goodsVideoTaskCurrentMainOption.value = 'subject'
        goodsVideoTaskCurrentStep.value = 0
    }
    function onSwitchMainOption2Model() {
        goodsVideoTaskCurrentMainOption.value = 'model'
        goodsVideoTaskCurrentStep.value = 1
    }
    function onSwitchMainOption2Scene() {
        goodsVideoTaskCurrentMainOption.value = 'scene'
        goodsVideoTaskCurrentStep.value = 2
    }
    function onSwitchMainOption2Number() {
        goodsVideoTaskCurrentMainOption.value = 'number'
        goodsVideoTaskCurrentStep.value = 3
    }
    function onSwitchMainOption2Status() {
        goodsVideoTaskCurrentMainOption.value = 'status'
        goodsVideoTaskCurrentStep.value = 4
    }

    function onTranslateChinesePromptByTencent() {
        let params = {
            text: goodsVideoBuildOptions.prompt,
            source: 'zh',
            to: 'en',
        }
        translateByTencent(params).then((res) => {
            let translatedText = res.data.data[0]
            goodsVideoBuildOptions.prompt_en = translatedText
        }).catch((err) => {
            console.log(err)
        })
    }

    function onTranslateChinesePromptByAzure() {
        let params = {
            text: goodsVideoBuildOptions.prompt,
            source: 'zh',
            to: 'en',
        }
        translateByAzure(params).then((res) => {
            let translatedText = res.data.data[0]
            goodsVideoBuildOptions.prompt_en = translatedText
        }).catch((err) => {
            console.log(err)
        })
    }

    function onTranslateChinesePromptByAlibaba() {
        let params = {
            text: goodsVideoBuildOptions.prompt_cn,
            source: 'zh',
            to: 'en',
        }
        translateByAlibaba(params).then((res) => {
            let translatedText = res.data.data[0]
            goodsVideoBuildOptions.prompt_en = translatedText
        }).catch((err) => {
            console.log(err)
        })
    }

    function onSubmitGoodsVideoTask(type) {
        let task_id = ''
        let subject_id = ''
        let subject_name = ''
        let subject_thumb_url = ''
        let pretrain_model_id = ''
        let pretrain_model_name = ''
        let pretrain_model_thumb_url = ''
        let prompt_cn = ''
        let prompt_cn_negative = ''
        let prompt_en = ''
        let prompt_en_negative = ''
        let generat_number = 1
        let stencil_ids = ''
        let medium_style = ''
        let medium_style_id = ''
        let composition_style = ''
        let composition_style_id = ''
        let paint_style = ''
        let paint_style_id = ''
        let scene_style = ''
        let scene_style_id = ''
        let location_style = ''
        let location_style_id = ''
        let person_style = ''
        let person_style_id = ''
        let face_style = ''
        let face_style_id = ''
        let tags_style = ''
        let tags_style_id = ''
        let video_ref = ''
        let video_mask = ''
        let video_mask_type = ''
        let video_build_type = ''

        let checkItems = {
            'prompt_en': '英文提示词还没有设置',
            'medium_style_id': '媒质类型还没有设置',
            'composition_style_id': '构图类型还没有设置',
            // 'paint_style_id': '画风类型还没有设置',
            'scene_style_id': '场景/动作类型还没有设置',
            'location_style_id': '位置类型还没有设置',
            // 'person_style_id': '人物类型还没有设置',
            // 'face_style_id': '面部类型还没有设置',
            // 'tags_style_id': '标签还没有设置',
        }
        let invalidItems = []

        if (!goodsVideoBuildOptions['subject_id']) {
            let checkResultMessage = '主题还没有选择'
            ElMessageBox.alert(checkResultMessage, '提示', {
                callback: () => {
                    return
                }
            })
        } else if (!goodsVideoBuildOptions['pretrain_model_id']) {
            let checkResultMessage = '训练模型还没有选择'
            ElMessageBox.alert(checkResultMessage, '提示', {
                callback: () => {
                    return
                }
            })
        } else {
            for (let itemKey in checkItems) {
                if (!goodsVideoBuildOptions[itemKey]) {
                    invalidItems.push(
                        checkItems[itemKey]
                    )
                }
            }
            // if (invalidItems.length == checkItems.keys.length) {
            if (invalidItems.length == 5) {
                let checkResultMessage = '[' + invalidItems.join(',\n') + '];几项中需要选择其中一项!'
                ElMessageBox.alert(checkResultMessage, '提示', {
                    callback: () => {
                        return
                    }
                })
            } else {
                subject_id = goodsVideoBuildOptions.subject_id
                subject_name = goodsVideoBuildOptions.subject_name
                subject_thumb_url = goodsVideoBuildOptions.subject_thumb_url
                pretrain_model_id = goodsVideoBuildOptions.pretrain_model_id
                pretrain_model_name = goodsVideoBuildOptions.pretrain_model_name
                pretrain_model_thumb_url = goodsVideoBuildOptions.pretrain_model_thumb_url
                prompt_cn = goodsVideoBuildOptions.prompt_cn
                prompt_cn_negative = goodsVideoBuildOptions.prompt_cn_negative
                prompt_en = goodsVideoBuildOptions.prompt_en
                prompt_en_negative = goodsVideoBuildOptions.prompt_en_negative
                generat_number = goodsVideoBuildOptions.batch_size
                medium_style_id = goodsVideoBuildOptions.medium_style_id
                medium_style = goodsVideoBuildOptions.medium_style
                composition_style_id = goodsVideoBuildOptions.composition_style_id
                composition_style = goodsVideoBuildOptions.composition_style
                paint_style_id = goodsVideoBuildOptions.paint_style_id
                paint_style = goodsVideoBuildOptions.paint_style
                scene_style_id = goodsVideoBuildOptions.scene_style_id
                scene_style = goodsVideoBuildOptions.scene_style
                location_style_id = goodsVideoBuildOptions.location_style_id
                location_style = goodsVideoBuildOptions.location_style
                person_style_id = goodsVideoBuildOptions.person_style_id
                person_style = goodsVideoBuildOptions.person_style
                face_style_id = goodsVideoBuildOptions.face_style_id
                face_style = goodsVideoBuildOptions.face_style
                video_build_type = type
                stencil_ids = medium_style_id + ',' + composition_style_id + ',' + paint_style_id + ',' + scene_style_id + ',' + location_style_id + ',' + person_style_id + ',' + face_style_id

                task_id = goodsVideoTaskCurrentId.value
                let params = {
                    task_id: task_id,
                    subject_id: subject_id,
                    subject_name: subject_name,
                    subject_thumb_url: subject_thumb_url,
                    pretrain_model_id: pretrain_model_id,
                    pretrain_model_name: pretrain_model_name,
                    pretrain_model_thumb_url: pretrain_model_thumb_url,
                    medium_style_id: medium_style_id,
                    medium_style: medium_style,
                    composition_style_id: composition_style_id,
                    composition_style: composition_style,
                    paint_style_id: paint_style_id,
                    paint_style: paint_style,
                    scene_style_id: scene_style_id,
                    scene_style: scene_style,
                    location_style_id: location_style_id,
                    location_style: location_style,
                    person_style_id: person_style_id,
                    person_style: person_style,
                    face_style_id: face_style_id,
                    face_style: face_style,
                    tags_style_id: tags_style_id,
                    tags_style: tags_style,
                    stencil_ids: stencil_ids,
                    batch_size: generat_number,
                    prompt_cn: prompt_cn,
                    prompt_cn_negative: prompt_cn_negative,
                    prompt_en: prompt_en,
                    prompt_en_negative: prompt_en_negative,
                    video_build_type: video_build_type,
                }


                hasVideoTaskProgressPreview.value = false
                goodsVideoTaskVideosPreview.value = []
                goodsVideoTaskProgressing.value = false
                goodsVideoTaskProgressValue.value = 0
                goodsVideoTaskProgressInfo.value = ''
                goodsVideoTaskCurrentGeneratedVideos.value = []
                goodsVideoBuildTaskResult.value = []

                submitVideoTask(params).then((res) => {
                    goodsVideoTaskCurrentMainOption.value = 'status'

                    goodsVideoTaskProgressing.value = true
                    goodsVideoTaskCurrentGeneratedVideos.value = []
                    let task_id = res.data.data.id
                    let task_task_id = res.data.data.task_id
                    let task_successful = res.data.data.task_successful
                    let task_current_media_site = res.data.data.task_media_site
                    localStorage.setItem('current_task_id', task_id)
                    localStorage.setItem('current_task_task_id', task_task_id)
                    if (task_successful != 1) {
                        goodsVideoTaskTimerId.value = setInterval(() => {
                            let current_task_id = localStorage.getItem('current_task_id')
                            let taskParams = {
                                task_id: current_task_id,
                            }
                            getVideoTaskProgress(taskParams).then((progressRes) => {   
                                task_current_media_site = progressRes.data.data.progress_current_media_site
                                // let progress_current_id = progressRes.data.data.progress_current_id
                                // let progress_current_status = progressRes.data.data.progress_current_status
                                let progress_current_successful = progressRes.data.data.progress_current_successful
                                let progress_current_result = progressRes.data.data.progress_current_result
                                let progress_current_value = progressRes.data.data.progress_current_value
                                let progress_current_info = progressRes.data.data.progress_current_info
                                let progress_current_videos = progressRes.data.data.progress_current_videos

                                goodsVideoTaskProgressInfo.value = progress_current_info
                                goodsVideoTaskProgressValue.value = progress_current_value
                                if (progress_current_videos.length > 0) {
                                    hasVideoTaskProgressPreview.value = true
                                }
                                goodsVideoTaskVideosPreview.value = progress_current_videos

                                if (progress_current_successful) {
                                    goodsVideoTaskProgressing.value =false
                                    let current_task_id = localStorage.getItem('current_task_id')
                                    let current_task_task_id = localStorage.getItem('current_task_task_id')
                                    let taskParams = {
                                        task_id: current_task_id
                                    }
                                    getVideoTaskResult(taskParams).then((res) => {
                                        let task_videos_result = res.data.data.videos
                                        goodsVideoTaskProgressing.value = false
                                        goodsVideoTaskCurrentGeneratedVideos.value = task_videos_result
                                    }).catch((err) => {
                                        console.log(err)
                                    })
                                    clearInterval(goodsVideoTaskTimerId.value)
                                } else {
                                    goodsVideoTaskCurrentGeneratedVideos.value = progress_current_videos
                                }
                            }).catch((err) => {
                                console.log(err)
                            })
                        }, 1000)
                    } else {
                        goodsVideoTaskProgressing.value =false
                        let current_task_id = localStorage.getItem('current_task_id')
                        let taskParams = {
                            id: current_task_id
                        }
                        getVideoTaskResult(taskParams).then((res) => {
                            let task_result = res.data.data.result
                            let taskVideoUrls = task_result.video_urls
                            let taskVideoRealUrls = []
                            let media_site_base_url = task_current_media_site
                            taskVideoUrls.forEach((el) => {
                                taskVideoRealUrls.push(media_site_base_url + el)
                            })
                            goodsVideoTaskCurrentGeneratedVideos.value = taskVideoRealUrls
                        }).catch((err) => {
                            console.log(err)
                        })
                    }
                }).catch((err) => {
                    console.log(err)
                })
            }
        }
    }

    function onUpdateGoodsVideoTask() {
        let currentContext = {
            pretrainModelCurrentThumbUrl: pretrainModelCurrentThumbUrl.value,
            goodsVideoBuildOptions: goodsVideoBuildOptions,
        }
        let params = {
            id: goodsVideoTaskCurrentId.value,
            context: currentContext,
        }
        updateVideoTask(params).then(() => {
            ElMessage('保存完成')
        }).catch((err) => {
            console.log(err)
        })
    }

    function onQueryGoodsVideoTask() {
        let params = {
            task_id: goodsVideoTaskCurrent.value['task_id']
        }
        let videos = []
        goodsVideoBuildTaskResult.value = []
        queryVideoTask(params).then((res) => {
            videos = res.data.data.videos
            goodsVideoBuildTaskResult.value = videos
        }).catch((err) => {
            console.log(err)
        })
    }

    function onDoneGoodsVideoTask() {
        console.log(goodsVideoTaskTimerId.value)
        clearInterval(goodsVideoTaskTimerId.value)
        let params = {
            id: goodsVideoTaskCurrentId.value,
            status: 'SUCCESS',
            successful: 1,
        }
        updateVideoTask(params).then((res) => {
            isCurrentGoodsVideoTaskTodo.value = false
            retriveVideoTask(params).then((res) => {
                goodsVideoTaskCurrent.value = reformatTask(res.data.data)
                nextTick(() => {
                    let newUserTaskList = []
                    userTaskList.value.forEach((el) => {
                        if (el.id == goodsVideoTaskCurrentId.value) {
                            el = goodsVideoTaskCurrent.value
                        }
                        newUserTaskList.push(el)
                    })
                    userTaskList.value = newUserTaskList
                    onShowGoodsVideoCurrentTask(
                        goodsVideoTaskCurrentId.value,
                        goodsVideoTaskCurrent.value
                    )
                })
            }).catch((err) => {
                console.log(err)
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    function onReDoingGoodsVideoTask() {
        let params = {
            id: goodsVideoTaskCurrentId.value,
            status: 'STARTED',
            successful: -1,
        }
        updateVideoTask(params).then((res) => {
            isCurrentGoodsVideoTaskTodo.value = true
            goodsVideoTaskCurrent.value = reformatTask(res.data.data)
            userTaskList.value.forEach((el) => {
                nextTick(() => {
                    let newUserTaskList = []
                    userTaskList.value.forEach((el) => {
                        if (el.id == goodsVideoTaskCurrentId.value) {
                            el = goodsVideoTaskCurrent.value
                        }
                        newUserTaskList.push(el)
                    })
                    userTaskList.value = newUserTaskList
                    onShowGoodsVideoCurrentTask(
                        goodsVideoTaskCurrentId.value,
                        goodsVideoTaskCurrent.value
                    )
                })
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    function onDestroyGoodsVideoTask() {
        ElMessageBox.confirm('确认要删除[' + goodsVideoTaskCurrent.value.name + ']吗？','提示',
            {
                type: 'danger',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                callback: (action) => {
                    let params = {
                        id: goodsVideoTaskCurrentId.value
                    }
                    destroyVideoTask(params).then(() => {
                        getUserVideoTasks(1)
                        onShowGoodsVideoMain()
                    }).catch(() => {

                    })
                }
            }
        )
    }

    function onEditTaskName() {
        if (goodsVideoTaskCurrent.value.task_name) {
            taskNewName.value = goodsVideoTaskCurrent.value.task_name
        } else {
            taskNewName.value = goodsVideoTaskCurrent.value.name
        }
        isShowTaskEditDialog.value = true
    }

    function onCancelTaskEdit() {
        isShowTaskEditDialog.value = false
    }

    function onConfirmTaskEdit() {
        isShowTaskEditDialog.value = false
        let params = {
            id: goodsVideoTaskCurrentId.value,
            name: taskNewName.value
        }
        updateVideoTask(params).then((res) => {
            goodsVideoTaskCurrent.value = reformatTask(res.data.data)
            taskNewName.value = ''
            userTaskList.value.forEach((el) => {
                let id = el.id
                let currentCompareId = goodsVideoTaskCurrent.value.id
                if (id == currentCompareId) {
                    el.name = goodsVideoTaskCurrent.value.task_name
                }
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    function getStatusText(status) {
        let result = ''
        let statusTextDict = {
            'PENDING': '',
            'STARTED': '',
            'SUCCESS': '已完成',
            'RETRY': '',
            'FAILURE': '',
        }
        if (statusTextDict.hasOwnProperty(status?.toUpperCase())) {
            result = statusTextDict[status?.toUpperCase()]
        } else {
            result = ''
        }
        return result
    }

    function onChangeVideoStencilMediumStyle(stencil) {
        goodsVideoBuildOptions.medium_style = stencil.name
        goodsVideoBuildOptions.medium_style_id = stencil.id
    }

    function onChangeVideoStencilCompositionStyle(stencil) {
        goodsVideoBuildOptions.composition_style = stencil.name
        goodsVideoBuildOptions.composition_style_id = stencil.id
    }

    function onChangeVideoStencilPaintStyle(stencil) {
        goodsVideoBuildOptions.paint_style = stencil.name
        goodsVideoBuildOptions.paint_style_id = stencil.id
    }

    function onChangeVideoStencilSceneStyle(stencil) {
        goodsVideoBuildOptions.scene_style = stencil.name
        goodsVideoBuildOptions.scene_style_id = stencil.id
    }

    function onChangeVideoStencilLocationStyle(stencil) {
        goodsVideoBuildOptions.location_style = stencil.name
        goodsVideoBuildOptions.location_style_id = stencil.id
    }

    function onChangeVideoStencilPersonStyle(stencil) {
        goodsVideoBuildOptions.person_style = stencil.name
        goodsVideoBuildOptions.person_style_id = stencil.id
    }

    function onChangeVideoStencilFaceStyle(stencil) {
        goodsVideoBuildOptions.face_style = stencil.name
        goodsVideoBuildOptions.face_style_id = stencil.id
    }

    function onChangeVideoStencilTagsStyle(stencil) {
        goodsVideoBuildOptions.tag_style = stencil.name
        goodsVideoBuildOptions.tag_style_id = stencil.id
    }

    function onCollectTaskVideo(video, is_collected, task_id, id, task_media_site, asset_url) {
        let params = {
            id: id,
            task_id: task_id,
            task_media_site: task_media_site,
            asset_url: asset_url,
            task_type: 'goods-video',
        }

        if (!is_collected) {
            createUserVideoCollect(params).then((res) => {
                video.is_collected = true
                let message = res.msg
                ElMessage({
                    message: message,
                    type: 'success',
                })
            }).catch((err) => {
                let message = res.msg
                ElMessage({
                    message: message,
                    type: 'error',
                })
                console.log(err)
            })
        } else if (is_collected) {
            destroyUserVideoCollect(params).then((res) => {
                video.is_collected = false
                let message = res.msg
                ElMessage({
                    message: message,
                    type: 'warning'
                })
            }).catch((err) => {
                let message = res.msg
                ElMessage({
                    message: message,
                    type: 'error'
                })
                console.log(err)
            })
        }
    }

    function onDestroyTaskVideo(id, task_id) {
        ElMessageBox.confirm('确认要删除这张图吗？', '危险提示',{
            type: 'danger',
            callback: (action) => {
                let params = {
                    id: id,
                    task_id: task_id,
                }
                destroyGoodsVideo(params).then((res) => {
                    let taskParams = {
                        id: goodsVideoTaskCurrentId.value,
                    }
                    retriveVideoTask(taskParams).then((res) => {
                        goodsVideoTaskCurrent.value = reformatTask(res.data.data)
                        onShowGoodsVideoCurrentTask(
                            goodsVideoTaskCurrentId.value,
                            goodsVideoTaskCurrent.value)
                    }).catch((err) => {
                        console.log(err)
                    })
                    
                }).catch((err) => {
                    console.log(err)
                })
            }
        })
    }

    function onToggleTaskMoreDetails() {
        isShowTaskMoreDetails.value = !isShowTaskMoreDetails.value
    }

</script>

<style scoped lang="scss">
    ::v-deep(.el-tabs__content){
        // overflow:hidden 会导致 分页下拉框超出部分会被.el-tabs__content隐藏
        overflow: visible;
    }
    .page {
        position: relative;
        .aside {
            position: relative;
            .list {
                position: relative;
                display: flex;
                flex-direction: column;
                .list-item {
                    display: flex;
                    flex-direction: row;
                    background-color: #fff;
                    color: #424242;
                    margin-bottom: 4px;
                    &.active {
                        background-color: #eee;
                        color: #333;
                    }
                    .item-thumb {
                        width: 48px;
                        height: 48px;
                        border-radius: 50%;
                        margin-right: 4px;
                    }
                    .item-content {
                        display: flex;
                        flex-direction: row;
                    }
                    .item-actions {

                    }
                }
            }
        }
        .main {
            position: relative;
            .section--main {
                position: relative;
            }
            .section--new-goodsvideo {
                position: relative;
                .footer--task-create {
                    background-color: #fafafa;
                    opacity: 0.7;
                    color: #212121;
                }                
            }
            .section--current-goodsvideo {
                position: relative;
            }

            .main-gutter {
            }

            .gutter-switch {
                &.active {
                    background-color: #e0e0e0;
                    color: #212121;
                }
            }

            .flag-icon {
                color: #212121;
                &.flag-icon--fin {
                    color: #000;
                }
            }
            .flag-label {
                margin-left: 4px;
                margin-right: 4px;
                &.flag-label--fin {
                    color: #000;
                }
            }
        }
        .section-header {
            border-bottom: 1px dashed #eee;
        }
        .dialog--finetune {
            .finetune-pen-switch {
                background-color: #fff;
                color: #757575;
                &.active {
                    background-color: #eee;
                    color: #424242;
                }
            }
        }
        :deep(.desc-label) {
            padding-left: 16px;
            margin-left: 16px;
        }
        :deep(.desc-content) {
            padding-left: 16px;
        }

        .aside--task-nav {

        }
        .footer--task-actions {
        }

        :deep(.el-tabs__item.is-active .label) {
            color: #212121 !important;
            font-weight: 700;
        }
    }
</style>