<template>
    <div class="personalmypoints-tontent">
         <div>
            <el-empty
                description="敬请期待"
            >
            </el-empty>
        </div>
        <!-- <div class="my-text">
            <el-text >已开通会员  会员有效期：2024年12月30日00:00:00</el-text>
            <el-text >未开通会员  会员有效期：</el-text>
        </div> -->
        <!-- <el-text class="mx-1">已开通会员/未开通会员  会员有效期：2024年12月30日00:00:00</el-text>
        <div class="my-points">
            <div class="points-recharge">
                    <el-text>永久积分<span class="character"> 300,000</span></el-text>
                    <el-text>单日有效积分<span class="character"> 500</span></el-text>
            </div>
            <div class="points-integral">
                <el-text > <el-button  class="recharge-but" @click="onMyIntegralRecharge">积分充值</el-button></el-text>
            </div>
        </div> -->
    </div>
</template> 
<script setup>
// import {ref}from 'vue'
import {useRouter}from 'vue-router'
//路由
const router = useRouter()
//积分充值事件
const onMyIntegralRecharge = ()=>{
        router.push('/recharge')
}
</script>
<style lang="scss">
.personalmypoints-tontent{
    width:100%;
    .my-points{
        width: 95%;
        height: 100px;
        margin-left: 5px;
        border: 1px #000 solid;
        display: flex;
        align-items: center;
        .points-recharge{
            width:70%;
            height: 95%;
            // background: red;
            display: flex;
            justify-content: space-around;
            border-right: #8c939d 1px solid;
            .character{
                position: relative; /* 或 absolute/fixed */
                top: -8px; /* 向上移动20像素 */
                font-size: 22px;
                font-weight: bold;
            }
        }
        .points-integral{
            flex:1;
            // height:95%;
            // background: palevioletred;
            // border-left: 1px solid #000;
            text-align: center;
            .recharge-but{
                background: #000;
                color:#fff;
            }
        }
    }
}
</style>