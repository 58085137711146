<template>
    <div class="pageGen">
        <el-form label-position="top" ref="ruleFormRef" :model="formValues" :rules="rules" label-width="120px"
            class="my-form">
            <div class="textareaContainer" style="position: relative;">
                <el-form-item prop="value1" label="画面生成" class="textareaItem ">
                    <span :style="{ color: formValues.value1?.length > 3000 ? 'red' : '#909399', }"
                        style="position: absolute;bottom: 0px;right: 18px;z-index: 2; font-size: 12px;">
                        {{ formValues.value1?.length || 0 }} / 3000
                    </span>
                    <el-input input-style="height: 100%" :class="['fullTextarea',formValues.value1?.length > 3000 ? 'active':'']" v-model="formValues.value1" type="textarea"
                        placeholder="复制粘贴 小说推文故事">
                    </el-input>
                </el-form-item>
            </div>
            <div class="formContainer">
                <!-- <el-form-item label="内容风格" prop="value2">
                    <CjSelect v-model="formValues.value2" :defaultSelectOne="true"
                        :fetchConfig="{ apiName: 'demo_list', otherParams: {} }"
                        :optionConfig="{ label: 'name', value: 'id' }" />
                </el-form-item> -->
                <el-form-item label="画面大小" prop="value3">
                    <CjSelect v-model="formValues.value3" :defaultSelectOne="true"
                        :fetchConfig="{ apiName: 'noveltweet/video_scale', otherParams: {} }"
                        :optionConfig="{ label: 'scale', value: 'id' }" />
                </el-form-item>
                <el-form-item label="画面风格" prop="value4">
                    <ModalItemSelect v-model="formValues.value4" :defaultSelectOne="true" modalTitle="画面风格"
                        :fetchConfig="{ apiName: 'noveltweet/screen_style', otherParams: {} }"
                        :optionConfig="{ label: 'title', value: 'id', img: 'url' }" />
                </el-form-item>
                <el-form-item label="配音角色和语速" prop="value5">
                    <ModalItemSelect2 v-model="formValues.value5" :defaultSelectOne="true" modalTitle="配音角色和语速"
                        :fetchConfig="{ apiName: 'noveltweet/dub', otherParams: {} }"
                        :optionConfig="{ label: 'title', value: 'id', img: 'url' }"
                        :valueTextFormat="(data) => data.label + ' ' + data.speed" :type="'mp3'" />
                </el-form-item>
                <el-form-item label="背景音乐" prop="value6">
                    <ModalItemSelect v-model="formValues.value6" :defaultSelectOne="true" modalTitle="背景音乐"
                        :fetchConfig="{ apiName: 'noveltweet/music', otherParams: {} }"
                        :optionConfig="{ label: 'title', value: 'id', img: 'url' }" :type="'mp3'" />
                </el-form-item>
                <el-form-item label="字幕" prop="value7">
                    <ModalItemSelect v-model="formValues.value7" :defaultSelectOne="true" modalTitle="字幕"
                        :fetchConfig="{ apiName: 'noveltweet/typeface', otherParams: {} }"
                        :optionConfig="{ label: 'title', value: 'id', img: 'url' }" :itemStyle="{ background: '#000' }" />
                </el-form-item>
            </div>
        </el-form>
    </div>
</template>

<style src="./style.css" scoped></style>
<script setup>
import { ref, inject, reactive } from "vue"
import CjSelect from "@/comm/cj-form/inputs/Select/index.vue"
import ModalItemSelect from "@/comm/cj-form/inputs/ModalItemSelect/index.vue"
import ModalItemSelect2 from "@/comm/cj-form/inputs/ModalItemSelect2/index.vue"

const myFetch = inject('myFetch')

const ruleFormRef = ref()
const formValues = ref({
    value1: "", // 左边故事字段
    // value2: "", // 内容风格
    value3: "", // 画面大小
    value4: "", // 画面风格
    value5: "", // 配音角色和语速   
    value6: "", // 背景音乐
    value7: "", // 字幕
})

const rules = reactive({
    value1: [
        { required: true, message: '必填项' },
    ],
    // value2: [
    //     { required: true, message: '必填项' },
    // ],
    value3: [
        { required: true, message: '必填项' },
    ],
    value4: [
        { required: true, message: '必填项' },
    ],
})


const getValues = async () => {
    return new Promise((resolve) => {
        ruleFormRef.value.validate((valid, fields) => {
            if (valid) {
                resolve({ ...formValues.value })
            } else {
                console.log('error submit!', fields)
            }
        })
    })
}
defineExpose({
    setValues: (values) => {
        for (const key in values) {
            formValues.value[key] = values[key];
        }
    },
    getValues,
    getValuesSync: () => {
        return formValues.value
    }
})
</script>
<style>
.textareaItem .el-form-item__content {
    height: calc(100% - 22px);
}


.textareaContainer .el-textarea__inner {
    resize: none !important;
}
.active{
    border: 1px red solid;
    border-radius: 5px;
    /* box-shadow:none; */
}
</style>