<template>
    <el-select v-model="value" v-bind="props.selectAttr" @change="valueChange" class="my-select">
        <el-option v-for="item in optionData" :key="item.value" :label="item.label" :value="item.value"
            v-bind="props.optionAttr" />
    </el-select>
</template>
<style src="./style.css" scoped></style>
<script setup>
import { ref, inject, onMounted } from "vue"
import { ElMessage } from 'element-plus'

const myFetch = inject('myFetch')
/**
 * props.selectAttr: {}  el-select 的配置
 * props.optionAttr: {}  el-option 的配置  
 * props.fetchConfig: {} 请求接口配置的配置
 * props.defaultSelectOne 默认选中第一个选项
 * 
 * ins.refresh()  刷新接口
*/
const props = defineProps(['selectAttr', 'optionAttr', 'fetchConfig', 'optionConfig', 'defaultSelectOne', 'modelValue']);
const emit = defineEmits(['update:modelValue'])

const optionConfig = {
    label: "label",
    value: "value",
    ...props.optionConfig
}
const optionData = ref([])
const value = ref(props.modelValue)

function valueChange(value) {
    emit('update:modelValue', value);
}

async function refresh() {
    // optionData.value = [
    //     {
    //         value: '1',
    //         label: '1',
    //     }, 
    // ]; 
    const { label: lKey, value: vKey } = optionConfig;
    const { apiName, otherParams } = props.fetchConfig || {};
    const { code, msg, data } = await myFetch(apiName, otherParams);
    if (code === 2000) {
        optionData.value = data.list.map(item => ({
            label: item[lKey],
            value: item[vKey],
        }))
        if (props.defaultSelectOne && optionData.value[0] && !value.value) {
            const oneValue = optionData.value[0].value;
            valueChange(oneValue);
            value.value = oneValue;
        }
    } else {
        ElMessage.error(msg);
    }

}


onMounted(() => {
    refresh();
})
defineExpose({
    refresh
})

</script>
<style scoped>
.my-select {
    width: 100%;
}
</style>