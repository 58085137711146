
import MyStorage from "./storage"
import { ElMessage } from 'element-plus'
import {API_URL}from '../config'
//引入token
import {getStorage} from '@/utils/util'
const uploadToken = getStorage('logintoken')
const access_token = 'JWT' + uploadToken
/**
 * uploadUrl 上传的服务地址
 * file 上传输入框的 event.target.files[0]
 * progressCb 上传进度的回调 event
 *
 * 调用实列
 * upload(uploadUrl, file)
 */
const upload = (uploadUrl, file, progressCb) => {  
    return new Promise(function (resolve, reject) {
        // const { domain } = window.configs;

        // const loginInfo = MyStorage.getItem("loginInfo");
        // let access_token = loginInfo ? loginInfo.data["access_token"] : undefined;
        // if (!access_token && verifyToken) {
        //     resolve({
        //         success: false,
        //         code: "902",
        //         message: "没有登录信息，请登录！",
        //     })
        //     return;
        // }

 
        let fd = new FormData();
        fd.append("fileToUpload", file);
        let apiAddress = uploadUrl.indexOf("http") !== -1 ? uploadUrl : API_URL + uploadUrl;
        console.log(apiAddress);
        //令牌
        const tokenObj = { Authorization: access_token };

        $.ajax({
            url: apiAddress,
            type: "POST",
            data: fd,
            processData: false,
            contentType: false,
            dataType: "json",
            // async: false,
            headers: {
                ...tokenObj,
            },
            success: function ({ data, success, message, code }) { 
                // if (code === "3007" || code === "A0230") {
                // //需要去刷新缓存
                // // window._refreshTokenIng = true;
                // const loginInfo = MyStorage.getItem("loginInfo").data;
                // const {
                // 	apis,
                // 	appInfo: { grant_type, client_id, client_secret },
                // } = window.configs;
                // const isNewVer = grant_type && client_id && client_secret;
                // if (isNewVer && !loginInfo.refresh_token) {
                // 	//需要重新登录
                // 	resolve({
                // 		success: false,
                // 		code: "901",
                // 		message: lanObj["901"],
                // 	});
                // 	return;
                // }
                // const loginApiGetStr = !isNewVer
                // 	? "refreshAccessToken"
                // 	: `${apis["refreshAccessToken"]}?grant_type=refresh_token&client_id=${client_id}&client_secret=${client_secret}&refresh_token=${loginInfo.refresh_token}`;

                // MyFetch(loginApiGetStr, { token: loginInfo.token }, "1", !isNewVer).then(({ success, data, message, code }) => {
                // 	// window._refreshTokenIng = false;
                // 	if (success) {
                // 		let newLoginInfo = {};
                // 		if (isNewVer) {
                // 			newLoginInfo = data;
                // 		} else {
                // 			newLoginInfo["token"] = data;
                // 		}
                // 		let _loginInfo = {
                // 			...loginInfo,
                // 			...newLoginInfo,
                // 		};

                // 		MyStorage.setItem("loginInfo", { ..._loginInfo }).then((loginInfo) => {
                // 			//重新执行失败的请求
                // 			upload(uploadUrl)(e).then((res) => {
                // 				resolve(res);
                // 			});
                // 		});
                // 	} else {
                // 		// 直接跳转登录页面
                // 		resolve({
                // 			success: false,
                // 			code: "901",
                // 			message: lanObj["901"],
                // 		});

                // 		// if (code === "-1") {
                // 		// 	notification.error({
                // 		// 		message: "系统遇到问题，请联系运维人员",
                // 		// 		description: message,
                // 		// 		duration: 3,
                // 		// 	});
                // 		// } else {
                // 		// 	notification.warn({
                // 		// 		message: "提示",
                // 		// 		description: message,
                // 		// 		duration: 3,
                // 		// 	});
                // 		// }
                // 	}
                // });
                // } else {
                //成功后的回调事件
                if (success) {
                    resolve({ data, success, message, code });
                } else {
                    reject({ data, success, message, code });
                }
                // }
            },
            error: function (error) {
                reject({ data: null, success: false, message: error.statusText });
                ElMessage.error(`error:${error.statusText}`)  
            },
            xhr: function () {
                const xhr = new XMLHttpRequest();
                // 使用XMLHttpRequest.upload监听上传过程，注册progress事件，打印回调函数中的event事件
                xhr.upload.addEventListener("progress", function (e) {
                    // e.loaded代表上传了多少
                    // e.total代表总数为多少
                    progressCb &&
                        progressCb({
                            loaded: e.loaded,
                            total: e.total,
                            isTrusted: e.isTrusted,
                            percent: ((e.loaded / e.total) * 100).toFixed(2),
                        });
                });

                return xhr;
            },
        });
    });
};
export default upload;
