<template>
    <div
        class="page page--text2image-manage lycontainer flex w-full h-full"
        :class="{'ly-is-full':isFull}">
        <iframe
            :src="remoteServerUrl" 
            class="flex-1 w-full h-full"
        />
    </div>
</template>

<script setup>
    import { ref, reactive, nextTick, onMounted,onBeforeUnmount,getCurrentInstance} from 'vue'
    import { useRoute } from "vue-router";
    import { ElMessage, ElMessageBox, genFileId } from 'element-plus'
    // import Konva from 'konva'
    import { 
        getSdConfig,
        getUserImageCollectList,createUserImageCollect,destroyUserImageCollect,
        getUserImageTaskList, 
        createImageTask, retriveImageTask, updateImageTask, destroyImageTask, queryImageTask,
        submitImageTask, getImageTaskProgress, getImageTaskResult, 
        getUserImageRefList, uploadGoodsImageRef,destroyGoodsImageRef,
        destroyGoodsImage,
        getGoodsImageMaskAuto, uploadGoodsImageMask, 
        getUserImagePretrainModelList, getImageStencilCategoryList, getImageStencilList,
        sendSegmentReq,
        translateByTencent, translateByAzure, translateByAlibaba } from '@/api/api'
    import {hasPermission,getTableHeight} from "@/utils/util";
    import { MEDIA_SITE_ROOT } from '@/config'
    
    const { proxy } = getCurrentInstance()

    const route = useRoute();

    let isFull = ref(false)
    let isShowTaskMoreDetails = ref(false)
    let footerNav = ref()

    function setFull(){
        isFull.value=!isFull.value
        window.dispatchEvent(new Event('resize'))
    }

    let scrowHight = ref(0)

    let remoteServerUrl = ref('')
    let sdConfig = ref({})

    let activeTopNavTab = ref("goods")
    let activeMainNavTab = ref("collect")
    let activeNewNavTab = ref("stencil")
    let editableTopNavTabs = ref([])

    let userTaskList = ref([
    ])
    let userTaskListCurrentPage = ref(1)
    let userTaskListScrollHeight = ref('100%')
    let hasMoreUserTaskList = ref(false)

    let userCollectList = ref([])
    let userCollectListCurrentPage = ref(1)
    let hasMoreUserCollectList = ref(false)
    let galleryList = ref([])

    let isShowGoodsImageMain = ref(true)
    let isCurrentGoodsImageTaskTodo = ref(true)
    let isShowCurrentGoodsImageTask = ref(false)
    let isShowUploadRef = ref(false)
    let isShowUploadMask = ref(false)

    let taskNewName = ref('')
    let isShowTaskEditDialog = ref(false)
    let isShowFinetuneDialog = ref(false)

    let pretrainModelType = ref('upload')
    let pretrainModelCurrentThumbUrl = ref('')
    let pretrainModelCurrentName = ref('')
    let pretrainModelCurrentId = ref('')
    let goodsImageRefGalleryCurrent = ref('')
    let goodsImageRefList = ref([])
    let goodsImageRefUploaded = ref('')
    let goodsImageRefUploaderRef = ref()

    let imagePretrainModelList = ref([])

    let imageStencilMediumStyleList = ref([])
    let imageStencilPaintStyleList = ref([])
    let imageStencilCompositionStyleList = ref([])
    let imageStencilSceneStyleList = ref([])
    let imageStencilLocationStyleList = ref([])
    let imageStencilPersonStyleList = ref([])
    let imageStencilFaceStyleList = ref([])
    let imageStencilTagList = ref([])

    let goodsImageTaskCurrentMainOption = ref('model')
    let goodsImageBuildTaskResult = ref([])
    let goodsImageTaskCurrent = ref()
    let goodsImageTaskCurrentId = ref()
    let goodsImageTaskCurrentTaskId = ref()
    let goodsImageTaskCurrentContextDescList = ref([])
    let goodsImageTaskCurrentTotalImageList = ref([])
    let goodsImageTaskCurrentName = ref()
    let goodsImageTaskCurrentTaskName = ref()
    let goodsImageTaskCurrentStep = ref(0)

    let goodsImageMaskType = ref('auto')
    let goodsImageMaskAuto = ref()
    let goodsImageMaskAutoGetType = ref('extract')
    let goodsImageMaskIsNeedFinetune = ref(true)
    let goodsImageMaskUploaded = ref()
    let goodsImageMaskUploaderRef = ref()

    let finetuneStageContainer = ref()
    let finetuneStage = ref()
    let finetuneStageConfig= ref({
        width: 300,
        height: 300,
    })
    let finetuneStageLayer = ref()
    let finetuneStageLayerConfig= ref({
    })
    let finetuneStageLayerBgImage = ref()
    let finetuneStageLayerBgImageConfig= ref({
        image: '',
        x: 0,
        y: 0,
        width: 300,
        height: 300,
    })

    let finetuneExpectedContainer = ref()
    let finetuneExpected = ref()
    let finetuneExpectedConfig= ref({
        width: 300,
        height: 300,
    })
    let finetuneExpectedLayerConfig= ref({
    })
    let finetuneExpectedImage= ref()
    let finetuneExpectedImageConfig= ref({
    })
    let finetuneExpectedInfo = ref('')
    let finetuneExpectedResult = ref('')

    let finetunePenMode = ref('include')
    let finetunePenMudgeWidth = ref(4)
    let finetunePositivePoints = ref([])
    let finetuneNegativePoints = ref([])

    let goodsImageFinetuneType = ref('auto-extract')
    let goodsImageFinetune = ref('')
    let goodsImageFinetuneWidth = ref(256)
    let goodsImageFinetuneHeight = ref(256)
    let goodsImageFinetuneWidthOrig = ref(256)
    let goodsImageFinetuneHeightOrig = ref(256)
    let goodsImageFinetuneScale = ref()
    let goodsImageFinetunePreview = ref('')

    let goodsImageBuildOptions = reactive({
        pretrain_model_id: '',
        pretrain_model_name: '',
        pretrain_model_thumb_url: '',
        pretrain_model_lora_name: '',
        prompt_cn: '',
        prompt_cn_negative: '',
        prompt_en: '',
        prompt_en_negative: '',
        batch_size: 4,
        medium_style: '',
        medium_style_id: '',
        paint_style: '',
        paint_style_id: '',
        scene_style: '',
        scene_style_id: '',
        location_style: '',
        location_style_id: '',
        composition_style: '',
        composition_style_id: '',
        person_style: '',
        person_style_id: '',
        face_style: '',
        face_style_id: '',
        tags_style: '',
        tags_style_id: '',
    })
    let goodsImageBuildHelp = ref(
        `
        描述你想要生成的图片内容，支持中英文，但是最终都要生成英文才能提交。
        `
    )

    let goodsImageMaskProgressing = ref(false)

    let goodsImageTaskProgressing = ref(false)
    let goodsImageTaskProgressValue = ref(0)
    let goodsImageTaskProgressInfo = ref('')
    let hasImageTaskProgressPreview = ref(false)
    let goodsImageTaskImagesPreview = ref([])
    let goodsImageTaskCurrentGeneratedImages = ref([])
    let goodsImageTaskTimerId = ref(-1)

    let goodsImageRefCanvas = ref()
    let goodsImageMaskCanvas = ref()

    let goodsImageRefStageConfig= ref({
        width: 300,
        height: 300,
    })
    
    let goodsImageRefLayerConfig= ref()
    let goodsImageMaskLayerConfig= ref()
    let goodsImageMaskImageConfig= ref({
        image: ''
    })


    function getTopNavTabs() {
        // platformsettingsSysconfig({limit:999,parent__isnull:true}).then(res=>{
        //     if(res.code == 2000){
        //         editableTopNavTabs.value = res.data.data
        //     }
        // })
        editableTopNavTabs.value = [
            {
                title: '商品图',
                key: 'goods',
            },
        ]
    }
    function handleResize(){
        nextTick(() => {
            let tabSelectHeight = proxy.$refs.tableSelect?proxy.$refs.tableSelect.offsetHeight:0
            tabSelectHeight = isFull.value?tabSelectHeight + 90 :tabSelectHeight + 200
            scrowHight.value = getTableHeight(tabSelectHeight,false)
        })
    }

    onMounted(() => {
        innerGetSdConfig()

        getTopNavTabs()
        handleResize()
        window.addEventListener('resize',handleResize)

        getUserTasks()
        getUserCollects(1)
        getUserGoodsImageRefs()
        getUserImagePretainModels()
        getImageStencils('medium')
        getImageStencils('composition')
        getImageStencils('paint')
        getImageStencils('scene')
        getImageStencils('location')
        getImageStencils('person')
        getImageStencils('face')
        // getImageStencils('tag')

        // if (finetuneStageContainer.value) {
        //     app.mount(finetuneStageContainer.value);
        // }
    })
    onBeforeUnmount(()=>{
        window.removeEventListener('resize',handleResize)
    })

    defineExpose({
        setFull
    })

    function innerGetSdConfig() {
        getSdConfig().then((res) => {
            sdConfig.value = res.data
            remoteServerUrl.value = sdConfig.value['fooocus-server-commerce']
        }).catch((ex) => {
            console.log(ex)
        })
    }

    function getUserCollects(page = userCollectListCurrentPage.value) {
        let params = {
            page: page,
            task_type: 'goods-image-train',
        }
        getUserImageCollectList(params).then((res) => {
            let remList = res.data?.data
            remList && remList.forEach((el) => {
                el.is_collected = true
                el.task_id = el.task
            })
            let hasNext = res.data?.has_next
            if (hasNext) {
                hasMoreUserCollectList.value = true
            } else {
                hasMoreUserCollectList.value = false
            }
            if (page == 1) {
                userCollectList.value = remList
            } else {
                userCollectList.value = userCollectList.value.concat(remList)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    function onMoreUserCollects() {
        userCollectListCurrentPage.value = parseInt(userCollectListCurrentPage.value) + 1
        getUserCollects(userCollectListCurrentPage.value)
    }

    function getTaskContextDescList(context) {
        let result = []
        if (context?.goodsImageBuildOptions?.pretrain_model_name) {
            result.push('预训练模型:' + context?.goodsImageBuildOptions?.pretrain_model_name)
        }
        if (context?.goodsImageBuildOptions?.prompt_cn) {
            result.push('中文提示词:' + context?.goodsImageBuildOptions?.prompt_cn)
        }
        if (context?.goodsImageBuildOptions?.prompt_en) {
            result.push('英文提示词:' + context?.goodsImageBuildOptions?.prompt_en)
        }
        if (context?.goodsImageBuildOptions?.medium_style) {
            result.push('输出媒质类型:' + context?.goodsImageBuildOptions?.medium_style)
        }
        if (context?.goodsImageBuildOptions?.composition_style) {
            result.push('构图类型:' + context?.goodsImageBuildOptions?.composition_style)
        }
        // if (context?.goodsImageBuildOptions?.paint_style) {
        //     result.push('画风类型:' + context?.goodsImageBuildOptions?.paint_style)
        // }
        if (context?.goodsImageBuildOptions?.scene_style) {
            result.push('动作/场景类型:' + context?.goodsImageBuildOptions?.scene_style)
        }
        if (context?.goodsImageBuildOptions?.location_style) {
            result.push('位置类型:' + context?.goodsImageBuildOptions?.location_style)
        }
        // if (context?.goodsImageBuildOptions?.person_style) {
        //     result.push('人物类型:' + context?.goodsImageBuildOptions?.person_style)
        // }
        // if (context?.goodsImageBuildOptions?.face_style_id) {
        //     result.push('面部类型:' + context?.goodsImageBuildOptions?.face_style_id)
        // }
        if (context?.goodsImageBuildOptions?.tags_style) {
            result.push('标签:' + context?.goodsImageBuildOptions?.tags_style)
        }
        return result
    }

    function reformatTask(dataOrig) {
        let result = {}
        let thumb_url = ''
        let taskImages = []
        let taskCurrentResult = []
        let taskContext = {}

        if (dataOrig?.assets && dataOrig?.assets.length > 0) {
            thumb_url = dataOrig?.assets[0]?.asset_thumb_medium_url
        }
        if (dataOrig) {
            let name = '任务-'+dataOrig.id
            if (dataOrig.task_name) {
                name = dataOrig.task_name
            }
            if (dataOrig.assets) {
                dataOrig.assets.sort((a,b) => {
                    if (a.create_datetime > b.create_datetime) {
                        return -1
                    } else if (a.create_datetime < b.create_datetime) {
                        return 1
                    } else {
                        return 0
                    }
                })
                dataOrig.assets.forEach((el) => {
                    if (el.asset_url) {
                        el.asset_url = el.asset_url
                        taskImages.push({
                            'id': el.id, 
                            'asset_url': el.asset_url, 
                            'asset_thumb_medium_url': el.asset_thumb_medium_url, 
                            'asset_thumb_url': el.asset_thumb_url, 
                            'is_collected': el.is_collected,
                            'task_id': dataOrig.id,
                            'task_media_site': dataOrig.task_media_site,
                            'asset_url': el.asset_url,
                        })
                    } else if (el?.fields?.asset_url) {
                        el.fields.asset_url = el.fields.asset_url
                        taskImages.push({
                            'id': el.fields.id, 
                            'asset_url': el.fields.asset_url, 
                            'asset_thumb_medium_url': el.fields.asset_thumb_medium_url, 
                            'asset_thumb_url': el.fields.asset_thumb_url, 
                            'is_collected': el.fields.is_collected,
                            'task_id': dataOrig.id,
                            'task_media_site': dataOrig.task_media_site,
                            'asset_url': el.fiels.asset_url,
                        })
                    }
                })
            }
            if (dataOrig.task_context) {
                if (typeof(dataOrig.task_context) == 'string') {
                    taskContext = JSON.parse(dataOrig.task_context)
                } else {
                    taskContext = dataOrig.task_context
                }
            }
            if (dataOrig.task_result) {
                if (typeof(dataOrig.task_context) == 'string') {
                    taskCurrentResult = JSON.parse(dataOrig.task_result)
                } else {
                    taskCurrentResult = dataOrig.task_result
                }
            }
            result = {
                id: dataOrig.id,
                name: name,
                thumb_url: thumb_url,
                task_id: dataOrig.task_id,
                task_name: dataOrig.task_name,
                status: dataOrig.status,
                status_text: getStatusText(dataOrig.status),
                task_status: dataOrig.task_status,
                task_context: taskContext,
                task_date_created: dataOrig.task_date_created,
                task_date_done: dataOrig.task_date_done,
                task_args: dataOrig.task_args,
                task_successful: dataOrig.task_successful,
                task_media_site: dataOrig.task_media_site,
                task_images: taskImages,
                task_result: taskCurrentResult,
            }
        }
        return result
    }

    function getUserTasks(page = userTaskListCurrentPage.value, currentTaskId = null, currentTask = null) {
        let params = {
            page: page,
            task_type: 'goods-image-train',
        }
        getUserImageTaskList(params).then((res) => {
            let remList = res.data.data
            let hasNext = res.data.has_next
            if (hasNext) {
                hasMoreUserTaskList.value = true
            } else {
                hasMoreUserTaskList.value = false
            }
            let transList = []
            transList = remList.map((el) => {
                return reformatTask(el)
            })
            console.log('transList')
            console.log(transList)
            if (page == 1) {
                userTaskList.value = transList
            } else {
                userTaskList.value = userTaskList.value.concat(transList)
            }
            if (currentTaskId && currentTask) {
                onShowGoodsImageCurrentTask(currentTaskId, currentTask)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    function onMoreUserTaskList() {
        userTaskListCurrentPage.value = parseInt(userTaskListCurrentPage.value) + 1
        let page = userTaskListCurrentPage.value
        getUserTasks(page)
    }

    function onChangePretrainModel(values) {
        pretrainModelCurrentThumbUrl.value = values.thumb_url
        pretrainModelCurrentName.value = values.name
        pretrainModelCurrentId.value = values.id
        goodsImageBuildOptions.pretrain_model_id = values.id
        goodsImageBuildOptions.pretrain_model_name = values.name
        goodsImageBuildOptions.pretrain_model_thumb_url = values.thumb_url
        goodsImageBuildOptions.pretrain_model_lora_name = values.lora_name
        onSwitchMainOption2Scene()
    }

    function onProcessGoodsImageRefActions(command) {
        if (command == 'drop') {
            ElMessageBox.confirm('确认要删除这项参考图吗？', '提示',{}).then(() => {
                let params = {
                    id: goodsImageRefGalleryCurrentId.value
                }
                destroyGoodsImageRef(params).then((res) => {
                    getUserGoodsImageRefs()
                }).catch((err) => {
                    console.log(err)
                })
            }).catch(() => {

            })
        }
    }

    function getUserGoodsImageRefs() {
        let params = {
            page: 1,
        }
        getUserImageRefList(params).then((res) => {
            goodsImageRefList.value = res.data.data
        }).catch((err) => {
            console.log(err)
        })
    }

    function getUserImagePretainModels() {
        let params = {
            page: 1,
        }
        getUserImagePretrainModelList(params).then((res) => {
            imagePretrainModelList.value = res.data.data
        }).catch((err) => {
            console.log(err)
        })
    }

    function getImageStencils(styleName) {
        let params = {
            page: 1,
        }
        if (styleName == 'medium') {
            params['stencil_category_id'] = 1
            getImageStencilList(params).then((res) => {
                imageStencilMediumStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'composition') {
            params['stencil_category_id'] = 2
            getImageStencilList(params).then((res) => {
                imageStencilCompositionStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'paint') {
            params['stencil_category_id'] = 3
            getImageStencilList(params).then((res) => {
                imageStencilPaintStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'scene') {
            params['stencil_category_id'] = 4
            getImageStencilList(params).then((res) => {
                imageStencilSceneStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'location') {
            params['stencil_category_id'] = 5
            getImageStencilList(params).then((res) => {
                imageStencilLocationStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'person') {
            params['stencil_category_id'] = 8
            getImageStencilList(params).then((res) => {
                imageStencilPersonStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'face') {
            params['stencil_category_id'] = 9
            getImageStencilList(params).then((res) => {
                imageStencilFaceStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'tag') {
            params['stencil_category_id'] = 10
            getImageStencilList(params).then((res) => {
                imageStencilTagList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        }
    }

    function onShowGoodsImageMain() {
        isShowGoodsImageMain.value = true
        isShowCurrentGoodsImageTask.value = false
        isCurrentGoodsImageTaskTodo.value = false
        userCollectListCurrentPage.value = 1
        getUserCollects(userCollectListCurrentPage.value)
        goodsImageTaskCurrentId.value = null
        goodsImageTaskCurrentTaskId.value = null
    }

    function onNewGoodsImageTask() {
        isShowGoodsImageMain.value = false
        isShowCurrentGoodsImageTask.value = true
        isCurrentGoodsImageTaskTodo.value = true

        goodsImageTaskProgressing.value = false
        hasImageTaskProgressPreview.value = false
        goodsImageTaskImagesPreview.value = []

        goodsImageBuildOptions.pretrain_model_id = ''
        goodsImageBuildOptions.pretrain_model_name = ''
        goodsImageBuildOptions.pretrain_model_thumb_url = ''
        goodsImageBuildOptions.pretrain_model_lora_name = ''
        goodsImageBuildOptions.prompt_cn = ''
        goodsImageBuildOptions.prompt_cn_negative = ''
        goodsImageBuildOptions.prompt_en = ''
        goodsImageBuildOptions.prompt_en_negative = ''
        goodsImageBuildOptions.batch_size = 4
        goodsImageBuildOptions.medium_style = ''
        goodsImageBuildOptions.medium_style_id = ''
        goodsImageBuildOptions.paint_style = ''
        goodsImageBuildOptions.paint_style_id = ''
        goodsImageBuildOptions.scene_style = ''
        goodsImageBuildOptions.scene_style_id = ''
        goodsImageBuildOptions.location_style = ''
        goodsImageBuildOptions.location_style_id = ''
        goodsImageBuildOptions.composition_style = ''
        goodsImageBuildOptions.composition_style_id = ''
        goodsImageBuildOptions.person_style = ''
        goodsImageBuildOptions.person_style_id = ''
        goodsImageBuildOptions.face_style = ''
        goodsImageBuildOptions.face_style_id = ''
        goodsImageBuildOptions.tags_style = ''
        goodsImageBuildOptions.tags_style_id = ''

        let params = {
            task_type: 'goods-image-train',
        }
        createImageTask(params).then((res) => {
            let newTaskId = res.data.data.id
            let newTask = res.data.data
            newTask = reformatTask(newTask)
            userTaskList.value.push(newTask)
            onShowGoodsImageCurrentTask(newTaskId, newTask)
        }).catch((err) => {
            console.log(err)
        })
    }

    function onShowGoodsImageCurrentTask(taskId, task) {
        isShowTaskMoreDetails.value = false
        goodsImageTaskCurrentId.value = taskId
        goodsImageTaskCurrentTaskId.value = task.task_id
        goodsImageTaskCurrent.value = task
        goodsImageTaskCurrentName.value = task.name
        goodsImageTaskCurrentTaskName.value = task.task_name
        if (task.task_images) {
            goodsImageTaskCurrentTotalImageList.value = task.task_images
        }
        goodsImageTaskCurrentGeneratedImages.value = task.task_result
        goodsImageTaskCurrentMainOption.value = 'model'
        goodsImageTaskCurrentStep.value = 0

        isShowGoodsImageMain.value = false
        isShowCurrentGoodsImageTask.value = true
        isCurrentGoodsImageTaskTodo.value = task.task_successful != 1

        
        let ctxPretrainModelCurrentThumbUrl = ''
        let ctxPretrainModelCurrentName = ''
        let ctxPretrainModelCurrentLoraName = ''
        let ctxPretrainModelCurrentId = ''
        let ctxPretrainModelCurrent = {}
        let ctxGoodsImageBuildOptions = {}
        let context = {}
        if (task.task_context) {
            if (typeof(context) == 'string') {
                context = JSON.parse(context)
            } else {
                context = task.task_context
            }
        }
        if (context.goodsImageBuildOptions) {
            ctxPretrainModelCurrentThumbUrl = context.goodsImageBuildOptions.pretrain_model_thumb_url
            ctxPretrainModelCurrentName = context.goodsImageBuildOptions.pretrain_model_name
            ctxPretrainModelCurrentId = context.goodsImageBuildOptions.pretrain_model_id
            ctxPretrainModelCurrentLoraName = context.goodsImageBuildOptions.pretrain_model_lora_name
            ctxGoodsImageBuildOptions = context.goodsImageBuildOptions

            pretrainModelCurrentThumbUrl.value = ctxPretrainModelCurrentThumbUrl
            pretrainModelCurrentName.value = ctxPretrainModelCurrentName
            pretrainModelCurrentId.value = ctxPretrainModelCurrentId
            goodsImageBuildOptions.pretrain_model_id = ctxGoodsImageBuildOptions.pretrain_model_id
            goodsImageBuildOptions.pretrain_model_name = ctxGoodsImageBuildOptions.pretrain_model_name
            goodsImageBuildOptions.pretrain_model_thumb_url = ctxGoodsImageBuildOptions.pretrain_model_thumb_url
            goodsImageBuildOptions.pretrain_model_lora_name = ctxGoodsImageBuildOptions.pretrain_model_lora_name
            goodsImageBuildOptions.batch_size = ctxGoodsImageBuildOptions.batch_size
            goodsImageBuildOptions.prompt_cn = ctxGoodsImageBuildOptions.prompt_cn
            goodsImageBuildOptions.prompt_en = ctxGoodsImageBuildOptions.prompt_en
            goodsImageBuildOptions.prompt_en_negative = ctxGoodsImageBuildOptions.prompt_en_negative
            goodsImageBuildOptions.medium_style = ctxGoodsImageBuildOptions.medium_style
            goodsImageBuildOptions.medium_style_id = ctxGoodsImageBuildOptions.medium_style_id
            goodsImageBuildOptions.composition_style = ctxGoodsImageBuildOptions.composition_style
            goodsImageBuildOptions.composition_style_id = ctxGoodsImageBuildOptions.composition_style_id
            goodsImageBuildOptions.paint_style = ctxGoodsImageBuildOptions.paint_style
            goodsImageBuildOptions.paint_style_id = ctxGoodsImageBuildOptions.paint_style_id
            goodsImageBuildOptions.scene_style = ctxGoodsImageBuildOptions.scene_style
            goodsImageBuildOptions.scene_style_id = ctxGoodsImageBuildOptions.scene_style_id
            goodsImageBuildOptions.person_style = ctxGoodsImageBuildOptions.person_style
            goodsImageBuildOptions.person_style_id = ctxGoodsImageBuildOptions.person_style_id
            goodsImageBuildOptions.face_style = ctxGoodsImageBuildOptions.face_style
            goodsImageBuildOptions.face_style_id = ctxGoodsImageBuildOptions.face_style_id
            goodsImageBuildOptions.tags_style = ctxGoodsImageBuildOptions.tags_style
            goodsImageBuildOptions.tags_style_id = ctxGoodsImageBuildOptions.tags_style_id

            goodsImageTaskCurrentContextDescList.value = getTaskContextDescList(task.task_context)
        } else {
            pretrainModelCurrentThumbUrl.value = ''
            pretrainModelCurrentName.value = ''
            pretrainModelCurrentId.value = ''

            goodsImageBuildOptions.pretrain_model_id = ''
            goodsImageBuildOptions.pretrain_model_name = ''
            goodsImageBuildOptions.pretrain_model_thumb_url = ''
            goodsImageBuildOptions.pretrain_model_lora_name = ''
            goodsImageBuildOptions.batch_size = 4
            goodsImageBuildOptions.prompt_cn = ''
            goodsImageBuildOptions.prompt_en = ''
            goodsImageBuildOptions.prompt_en_negative = ''
            goodsImageBuildOptions.medium_style = ''
            goodsImageBuildOptions.medium_style_id = ''
            goodsImageBuildOptions.composition_style = ''
            goodsImageBuildOptions.composition_style_id = ''
            goodsImageBuildOptions.paint_style = ''
            goodsImageBuildOptions.paint_style_id = ''
            goodsImageBuildOptions.scene_style = ''
            goodsImageBuildOptions.scene_style_id = ''
            goodsImageBuildOptions.person_style = ''
            goodsImageBuildOptions.person_style_id = ''
            goodsImageBuildOptions.face_style = ''
            goodsImageBuildOptions.face_style_id = ''
            goodsImageBuildOptions.tags_style = ''
            goodsImageBuildOptions.tags_style_id = ''
        }
    }

    function onSwitchMainOption2Mask() {
        goodsImageTaskCurrentMainOption.value = 'mask'
    }
    function onSwitchMainOption2Model() {
        goodsImageTaskCurrentMainOption.value = 'model'
        goodsImageTaskCurrentStep.value = 0
    }
    function onSwitchMainOption2Scene() {
        goodsImageTaskCurrentMainOption.value = 'scene'
        goodsImageTaskCurrentStep.value = 1
    }
    function onSwitchMainOption2Number() {
        goodsImageTaskCurrentMainOption.value = 'number'
        goodsImageTaskCurrentStep.value = 2
    }
    function onSwitchMainOption2Status() {
        goodsImageTaskCurrentMainOption.value = 'status'
        goodsImageTaskCurrentStep.value = 3
    }

    function onTranslateChinesePromptByTencent() {
        let params = {
            text: goodsImageBuildOptions.prompt,
            source: 'zh',
            to: 'en',
        }
        translateByTencent(params).then((res) => {
            let translatedText = res.data.data[0]
            goodsImageBuildOptions.prompt_en = translatedText
        }).catch((err) => {
            console.log(err)
        })
    }

    function onTranslateChinesePromptByAzure() {
        let params = {
            text: goodsImageBuildOptions.prompt,
            source: 'zh',
            to: 'en',
        }
        translateByAzure(params).then((res) => {
            let translatedText = res.data.data[0]
            goodsImageBuildOptions.prompt_en = translatedText
        }).catch((err) => {
            console.log(err)
        })
    }

    function onTranslateChinesePromptByAlibaba() {
        let params = {
            text: goodsImageBuildOptions.prompt_cn,
            source: 'zh',
            to: 'en',
        }
        translateByAlibaba(params).then((res) => {
            let translatedText = res.data.data[0]
            goodsImageBuildOptions.prompt_en = translatedText
        }).catch((err) => {
            console.log(err)
        })
    }

    function onSubmitGoodsImageTask(type) {
        let task_id = ''
        let pretrain_model_id = ''
        let pretrain_model_name = ''
        let pretrain_model_thumb_url = ''
        let pretrain_model_lora_name = ''
        let prompt_cn = ''
        let prompt_cn_negative = ''
        let prompt_en = ''
        let prompt_en_negative = ''
        let generat_number = 1
        let stencil_ids = ''
        let medium_style = ''
        let medium_style_id = ''
        let composition_style = ''
        let composition_style_id = ''
        let paint_style = ''
        let paint_style_id = ''
        let scene_style = ''
        let scene_style_id = ''
        let location_style = ''
        let location_style_id = ''
        let person_style = ''
        let person_style_id = ''
        let face_style = ''
        let face_style_id = ''
        let tags_style = ''
        let tags_style_id = ''
        let image_ref = ''
        let image_mask = ''
        let image_mask_type = ''
        let image_build_type = ''

        let checkItems = {
            'prompt_cn': '提示词还没有设置',
            // 'prompt_en': '英文提示词还没有设置',
            'medium_style_id': '输出媒质类型还没有设置',
            'scene_style_id': '场景/动作类型还没有设置',
            // 'composition_style_id': '构图类型还没有设置',
            // 'paint_style_id': '画风类型还没有设置',
            // 'location_style_id': '位置类型还没有设置',
            // 'person_style_id': '人物类型还没有设置',
            // 'face_style_id': '面部类型还没有设置',
            // 'tags_style_id': '标签还没有设置',
        }
        let invalidItems = []

        if (!goodsImageBuildOptions['pretrain_model_id']) {
            let checkResultMessage = '预训练模型还没有选择'
            ElMessageBox.alert(checkResultMessage, '提示', {
                callback: () => {
                    return
                }
            })
        } else {
            for (let itemKey in checkItems) {
                if (!goodsImageBuildOptions[itemKey]) {
                    invalidItems.push(
                        checkItems[itemKey]
                    )
                }
            }
            if (invalidItems.length == Object.keys(checkItems).length) {
            // if (invalidItems.length == 5) {
                let checkResultMessage = '[' + invalidItems.join(',\n') + '];几项中需要选择其中一项!'
                ElMessageBox.alert(checkResultMessage, '提示', {
                    callback: () => {
                        return
                    }
                })
            } else {
                pretrain_model_id = goodsImageBuildOptions.pretrain_model_id
                pretrain_model_name = goodsImageBuildOptions.pretrain_model_name
                pretrain_model_thumb_url = goodsImageBuildOptions.pretrain_model_thumb_url
                pretrain_model_lora_name = goodsImageBuildOptions.pretrain_model_lora_name
                prompt_cn = goodsImageBuildOptions.prompt_cn
                prompt_cn_negative = goodsImageBuildOptions.prompt_cn_negative
                prompt_en = goodsImageBuildOptions.prompt_en
                prompt_en_negative = goodsImageBuildOptions.prompt_en_negative
                generat_number = goodsImageBuildOptions.batch_size
                medium_style_id = goodsImageBuildOptions.medium_style_id
                medium_style = goodsImageBuildOptions.medium_style
                composition_style_id = goodsImageBuildOptions.composition_style_id
                composition_style = goodsImageBuildOptions.composition_style
                paint_style_id = goodsImageBuildOptions.paint_style_id
                paint_style = goodsImageBuildOptions.paint_style
                scene_style_id = goodsImageBuildOptions.scene_style_id
                scene_style = goodsImageBuildOptions.scene_style
                location_style_id = goodsImageBuildOptions.location_style_id
                location_style = goodsImageBuildOptions.location_style
                person_style_id = goodsImageBuildOptions.person_style_id
                person_style = goodsImageBuildOptions.person_style
                face_style_id = goodsImageBuildOptions.face_style_id
                face_style = goodsImageBuildOptions.face_style
                image_build_type = type
                stencil_ids = medium_style_id + ',' + composition_style_id + ',' + paint_style_id + ',' + scene_style_id + ',' + location_style_id + ',' + person_style_id + ',' + face_style_id

                task_id = goodsImageTaskCurrentId.value
                let params = {
                    task_id: task_id,
                    pretrain_model_id: pretrain_model_id,
                    pretrain_model_name: pretrain_model_name,
                    pretrain_model_thumb_url: pretrain_model_thumb_url,
                    pretrain_model_lora_name: pretrain_model_lora_name,
                    medium_style_id: medium_style_id,
                    medium_style: medium_style,
                    composition_style_id: composition_style_id,
                    composition_style: composition_style,
                    paint_style_id: paint_style_id,
                    paint_style: paint_style,
                    scene_style_id: scene_style_id,
                    scene_style: scene_style,
                    location_style_id: location_style_id,
                    location_style: location_style,
                    person_style_id: person_style_id,
                    person_style: person_style,
                    face_style_id: face_style_id,
                    face_style: face_style,
                    tags_style_id: tags_style_id,
                    tags_style: tags_style,
                    stencil_ids: stencil_ids,
                    batch_size: generat_number,
                    prompt_cn: prompt_cn,
                    prompt_cn_negative: prompt_cn_negative,
                    prompt_en: prompt_en,
                    prompt_en_negative: prompt_en_negative,
                    image_build_type: image_build_type,
                }


                hasImageTaskProgressPreview.value = false
                goodsImageTaskImagesPreview.value = []
                goodsImageTaskProgressing.value = false
                goodsImageTaskProgressValue.value = 0
                goodsImageTaskProgressInfo.value = ''
                goodsImageTaskCurrentGeneratedImages.value = []
                goodsImageBuildTaskResult.value = []

                submitImageTask(params).then((res) => {
                    goodsImageTaskCurrentMainOption.value = 'status'

                    goodsImageTaskProgressing.value = true
                    goodsImageTaskCurrentGeneratedImages.value = []
                    let task_id = res.data.data.id
                    let task_task_id = res.data.data.task_id
                    let task_successful = res.data.data.task_successful
                    let task_current_media_site = res.data.data.task_media_site
                    localStorage.setItem('current_task_id', task_id)
                    localStorage.setItem('current_task_task_id', task_task_id)
                    if (task_successful != 1) {
                        goodsImageTaskTimerId.value = setInterval(() => {
                            let current_task_id = localStorage.getItem('current_task_id')
                            let current_task_task_id = localStorage.getItem('current_task_task_id')
                            let taskParams = {
                                id: current_task_id,
                                task_id: current_task_task_id,
                            }
                            getImageTaskProgress(taskParams).then((progressRes) => {   
                                task_current_media_site = progressRes.data.data.progress_current_media_site
                                // let progress_current_id = progressRes.data.data.progress_current_id
                                // let progress_current_status = progressRes.data.data.progress_current_status
                                let progress_current_successful = progressRes.data.data.progress_current_successful
                                let progress_current_status = progressRes.data.data.progress_current_status
                                let progress_current_result = progressRes.data.data.progress_current_result
                                let progress_current_value = progressRes.data.data.progress_current_value
                                let progress_current_info = progressRes.data.data.progress_current_info
                                let progress_current_images = progressRes.data.data.progress_current_images

                                goodsImageTaskProgressInfo.value = progress_current_info
                                goodsImageTaskProgressValue.value = progress_current_value
                                if (progress_current_images.length > 0) {
                                    hasImageTaskProgressPreview.value = true
                                }
                                goodsImageTaskImagesPreview.value = progress_current_images

                                if (progress_current_successful) {
                                    onDoneGoodsImageTask()
                                    // goodsImageTaskProgressing.value =false
                                    // let current_task_id = localStorage.getItem('current_task_id')
                                    // let current_task_task_id = localStorage.getItem('current_task_task_id')
                                    // let taskParams = {
                                    //     id: current_task_id,
                                    //     task_id: current_task_task_id,
                                    // }
                                    // getImageTaskResult(taskParams).then((res) => {
                                    //     let task_images_result = res.data.data.images
                                    //     goodsImageTaskProgressing.value = false
                                    //     goodsImageTaskCurrentGeneratedImages.value = task_images_result
                                    // }).catch((err) => {
                                    //     console.log(err)
                                    // })
                                    // clearInterval(goodsImageTaskTimerId.value)
                                } else {
                                    goodsImageTaskCurrentGeneratedImages.value = progress_current_images
                                }
                            }).catch((err) => {
                                console.log(err)
                            })
                        }, 1000)
                    } else {
                        goodsImageTaskProgressing.value =false
                        let current_task_id = localStorage.getItem('current_task_id')
                        let current_task_task_id = localStorage.getItem('current_task_task_id')
                        let taskParams = {
                            id: current_task_id,
                            task_id: current_task_task_id,
                        }
                        getImageTaskResult(taskParams).then((res) => {
                            let task_result = res.data.data.result
                            let taskImageUrls = task_result
                            // let taskImageRealUrls = []
                            // let media_site_base_url = task_current_media_site
                            // taskImageUrls.forEach((el) => {
                            //     // taskImageRealUrls.push(media_site_base_url + el)
                            // })
                            goodsImageTaskCurrentGeneratedImages.value = taskImageUrls
                        }).catch((err) => {
                            console.log(err)
                        })
                    }
                }).catch((err) => {
                    console.log(err)
                })
            }
        }
    }

    function onUpdateGoodsImageTask() {
        let currentContext = {
            pretrainModelCurrentThumbUrl: pretrainModelCurrentThumbUrl.value,
            goodsImageBuildOptions: goodsImageBuildOptions,
        }
        let params = {
            id: goodsImageTaskCurrentId.value,
            task_id: goodsImageTaskCurrentTaskId.value,
            context: currentContext,
        }
        updateImageTask(params).then(() => {
            ElMessage('保存完成')
        }).catch((err) => {
            console.log(err)
        })
    }

    function onQueryGoodsImageTask() {
        let params = {
            task_id: goodsImageTaskCurrent.value['task_id']
        }
        let images = []
        goodsImageBuildTaskResult.value = []
        queryImageTask(params).then((res) => {
            images = res.data.data.images
            goodsImageBuildTaskResult.value = images
        }).catch((err) => {
            console.log(err)
        })
    }

    function onDoneGoodsImageTask() {
        console.log(goodsImageTaskTimerId.value)
        clearInterval(goodsImageTaskTimerId.value)
        let params = {
            id: goodsImageTaskCurrentId.value,
            task_id: goodsImageTaskCurrentTaskId.value,
            status: 'SUCCESS',
            successful: 1,
        }
        updateImageTask(params).then((res) => {
            isCurrentGoodsImageTaskTodo.value = false
            retriveImageTask(params).then((res) => {
                goodsImageTaskCurrent.value = reformatTask(res.data.data)
                nextTick(() => {
                    let newUserTaskList = []
                    userTaskList.value.forEach((el) => {
                        if (el.id == goodsImageTaskCurrentId.value) {
                            el = goodsImageTaskCurrent.value
                        }
                        newUserTaskList.push(el)
                    })
                    userTaskList.value = newUserTaskList
                    onShowGoodsImageCurrentTask(
                        goodsImageTaskCurrentId.value,
                        goodsImageTaskCurrent.value
                    )
                })
            }).catch((err) => {
                console.log(err)
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    function onReDoingGoodsImageTask() {
        let params = {
            id: goodsImageTaskCurrentId.value,
            status: 'STARTED',
            successful: -1,
        }
        updateImageTask(params).then((res) => {
            isCurrentGoodsImageTaskTodo.value = true
            goodsImageTaskCurrent.value = reformatTask(res.data.data)
            userTaskList.value.forEach((el) => {
                nextTick(() => {
                    let newUserTaskList = []
                    userTaskList.value.forEach((el) => {
                        if (el.id == goodsImageTaskCurrentId.value) {
                            el = goodsImageTaskCurrent.value
                        }
                        newUserTaskList.push(el)
                    })
                    userTaskList.value = newUserTaskList
                    onShowGoodsImageCurrentTask(
                        goodsImageTaskCurrentId.value,
                        goodsImageTaskCurrent.value
                    )
                })
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    function onDestroyGoodsImageTask() {
        ElMessageBox.confirm('确认要删除[' + goodsImageTaskCurrent.value.name + ']吗？','提示',
            {
                type: 'danger',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                callback: (action) => {
                    let params = {
                        id: goodsImageTaskCurrentId.value
                    }
                    destroyImageTask(params).then(() => {
                        getUserTasks(1)
                        onShowGoodsImageMain()
                    }).catch(() => {

                    })
                }
            }
        )
    }

    function onEditTaskName() {
        if (goodsImageTaskCurrent.value.task_name) {
            taskNewName.value = goodsImageTaskCurrent.value.task_name
        } else {
            taskNewName.value = goodsImageTaskCurrent.value.name
        }
        isShowTaskEditDialog.value = true
    }

    function onCancelTaskEdit() {
        isShowTaskEditDialog.value = false
    }

    function onConfirmTaskEdit() {
        isShowTaskEditDialog.value = false
        let params = {
            id: goodsImageTaskCurrentId.value,
            name: taskNewName.value
        }
        updateImageTask(params).then((res) => {
            goodsImageTaskCurrent.value = reformatTask(res.data.data)
            taskNewName.value = ''
            userTaskList.value.forEach((el) => {
                let id = el.id
                let currentCompareId = goodsImageTaskCurrent.value.id
                if (id == currentCompareId) {
                    el.name = goodsImageTaskCurrent.value.task_name
                }
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    function getStatusText(status) {
        let result = ''
        let statusTextDict = {
            'PENDING': '',
            'STARTED': '',
            'SUCCESS': '已完成',
            'RETRY': '',
            'FAILURE': '',
        }
        if (statusTextDict.hasOwnProperty(status?.toUpperCase())) {
            result = statusTextDict[status?.toUpperCase()]
        } else {
            result = ''
        }
        return result
    }

    function onChangeImageStencilMediumStyle(stencil) {
        goodsImageBuildOptions.medium_style = stencil.name
        goodsImageBuildOptions.medium_style_id = stencil.id
    }

    function onChangeImageStencilCompositionStyle(stencil) {
        goodsImageBuildOptions.composition_style = stencil.name
        goodsImageBuildOptions.composition_style_id = stencil.id
    }

    function onChangeImageStencilPaintStyle(stencil) {
        goodsImageBuildOptions.paint_style = stencil.name
        goodsImageBuildOptions.paint_style_id = stencil.id
    }

    function onChangeImageStencilSceneStyle(stencil) {
        goodsImageBuildOptions.scene_style = stencil.name
        goodsImageBuildOptions.scene_style_id = stencil.id
    }

    function onChangeImageStencilLocationStyle(stencil) {
        goodsImageBuildOptions.location_style = stencil.name
        goodsImageBuildOptions.location_style_id = stencil.id
    }

    function onChangeImageStencilPersonStyle(stencil) {
        goodsImageBuildOptions.person_style = stencil.name
        goodsImageBuildOptions.person_style_id = stencil.id
    }

    function onChangeImageStencilFaceStyle(stencil) {
        goodsImageBuildOptions.face_style = stencil.name
        goodsImageBuildOptions.face_style_id = stencil.id
    }

    function onChangeImageStencilTagsStyle(stencil) {
        goodsImageBuildOptions.tag_style = stencil.name
        goodsImageBuildOptions.tag_style_id = stencil.id
    }

    function onCollectTaskImage(image, is_collected, task_id, id, task_media_site, asset_url, asset_thumb_medium_url) {
        let params = {
            id: id,
            task_id: task_id,
            task_media_site: task_media_site,
            asset_url: asset_url,
            asset_thumb_medium_url: asset_thumb_medium_url,
            task_type: 'goods-image-train',
        }

        if (!is_collected) {
            createUserImageCollect(params).then((res) => {
                image.is_collected = true
                let message = res.msg
                ElMessage({
                    message: message,
                    type: 'success',
                })
            }).catch((err) => {
                let message = res.msg
                ElMessage({
                    message: message,
                    type: 'error',
                })
                console.log(err)
            })
        } else if (is_collected) {
            destroyUserImageCollect(params).then((res) => {
                image.is_collected = false
                let message = res.msg
                ElMessage({
                    message: message,
                    type: 'warning'
                })
            }).catch((err) => {
                let message = res.msg
                ElMessage({
                    message: message,
                    type: 'error'
                })
                console.log(err)
            })
        }
    }

    function onDestroyTaskImage(id, task_id) {
        ElMessageBox.confirm('确认要删除这张图吗？', '危险提示',{
            type: 'danger',
            callback: (action) => {
                let params = {
                    id: id,
                    task_id: task_id,
                }
                destroyGoodsImage(params).then((res) => {
                    let taskParams = {
                        id: goodsImageTaskCurrentId.value,
                    }
                    retriveImageTask(taskParams).then((res) => {
                        goodsImageTaskCurrent.value = reformatTask(res.data.data)
                        onShowGoodsImageCurrentTask(
                            goodsImageTaskCurrentId.value,
                            goodsImageTaskCurrent.value)
                    }).catch((err) => {
                        console.log(err)
                    })
                    
                }).catch((err) => {
                    console.log(err)
                })
            }
        })
    }

    function onToggleTaskMoreDetails() {
        isShowTaskMoreDetails.value = !isShowTaskMoreDetails.value
    }

</script>

<style scoped lang="scss">
    ::v-deep(.el-tabs__content){
        // overflow:hidden 会导致 分页下拉框超出部分会被.el-tabs__content隐藏
        overflow: visible;
    }
    .page--text2image-manage {
        height: calc(100vh - 116px);
    }
    .page {
        position: relative;
        .aside {
            position: relative;
            .list {
                position: relative;
                display: flex;
                flex-direction: column;
                .list-item {
                    display: flex;
                    flex-direction: row;
                    background-color: #fff;
                    color: #424242;
                    margin-bottom: 4px;
                    &.active {
                        background-color: #eee;
                        color: #333;
                    }
                    .item-thumb {
                        width: 48px;
                        height: 48px;
                        border-radius: 50%;
                        margin-right: 4px;
                    }
                    .item-content {
                        display: flex;
                        flex-direction: row;
                    }
                    .item-actions {

                    }
                }
            }
        }
        .main {
            position: relative;
            .section--main {
                position: relative;
            }
            .section--new-goodsimage {
                position: relative;
                .footer--task-create {
                    background-color: #fafafa;
                    opacity: 0.7;
                    color: #212121;
                }                
            }
            .section--current-goodsimage {
                position: relative;
            }

            .main-gutter {
            }

            .gutter-switch {
                &.active {
                    background-color: #e0e0e0;
                    color: #212121;
                }
            }

            .flag-icon {
                color: #212121;
                &.flag-icon--fin {
                    color: #000;
                }
            }
            .flag-label {
                margin-left: 4px;
                margin-right: 4px;
                &.flag-label--fin {
                    color: #000;
                }
            }
        }
        .section-header {
            border-bottom: 1px dashed #eee;
        }
        .dialog--finetune {
            .finetune-pen-switch {
                background-color: #fff;
                color: #757575;
                &.active {
                    background-color: #eee;
                    color: #424242;
                }
            }
        }
        :deep(.desc-label) {
            padding-left: 16px;
            margin-left: 16px;
        }
        :deep(.desc-content) {
            padding-left: 16px;
        }

        .aside--task-nav {

        }
        .footer--task-actions {
        }

        :deep(.el-tabs__item.is-active .label) {
            color: #212121 !important;
            font-weight: 700;
        }
    }
</style>