<template>
    <div class="page page--video-manage lycontainer" :class="{'ly-is-full':isFull}">
        <div class="flex-1">
            <el-tabs 
                class="tabs tabs--top-nav"
                type="border-card" 
                v-model="activeTopNavTab" 
            >
                <el-tab-pane
                    class="tab-pane tab-pane--goods"
                    name="goods"
                    label="短视频"
                >
                    <template #label>
                    <span class="label flex items-center">
                        <el-icon><PriceTag /></el-icon>
                        <span>短视频</span>
                    </span>
                    </template>
                    <el-container
                        class="container container--goods -mt-2"
                    >
                        <el-aside
                            class="aside aside--task-nav flex flex-col pr-2"
                            width="320px">
                            <div
                                class="flex flex-col mt-2 mb-2 shadow p-2"
                                @click="onShowGoodsVideoMain"
                            >
                                <h5
                                    class="flex flex-row justify-between items-center"
                                >
                                    <el-tooltip
                                        content="查看我的收藏列表"
                                        placement="left-start"
                                    >
                                        <span class="flex flex-row items-center justify-between">
                                            <el-icon>
                                                <StarFilled size="32px"></StarFilled>
                                            </el-icon>
                                            <i class="ml-1 not-italic ">短视频创作</i>
                                        </span>
                                    </el-tooltip>
                                </h5>
                                <p class="mt-2">
                                    <el-text type="info">
                                        简单几步，快速生成商业效果短视频
                                    </el-text>
                                </p>
                            </div>
                            <div class="border-t-dashed border-t-2 mt-1 pt-1">
                                <el-button
                                    round 
                                    plain
                                    type="primary"
                                    size="large"
                                    class="w-full"
                                    @click="onNewGoodsVideoTask"
                                >
                                    <i class="not-italic text-large font-bold">新建任务</i>
                                </el-button>
                            </div>
                            <div class="mt-4 mb-1 pb-6">
                                <el-scrollbar
                                    style="height: calc(100vh - 340px)"
                                >
                                    <ul class="list">
                                        <li
                                            class="flex items-center list-item list-item--task h-14 mb-4 pl-2 pr-2 rounded"
                                            v-for="(task, index) in userTaskList"
                                            :key="index"
                                            :class="{'active': goodsVideoTaskCurrentId == task.id}"
                                            @click="onShowGoodsVideoCurrentTask(task.id, task)"
                                        >
                                            <div class="item-thumb flex items-center">
                                                <el-image
                                                    :src="task.thumb_url"
                                                    loading="lazy"
                                                    fit="cover"
                                                    style="width: 40px;height:40px"
                                                    class="mr-2 rounded-full"
                                                    @error.once="onLoadDefaultTaskIcon"
                                                >
                                                    <template #placeholder>
                                                        <el-icon><CameraFilled /></el-icon>
                                                    </template>
                                                    <template #error>
                                                        <div class="flex items-center justify-center">
                                                            <el-icon size="40px"><Compass /></el-icon>
                                                        </div>
                                                    </template>
                                                </el-image>
                                            </div>
                                            <div class="item-content flex flex-1 items-center">
                                                <div class="title text-sm">{{  task.name }}</div>
                                                <div class="subtitle text-sm">{{  task.status_desc?'('+task.status_desc+')':'' }}</div>
                                            </div>
                                            <div class="flex justify-end item-actions">
                                                <!-- <el-divider
                                                    direction="vertical"
                                                    border-type="dotted"
                                                ></el-divider> -->
                                                <el-tooltip
                                                    content="删除任务"
                                                >
                                                    <el-icon
                                                        @click="onDestroyGoodsVideoTask"
                                                    >
                                                        <Delete />
                                                    </el-icon>
                                                </el-tooltip>
                                            </div>
                                        </li>
                                    </ul>
                                </el-scrollbar>
                                <div
                                    class="more-director text-center mt-4 fixed bottom-[16px]"
                                    :class="{'left-[360px]': !collapsed, 'left-[120px]': collapsed}"
                                >
                                    <el-button
                                        round
                                        plain
                                        @click="onMoreUserTaskList"
                                        v-if="hasMoreUserTaskList"
                                    >更多历史任务</el-button>
                                    <el-button
                                        disabled
                                        round
                                        plain
                                        v-if="!hasMoreUserTaskList"
                                    >已经到底了</el-button>
                                </div>
                            </div>
                        </el-aside>
                        <el-main
                            class="main bg-neutral-100 pt-0 pb-2 -mr-[15]"
                        >
                            <article
                                class="section--main mb-4"
                                v-if="isShowGoodsVideoMain"
                            >
                                <el-tabs
                                    v-model="activeMainNavTab"
                                    class="tabs "
                                >
                                    <el-tab-pane
                                        name="collect"
                                        class="pb-6 flex flex-col"
                                    >
                                        <template #label>
                                            <el-text
                                                type="info"
                                                size="default"
                                                class="font-medium text-base neutral-700"
                                            >
                                                我收藏的短视频
                                            </el-text>
                                        </template>
                                        <div
                                            class="label flex justify-start items-center"
                                            v-if="userVideoCollectList && userVideoCollectList.length == 0"
                                        >
                                            <el-empty
                                                description="暂无收藏短视频"
                                            >
                                            </el-empty>
                                        </div>
                                        <div
                                            class="flex flex-row flex-wrap"
                                            v-if="userVideoCollectList && userVideoCollectList.length > 0"
                                        >
                                            <div
                                                class="flex flex-col task-video ml-2 mr-2 mb-2 shadow-2xl rounded-2xl p-2"
                                                v-for="(collect,index) in userVideoCollectList"
                                                :key="index"
                                            >
                                                <figure
                                                    class="task-video shadow-none rounded p-1 ml-2 mr-2 mb-2"
                                                    v-if="collect.is_collected"
                                                    @click="onShowVideo(collect, 'collect')"
                                                >
                                                    <div class="video relative">
                                                        <el-image
                                                            fit="cover"
                                                            loading="lazy"
                                                            class="rounded"
                                                            style="width: 252px;"
                                                            :src="collect.object_asset_thumb_medium_url"
                                                        >
                                                            <template #placeholder>
                                                                <el-icon><CameraFilled /></el-icon>
                                                            </template>
                                                        </el-image>
                                                        <div class="absolute top-[50%] right-[50%] shadow">
                                                            <el-icon
                                                                color="#f5f5f5"
                                                                size="32px"
                                                            ><VideoPlay /></el-icon>
                                                        </div>
                                                    </div>
                                                </figure>
                                                <div
                                                    class="footer mt-1"
                                                    v-if="collect.is_collected"
                                                >
                                                    <div class="flex flex-row justify-between">
                                                        <div class="action"
                                                            @click="onCollectTaskVideo(
                                                                collect, 
                                                                collect.is_collected, 
                                                                collect.task_id, 
                                                                collect.object_id, 
                                                                collect.task_media_site, 
                                                                collect.object_asset_url,
                                                                collect.object_asset_thumb_medium_url,
                                                            )"
                                                        >
                                                            <el-icon>
                                                                <StarFilled size="24px"></StarFilled>
                                                            </el-icon>
                                                        </div>
                                                        <div class="action ml-1"
                                                            @click="onDownloadTaskVideo(collect.object_id, collect.object_asset_url)"
                                                        >
                                                            <el-icon>
                                                                <Download></Download>
                                                            </el-icon>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <footer
                                            class="more-director text-center"
                                            v-if="userVideoCollectList && userVideoCollectList.length > 0"
                                        >
                                            <el-button
                                                round
                                                plain
                                                @click="onMoreUserCollects"
                                                v-if="hasMoreUserCollectList"
                                            >
                                                更多收藏
                                            </el-button>
                                            <el-text
                                                class="info"
                                                v-if="!hasMoreUserCollectList"
                                            >
                                                没有更多了
                                            </el-text>
                                        </footer>
                                    </el-tab-pane>
                                </el-tabs>
                            </article>

                            <article
                                class="section--current-task-doing bg-neutral-100 pb-16"
                                v-if="isShowCurrentGoodsVideoTask && isCurrentGoodsVideoTaskTodo"
                            >
                                <nav class="mb-2 mt-2">
                                    <el-steps
                                        :align-center="true"
                                        :active="goodsVideoTaskCurrentStep"
                                        class=""
                                    >
                                        <el-step title="选择视频风格">
                                            <template #description>
                                                <div
                                                    class="flex flex-col items-center justify-center"
                                                >
                                                    <el-image
                                                        :src="videoSubjectCurrentThumbUrl"
                                                        style="width: 96px;"
                                                        class="rounded-full"                                                        loading="lazy"
                                                        fit="contain"
                                                        :preview-src-list="[videoSubjectCurrentThumbUrl]"
                                                        v-if="videoSubjectCurrentThumbUrl"
                                                    >
                                                        <template #placeholder>
                                                            <el-icon><CameraFilled /></el-icon>
                                                        </template>
                                                    </el-image>
                                                    <el-text
                                                        type="info"
                                                        v-if="videoSubjectCurrentName"
                                                    >{{ videoSubjectCurrentName }}</el-text>
                                                    <el-text
                                                        type="info"
                                                        v-if="!videoSubjectCurrentName"
                                                    >等待选取</el-text>
                                                </div>
                                            </template>
                                        </el-step>
                                        <!-- <el-step title="选择训练模型">
                                            <template #description>
                                                <div
                                                    class="flex flex-col items-center justify-center"
                                                >
                                                    <el-image
                                                        :src="pretrainModelCurrentThumbUrl"
                                                        style="width: 96px;"
                                                        loading="lazy"
                                                        fit="contain"
                                                        :preview-src-list="[pretrainModelCurrentThumbUrl]"
                                                        v-if="pretrainModelCurrentThumbUrl"
                                                    >
                                                        <template #placeholder>
                                                            <el-icon><CameraFilled /></el-icon>
                                                        </template>
                                                    </el-image>
                                                    <el-text
                                                        type="info"
                                                        v-if="pretrainModelCurrentName"
                                                    >{{ pretrainModelCurrentName }}</el-text>
                                                    <el-text
                                                        type="info"
                                                        v-if="!pretrainModelCurrentName"
                                                    >等待选取</el-text>
                                                </div>
                                            </template>
                                        </el-step> -->
                                        <el-step
                                            title="选择参考图"
                                            v-if="goodsVideoBuildOptions.prompt_is_image_prompt"
                                        >
                                            <template #description>
                                                <el-image
                                                    :src="goodsVideoBuildOptions.prompt_image"
                                                    style="width: 96px;"
                                                    class="rounded"
                                                    loading="lazy"
                                                    fit="contain"
                                                    :preview-src-list="[goodsVideoBuildOptions.prompt_image]"
                                                    v-if="goodsVideoBuildOptions.prompt_image"
                                                >
                                                    <template #placeholder>
                                                        <el-icon><CameraFilled /></el-icon>
                                                    </template>
                                                </el-image>
                                            </template>
                                        </el-step>

                                        <el-step
                                            title="编辑视频文案"
                                            v-if="!goodsVideoBuildOptions.prompt_is_svd"
                                        >
                                            <template #description>
                                                <el-space wrap>
                                                    <el-tooltip
                                                        :content="workflowCurrentScheduleFrameValues"
                                                        placement="bottom"                                                    
                                                        v-if="workflowCurrentScheduleFrameValues"
                                                    >
                                                        <el-text
                                                            type="info"
                                                            :truncated="true"
                                                            line-clamp="2"
                                                            class="truncated w-[64px] p-1 border border-dashed rounded"
                                                        >
                                                            {{ workflowCurrentScheduleFrameValues }}
                                                        </el-text>
                                                    </el-tooltip>
                                                    <el-tooltip
                                                        :content="goodsVideoBuildOptions.prompt_cn"
                                                        placement="bottom"                                                    
                                                        v-if="goodsVideoBuildOptions.prompt_cn"
                                                    >
                                                        <el-text
                                                            type="info"
                                                            :truncated="true"
                                                            line-clamp="2"
                                                            class="truncate w-[64px] p-1 border border-dashed rounded"
                                                        >
                                                            {{ goodsVideoBuildOptions.prompt_cn }}
                                                        </el-text>
                                                    </el-tooltip>
                                                    <el-tooltip
                                                        :content="goodsVideoBuildOptions.prompt_en"
                                                        placement="bottom"                                                    
                                                        v-if="goodsVideoBuildOptions.prompt_en"
                                                    >
                                                        <el-text
                                                            type="info"
                                                            :truncated="true"
                                                            line-clamp="2"
                                                            class="truncate w-[64px] p-1 border border-dashed rounded"
                                                        >
                                                            {{ goodsVideoBuildOptions.prompt_en }}
                                                        </el-text>
                                                    </el-tooltip>
                                                    <el-tag size="default" v-if="goodsVideoBuildOptions.medium_style">
                                                        {{ goodsVideoBuildOptions.medium_style }}
                                                    </el-tag>
                                                    <el-tag size="default" v-if="goodsVideoBuildOptions.composition_style">
                                                        {{ goodsVideoBuildOptions.composition_style }}
                                                    </el-tag>
                                                    <el-tag size="default" v-if="goodsVideoBuildOptions.paint_style">
                                                        {{ goodsVideoBuildOptions.paint_style }}
                                                    </el-tag>
                                                    <el-tag size="default" v-if="goodsVideoBuildOptions.scene_style">
                                                        {{ goodsVideoBuildOptions.scene_style }}
                                                    </el-tag>
                                                    <el-tag size="default" v-if="goodsVideoBuildOptions.location_style">
                                                        {{ goodsVideoBuildOptions.location_style }}
                                                    </el-tag>
                                                    <el-tag size="default" v-if="goodsVideoBuildOptions.person_style">
                                                        {{ goodsVideoBuildOptions.person_style }}
                                                    </el-tag>
                                                    <el-tag size="default" v-if="goodsVideoBuildOptions.face_style">
                                                        {{ goodsVideoBuildOptions.face_style }}
                                                    </el-tag>
                                                    <el-tag size="default" v-if="goodsVideoBuildOptions.tags_style">
                                                        {{ goodsVideoBuildOptions.tags_style }}
                                                    </el-tag>
                                                </el-space>
                                            </template>
                                        </el-step>
                                        
                                        <!-- <el-step title="选择风格模型">
                                            <template #description>
                                                <el-space wrap>
                                                    <el-text
                                                        type="info"
                                                        :truncated="true"
                                                        line-clamp="2"
                                                        class="truncated w-[64px] p-1 border border-dashed rounded"
                                                        v-if="goodsVideoBuildOptions.model_name"
                                                    >
                                                        {{ goodsVideoBuildOptions.model_name }}
                                                    </el-text>
                                                </el-space>
                                        </template>
                                        </el-step> -->
                                        <!-- <el-step title="视频时长">
                                            <template #description>
                                                <el-space wrap>
                                                    <el-tag 
                                                        type="danger"
                                                        size="default"
                                                        v-if="goodsVideoBuildOptions.video_duration"
                                                    >
                                                        {{ goodsVideoBuildOptions.video_duration }}秒
                                                    </el-tag>
                                                    <el-tag 
                                                        type="danger"
                                                        size="default"
                                                        v-if="goodsVideoBuildOptions.video_fps"
                                                    >
                                                        {{ goodsVideoBuildOptions.video_fps }}帧/秒
                                                    </el-tag>
                                                </el-space>
                                        </template>
                                        </el-step> -->
                                        <el-step title="开始生成视频">
                                            <template #description>
                                            </template>
                                        </el-step>
                                    </el-steps>
                                </nav>
                                <div class="flex pb-6 mt-4">
                                    <!-- <aside class="flex flex-col justify-start items-end min-[80px] pl-1 pr-2 pt-2 pb-8 main-gutter border-0 border-dashed border-neutral-700">
                                        <div
                                            class="gutter-switch p-2 rounded border-r border border-dashed  border-neutral-500 mb-2 flex flex-col justify-center items-center"
                                            :class="{'active': goodsVideoTaskCurrentMainOption == 'subject'}"
                                            @click="onSwitchMainOption2Subject"
                                        >
                                            <h5 class="text-large font-bold">视频风格</h5>
                                            <el-icon><Place /></el-icon>
                                        </div>
                                        <i class="not-italic flex flex-col justify-center items-end w-full mt-1 mb-2">
                                            <el-icon color="#737373"><Bottom /></el-icon>
                                        </i>
                                        <div
                                            class="gutter-switch p-2 rounded border-r border border-dashed  border-neutral-500 mb-2 flex flex-col justify-center items-center"
                                            :class="{'active': goodsVideoTaskCurrentMainOption == 'model'}"
                                            @click="onSwitchMainOption2Model"
                                        >
                                            <h5 class="text-large font-bold">选择训练模型</h5>
                                            <el-icon><Place /></el-icon>
                                        </div>
                                        <i class="not-italic flex flex-col justify-center items-center w-full mt-1 mb-2">
                                            <el-icon color="#737373"><Bottom /></el-icon>
                                        </i>
                                        <div
                                            class="gutter-switch p-2 rounded border-r border border-dashed  border-neutral-500 mb-2 flex flex-col justify-center items-center"
                                            :class="{'active': goodsVideoTaskCurrentMainOption == 'prompt'}"
                                            @click="onSwitchMainOption2Prompt"
                                        >
                                            <h5 class="text-large font-bold">视频文案</h5>
                                            <el-icon><Place /></el-icon>
                                        </div>
                                        <i class="not-italic flex flex-col justify-center items-end w-full mt-1 mb-2">
                                            <el-icon color="#737373"><Bottom /></el-icon>
                                        </i>
                                        <div
                                            class="gutter-switch p-2 rounded border-r border border-dashed  border-neutral-500 mb-2 flex flex-col justify-center items-center"
                                            :class="{'active': goodsVideoTaskCurrentMainOption == 'model'}"
                                            @click="onSwitchMainOption2Model"
                                        >
                                            <h5 class="text-large font-bold">风格模型</h5>
                                            <el-icon><Place /></el-icon>
                                        </div>
                                        <i class="not-italic flex flex-col justify-center items-end w-full mt-1 mb-2">
                                            <el-icon color="#737373"><Bottom /></el-icon>
                                        </i>
                                        <div
                                            class="gutter-switch p-2 rounded border-r border border-dashed  border-neutral-500 mb-2 flex flex-col justify-center items-center"
                                            :class="{'active': goodsVideoTaskCurrentMainOption == 'duration'}"
                                            @click="onSwitchMainOption2Duration"
                                        >
                                            <h5 class="text-large font-bold">视频时长</h5>
                                            <el-icon><Place /></el-icon>
                                        </div>
                                        <i class="not-italic flex flex-col justify-center items-end w-full mt-1 mb-2">
                                            <el-icon color="#737373"><Bottom /></el-icon>
                                        </i>
                                        <div
                                            class="gutter-switch p-2 rounded border-r border border-dashed  border-neutral-500 mb-2 flex flex-col justify-center items-center"
                                            :class="{'active': goodsVideoTaskCurrentMainOption == 'status'}"
                                            @click="onSwitchMainOption2Status"
                                        >
                                            <h5 class="text-large font-bold">生成状态</h5>
                                            <el-icon>
                                                <DCaret></DCaret>
                                            </el-icon>
                                        </div>
                                    </aside> -->
                                    <div class="flex flex-col flex-1 pl-2 pb-6 main-options border-dashed botder-t">
                                        <nav class="flex flex-col mb-2">
                                            <div class="mb-1">
                                                <el-button
                                                    round
                                                    plain
                                                    @click="onEditTaskName"
                                                >
                                                    {{ goodsVideoTaskCurrentName }}
                                                    <el-icon
                                                        color="#9e9e9e"
                                                        class="ml-1"
                                                    >
                                                        <EditPen />
                                                    </el-icon>
                                                </el-button>
                                            </div>
                                        </nav>

                                        <section
                                            class="option--number flex flex-col flex-1 pt-2"
                                            v-if="goodsVideoTaskCurrentMainOption == 'subject'"
                                        >
                                            <h5
                                                class="section-header flex items-center text-large font-bold mb-4"
                                            >
                                                <el-icon><DArrowRight /></el-icon>
                                                <i class="not-italic ml-2">推荐视频风格</i>
                                            </h5>
                                            <div
                                                class="flex flex-col flex-wrap flex-1 mt-2"
                                            >
                                                <el-radio-group
                                                    class="flex-1 flex flex-col items-start"
                                                    v-model="videoSubjectCurrentId"
                                                >
                                                <div class="tabs-header flex flex-row flex-1 justify-start items-center mt-2 h-[48px]">
                                                    <el-badge
                                                        :value = "subjectType.counts"
                                                        v-for="(subjectType,index) in videoSubjectTypeIndex"
                                                        :key="index"
                                                        type="danger"
                                                        class="mr-4"
                                                    >
                                                        <el-button
                                                            plain
                                                            round
                                                            type="info"
                                                            class="subject-nav-btn not-italic flex flex-col items-center text-sm h-[48px]"
                                                            @click="onChangeVideoSubjectType(subjectType.id, subjectType.description)"
                                                            :class="{'active': subjectTypeCurrentId == subjectType.id}"
                                                        >
                                                            {{ subjectType.description}}
                                                        </el-button>
                                                    </el-badge>
                                                </div>
                                                <div class="tabs-content flex flex-row flex-wrap justify-start items-center mt-8">
                                                    <div
                                                        class="flex"
                                                        v-for="(subjectType,index) in videoSubjectTypeIndex"
                                                        :key="index"
                                                    >
                                                        <div
                                                            class="tab-pane flex flex-row flex-wrap"
                                                            v-if="subjectTypeCurrentId == subjectType.id"
                                                        >
                                                            <div
                                                                class="flex flex-row flex-wrap justify-start"
                                                                v-for="(subject, subjectIndex) in subjectType.subjects"
                                                                :key="subjectIndex"
                                                            >
                                                                <figure
                                                                    class="video-subject mr-1 mt-1 flex flex-col p-1"
                                                                    :class="{'active': subject.id == videoSubjectCurrentId}"
                                                                >
                                                                    <el-image 
                                                                        :src="subject.thumb_url"
                                                                        style="width: 136px;height:136px"
                                                                        class="rounded-full"
                                                                        fit="cover"
                                                                        loading="lazy"
                                                                        @click="onChangeVideoSubject({
                                                                                'thumb_url': subject.thumb_url, 
                                                                                'id': subject.id, 
                                                                                'name': subject.name,
                                                                                'prompt_schedule_mode': subject.is_schedule ? '分帧视频文案' :'不分帧视频文案',
                                                                                'prompt_is_schedule': subject.is_schedule ? 1 : 0,
                                                                                'prompt_is_image_prompt': subject.is_image_prompt ? 1 : 0,
                                                                                'prompt_is_svd': subject.is_svd ? 1 : 0,
                                                                                'prompt_is_svd_freesize': subject.is_svd_freesize ? 1 : 0,
                                                                                'workflow_schedule_prompts': subject.workflow_schedule_prompts,
                                                                                'workflow_schedule_frames': subject.workflow_schedule_frames,
                                                                            })"
                                                                    >
                                                                        <template #placeholder>
                                                                            <el-icon><CameraFilled /></el-icon>
                                                                        </template>
                                                                    </el-image>

                                                                    <el-text
                                                                        type="info"    
                                                                        class="mt-1"
                                                                        @click="onChangeVideoSubject({
                                                                                'thumb_url': subject.thumb_url, 
                                                                                'id': subject.id, 
                                                                                'name': subject.name,
                                                                                'prompt_schedule_mode': subject.is_schedule ? '分帧视频文案' :'不分帧视频文案',
                                                                                'prompt_is_schedule': subject.is_schedule ? 1 : 0,
                                                                                'prompt_is_image_prompt': subject.is_image_prompt ? 1 : 0,
                                                                                'prompt_is_svd': subject.is_svd ? 1 : 0,
                                                                                'prompt_is_svd_freesize': subject.is_svd_freesize ? 1 : 0,
                                                                                'workflow_schedule_prompts': subject.workflow_schedule_prompts,
                                                                                'workflow_schedule_frames': subject.workflow_schedule_frames,
                                                                            })"
                                                                    >
                                                                    </el-text>
                                                                    <figcaption class="ml-1 flex items-center justify-between">
                                                                        <el-radio
                                                                            :label="subject.id"
                                                                            data-id="subject.id"
                                                                            @change="onChangeVideoSubject({
                                                                                'thumb_url': subject.thumb_url, 
                                                                                'id': subject.id, 
                                                                                'name': subject.name,
                                                                                'prompt_schedule_mode': subject.is_schedule ? '分帧视频文案' :'不分帧视频文案',
                                                                                'prompt_is_schedule': subject.is_schedule ? 1 : 0,
                                                                                'prompt_is_image_prompt': subject.is_image_prompt ? 1 : 0,
                                                                                'prompt_is_svd': subject.is_svd ? 1 : 0,
                                                                                'prompt_is_svd_freesize': subject.is_svd_freesize ? 1 : 0,
                                                                                'workflow_schedule_prompts': subject.workflow_schedule_prompts,
                                                                                'workflow_schedule_frames': subject.workflow_schedule_frames,
                                                                            })"
                                                                        >{{ subject.name }}</el-radio>
                                                                    </figcaption>
                                                                </figure>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <figure
                                                    class="mr-1 mt-1 flex flex-col"
                                                    v-for="(subject,index) in videoSubjectList"
                                                    :key="index"
                                                >
                                                    <el-image 
                                                        :src="subject.thumb_url"
                                                        :preview-src-list="[subject.thumb_url]"
                                                        style="width: 136px;height:136px"
                                                        class="rounded-full"
                                                        fit="cover"
                                                        loading="lazy"
                                                        @click="onChangeVideoSubject({
                                                                'thumb_url': subject.thumb_url, 
                                                                'id': subject.id, 
                                                                'name': subject.name,
                                                                'prompt_schedule_mode': subject.is_schedule ? '分帧视频文案' :'不分帧视频文案',
                                                                'prompt_is_schedule': subject.is_schedule ? 1 : 0,
                                                                'prompt_is_image_prompt': subject.is_image_prompt ? 1 : 0,
                                                                'prompt_is_svd': subject.is_svd ? 1 : 0,
                                                                'prompt_is_svd_freesize': subject.is_svd_freesize ? 1 : 0,
                                                                'workflow_schedule_prompts': subject.workflow_schedule_prompts,
                                                                'workflow_schedule_frames': subject.workflow_schedule_frames,
                                                            })"
                                                    >
                                                        <template #placeholder>
                                                            <el-icon><CameraFilled /></el-icon>
                                                        </template>
                                                    </el-image>

                                                    <el-text
                                                        type="info"    
                                                        class="mt-1"
                                                        @click="onChangeVideoSubject({
                                                                'thumb_url': subject.thumb_url, 
                                                                'id': subject.id, 
                                                                'name': subject.name,
                                                                'prompt_schedule_mode': subject.is_schedule ? '分帧视频文案' :'不分帧视频文案',
                                                                'prompt_is_schedule': subject.is_schedule ? 1 : 0,
                                                                'prompt_is_image_prompt': subject.is_image_prompt ? 1 : 0,
                                                                'prompt_is_svd': subject.is_svd ? 1 : 0,
                                                                'prompt_is_svd_freesize': subject.is_svd_freesize ? 1 : 0,
                                                                'workflow_schedule_prompts': subject.workflow_schedule_prompts,
                                                                'workflow_schedule_frames': subject.workflow_schedule_frames,
                                                            })"
                                                    >
                                                    </el-text>
                                                    <figcaption class="ml-1 flex items-center justify-between">
                                                        <el-radio 
                                                            :label="subject.thumb_url"
                                                            data-id="subject.id"
                                                            @change="onChangeVideoSubject({
                                                                'thumb_url': subject.thumb_url, 
                                                                'id': subject.id, 
                                                                'name': subject.name,
                                                                'prompt_schedule_mode': subject.is_schedule ? '分帧视频文案' :'不分帧视频文案',
                                                                'prompt_is_schedule': subject.is_schedule ? 1 : 0,
                                                                'prompt_is_image_prompt': subject.is_image_prompt ? 1 : 0,
                                                                'prompt_is_svd': subject.is_svd ? 1 : 0,
                                                                'prompt_is_svd_freesize': subject.is_svd_freesize ? 1 : 0,
                                                                'workflow_schedule_prompts': subject.workflow_schedule_prompts,
                                                                'workflow_schedule_frames': subject.workflow_schedule_frames,
                                                            })"
                                                        >{{ subject.name }}</el-radio>
                                                    </figcaption>
                                                </figure> -->
                                                </el-radio-group>
                                            </div>
                                        </section>

                                        <section
                                            class="option--status flex flex-1 flex-col pt-2 mb-4"
                                            v-if="goodsVideoTaskCurrentProgressing"
                                        >
                                            <h5 class="text-large font-bold mb-4">
                                                生成状态
                                            </h5>
                                            <p class="ml-2 flex-1">
                                                <blockquote
                                                    class="px-1 py-1 flex flex-1 flex-col"
                                                    v-if="goodsVideoTaskCurrentProgressing"
                                                >
                                                    <el-space
                                                        wrap
                                                        v-if="hasVideoTaskProgressPreview && goodsVideoTaskVideosPreview.length > 0"
                                                    >
                                                        <el-image
                                                            :src="video"
                                                            style="width: 96px;"
                                                            class="rounded-full"
                                                            loading="lazy"
                                                            fit="contain"
                                                            :preview-src-list="[video]"
                                                            v-for="(video,index) in goodsVideoTaskVideosPreview"
                                                            :key="index"
                                                        >
                                                            <template #placeholder>
                                                                <el-icon><CameraFilled /></el-icon>
                                                            </template>
                                                        </el-image>
                                                    </el-space>
                                                    <el-progress
                                                        :percentage="100"
                                                        status="success"
                                                        :indeterminate="true"
                                                        :duration="1"
                                                    />
                                                    <div class="progress-help-info">
                                                        {{ goodsVideoTaskProgressInfo }}
                                                    </div>
                                                </blockquote>
                                                <blockquote
                                                    class="px-1 py-1 flex flex-1 flex-wrap"
                                                    v-if="!goodsVideoTaskCurrentProgressing && goodsVideoTaskCurrentGeneratedVideos"
                                                >
                                                    <el-space wrap>
                                                        <el-image
                                                            :src="video"
                                                            :preview-src-list="[video]"
                                                            style="width: 96px;"
                                                            class="rounded-full"                                                            loading="lazy"
                                                            fit="contain"
                                                            v-for="(video,index) in goodsVideoTaskCurrentGeneratedVideos"
                                                            :key="index"
                                                        >
                                                            <template #placeholder>
                                                                <el-icon><CameraFilled /></el-icon>
                                                            </template>
                                                        </el-image>
                                                    </el-space>
                                                </blockquote>
                                                <blockquote
                                                    class="px-1 py-1 flex flex-1 flex-col"
                                                    v-if="goodsVideoBuildTaskResult"
                                                >
                                                    <el-space wrap>
                                                        <div class=""
                                                            v-for="(video,index) in goodsVideoBuildTaskResult"
                                                            :key="index"
                                                        >
                                                            <el-image
                                                                :src="video"
                                                                :preview-src-list="[video]"
                                                                loading="lazy"
                                                                style="width: 96px;"
                                                                class="rounded-full"                                                                
                                                                fit="contain"
                                                            >
                                                                <template #placeholder>
                                                                    <el-icon><CameraFilled /></el-icon>
                                                                </template>
                                                            </el-image>
                                                        </div>
                                                    </el-space>
                                                </blockquote>
                                            </p>
                                        </section>

                                        <section
                                            class=""
                                            v-if="videoSubjectCurrentName"
                                        >
                                            <el-divider />
                                            <nav class="flex flex-col mb-2">
                                                <div class="flex flex-row mt-1">
                                                    <el-text
                                                        type="info"
                                                        size="default"
                                                        class="font-light text-sm neutral-500">
                                                        当前视频风格:
                                                    </el-text>
                                                    <p class="ml-2 font-semibold text-sm neutral-600">
                                                        {{ videoSubjectCurrentName }}
                                                    </p>
                                                </div>
                                                <!-- <div class="mb-1">
                                                    <el-button
                                                        round
                                                        plain
                                                        @click="onEditTaskName"
                                                    >
                                                        <el-icon
                                                            color="#9e9e9e"
                                                        >
                                                            <EditPen />
                                                        </el-icon>
                                                    {{ goodsVideoTaskCurrentName }}
                                                    </el-button>
                                                </div>
                                                -->
                                                <!-- <div class="flex flex-row">
                                                    <el-text
                                                        type="info"
                                                        size="default"
                                                        class="font-light text-sm neutral-500">
                                                        视频文案是否分帧:
                                                    </el-text>
                                                    <p class="ml-2 font-semibold text-sm neutral-600">
                                                        {{ goodsVideoBuildOptions.prompt_schedule_mode }}
                                                    </p>
                                                </div> -->
                                            </nav>
                                            <el-form
                                                label-position="top"
                                                label-width="140px"
                                            >
                                                <el-form-item
                                                    label-position="top"
                                                    v-if="goodsVideoBuildOptions.prompt_is_image_prompt"
                                                >
                                                    <template #label>
                                                        <el-text
                                                            type="info"
                                                            size="default"
                                                            class="font-bold text-base neutral-700"
                                                        >
                                                            上传参考图:
                                                        </el-text>
                                                    </template>
                                                    <div class="flex flex-col">
                                                        <el-upload
                                                            ref="goodsVideoPromptImageUpload"
                                                            class="w-[192px] h-[192px] flex justify-center items-center border rounded-xl border-dashed p-2"
                                                            action="#"
                                                            :show-file-list="true"
                                                            list-type="picture-card"
                                                            :limit="1"
                                                            :multiple="false"
                                                            v-model:file-list="goodsVideoPromptImageList"
                                                            :http-request="onPromptImageUploadRequest"
                                                            :on-success="onPromptImageUploadSuccess"
                                                            :on-change="onPromptImageChange"
                                                            :before-upload="onPromptImageBeforeUpload"
                                                        >
                                                            <el-icon
                                                                class="avatar-uploader-icon p-2 font-thin text-neutral-200"
                                                                color="#bdbdbd"
                                                                size="48"
                                                                v-if="!goodsVideoBuildOptions.prompt_image"
                                                            >
                                                                <Plus />
                                                            </el-icon>
                                                            <el-image
                                                                class="flex-1 w-[108px] h-[108px]"
                                                                fit="contain"
                                                                :src="goodsVideoBuildOptions.prompt_image"
                                                                v-if="goodsVideoBuildOptions.prompt_image"
                                                            >
                                                            </el-image>
                                                            <!-- <template #file="{file}">
                                                                <div>
                                                                    <el-image
                                                                        class="el-upload-list__item-thumbnail" 
                                                                        fit="cover"
                                                                        :src="file.url"
                                                                    >
                                                                    </el-image>
                                                                    <span class="el-upload-list__item-actions">
                                                                        <span
                                                                            class="el-upload-list__item-delete"
                                                                            @click="onPromptImageUploadRemove(file)"
                                                                        >
                                                                            <el-icon><Delete /></el-icon>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </template> -->
                                                            <!-- <template #trigger>
                                                                <el-icon
                                                                    class="avatar-uploader-icon p-2 font-thin neutral-200"
                                                                    color="#bdbdbd"
                                                                    size="48"
                                                                >
                                                                    <Plus />
                                                                </el-icon>
                                                            </template> -->
                                                            <!-- <div class="rounded-xl border-dashed">
                                                                <el-image
                                                                    fit="cover"
                                                                    v-if="goodsVideoBuildOptions.prompt_image"
                                                                    :src="goodsVideoBuildOptions.prompt_image"
                                                                >
                                                                </el-image>
                                                            </div> -->
                                                        </el-upload>
                                                        <footer
                                                            class="mt-1 mb-1 text-red-400"
                                                            v-if="!goodsVideoBuildOptions.prompt_is_svd_freesize"
                                                        >
                                                            * 上传图片的长宽尺码都需要是64的倍数
                                                        </footer>
                                                    </div>
                                                </el-form-item>

                                                <!-- <el-form-item
                                                    label=""
                                                    v-if="!goodsVideoBuildOptions.prompt_is_schedule"
                                                >
                                                    <template #label>
                                                        <div class="flex flex-row">
                                                            <el-text 
                                                                type="info" 
                                                                size="default" 
                                                                class="font-light text-sm neutral-500">
                                                                视频文案内容合并模式:
                                                            </el-text>
                                                            <p
                                                                class="ml-2 font-semibold text-sm neutral-600"
                                                                v-if="videoSubjectCurrent.prompt_is_schedule"
                                                            >
                                                                覆盖模式
                                                            </p>
                                                        </div>
                                                    </template>
                                                    <el-radio-group
                                                        v-model="goodsVideoBuildOptions.is_overweite_prompt"
                                                        class="flex flex-col flex-wrap justify-start items-start pl-2"
                                                        v-if="!videoSubjectCurrent.prompt_is_schedule"
                                                    >
                                                        <el-radio
                                                            :label="true"
                                                            model-value="true"
                                                        >
                                                            覆盖模式【注意：覆盖后会是全新的内容】
                                                        </el-radio>
                                                        <el-radio
                                                            :label="false"
                                                            model-value="false"
                                                        >
                                                            叠加模式【在原视频风格场景内容之上，叠加显示用户描述的期望内容】
                                                        </el-radio>
                                                    </el-radio-group>
                                                </el-form-item> -->

                                                <el-form-item label="">
                                                    <template #label>
                                                        <div class="flex flex-col justify-start items-start"
                                                            v-if="!goodsVideoBuildOptions.prompt_is_svd"
                                                        >
                                                            <el-text
                                                                type="info"
                                                                size="default"
                                                                class="font-bold text-base neutral-700 self-start"
                                                            >
                                                                {{ prompt_cn_tip }}
                                                                <!-- [当前总帧数: {{ parseInt(goodsVideoBuildOptions.video_duration) * parseInt(goodsVideoBuildOptions.video_fps) }}] -->
                                                            </el-text>
                                                            <!-- <el-button
                                                                round
                                                                plain
                                                                type="danger"
                                                                class="mt-1"
                                                                @click="onResetFramePromptKeys"
                                                                v-if="goodsVideoBuildOptions.prompt_is_schedule"
                                                            >
                                                                自动重新分配分帧号(建议在总帧数改变后才执行)
                                                            </el-button> -->
                                                        </div>
                                                    </template>
                                                    <dl
                                                        class="flex-1"
                                                        v-if="goodsVideoBuildOptions.prompt_is_schedule"
                                                    >
                                                        <template
                                                            v-for="(scheduleFrame, index) in workflowCurrentScheduleFrames"
                                                            :key="index"
                                                        >
                                                        <div class="flex flex-col mb-1 flex-1">
                                                            <h5 class="flex flex-row mb-1 flex-1">
                                                                <i class="not-italic mr-1">
                                                                    分镜-{{  parseInt(index) + 1 }}
                                                                </i>
                                                                <!-- <i class="not-italic mr-1">
                                                                    第
                                                                </i> -->
                                                                <!-- <dt
                                                                    class="mr-1"
                                                                > -->
                                                                <!--
                                                                        :max="parseInt(goodsVideoBuildOptions.video_duration) * parseInt(goodsVideoBuildOptions.video_fps)"
                                                                -->
                                                                    <!-- <el-input-number
                                                                        v-model="scheduleFrame.frame_key"
                                                                        :max="99999"
                                                                        :min="0"
                                                                    >
                                                                    </el-input-number> -->
                                                                <!-- </dt> -->
                                                                <!-- <i class="not-italic ml-1 mr-1">
                                                                    帧
                                                                </i> -->
                                                                <el-divider direction="vertical" border-style="dotted" class="items-center"></el-divider>
                                                                <dt class="flex flex-1 justify-end">
                                                                    <!-- <el-tooltip
                                                                        content="增加一项分帧"
                                                                        placement="bottom"
                                                                    >
                                                                        <el-button
                                                                            plain
                                                                            round
                                                                            @click="onCreateFramePrompt(index+1, scheduleFrame.frame_key)"
                                                                        >
                                                                            <el-icon>
                                                                                <Plus size="36px"></Plus>
                                                                            </el-icon>
                                                                        </el-button>
                                                                    </el-tooltip> -->
                                                                    <!-- <el-tooltip
                                                                        content="复制一项分帧"
                                                                        placement="bottom"
                                                                    >
                                                                        <el-button
                                                                            plain
                                                                            round
                                                                            @click="onCopyFramePrompt(index+1, scheduleFrame.frame_key, scheduleFrame.frame_value)"
                                                                        >
                                                                            <el-icon>
                                                                                <CopyDocument size="36px"></CopyDocument>
                                                                            </el-icon>
                                                                        </el-button>
                                                                    </el-tooltip> -->
                                                                    <!-- <el-tooltip
                                                                        content="删除一项分帧"
                                                                        placement="bottom"
                                                                    >
                                                                        <el-button
                                                                            plain
                                                                            round
                                                                            type="danger"
                                                                            @click="onDestroyFramePrompt(index)"
                                                                        >
                                                                            <el-icon>
                                                                                <Delete size="36px"></Delete>
                                                                            </el-icon>
                                                                        </el-button>
                                                                    </el-tooltip> -->
                                                                </dt>
                                                            </h5>
                                                            <p class="">
                                                                <dd 
                                                                    class="ml-0 mr-1"
                                                                >
                                                                    <el-input
                                                                        v-model="scheduleFrame.frame_value"
                                                                        type="textarea"
                                                                        :autosize="{minRows: 2, maxRows: 15}"
                                                                        placeholder="视频文案说明"
                                                                        @input="onChangeVideoScheduleFrame(scheduleFrame.frame_value)"
                                                                    ></el-input>
                                                                </dd>
                                                            </p>
                                                        </div>
                                                        </template>
                                                    </dl>
                                                    <!-- <el-input
                                                        v-model="goodsVideoBuildOptions.prompt_cn"
                                                        type="textarea"
                                                        class="ml-0"
                                                        :autosize="{minRows: 5, maxRows: 15}"
                                                        placeholder="输入视频的主题、背景设定、以及情节等"
                                                        v-if="goodsVideoBuildOptions.prompt_is_schedule"
                                                    ></el-input> -->
                                                    <el-input
                                                        v-model="goodsVideoBuildOptions.prompt_cn"
                                                        type="textarea"
                                                        class="ml-0"
                                                        :autosize="{minRows: 5, maxRows: 15}"
                                                        placeholder="输入视频的主题、背景设定、以及情节等"
                                                        v-if="!goodsVideoBuildOptions.prompt_is_schedule && !goodsVideoBuildOptions.prompt_is_svd"
                                                    ></el-input>
                                                </el-form-item>
                                            </el-form>
                                            <!-- <el-divider
                                                class="mt-2 mb-2"
                                                border-style="dashed"
                                            >
                                                <p class="flex items-center bg-transparent">
                                                    更多快速模板
                                                    <el-icon><ArrowDown /></el-icon>
                                                </p>
                                            </el-divider> -->
                                            <!-- <el-form
                                                label-position="top"
                                                label-width="100px"
                                                :model="goodsVideoBuildOptions"
                                            > -->
                                                <!-- <el-form-item label="">
                                                    <template #label>
                                                        <el-text 
                                                            type="info" 
                                                            size="default" 
                                                            class="font-medium text-base neutral-700 pl-1 border-l-2 border-neutral-700"
                                                        >媒质输出类型</el-text>
                                                    </template>
                                                    <div
                                                        class="flex flex-row flex-wrap pl-6"
                                                    >
                                                        <el-radio-group
                                                            v-model="goodsVideoBuildOptions.medium_style"
                                                        >
                                                        <div
                                                            class="flex flex-row flex-wrap"
                                                            v-for="(stencil,index) in videoStencilMediumStyleList"
                                                            :key="index"
                                                        >
                                                            <figure
                                                                class="flex flex-col justify-center items-center mr-2 mb-2 rounded-3xl p-2 border border-dashed border-neutral-600"
                                                                @click="onChangeVideoStencilMediumStyle(stencil)"
                                                            >
                                                                <el-image
                                                                    :src="stencil.thumb_url"
                                                                    style="width: 40px;height:40px"
                                                                    class="rounded-full"                                                                            fit="cover"
                                                                    loading="lazy"
                                                                    >
                                                                    <template #placeholder>
                                                                        <el-icon><CameraFilled /></el-icon>
                                                                    </template>
                                                                </el-image>
                                                                <el-radio
                                                                    class="mt-1"
                                                                    :label="stencil.name"
                                                                >
                                                                {{  stencil.name }}
                                                                </el-radio>
                                                            </figure>
                                                        </div>
                                                        </el-radio-group>
                                                    </div>
                                                </el-form-item> -->
                                                <!-- <el-form-item label="">
                                                    <template #label>
                                                        <el-text type="info" size="default" class="font-medium text-base neutral-700">构图类型</el-text>
                                                    </template>
                                                    <div
                                                        class="flex flex-row flex-wrap pl-6"
                                                    >
                                                        <el-radio-group
                                                            v-model="goodsVideoBuildOptions.composition_style"
                                                        >
                                                        <div
                                                            class="flex flex-row flex-wrap"
                                                            v-for="(stencil,index) in videoStencilCompositionStyleList"
                                                            :key="index"
                                                        >
                                                            <figure
                                                                class="flex flex-col justify-center items-center mr-2 mb-2 rounded-3xl p-2 border border-dashed border-neutral-600"
                                                                @click="onChangeVideoStencilCompositionStyle(stencil)"
                                                            >
                                                                <el-image
                                                                    :src="stencil.thumb_url"
                                                                    style="width: 40px;height:40px"
                                                                    class="rounded-full"                                                                            fit="cover"
                                                                    loading="lazy"
                                                                >
                                                                    <template #placeholder>
                                                                        <el-icon><CameraFilled /></el-icon>
                                                                    </template>
                                                                </el-image>
                                                                <el-radio
                                                                    class="mt-1"
                                                                    :label="stencil.name"
                                                                >
                                                                {{  stencil.name }}
                                                                </el-radio>
                                                            </figure>
                                                        </div>
                                                        </el-radio-group>
                                                    </div>
                                                </el-form-item> -->
                                                <!-- <el-form-item label="">
                                                    <template #label>
                                                        <el-text 
                                                            type="info" 
                                                            size="default" 
                                                            class="font-medium text-base neutral-700"
                                                        >
                                                            <span class="border-l-2 pl-2 border-neutral-700 border-dotted mr-2 font-bold text-neutral-900">
                                                                动作
                                                            </span>
                                                        </el-text>
                                                    </template>
                                                    <div
                                                        class="flex flex-row flex-wrap pl-6"
                                                    >
                                                        <el-radio-group
                                                            v-model="goodsVideoBuildOptions.scene_style"
                                                        >
                                                        <div
                                                            class="flex flex-row flex-wrap"
                                                            v-for="(stencil,index) in videoStencilSceneStyleList"
                                                            :key="index"
                                                        >
                                                            <figure
                                                            class="flex flex-col justify-center items-center mr-2 mb-2 rounded-3xl p-2 border border-dashed border-neutral-600"
                                                                @click="onChangeVideoStencilSceneStyle(stencil)"
                                                            >
                                                                <el-image
                                                                    :src="stencil.thumb_url"
                                                                    style="width: 40px;height:40px"
                                                                    class="rounded-full"                                                                            fit="cover"
                                                                    loading="lazy"
                                                                >
                                                                    <template #placeholder>
                                                                        <el-icon><CameraFilled /></el-icon>
                                                                    </template>
                                                                </el-image>
                                                                <el-radio
                                                                    class="mt-1"
                                                                    :label="stencil.name"
                                                                >
                                                                {{  stencil.name }}
                                                                </el-radio>
                                                            </figure>
                                                        </div>
                                                        </el-radio-group>
                                                    </div>
                                                </el-form-item> -->
                                                <!--
                                                <el-form-item label="">
                                                    <template #label>
                                                        <el-text type="info" size="default" class="font-medium text-base neutral-700">画风类型</el-text>
                                                    </template>
                                                    <div
                                                        class="flex flex-row flex-wrap pl-6"
                                                    >
                                                        <el-radio-group
                                                            v-model="goodsVideoBuildOptions.paint_style"
                                                        >
                                                        <div
                                                            class="flex flex-row flex-wrap"
                                                            v-for="(stencil,index) in videoStencilPaintStyleList"
                                                            :key="index"
                                                        >
                                                            <figure
                                                                class="flex flex-col justify-center items-center mr-2 mb-2 rounded-3xl p-2 border border-dashed border-neutral-600"
                                                                @click="onChangeVideoStencilPaintStyle(stencil)"
                                                            >
                                                                <el-image
                                                                    :src="stencil.thumb_url"
                                                                    style="width: 40px;height:40px"
                                                                    fit="cover"
                                                                    loading="lazy"
                                                                ></el-image>
                                                                <el-radio
                                                                    class="mt-1"
                                                                    :label="stencil.name"
                                                                >
                                                                {{  stencil.name }}
                                                                </el-radio>
                                                            </figure>
                                                        </div>
                                                        </el-radio-group>
                                                    </div>
                                                </el-form-item> -->
                                                <!-- <el-form-item label="">
                                                    <template #label>
                                                        <el-text 
                                                            type="info" 
                                                            size="default" 
                                                            class="font-medium text-base neutral-700"
                                                        >
                                                            <span class="border-l-2 pl-2 border-neutral-700 border-dotted mr-2 font-bold text-neutral-900">
                                                                位置
                                                            </span>
                                                        </el-text>
                                                    </template>
                                                    <div
                                                        class="flex flex-row flex-wrap pl-6"
                                                    >
                                                        <el-radio-group
                                                            v-model="goodsVideoBuildOptions.location_style"
                                                        >
                                                        <div
                                                            class="flex flex-row flex-wrap"
                                                            v-for="(stencil,index) in videoStencilLocationStyleList"
                                                            :key="index"
                                                        >
                                                            <figure
                                                            class="flex flex-col justify-center items-center mr-2 mb-2 rounded-3xl p-2 border border-dashed border-neutral-600"
                                                                @click="onChangeVideoStencilLocationStyle(stencil)"
                                                            >
                                                                <el-image
                                                                    :src="stencil.thumb_url"
                                                                    style="width: 40px;height:40px"
                                                                    class="rounded-full"                                                                            fit="cover"
                                                                    loading="lazy"
                                                                >
                                                                    <template #placeholder>
                                                                        <el-icon><CameraFilled /></el-icon>
                                                                    </template>
                                                                </el-image>
                                                                <el-radio
                                                                    class="mt-1"
                                                                    :label="stencil.name"
                                                                >
                                                                {{  stencil.name }}
                                                                </el-radio>
                                                            </figure>
                                                        </div>
                                                        </el-radio-group>
                                                    </div>
                                                </el-form-item> -->
                                                <!--
                                                <el-form-item label="">
                                                    <template #label>
                                                        <el-text type="info" size="default" class="font-medium text-base neutral-700">
                                                            人物类型</el-text>
                                                    </template>
                                                    <div
                                                        class="flex flex-row flex-wrap pl-6"
                                                    >
                                                        <el-radio-group
                                                            v-model="goodsVideoBuildOptions.person_style"
                                                        >
                                                        <div
                                                            class="flex flex-row flex-wrap"
                                                            v-for="(stencil,index) in videoStencilPersonStyleList"
                                                            :key="index"
                                                        >
                                                            <figure
                                                                class="flex flex-col justify-center items-center mr-2 mb-2 rounded-3xl p-2 border border-dashed border-neutral-600"
                                                                @click="onChangeVideoStencilPersonStyle(stencil)"
                                                            >
                                                                <el-image
                                                                    :src="stencil.thumb_url"
                                                                    style="width: 40px;height:40px"
                                                                    fit="cover"
                                                                    loading="lazy"
                                                                ></el-image>
                                                                <el-radio
                                                                    class="mt-1"
                                                                    :label="stencil.name"
                                                                >
                                                                {{  stencil.name }}
                                                                </el-radio>
                                                            </figure>
                                                        </div>
                                                        </el-radio-group>
                                                    </div>
                                                </el-form-item> -->
                                                <!--
                                                <el-form-item label="">
                                                    <template #label>
                                                        <el-text type="info" size="default" class="font-medium text-base neutral-700">
                                                            面部风格</el-text>
                                                    </template>
                                                    <div
                                                        class="flex flex-row flex-wrap pl-6"
                                                    >
                                                        <el-radio-group
                                                            v-model="goodsVideoBuildOptions.face_style"
                                                        >
                                                        <div
                                                            class="flex flex-row flex-wrap"
                                                            v-for="(stencil,index) in videoStencilFaceStyleList"
                                                            :key="index"
                                                        >
                                                            <figure
                                                                class="flex flex-col justify-center items-center mr-2 mb-2 rounded-3xl p-2 border border-dashed border-neutral-600"
                                                                @click="onChangeVideoStencilFaceStyle(stencil)"
                                                            >
                                                                <el-image
                                                                    :src="stencil.thumb_url"
                                                                    style="width: 40px;height:40px"
                                                                    fit="cover"
                                                                    loading="lazy"
                                                                ></el-image>
                                                                <el-radio
                                                                    class="mt-1"
                                                                    :label="stencil.name"
                                                                >
                                                                {{  stencil.name }}
                                                                </el-radio>
                                                            </figure>
                                                        </div>
                                                        </el-radio-group>
                                                    </div>
                                                </el-form-item> -->
                                                <!--
                                                <el-form-item label="">
                                                    <template #label>
                                                        <el-text type="info" size="default" class="font-medium text-base neutral-700">
                                                            标签</el-text>
                                                    </template>
                                                </el-form-item>
                                            </el-form> -->
                                        </section>

                                        <!-- <section
                                            class="option--number flex flex-1 flex-col pt-2"
                                            v-if="goodsVideoTaskCurrentMainOption == 'model'"
                                        >
                                            <h5
                                                class="section-header text-large font-bold mb-4"
                                            >
                                                当前用户预训练模型
                                            </h5>
                                            <div
                                                class="flex flex-wrap"
                                            >
                                                <el-radio-group
                                                    v-model="pretrainModelCurrentThumbUrl"
                                                >
                                                <figure
                                                    class="mr-1 mt-1 flex flex-col"
                                                    v-for="(model,index) in videoPretrainModelList"
                                                    :key="index"
                                                >
                                                    <el-image 
                                                        :src="model.thumb_url"
                                                        :preview-src-list="[model.thumb_url]"
                                                        style="width: 136px;height:136px"
                                                        class="rounded-full"
                                                        fit="cover"
                                                        loading="lazy"
                                                        @click="onChangePretrainModel({
                                                                'thumb_url': model.thumb_url, 
                                                                'id': model.id, 
                                                                'name': model.name,
                                                                'model': model})"
                                                    >
                                                        <template #placeholder>
                                                            <el-icon><CameraFilled /></el-icon>
                                                        </template>
                                                    </el-image>
                                                    <el-text
                                                        type="info"    
                                                        class="mt-1"
                                                        @click="onChangePretrainModel({
                                                                'thumb_url': model.thumb_url, 
                                                                'id': model.id, 
                                                                'name': model.name,
                                                                'model': model})"
                                                    >
                                                        {{ model.name }}
                                                    </el-text>
                                                    <figcaption class="ml-1 flex items-center justify-between">
                                                        <el-radio 
                                                            :label="model.thumb_url"
                                                            data-id="model.id"
                                                            @change="onChangePretrainModel({
                                                                'thumb_url': model.thumb_url, 
                                                                'id': model.id, 
                                                                'name': model.name,
                                                                'model': model})"
                                                        >使用这个模型</el-radio>
                                                    </figcaption>
                                                </figure>
                                                </el-radio-group>
                                            </div>
                                        </section> -->
                                        <section
                                            class="option--scene flex flex-1 flex-col pt-2"
                                            v-if="goodsVideoTaskCurrentMainOption == 'prompt'"
                                        >
                                            <el-tabs
                                                v-model="activeNewNavTab"
                                                class="mt-0 w-auto"
                                            >
                                                <el-tab-pane
                                                    name="stencil">
                                                    <template #label>
                                                        <el-text type="info" size="default" class="font-medium text-base neutral-700">
                                                            视频文案
                                                        </el-text>
                                                    </template>
                                                </el-tab-pane>
                                                <!-- <el-tab-pane
                                                    name="custom"
                                                >
                                                    <template #label>
                                                        <el-text type="info" size="default" class="font-medium text-base neutral-700">场景/动作自定义</el-text>
                                                    </template>
                                                    <el-form
                                                        label-position="left"
                                                        label-width="100px"
                                                    >
                                                        <el-form-item label="">
                                                            <template #label>
                                                                <el-text type="info" size="default" class="font-medium text-base neutral-700">中文场景/动作说明</el-text>
                                                            </template>
                                                            <el-input
                                                                v-model="goodsVideoBuildOptions.prompt_cn"
                                                                type="textarea"
                                                                :autosize="{minRows: 3, maxRows: 5}"
                                                                placeholder="文字描述"
                                                            ></el-input>
                                                        </el-form-item>
                                                        <el-form-item label="">
                                                            <el-button
                                                                plain
                                                                round
                                                                class="btn--translate"
                                                                @click="onTranslateChinesePromptByAlibaba"
                                                            >中译英</el-button>
                                                        </el-form-item>                                        
                                                        <el-form-item label="">
                                                            <template #label>
                                                                <el-text type="info" size="default" class="font-medium text-base neutral-700">英文视频文案（生成需要）</el-text>
                                                            </template>
                                                            <el-input
                                                                v-model="goodsVideoBuildOptions.prompt_en"
                                                                type="textarea"
                                                                :autosize="{minRows: 3, maxRows: 5}"
                                                                placeholder="文字描述"
                                                            ></el-input>
                                                        </el-form-item>                                        
                                                    </el-form>
                                                    <blockquote class="help-block mt-1">
                                                        <el-text
                                                            type="info"
                                                            size="default"
                                                        >
                                                            {{ goodsVideoBuildHelp }}
                                                        </el-text>
                                                    </blockquote>
                                                </el-tab-pane> -->
                                            </el-tabs>
                                        </section>
                                        <section
                                            class="option--number flex flex-col pt-2"
                                            v-if="goodsVideoTaskCurrentMainOption == 'model'"
                                        >
                                            <h5
                                                class="section-header text-large font-bold mb-4"
                                            >
                                                <el-icon><DArrowRight /></el-icon>
                                                <i class="not-italic ml-2">推荐风格模型</i>
                                            </h5>
                                            <div
                                                class="flex flex-wrap"
                                            >
                                                <el-radio-group
                                                    v-model="videoModelCurrentId"
                                                >
                                                <figure
                                                    class="mr-1 mt-1 flex flex-col"
                                                    v-for="(model,index) in videoModelList"
                                                    :key="index"
                                                >
                                                    <el-image 
                                                        :src="model.thumb_url"
                                                        :preview-src-list="[model.thumb_url]"
                                                        style="width: 136px;height:136px"
                                                        class="rounded-full"
                                                        fit="cover"
                                                        loading="lazy"
                                                        @click="onChangeVideoModel({
                                                                'thumb_url': model.thumb_url, 
                                                                'id': model.id, 
                                                                'name': model.name,
                                                                'value': model.value,
                                                                'model': model
                                                            })"
                                                    >
                                                        <template #placeholder>
                                                            <el-icon><CameraFilled /></el-icon>
                                                        </template>
                                                    </el-image>

                                                    <figcaption class="ml-1 flex items-center justify-between">
                                                        <el-radio 
                                                            v-model="model.value"
                                                            :label="model.value"
                                                            data-id="model.id"
                                                            @click="onChangeVideoModel({
                                                                'thumb_url': model.thumb_url, 
                                                                'id': model.id, 
                                                                'name': model.name,
                                                                'value': model.value,
                                                                'model': model
                                                            })"
                                                        >{{ model.name }}</el-radio>
                                                    </figcaption>
                                                </figure>
                                                </el-radio-group>
                                            </div>
                                        </section>
                                        <section
                                            class="option--number flex flex-1 flex-col pt-2"
                                            v-if="goodsVideoTaskCurrentMainOption == 'duration'"
                                        >
                                            <el-form label-position="top">
                                                <h5 class="text-large font-bold mb-4">
                                                    视频时长（建议2秒，注意: 一般情况下帧数总和不超过32帧）
                                                </h5>
                                                <el-form-item
                                                    label=""
                                                >
                                                    <el-input-number
                                                        :min="1"
                                                        :max="60"
                                                        placeholder="最长60秒"
                                                        v-model="goodsVideoBuildOptions.video_duration"
                                                    ></el-input-number>
                                                </el-form-item>
                                                <h5 class="text-large font-bold mb-4">
                                                    每秒帧数（建议8帧/秒）
                                                </h5>
                                                <el-form-item
                                                    label=""
                                                >
                                                    <el-input-number
                                                        :min="1"
                                                        :max="30"
                                                        placeholder="最高30帧/秒"
                                                        v-model="goodsVideoBuildOptions.video_fps"
                                                    ></el-input-number>
                                                </el-form-item>
                                            </el-form>
                                        </section>
                                    </div>
                                </div>

                                <footer
                                    class="footer--task-actions fixed bottom-2 p-2 mt-0 mb-0"
                                >
                                    <div class="flex justify-start items-center bg-transparent w-[320px] py-1 px-4 mt-0 mb-0 rounded bg-neutral-200 text-neutral-200">
                                        <div
                                            class="flex flex-row flex-nowrap items-center pl-0 mb-1"
                                        >
                                            <el-button 
                                                round
                                                size="large"
                                                type="danger"
                                                class="text-xl"
                                                @click="onSubmitGoodsVideoTask('goods-video')"
                                                :disabled="goodsVideoTaskCurrentProgressing"
                                            >
                                                开始生成
                                                <el-icon><Promotion /></el-icon>
                                            </el-button>
                                            <!-- <el-button 
                                                round
                                                plain
                                                @click="onEditTaskName"
                                            >
                                                <el-icon
                                                    color="#9e9e9e"
                                                >
                                                    <EditPen />
                                                </el-icon>
                                               {{ goodsVideoTaskCurrentName }}
                                            </el-button> -->
                                        </div>
                                        <i class="not-italic flex-1"></i>
                                        <div
                                            class="flex flex-row flex-nowrap items-center"
                                        >
                                            <el-button
                                                round
                                                type="info"
                                                :disabled="goodsVideoTaskCurrentTaskHistoryCount == 0"
                                                @click="onDoneGoodsVideoTask(goodsVideoTaskCurrentId, true)"
                                                v-if="isCurrentGoodsVideoTaskTodo"
                                            >
                                                <el-icon><Select /></el-icon>
                                                查看历史任务
                                            </el-button>
                                        </div>
                                    </div>
                                </footer>
                            </article>

                            <article
                                class="section--current-task-done bg-neutral-100"
                                v-if="isShowCurrentGoodsVideoTask && !isCurrentGoodsVideoTaskTodo"
                            >
                                <div class="top">
                                    <div class="flex flex-row flex-1 mt-2 items-center">
                                        <el-space :size="4">
                                            <div
                                                class="flex flex-row items-center mb-1"
                                                @click="onEditTaskName"
                                            >
                                                <el-text
                                                    class="mr-2 text-lg font-medium text-neutral-700"
                                                    type="primary"
                                                >
                                                    {{ goodsVideoTaskCurrentName ? goodsVideoTaskCurrentName : goodsVideoTaskCurrentTaskName }}
                                                </el-text>
                                                <el-icon
                                                    color="#9e9e9e"
                                                >
                                                    <EditPen />
                                                </el-icon>
                                            </div>
                                            <el-divider direction="vertical"></el-divider>
                                            <p class="flex mx-2 items-center"
                                                @click="onToggleTaskMoreDetails"
                                            >
                                                <div class="not-italic mr-1 text-sm font-normal">
                                                    详情
                                                    <!-- <i
                                                        class="not-italic ml-1"
                                                        v-if="goodsVideoTaskCurrentTaskAssetCount > 0"
                                                    >
                                                        视频数:{{ goodsVideoTaskCurrentTaskAssetCount }}
                                                    </i>
                                                    <i
                                                        class="not-italic ml-1"
                                                        v-if="goodsVideoTaskCurrentTaskHistoryCount > 0"
                                                    >
                                                        /任务历史数:{{ goodsVideoTaskCurrentTaskHistoryCount }}
                                                    </i> -->
                                                </div>
                                                <el-icon size="14">
                                                    <ArrowDown v-if="!isShowTaskMoreDetails"></ArrowDown>
                                                    <ArrowUp v-if="isShowTaskMoreDetails"></ArrowUp>
                                                </el-icon>
                                            </p>
                                            <el-divider direction="vertical"></el-divider>
                                            <div
                                                class="flex flex-row flex-1 items-center text-sm"
                                            >
                                                <span class="mr-1">任务ID: </span>
                                                <span>{{ goodsVideoTaskCurrent.task_id }}</span>
                                            </div>
                                        </el-space>
                                    </div>
                                </div>
                                <el-divider class="my-1" border-style="dashed"></el-divider>
                                <div class="main mt-1 p-4">
                                    <div class="main-header shadow border-dotted rounder"
                                        v-if="isShowTaskMoreDetails"
                                    >
                                        <div class="header-left">
                                            <el-descriptions
                                                title="任务详情"
                                                direction="vertical"
                                                :column="2"
                                                size="large"
                                            >
                                                <el-descriptions-item
                                                    label="创建时间"
                                                    label-class-name="desc-label"
                                                    class-name="desc-content"
                                                >
                                                    {{  goodsVideoTaskCurrent.task_date_created }}
                                                </el-descriptions-item>
                                                <el-descriptions-item
                                                    label="完成时间"
                                                    label-class-name="desc-label"
                                                    class-name="desc-content"
                                                >
                                                    {{  goodsVideoTaskCurrent.task_date_done }}
                                                </el-descriptions-item>
                                                <el-descriptions-item
                                                    label="任务提交最近设置"
                                                    label-class-name="desc-label"
                                                    class-name="desc-content"
                                                >
                                                    <el-space
                                                        wrap
                                                        v-if="goodsVideoTaskCurrentContextDescList"
                                                    >
                                                        <el-tag
                                                            v-for="(desc,index) in goodsVideoTaskCurrentContextDescList"
                                                            :key="index"
                                                        >
                                                            {{  desc }}
                                                        </el-tag>
                                                    </el-space>
                                                </el-descriptions-item>
                                            </el-descriptions>
                                        </div>
                                        <el-divider border-style="dashed"></el-divider>
                                    </div>
                                    <div class="main-body">
                                        <el-empty
                                            description="暂无完成短视频"
                                            v-if="goodsVideoTaskCurrentTaskAssetCount == 0"
                                        >
                                        </el-empty>
                                        <div
                                            class="flex flex-col flex-1 mt-2 mb-4 justify-between items-center"
                                            v-if="goodsVideoTaskCurrentHistoryAssets.length > 0"
                                        >
                                            <div
                                                class="flex flex-col flex-1 w-full"
                                                v-for="(history,index) in goodsVideoTaskCurrentHistoryAssets"
                                                :key="index"
                                            >
                                                <nav class="flex flex-row flex-1">
                                                    <h5 class="flex flex-col justify-start items-start font-bold text-neutral-500">
                                                        <span class="mt-1">
                                                            [{{  history.context_subject_name }}]
                                                        </span>
                                                        <span class="mt-1 max-w-[200px]">
                                                            <el-tooltip :content="history.context_prompt_cn">
                                                                <el-text class="" truncated>
                                                                    {{  history.context_prompt_cn }}
                                                                </el-text>
                                                            </el-tooltip>
                                                        </span>
                                                        <div class="mt-1">
                                                            <i class="not-italic">
                                                                {{ history.task_date_created }}
                                                            </i>
                                                            <i class="not-italic"></i>
                                                        </div>
                                                    </h5>
                                                    <p class="flex-1 flex items-center mt-1">
                                                        <i class="flex-1"></i>
                                                        <el-button
                                                            plain
                                                            round
                                                            @click="onReDoingGoodsVideoTask(history.context_prompt_cn, history)"
                                                            v-if="!isCurrentGoodsVideoTaskTodo"
                                                        >
                                                            再次执行任务
                                                        </el-button>
                                                    </p>
                                                </nav>
                                                <div 
                                                    class="flex flex-row flex-wrap pl-4 mt-2"
                                                    v-if="history.assets.length > 0"
                                                >
                                                    <figure
                                                        class="flex flex-col task-video ml-0 mr-2 mb-2 shadow-2xl rounded-2xl p-2"
                                                        v-for="(video,index) in history.assets"
                                                        :key="index"
                                                    >
                                                        <div
                                                            class="video relative"
                                                            @click="onShowVideo(video, 'detail')"
                                                        >
                                                            <el-image
                                                                :src="video.thumb_url"
                                                                class="rounded"
                                                                style="width:252px;"
                                                                fit="cover"
                                                                loading="lazy"
                                                            >
                                                                <template #placeholder>
                                                                    <el-icon><CameraFilled /></el-icon>
                                                                </template>
                                                            </el-image>
                                                            <div class="absolute top-[50%] right-[50%] shadow">
                                                                <el-icon
                                                                    color="#f5f5f5"
                                                                    size="32px"
                                                                ><VideoPlay /></el-icon>
                                                            </div>
                                                        </div>
                                                        <div class="footer mt-1">
                                                            <div class="flex flex-row justify-between">
                                                                <div class="flex flex-row">
                                                                    <div class="action"
                                                                        @click="onCollectTaskVideo(
                                                                            video,
                                                                            video.is_collected,
                                                                            video.task_id,
                                                                            video.id,
                                                                            video.task_media_site,
                                                                            video.url,
                                                                            video.thumb_url,
                                                                        )"
                                                                    >
                                                                        <el-icon v-if="video.is_collected">
                                                                            <StarFilled size="24px"></StarFilled>
                                                                        </el-icon>
                                                                        <el-icon v-if="!video.is_collected">
                                                                            <Star size="16px"></Star>
                                                                        </el-icon>
                                                                    </div>
                                                                    <div class="action ml-1"
                                                                        @click="onDownloadTaskVideo(video.id, video.url)"
                                                                    >
                                                                        <el-icon>
                                                                            <Download></Download>
                                                                        </el-icon>
                                                                    </div>
                                                                </div>
                                                                <div class="action"
                                                                    @click="onDestroyTaskVideo(video.id, video.task_id)"
                                                                >
                                                                    <el-icon>
                                                                        <Delete></Delete>
                                                                    </el-icon>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </figure>
                                                </div>
                                                <el-divider border-style="dashed" />
                                            </div>
                                        </div>
                                        <!-- <div 
                                            class="flex flex-row flex-wrap pl-4"
                                            v-if="goodsVideoTaskCurrentTotalVideoList.length > 0"
                                        >
                                            <figure
                                                class="flex flex-col task-video ml-2 mr-2 mb-2 shadow-2xl rounded-2xl p-2"
                                                v-for="(video,index) in goodsVideoTaskCurrentTotalVideoList"
                                                :key="index"
                                            >
                                                <div
                                                    class="video relative"
                                                    @click="onShowVideo(video, 'detail')"
                                                >
                                                    <el-image
                                                        :src="video.asset_thumb_medium_url"
                                                        class="rounded"
                                                        style="width:252px;"
                                                        fit="cover"
                                                        loading="lazy"
                                                    >
                                                        <template #placeholder>
                                                            <el-icon><CameraFilled /></el-icon>
                                                        </template>
                                                    </el-image>
                                                    <div class="absolute top-[50%] right-[50%] shadow">
                                                        <el-icon
                                                            color="#f5f5f5"
                                                            size="32px"
                                                        ><VideoPlay /></el-icon>
                                                    </div>
                                                </div>
                                                <div class="footer mt-1">
                                                    <div class="flex flex-row justify-between">
                                                        <div class="action"
                                                            @click="onCollectTaskVideo(
                                                                video,
                                                                video.is_collected,
                                                                video.task_id,
                                                                video.id,
                                                                video.task_media_site,
                                                                video.asset_url,
                                                                video.asset_thumb_medium_url,
                                                            )"
                                                        >
                                                            <el-icon v-if="video.is_collected">
                                                                <StarFilled size="24px"></StarFilled>
                                                            </el-icon>
                                                            <el-icon v-if="!video.is_collected">
                                                                <Star size="16px"></Star>
                                                            </el-icon>
                                                        </div>
                                                        <div class="action"
                                                            @click="onDestroyTaskVideo(video.id, video.task_id)"
                                                        >
                                                            <el-icon>
                                                                <Delete></Delete>
                                                            </el-icon>
                                                        </div>
                                                    </div>
                                                </div>
                                            </figure>
                                        </div> -->
                                    </div>
                                </div>
                            </article>
                        </el-main>
                    </el-container>
                </el-tab-pane>
            </el-tabs>
        </div>

        <el-dialog
            v-model="isShowTaskEditDialog"
            title="任务名称编辑"
        >
            <el-form
                label-position="top"
            >
                <el-form-item label="">
                    <template #label>
                        <el-text type="info" size="default" class="font-medium text-base neutral-700">
                            任务名字
                        </el-text>
                    </template>
                    <el-input v-model="taskNewName"></el-input>
                </el-form-item>
            </el-form>

            <template #footer>
                <el-button
                    plain
                    @click="onCancelTaskEdit"
                >
                    取消
                </el-button>
                <el-button
                    plain
                    @click="onConfirmTaskEdit"
                >
                    确定
                </el-button>
            </template>
        </el-dialog>

        <el-dialog
            v-model="isShowVideoDialog"
            class="flex flex-col justifify-center items-center"
            title="视频观看"
        >
            <video
                ref="video"
                class="w-[400px] h-[266px]"
                :poster="videoCurrentThumbUrl"
                :src="videoCurrentUrl"
                controls
            >
            </video>
            <!-- <video-player
                src="/your-path/video.mp4"
                :poster="videoCurrentThumbUrl"
                :source="videoCurrentUrl"
                controls
                :loop="true"
                :volume="0.6"
            /> -->
        </el-dialog>


    </div>
</template>

<script setup>
    import { ref, reactive, nextTick, onMounted,onBeforeUnmount,getCurrentInstance} from 'vue'
    import { useRoute } from "vue-router";
    import { ElMessage, ElMessageBox, genFileId } from 'element-plus'
    // import Konva from 'konva'
    import { 
        download,
        getUserVideoCollectList,createUserVideoCollect,destroyUserVideoCollect,
        getUserVideoTaskList, 
        createVideoTask, retriveVideoTask, updateVideoTask, destroyVideoTask, queryVideoTask,
        submitVideoTask, getVideoTaskProgress, getVideoTaskResult, 
        getUserTaskProgressQueue,
        // getUserVideoRefList, uploadGoodsVideoRef,destroyGoodsVideoRef,
        destroyGoodsVideo,
        // getGoodsVideoMaskAuto, uploadGoodsVideoMask, 
        getUserVideoSubjectTypeIndex, getUserVideoSubjectList, getUserVideoPretrainModelList, getVideoStencilCategoryList, getVideoStencilList,
        sendSegmentReq,
        getUserVideoModelList,
        uploadPromptImage,
        translateByTencent, translateByAzure, translateByAlibaba
    } from '@/api/api'
    import {hasPermission,getTableHeight} from "@/utils/util";
    import { MEDIA_SITE_ROOT } from '@/config'
    
    const { proxy } = getCurrentInstance()

    const route = useRoute();

    let bus = getCurrentInstance().appContext.config.globalProperties.$Bus;
    let collapsed = ref(false)

    let isFull = ref(false)
    let isShowTaskMoreDetails = ref(false)
    let footerNav = ref()

    function setFull(){
        isFull.value=!isFull.value
        window.dispatchEvent(new Event('resize'))
    }

    let scrowHight = ref(0)

    let userTaskProgressQueue = ref({})

    let activeTopNavTab = ref("goods")
    let activeMainNavTab = ref("collect")
    let activeNewNavTab = ref("stencil")
    let editableTopNavTabs = ref([])

    let userTaskList = ref([
    ])
    let userTaskListCurrentPage = ref(1)
    let userTaskListScrollHeight = ref('100%')
    let hasMoreUserTaskList = ref(false)

    let userVideoCollectList = ref([])
    let userVideoCollectListCurrentPage = ref(1)
    let hasMoreUserCollectList = ref(false)
    let galleryList = ref([])

    let isShowGoodsVideoMain = ref(true)
    let isCurrentGoodsVideoTaskTodo = ref(true)
    let isShowCurrentGoodsVideoTask = ref(false)
    let isShowUploadRef = ref(false)
    let isShowUploadMask = ref(false)

    let taskNewName = ref('')
    let isShowTaskEditDialog = ref(false)
    let isShowFinetuneDialog = ref(false)

    let videoSubjectCurrentThumbUrl = ref('')
    let videoSubjectCurrentName = ref('')
    let videoSubjectCurrentId = ref('')
    let videoSubjectCurrentIsSvd = ref(false)
    let videoSubjectCurrent = ref({})
    let videoSubjectCurrentPromptCn = ref('')

    let videoModelCurrentThumbUrl = ref('')
    let videoModelCurrentName = ref('')
    let videoModelCurrentValue = ref('')
    let videoModelCurrentId = ref('')
    let videoModelCurrent = ref({})
    let videoModelCurrentPromptCn = ref('')

    let pretrainModelType = ref('upload')
    let pretrainModelCurrentThumbUrl = ref('')
    let pretrainModelCurrentName = ref('')
    let pretrainModelCurrentId = ref('')
    let goodsVideoRefGalleryCurrent = ref('')
    let goodsVideoRefList = ref([])
    let goodsVideoRefUploaded = ref('')
    let goodsVideoRefUploaderRef = ref()

    let videoSubjectTypeIndex = ref([])
    let subjectTypeCurrentId = ref(0)
    let subjectTypeCurrentNeedImage = ref(false)
    let videoSubjectList = ref([])
    let videoPretrainModelList = ref([])
    let videoModelList = ref([])

    let videoSubjectTypeIdDefault = ref('')
    let videoSubjectTypeNameDefault = ref('')
    let videoSubjectIdDefault = ref('')
    let videoSubjectDefault = ref({})

    let videoStencilMediumStyleList = ref([])
    let videoStencilPaintStyleList = ref([])
    let videoStencilCompositionStyleList = ref([])
    let videoStencilSceneStyleList = ref([])
    let videoStencilLocationStyleList = ref([])
    let videoStencilPersonStyleList = ref([])
    let videoStencilFaceStyleList = ref([])
    let videoStencilTagList = ref([])

    let goodsVideoTaskCurrentMainOption = ref('subject')
    let goodsVideoBuildTaskResult = ref([])
    let goodsVideoTaskCurrent = ref()
    let goodsVideoTaskCurrentId = ref()
    let goodsVideoTaskCurrentContextDescList = ref([])
    let goodsVideoTaskCurrentTotalVideoList = ref([])
    let goodsVideoTaskCurrentHistoryAssets = ref()
    let goodsVideoTaskCurrentName = ref()
    let goodsVideoTaskCurrentTaskName = ref()
    let goodsVideoTaskCurrentTaskHistoryCount = ref(0)
    let goodsVideoTaskCurrentTaskAssetCount = ref(0)
    let goodsVideoTaskCurrentStep = ref(0)
    let goodsVideoTaskCurrentImaeSizeNeedValidate = ref(false)
    let goodsVideoTaskCurrentImaeSizeValidated = ref(true)

    let goodsVideoMaskType = ref('auto')
    let goodsVideoMaskAuto = ref()
    let goodsVideoMaskAutoGetType = ref('extract')
    let goodsVideoMaskIsNeedFinetune = ref(true)
    let goodsVideoMaskUploaded = ref()
    let goodsVideoMaskUploaderRef = ref()

    let finetuneStageContainer = ref()
    let finetuneStage = ref()
    let finetuneStageConfig= ref({
        width: 300,
        height: 300,
    })
    let finetuneStageLayer = ref()
    let finetuneStageLayerConfig= ref({
    })
    let finetuneStageLayerBgVideo = ref()
    let finetuneStageLayerBgVideoConfig= ref({
        video: '',
        x: 0,
        y: 0,
        width: 300,
        height: 300,
    })

    let finetuneExpectedContainer = ref()
    let finetuneExpected = ref()
    let finetuneExpectedConfig= ref({
        width: 300,
        height: 300,
    })
    let finetuneExpectedLayerConfig= ref({
    })
    let finetuneExpectedVideo= ref()
    let finetuneExpectedVideoConfig= ref({
    })
    let finetuneExpectedInfo = ref('')
    let finetuneExpectedResult = ref('')

    let finetunePenMode = ref('include')
    let finetunePenMudgeWidth = ref(4)
    let finetunePositivePoints = ref([])
    let finetuneNegativePoints = ref([])

    let goodsVideoFinetuneType = ref('auto-extract')
    let goodsVideoFinetune = ref('')
    let goodsVideoFinetuneWidth = ref(256)
    let goodsVideoFinetuneHeight = ref(256)
    let goodsVideoFinetuneWidthOrig = ref(256)
    let goodsVideoFinetuneHeightOrig = ref(256)
    let goodsVideoFinetuneScale = ref()
    let goodsVideoFinetunePreview = ref('')

    let prompt_cn_tip = ref('视频文案描述')

    let goodsVideoBuildOptions = reactive({
        subject_type_id: '',
        subject_type_name: '',
        subject_id: '',
        subject_name: '',
        subject_thumb_url: '',
        model_id: '',
        model_name: '',
        model_value: '',
        model_thumb_url: '',
        pretrain_model_id: '',
        pretrain_model_name: '',
        pretrain_model_thumb_url: '',
        pretrain_model_lora_name: '',
        prompt_cn: '',
        prompt_cn_negative: '',
        prompt_en: '',
        prompt_en_negative: '',
        is_overweite_prompt: true,
        batch_size: 4,
        prompt_is_svd: false,
        prompt_is_svd_freesize: true,
        prompt_is_image_prompt: false,
        prompt_image: '',
        prompt_image_name: '',
        prompt_is_schedule: false,
        prompt_schedule_mode: '',
        medium_style: '',
        medium_style_id: '',
        paint_style: '',
        paint_style_id: '',
        scene_style: '',
        scene_style_id: '',
        location_style: '',
        location_style_id: '',
        composition_style: '',
        composition_style_id: '',
        person_style: '',
        person_style_id: '',
        face_style: '',
        face_style_id: '',
        tags_style: '',
        tags_style_id: '',
        video_duration: 3,
        video_fps: 8,
    })

    const video = ref(null)
    let isShowVideoDialog = ref(false)
    let videoCurrentUrl = ref('')
    let videoCurrentThumbUrl = ref('')
    let videoPlayOptions = reactive({
        width: "800px", //播放器宽度
        height: "450px", //播放器高度
        color: "#409eff", //主题色
        title: "", //视频名称
        src: "", //视频源
        muted: false, //静音
        webFullScreen: false,
        speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速
        autoPlay: false, //自动播放
        loop: false, //循环播放
        mirror: false, //镜像画面
        ligthOff: false, //关灯模式
        volume: 0.3, //默认音量大小
        control: true, //是否显示控制
        controlBtns: [
            "audioTrack",
            "quality",
            "speedRate",
            "volume",
            "setting",
            "pip",
            "pageFullScreen",
            "fullScreen",
        ], //显示所有按钮,
    })
    let goodsVideoBuildHelp = ref(
        `
        描述你想要生成的图片内容，支持中英文，但是最终都要生成英文才能提交。
        `
    )

    let goodsVideoMaskProgressing = ref(false)

    let goodsVideoTaskCurrentProgressing = ref(false)
    let goodsVideoTaskProgressingQueue = ref({})
    let goodsVideoTaskProgressValue = ref(0)
    let goodsVideoTaskProgressInfo = ref('')
    let hasVideoTaskProgressPreview = ref(false)
    let goodsVideoTaskVideosPreview = ref([])
    let goodsVideoTaskCurrentGeneratedVideos = ref([])
    let goodsVideoTaskTimerId = ref(-1)

    let goodsVideoRefCanvas = ref()
    let goodsVideoMaskCanvas = ref()

    let goodsVideoRefStageConfig= ref({
        width: 300,
        height: 300,
    })
    
    let goodsVideoRefLayerConfig= ref()
    let goodsVideoMaskLayerConfig= ref()
    let goodsVideoMaskVideoConfig= ref({
        video: ''
    })

    let goodsVideoPromptImageUpload = ref()
    let goodsVideoPromptImageList = ref([])

    let workflowCurrentScheduleFrames = ref([])
    let workflowCurrentScheduleFrameValues = ref('')

    function _getUserTaskProgressQueue() {
        getUserTaskProgressQueue({'task_type': 'goods-video'}).then((res) => {
            userTaskProgressQueue.value = res.data.data
            goodsVideoTaskProgressingQueue.value = res.data.data
        }).catch((err) => {
            console.log(err)
        })
    }

    function getTopNavTabs() {
        // platformsettingsSysconfig({limit:999,parent__isnull:true}).then(res=>{
        //     if(res.code == 2000){
        //         editableTopNavTabs.value = res.data.data
        //     }
        // })
        editableTopNavTabs.value = [
            {
                title: '短视频',
                key: 'goods',
            },
        ]
    }
    function handleResize(){
        nextTick(() => {
            let tabSelectHeight = proxy.$refs.tableSelect?proxy.$refs.tableSelect.offsetHeight:0
            tabSelectHeight = isFull.value?tabSelectHeight + 90 :tabSelectHeight + 200
            scrowHight.value = getTableHeight(tabSelectHeight,false)
        })
    }

    onMounted(() => {
        bus.on("toggle", value => {
            collapsed.value = !value;
            console.log('bus toggle:'+value)
        });

        getTopNavTabs()
        handleResize()
        window.addEventListener('resize',handleResize)

        getUserVideoTasks()
        getUserVideoCollects(1)
        // getUserGoodsVideoRefs()
        getUserVideoSubjects()
        getUserVideoPretainModels()
        getUserVideoModels()
        
        // getVideoStencils('medium')
        // getVideoStencils('composition')
        // getVideoStencils('paint')
        getVideoStencils('scene')
        getVideoStencils('location')
        // getVideoStencils('person')
        // getVideoStencils('face')
        // getVideoStencils('tag')

        // if (finetuneStageContainer.value) {
        //     app.mount(finetuneStageContainer.value);
        // }
        // 获取任务进度队列
        _getUserTaskProgressQueue()

    })
    onBeforeUnmount(()=>{
        window.removeEventListener('resize',handleResize)
    })

    defineExpose({
        setFull
    })

    function getUserVideoCollects(page = userVideoCollectListCurrentPage.value) {
        let params = {
            page: page,
            task_type: 'goods-video',
        }
        getUserVideoCollectList(params).then((res) => {
            let remList = res.data?.data
            remList && remList.forEach((el) => {
                el.is_collected = true
                el.task_id = el.task
            })
            let hasNext = res.data?.has_next
            if (hasNext) {
                hasMoreUserCollectList.value = true
            } else {
                hasMoreUserCollectList.value = false
            }
            if (page == 1) {
                userVideoCollectList.value = remList
            } else {
                userVideoCollectList.value = userVideoCollectList.value.concat(remList)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    function onLoadDefaultTaskIcon() {

    }

    function onMoreUserCollects() {
        userVideoCollectListCurrentPage.value = parseInt(userVideoCollectListCurrentPage.value) + 1
        getUserVideoCollects(userVideoCollectListCurrentPage.value)
    }

    function getTaskContextDescList(context) {
        let result = []
        if (context?.goodsVideoBuildOptions?.subject_name) {
            result.push('视频风格:' + context?.goodsVideoBuildOptions?.subject_name)
        }
        if (context?.goodsVideoBuildOptions?.pretrain_model_name) {
            result.push('预训练模型:' + context?.goodsVideoBuildOptions?.pretrain_model_name)
        }
        if (context?.goodsVideoBuildOptions?.pretrain_model_lora_name) {
            // result.push('预训练模型Lora:' + context?.goodsVideoBuildOptions?.pretrain_model_lora_name)
        }
        if (context?.goodsVideoBuildOptions?.prompt_cn) {
            result.push('中文视频文案:' + context?.goodsVideoBuildOptions?.prompt_cn)
        }
        if (context?.goodsVideoBuildOptions?.prompt_en) {
            result.push('英文视频文案:' + context?.goodsVideoBuildOptions?.prompt_en)
        }
        if (context?.goodsVideoBuildOptions?.medium_style) {
            result.push('媒质输出类型:' + context?.goodsVideoBuildOptions?.medium_style)
        }
        if (context?.goodsVideoBuildOptions?.composition_style) {
            result.push('构图类型:' + context?.goodsVideoBuildOptions?.composition_style)
        }
        // if (context?.goodsVideoBuildOptions?.paint_style) {
        //     result.push('画风类型:' + context?.goodsVideoBuildOptions?.paint_style)
        // }
        if (context?.goodsVideoBuildOptions?.scene_style) {
            result.push('动作/场景类型:' + context?.goodsVideoBuildOptions?.scene_style)
        }
        if (context?.goodsVideoBuildOptions?.location_style) {
            result.push('位置类型:' + context?.goodsVideoBuildOptions?.location_style)
        }
        // if (context?.goodsVideoBuildOptions?.person_style) {
        //     result.push('人物类型:' + context?.goodsVideoBuildOptions?.person_style)
        // }
        // if (context?.goodsVideoBuildOptions?.face_style_id) {
        //     result.push('面部类型:' + context?.goodsVideoBuildOptions?.face_style_id)
        // }
        if (context?.goodsVideoBuildOptions?.tags_style) {
            result.push('标签:' + context?.goodsVideoBuildOptions?.tags_style)
        }
        return result
    }

    function reformatTask(dataOrig) {
        let result = {}
        let thumb_url = ''
        let taskVideos = []
        let taskHistoryAssets = []
        let taskCurrentResult = []
        let taskContext = {}

        if (dataOrig?.assets && dataOrig?.assets.length > 0) {
            // thumb_url = dataOrig.task_media_site + '' + dataOrig?.assets[0]?.asset_url
        }
        if (dataOrig) {
            let name = '任务-'+dataOrig.id
            if (dataOrig.task_name) {
                name = dataOrig.task_name
            }
            if (dataOrig.assets) {
                dataOrig.assets.sort((a,b) => {
                    if (a.create_datetime > b.create_datetime) {
                        return -1
                    } else if (a.create_datetime < b.create_datetime) {
                        return 1
                    } else {
                        return 0
                    }
                })
                dataOrig.assets.forEach((el) => {
                    if (el.asset_url) {
                        // el.asset_url = dataOrig.task_media_site + '' + el.asset_url
                        taskVideos.push({
                            'id': el.id, 
                            'asset_url': el.asset_url, 
                            'asset_thumb_url': el.asset_thumb_url, 
                            'asset_thumb_medium_url': el.asset_thumb_medium_url, 
                            'is_collected': el.is_collected,
                            'task_id': dataOrig.id,
                            'task_media_site': dataOrig.task_media_site,
                            'asset_url': el.asset_url,
                        })
                    } else if (el?.fields?.asset_url) {
                        // el.fields.asset_url = dataOrig.task_media_site + '' + el.fields.asset_url
                        taskVideos.push({
                            'id': el.fields.id, 
                            'asset_url': el.fields.asset_url, 
                            'asset_thumb_url': el.asset_thumb_url, 
                            'asset_thumb_medium_url': el.asset_thumb_medium_url, 
                            'is_collected': el.fields.is_collected,
                            'task_id': dataOrig.id,
                            'task_media_site': dataOrig.task_media_site,
                            'asset_url': el.fiels.asset_url,
                        })
                    }
                })
            }
            if (dataOrig.task_context) {
                if (typeof(dataOrig.task_context) == 'string') {
                    taskContext = JSON.parse(dataOrig.task_context)
                } else {
                    taskContext = dataOrig.task_context
                }
            }
            if (dataOrig.task_result) {
                if (typeof(dataOrig.task_context) == 'string') {
                    taskCurrentResult = JSON.parse(dataOrig.task_result)
                } else {
                    taskCurrentResult = dataOrig.task_result
                }
            }
            if (dataOrig.history_assets) {
                for (let history_id in dataOrig.history_assets) {
                    let historyValue = dataOrig.history_assets[history_id]
                    taskHistoryAssets.push(historyValue)
                }
            }
            result = {
                id: dataOrig.id,
                name: name,
                thumb_url: thumb_url,
                task_id: dataOrig.task_id,
                task_name: dataOrig.task_name,
                status: dataOrig.status,
                status_desc: getStatusDesc(dataOrig.status),
                task_status: dataOrig.task_status,
                task_context: taskContext,
                task_date_created: dataOrig.task_date_created,
                task_date_done: dataOrig.task_date_done,
                task_args: dataOrig.task_args,
                task_successful: dataOrig.task_successful,
                task_media_site: dataOrig.task_media_site,
                task_history_count: dataOrig.history_count,
                task_asset_count: dataOrig.asset_count,
                task_videos: taskVideos,
                task_history_assets: taskHistoryAssets,
                task_result: taskCurrentResult,
            }
        }
        return result
    }

    function getUserVideoTasks(page = userTaskListCurrentPage.value, currentTaskId = null, currentTask = null) {
        let params = {
            page: page,
            task_type: 'goods-video',
        }
        getUserVideoTaskList(params).then((res) => {
            let remList = res.data?.data
            let hasNext = res.data?.has_next
            if (hasNext) {
                hasMoreUserTaskList.value = true
            } else {
                hasMoreUserTaskList.value = false
            }
            let transList = []
            if (remList && remList.length > 0) {
                transList = remList.map((el) => {
                    return reformatTask(el)
                })
            }
            if (page == 1) {
                userTaskList.value = transList
            } else {
                userTaskList.value = userTaskList.value.concat(transList)
            }
            console.log('reformattask after')
            console.log(userTaskList.value)
            if (currentTaskId && currentTask) {
                onShowGoodsVideoCurrentTask(currentTaskId, currentTask)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    function onMoreUserTaskList() {
        userTaskListCurrentPage.value = parseInt(userTaskListCurrentPage.value) + 1
        let page = userTaskListCurrentPage.value
        getUserVideoTasks(page)
    }

    // function onChangePrompt(value) {
    //     if (!goodsVideoBuildOptions.prompt_is_schedule) {
    //         videoSubjectCurrentPromptCn.value = value
    //     }
    //     console.log(videoSubjectCurrentPromptCn.value)
    // }

    function onResetFramePromptKeys() {
        console.log(workflowCurrentScheduleFrames.value)
        let frames_count = parseInt(goodsVideoBuildOptions.video_duration) * parseInt(goodsVideoBuildOptions.video_fps)
        let keys_count = Object.keys(workflowCurrentScheduleFrames.value).length
        let keys_step = Math.floor(frames_count / keys_count)
        let newFrames = []
        let i = 0
        for (let key in workflowCurrentScheduleFrames.value) {
            let value = workflowCurrentScheduleFrames.value[key]['frame_value']
            let new_key = i
            newFrames.push({
                'frame_key': String(new_key),
                'frame_value': value,
            })
            i = i + keys_step
        }
        workflowCurrentScheduleFrames.value = newFrames
        console.log(JSON.stringify(newFrames))
    }

    function onCreateFramePrompt(idx, keyName) {
        workflowCurrentScheduleFrames.value.splice(idx,0,{
            'frame_key': parseInt(keyName) + 1,
            'frame_value': '',
        })
    }

    function onCopyFramePrompt(idx, keyName, value) {
        workflowCurrentScheduleFrames.value.splice(idx,0,{
            'frame_key': parseInt(keyName) + 1,
            'frame_value': value,
        })
    }

    function onDestroyFramePrompt(idx) {
        workflowCurrentScheduleFrames.value.splice(idx,1)        
    }

    function onChangeVideoSubjectType(id, description) {
        subjectTypeCurrentId.value = id
        goodsVideoTaskCurrentImaeSizeValidated.value = true
        goodsVideoBuildOptions.subject_type_id = id
        goodsVideoBuildOptions.subject_type_name = description
    }

    function onChangeVideoSubject(value) {
        console.log('onChangeVideoSubject')
        console.log(value)
        console.log(videoSubjectCurrentId.value)
        console.log(videoSubjectCurrent.value)
        console.log(goodsVideoBuildOptions.value)
        videoSubjectCurrentThumbUrl.value = value.thumb_url
        videoSubjectCurrentName.value = value.name
        videoSubjectCurrentId.value = value.id
        goodsVideoBuildOptions.subject_id = value.id
        goodsVideoBuildOptions.subject_name = value.name
        goodsVideoBuildOptions.subject_thumb_url = value.thumb_url
        
        goodsVideoBuildOptions.prompt_is_schedule = value.prompt_is_schedule
        goodsVideoBuildOptions.prompt_schedule_mode = value.prompt_schedule_mode
        goodsVideoBuildOptions.prompt_is_image_prompt = value.prompt_is_image_prompt

        if (value.prompt_is_schedule) {
            // goo
            dsVideoBuildOptions.prompt_cn = value.workflow_schedule_prompts
            workflowCurrentScheduleFrames.value = value.workflow_schedule_frames
            // 提取分镜文本内容
            let values = ''
            for( let frame in workflowCurrentScheduleFrames.value) {
                let key = frame['frame_key']
                let value = frame['frame_value']
                if (value) {
                    values = values + ',' + value
                }
            }
            if (values) {
                values = values.substring(1)
            }
            // console.log('分镜文本:'+values)
            workflowCurrentScheduleFrameValues.value = values
        } else {
            // goodsVideoBuildOptions.prompt_cn = videoSubjectCurrentPromptCn.value
            goodsVideoBuildOptions.is_overweite_prompt = true
        }

        goodsVideoBuildOptions.prompt_is_image_prompt = value.prompt_is_image_prompt
        goodsVideoBuildOptions.prompt_is_schedule = value.prompt_is_schedule
        goodsVideoBuildOptions.prompt_is_svd = value.prompt_is_svd
        goodsVideoBuildOptions.prompt_is_svd_freesize = value.prompt_is_svd_freesize
        videoSubjectCurrentIsSvd.value = value.prompt_is_svd
        if (value.prompt_is_schedule) {
            prompt_cn_tip.value = '多场景视频文案描述'
        } else {
            prompt_cn_tip.value = '单场景视频文案描述'
        }

        // onSwitchMainOption2Prompt()
 
        console.log('goodsVideoBuildOptions.prompt_is_schedule='+goodsVideoBuildOptions.prompt_is_schedule)
        console.log('workflowCurrentScheduleFrames=')
        console.log(workflowCurrentScheduleFrames)

    }

    function onChangeVideoModel(value) {
        // console.log(value)
        videoModelCurrentThumbUrl.value = value.thumb_url
        videoModelCurrentId.value = value.id
        videoModelCurrentName.value = value.name
        videoModelCurrentValue.value = value.value
        videoModelCurrent.value = value.subject
        goodsVideoBuildOptions.model_id = value.id
        goodsVideoBuildOptions.model_name = value.name
        goodsVideoBuildOptions.model_value = value.value
        goodsVideoBuildOptions.model_thumb_url = value.thumb_url

        onSwitchMainOption2Duration()
    }

    function onChangePretrainModel(values) {
        pretrainModelCurrentThumbUrl.value = values.thumb_url
        pretrainModelCurrentName.value = values.name
        pretrainModelCurrentId.value = values.id
        goodsVideoBuildOptions.pretrain_model_id = values.id
        goodsVideoBuildOptions.pretrain_model_name = values.name
        goodsVideoBuildOptions.pretrain_model_thumb_url = values.thumb_url
        goodsVideoBuildOptions.pretrain_model_lora_name = values.lora_name
        onSwitchMainOption2Prompt()
    }

    function onProcessGoodsVideoRefActions(command) {
        if (command == 'drop') {
            ElMessageBox.confirm('确认要删除这项参考图吗？', '提示',{}).then(() => {
                let params = {
                    id: goodsVideoRefGalleryCurrentId.value
                }
                // destroyGoodsVideoRef(params).then((res) => {
                //     getUserGoodsVideoRefs()
                // }).catch((err) => {
                //     console.log(err)
                // })
            }).catch(() => {

            })
        }
    }

    function getUserGoodsVideoRefs() {
        let params = {
            page: 1,
        }
        // getUserVideoRefList(params).then((res) => {
        //     goodsVideoRefList.value = res.data.data
        // }).catch((err) => {
        //     console.log(err)
        // })
    }

    function reformatSubjectTypeIndex(subjectTypeIndex) {
        let result = []

        let xScheduleFrames = []
        let subjects = []
        subjectTypeIndex.forEach((subjectType) => {
            subjects = subjectType.subjects
            subjects && subjects.forEach((el) => {
                let schedulePrompts = el.workflow_schedule_prompts
                xScheduleFrames = []
                if (schedulePrompts) {
                    schedulePrompts = JSON.parse(schedulePrompts)
                    for(let key in schedulePrompts) {
                        xScheduleFrames.push({
                            'frame_key': key,
                            'frame_value': schedulePrompts[String(key)],
                        })
                    }
                    el['workflow_schedule_frames'] = xScheduleFrames
                }
            })
            result.push(subjectType)
        })
        return result
    }

    function reformatSubjectList(subjectList) {
        let result = []

        let xScheduleFrames = []
        subjectList.forEach((el) => {
            let schedulePrompts = el.workflow_schedule_prompts
            xScheduleFrames = []
            if (schedulePrompts) {
                schedulePrompts = JSON.parse(schedulePrompts)
                for(let key in schedulePrompts) {
                    xScheduleFrames.push({
                        'frame_key': key,
                        'frame_value': schedulePrompts[String(key)],
                    })
                }
                el['workflow_schedule_frames'] = xScheduleFrames
            }
            result.push(el)
        })

        return result
    }

    function getUserVideoSubjects() {
        let params = {
            page: 1,
        }
        getUserVideoSubjectTypeIndex(params).then((res) => {
            let subjectTypeIndex = res.data.data
            let xSubjectTypeIndex = reformatSubjectTypeIndex(subjectTypeIndex)
            videoSubjectTypeIndex.value = xSubjectTypeIndex
            console.log('videoSubjectTypeIndex')
            console.log(videoSubjectTypeIndex.value)
            if (videoSubjectTypeIndex.value.length > 0) {
                videoSubjectTypeIdDefault.value = videoSubjectTypeIndex.value[0]['id']
                videoSubjectTypeNameDefault.value = videoSubjectTypeIndex.value[0]['description']
                if (videoSubjectTypeIndex.value[0]['subjects'] && videoSubjectTypeIndex.value[0]['subjects'].length > 0) {
                    videoSubjectIdDefault.value = videoSubjectTypeIndex.value[0]['subjects'][0]['id']
                    videoSubjectDefault.value = videoSubjectTypeIndex.value[0]['subjects'][0]
                }
            }
            console.log('videoSubjectTypeIdDefault')
            console.log(videoSubjectTypeIdDefault.value)
            console.log('videoSubjectIdDefault')
            console.log(videoSubjectIdDefault.value)
            console.log('videoSubjectTypeNameDefault')
            console.log(videoSubjectTypeNameDefault.value)
            console.log('videoSubjectDefault')
            console.log(videoSubjectDefault.value)

        }).catch((err) => {
            console.log(err)
        })
        // getUserVideoSubjectList(params).then((res) => {
        //     let subjectList = res.data.data
        //     let xSubjectList = reformatSubjectList(subjectList)
        //     videoSubjectList.value = xSubjectList
        //     // videoSubjectList.value = subjectList
        // }).catch((err) => {
        //     console.log(err)
        // })
    }

    function getUserVideoModels() {
        let params = {
            page: 1,
        }
        getUserVideoModelList(params).then((res) => {
            let modelList = res.data.data
            // let xSubjectList = reformatSubjectList(subjectList)

            videoModelList.value = modelList
        }).catch((err) => {
            console.log(err)
        })
    }
    
    function getUserVideoPretainModels() {
        let params = {
            page: 1,
        }
        getUserVideoPretrainModelList(params).then((res) => {
            videoPretrainModelList.value = res.data.data
        }).catch((err) => {
            console.log(err)
        })
    }

    function getVideoStencils(styleName) {
        let params = {
            page: 1,
        }
        if (styleName == 'medium') {
            params['stencil_category_id'] = 1
            getVideoStencilList(params).then((res) => {
                videoStencilMediumStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'composition') {
            params['stencil_category_id'] = 2
            getVideoStencilList(params).then((res) => {
                videoStencilCompositionStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'paint') {
            params['stencil_category_id'] = 3
            getVideoStencilList(params).then((res) => {
                videoStencilPaintStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'scene') {
            params['stencil_category_id'] = 4
            getVideoStencilList(params).then((res) => {
                videoStencilSceneStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'location') {
            params['stencil_category_id'] = 5
            getVideoStencilList(params).then((res) => {
                videoStencilLocationStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'person') {
            params['stencil_category_id'] = 8
            getVideoStencilList(params).then((res) => {
                videoStencilPersonStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'face') {
            params['stencil_category_id'] = 9
            getVideoStencilList(params).then((res) => {
                videoStencilFaceStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'tag') {
            params['stencil_category_id'] = 10
            getVideoStencilList(params).then((res) => {
                videoStencilTagList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        }
    }

    function onShowGoodsVideoMain() {
        isShowGoodsVideoMain.value = true
        isShowCurrentGoodsVideoTask.value = false
        isCurrentGoodsVideoTaskTodo.value = false
        userVideoCollectListCurrentPage.value = 1
        getUserVideoCollects(userVideoCollectListCurrentPage.value)
        goodsVideoTaskCurrentId.value = null
    }

    function onNewGoodsVideoTask() {
        isShowGoodsVideoMain.value = false
        isShowCurrentGoodsVideoTask.value = true
        isCurrentGoodsVideoTaskTodo.value = true

        goodsVideoTaskCurrentProgressing.value = false
        hasVideoTaskProgressPreview.value = false
        goodsVideoTaskVideosPreview.value = []

        goodsVideoBuildOptions.subject_type_id = videoSubjectTypeIdDefault.value
        goodsVideoBuildOptions.subject_type_name = videoSubjectTypeNameDefault.value
        goodsVideoBuildOptions.subject_id = videoSubjectIdDefault.value
        goodsVideoBuildOptions.subject_name = ''
        goodsVideoBuildOptions.subject_thumb_url = ''
        goodsVideoBuildOptions.pretrain_model_id = ''
        goodsVideoBuildOptions.pretrain_model_name = ''
        goodsVideoBuildOptions.pretrain_model_thumb_url = ''
        goodsVideoBuildOptions.pretrain_model_lora_name = ''
        goodsVideoBuildOptions.prompt_cn = ''
        goodsVideoBuildOptions.prompt_cn_negative = ''
        goodsVideoBuildOptions.prompt_en = ''
        goodsVideoBuildOptions.prompt_en_negative = ''
        goodsVideoBuildOptions.batch_size = 4
        goodsVideoBuildOptions.medium_style = ''
        goodsVideoBuildOptions.medium_style_id = ''
        goodsVideoBuildOptions.paint_style = ''
        goodsVideoBuildOptions.paint_style_id = ''
        goodsVideoBuildOptions.scene_style = ''
        goodsVideoBuildOptions.scene_style_id = ''
        goodsVideoBuildOptions.location_style = ''
        goodsVideoBuildOptions.location_style_id = ''
        goodsVideoBuildOptions.composition_style = ''
        goodsVideoBuildOptions.composition_style_id = ''
        goodsVideoBuildOptions.person_style = ''
        goodsVideoBuildOptions.person_style_id = ''
        goodsVideoBuildOptions.face_style = ''
        goodsVideoBuildOptions.face_style_id = ''
        goodsVideoBuildOptions.tags_style = ''
        goodsVideoBuildOptions.tags_style_id = ''

        goodsVideoTaskCurrentTaskHistoryCount.value = 0

        let params = {
            task_type: 'goods-video',
        }
        createVideoTask(params).then((res) => {
            let newTaskId = res.data.data.id
            let newTask = res.data.data
            console.log(res)
            newTask = reformatTask(newTask)
            userTaskList.value.push(newTask)
            console.log(newTask)
            newTask.task_history_count = 0
            onShowGoodsVideoCurrentTask(newTaskId, newTask)
            onChangeVideoSubject({
                'thumb_url': videoSubjectDefault.value.thumb_url, 
                'id': videoSubjectIdDefault.value, 
                'name': videoSubjectDefault.value.name,
                'prompt_schedule_mode': videoSubjectDefault.value.is_schedule ? '分帧视频文案' :'不分帧视频文案',
                'prompt_is_schedule': videoSubjectDefault.value.is_schedule ? 1 : 0,
                'prompt_is_image_prompt': videoSubjectDefault.value.is_image_prompt ? 1 : 0,
                'prompt_is_svd': videoSubjectDefault.value.is_svd ? 1 : 0,
                'prompt_is_svd_freesize': videoSubjectDefault.value.is_svd_freesize ? 1 : 0,
                'workflow_schedule_prompts': videoSubjectDefault.value.workflow_schedule_prompts,
                'workflow_schedule_frames': videoSubjectDefault.value.workflow_schedule_frames,
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    function restoreTaskContext(taskId, task) {
        goodsVideoTaskCurrentId.value = taskId
        goodsVideoTaskCurrent.value = task
        goodsVideoTaskCurrentName.value = task.name
        goodsVideoTaskCurrentTaskName.value = task.task_name
        goodsVideoTaskCurrentTaskHistoryCount.value = task.task_history_count
        goodsVideoTaskCurrentTaskAssetCount.value = task.task_asset_count

        console.log('call restoretaskcontext')
        console.log(Object.keys(goodsVideoTaskProgressingQueue.value))
        // progressingQueue
        console.log('Object.keys(goodsVideoTaskProgressingQueue.value)')
        console.log(Object.keys(goodsVideoTaskProgressingQueue.value))
        console.log('taskid='+taskId)
        console.log('Object.keys(goodsVideoTaskProgressingQueue.value).indexOf(taskId)')
        console.log(Object.keys(goodsVideoTaskProgressingQueue.value).indexOf(String(taskId)))
        if (Object.keys(goodsVideoTaskProgressingQueue.value).indexOf(String(taskId)) > -1) {
            goodsVideoTaskCurrentProgressing.value = true
        } else {
            goodsVideoTaskCurrentProgressing.value = false
        }
        console.log('in restoretaskcontext.. goodsVideoTaskProgressingQueue')
        console.log(goodsVideoTaskProgressingQueue.value)
        console.log('goodsVideoTaskCurrentProgressing='+goodsVideoTaskCurrentProgressing.value)
        console.log('-----')
        console.log('userTaskList')
        console.log(userTaskList.value)
        console.log('taskqueue{}')
        console.log(userTaskProgressQueue.value)
        console.log(`taskId=${taskId}`)
        console.log(`goodsVideoTaskCurrentProgressing=${goodsVideoTaskCurrentProgressing.value}`)

        if (task.task_videos) {
            goodsVideoTaskCurrentTotalVideoList.value = task.task_videos
        }
        goodsVideoTaskCurrentHistoryAssets.value = task.task_history_assets
        goodsVideoTaskCurrentGeneratedVideos.value = task.task_result
        // goodsVideoTaskCurrentMainOption.value = 'subject'
        goodsVideoTaskCurrentStep.value = 0

        isShowGoodsVideoMain.value = false
        isShowCurrentGoodsVideoTask.value = true
        isCurrentGoodsVideoTaskTodo.value = task.task_successful != 1

        let ctxPretrainModelCurrentThumbUrl = ''
        let ctxPretrainModelCurrentName = ''
        let ctxPretrainModelCurrentLoraName = ''
        let ctxPretrainModelCurrentId = ''
        let ctxPretrainModelCurrent = {}
        let ctxGoodsVideoBuildOptions = {}
        let context = {}
        if (task.task_context) {
            if (typeof(task.task_context) == 'string') {
                context = JSON.parse(task.task_context)
            } else {
                context = task.task_context
            }
        }
        console.log('task')
        console.log(task)
        console.log('task.task_context')
        console.log(task.task_context)
        console.log('context')
        console.log(context)
        console.log('context.goodsImageBuildOptions')
        console.log(context.goodsImageBuildOptions)
        if (context.goodsImageBuildOptions) {
            ctxGoodsVideoBuildOptions = context.goodsImageBuildOptions

            // ctxPretrainModelCurrentThumbUrl = ctxGoodsVideoBuildOptions.pretrain_model_thumb_url
            // ctxPretrainModelCurrentName = ctxGoodsVideoBuildOptions.pretrain_model_name
            // ctxPretrainModelCurrentLoraName = ctxGoodsVideoBuildOptions.pretrain_model_lora_name
            // ctxPretrainModelCurrentId = ctxGoodsVideoBuildOptions.pretrain_model_id
            
            videoSubjectCurrentThumbUrl.value = ctxGoodsVideoBuildOptions.subject_thumb_url
            videoSubjectCurrentName.value = ctxGoodsVideoBuildOptions.subject_name
            pretrainModelCurrentThumbUrl.value = ctxGoodsVideoBuildOptions.pretrain_model_thumb_url
            pretrainModelCurrentName.value = ctxGoodsVideoBuildOptions.pretrain_model_name
            pretrainModelCurrentId.value = ctxGoodsVideoBuildOptions.pretrain_model_id

            goodsVideoBuildOptions.subject_type_id = ctxGoodsVideoBuildOptions.subject_type_id
            subjectTypeCurrentId.value = ctxGoodsVideoBuildOptions.subject_type_id
            goodsVideoBuildOptions.subject_type_name = ctxGoodsVideoBuildOptions.subject_type_name
            goodsVideoBuildOptions.subject_id = ctxGoodsVideoBuildOptions.subject_id
            goodsVideoBuildOptions.subject_name = ctxGoodsVideoBuildOptions.subject_name
            goodsVideoBuildOptions.subject_thumb_url = ctxGoodsVideoBuildOptions.subject_thumb_url

            goodsVideoBuildOptions.pretrain_model_id = ctxGoodsVideoBuildOptions.pretrain_model_id
            goodsVideoBuildOptions.pretrain_model_name = ctxGoodsVideoBuildOptions.pretrain_model_name
            goodsVideoBuildOptions.pretrain_model_thumb_url = ctxGoodsVideoBuildOptions.pretrain_model_thumb_url
            goodsVideoBuildOptions.pretrain_model_lora_name = ctxGoodsVideoBuildOptions.pretrain_model_lora_name
            goodsVideoBuildOptions.batch_size = ctxGoodsVideoBuildOptions.batch_size
            goodsVideoBuildOptions.prompt_cn = ctxGoodsVideoBuildOptions.prompt_cn
            goodsVideoBuildOptions.prompt_en = ctxGoodsVideoBuildOptions.prompt_en
            goodsVideoBuildOptions.prompt_en_negative = ctxGoodsVideoBuildOptions.prompt_en_negative
            goodsVideoBuildOptions.medium_style = ctxGoodsVideoBuildOptions.medium_style
            goodsVideoBuildOptions.medium_style_id = ctxGoodsVideoBuildOptions.medium_style_id
            goodsVideoBuildOptions.composition_style = ctxGoodsVideoBuildOptions.composition_style
            goodsVideoBuildOptions.composition_style_id = ctxGoodsVideoBuildOptions.composition_style_id
            goodsVideoBuildOptions.paint_style = ctxGoodsVideoBuildOptions.paint_style
            goodsVideoBuildOptions.paint_style_id = ctxGoodsVideoBuildOptions.paint_style_id
            goodsVideoBuildOptions.scene_style = ctxGoodsVideoBuildOptions.scene_style
            goodsVideoBuildOptions.scene_style_id = ctxGoodsVideoBuildOptions.scene_style_id
            goodsVideoBuildOptions.person_style = ctxGoodsVideoBuildOptions.person_style
            goodsVideoBuildOptions.person_style_id = ctxGoodsVideoBuildOptions.person_style_id
            goodsVideoBuildOptions.face_style = ctxGoodsVideoBuildOptions.face_style
            goodsVideoBuildOptions.face_style_id = ctxGoodsVideoBuildOptions.face_style_id
            goodsVideoBuildOptions.tags_style = ctxGoodsVideoBuildOptions.tags_style
            goodsVideoBuildOptions.tags_style_id = ctxGoodsVideoBuildOptions.tags_style_id

            goodsVideoBuildOptions.prompt_is_svd = ctxGoodsVideoBuildOptions.prompt_is_svd
            goodsVideoBuildOptions.prompt_is_svd_freesize = ctxGoodsVideoBuildOptions.prompt_is_svd_freesize
            goodsVideoBuildOptions.prompt_schedule_mode = ctxGoodsVideoBuildOptions.prompt_schedule_mode
            goodsVideoBuildOptions.prompt_is_schedule = ctxGoodsVideoBuildOptions.prompt_is_schedule
            goodsVideoBuildOptions.prompt_is_image_prompt = ctxGoodsVideoBuildOptions.prompt_is_image_prompt
            goodsVideoBuildOptions.workflow_schedule_prompts = ctxGoodsVideoBuildOptions.workflow_schedule_prompts
            goodsVideoBuildOptions.workflow_schedule_frames = ctxGoodsVideoBuildOptions.            goodsVideoBuildOptions.workflow_schedule_frames = ctxGoodsVideoBuildOptions.prompt_is_svd

            goodsVideoTaskCurrentContextDescList.value = getTaskContextDescList(task.task_context)
        } else {
            pretrainModelCurrentThumbUrl.value = ''
            pretrainModelCurrentName.value = ''
            pretrainModelCurrentId.value = ''

            goodsVideoBuildOptions.subject_type_id = videoSubjectTypeIdDefault.value
            subjectTypeCurrentId.value = videoSubjectTypeIdDefault.value
            goodsVideoBuildOptions.subject_type_name = videoSubjectTypeNameDefault.value
            goodsVideoBuildOptions.subject_id = videoSubjectIdDefault.value
            goodsVideoBuildOptions.subject_name = ''
            goodsVideoBuildOptions.subject_thumb_url = ''
            goodsVideoBuildOptions.pretrain_model_id = ''
            goodsVideoBuildOptions.pretrain_model_name = ''
            goodsVideoBuildOptions.pretrain_model_lora_name = ''
            goodsVideoBuildOptions.pretrain_model_thumb_url = ''
            goodsVideoBuildOptions.batch_size = 4
            goodsVideoBuildOptions.prompt_cn = ''
            goodsVideoBuildOptions.prompt_en = ''
            goodsVideoBuildOptions.prompt_en_negative = ''
            goodsVideoBuildOptions.medium_style = ''
            goodsVideoBuildOptions.medium_style_id = ''
            goodsVideoBuildOptions.composition_style = ''
            goodsVideoBuildOptions.composition_style_id = ''
            goodsVideoBuildOptions.paint_style = ''
            goodsVideoBuildOptions.paint_style_id = ''
            goodsVideoBuildOptions.scene_style = ''
            goodsVideoBuildOptions.scene_style_id = ''
            goodsVideoBuildOptions.person_style = ''
            goodsVideoBuildOptions.person_style_id = ''
            goodsVideoBuildOptions.face_style = ''
            goodsVideoBuildOptions.face_style_id = ''
            goodsVideoBuildOptions.tags_style = ''
            goodsVideoBuildOptions.tags_style_id = ''

            goodsVideoBuildOptions.prompt_is_svd = 0
            goodsVideoBuildOptions.prompt_is_svd_freesize = 1
            goodsVideoBuildOptions.prompt_schedule_mode = ''
            goodsVideoBuildOptions.prompt_is_schedule = ''
            goodsVideoBuildOptions.prompt_is_image_prompt = 0
            goodsVideoBuildOptions.workflow_schedule_prompts = ''
            goodsVideoBuildOptions.workflow_schedule_frames = ''
        }

        // 切换具体视频风格
        onChangeVideoSubject({
            'thumb_url': goodsVideoBuildOptions.subject_thumb_url,
            'id': goodsVideoBuildOptions.subject_id, 
            'name': goodsVideoBuildOptions.subject_name,

            'prompt_is_svd': goodsVideoBuildOptions.prompt_is_svd ? 1 : 0,
            'prompt_is_svd_freesize': goodsVideoBuildOptions.prompt_is_svd_freesize ? 1 : 0,

            'prompt_schedule_mode': goodsVideoBuildOptions.prompt_schedule_mode ? '分帧视频文案' :'不分帧视频文案',
            'prompt_is_schedule': goodsVideoBuildOptions.prompt_is_schedule ? 1 : 0,
            'prompt_is_image_prompt': goodsVideoBuildOptions.prompt_is_image_prompt ? 1 : 0,
            'workflow_schedule_prompts': goodsVideoBuildOptions.workflow_schedule_prompts,
            'workflow_schedule_frames': goodsVideoBuildOptions.workflow_schedule_frames,
        })

    }

    function onShowGoodsVideoCurrentTask(taskId, task) {

        console.log('onshowgoodsvideo currenttask')
        isShowTaskMoreDetails.value = false
        restoreTaskContext(taskId, task)
    }

    function onSwitchMainOption2Mask() {
        goodsVideoTaskCurrentMainOption.value = 'mask'
    }
    function onSwitchMainOption2Subject() {
        goodsVideoTaskCurrentMainOption.value = 'subject'
        goodsVideoTaskCurrentStep.value = 0
    }
    function onSwitchMainOption2Prompt() {
        goodsVideoTaskCurrentMainOption.value = 'prompt'
        goodsVideoTaskCurrentStep.value = 2
    }
    function onSwitchMainOption2Model() {
        goodsVideoTaskCurrentMainOption.value = 'model'
        goodsVideoTaskCurrentStep.value = 1
    }
    function onSwitchMainOption2Duration() {
        goodsVideoTaskCurrentMainOption.value = 'duration'
        goodsVideoTaskCurrentStep.value = 3
    }
    function onSwitchMainOption2Status() {
        goodsVideoTaskCurrentMainOption.value = 'status'
        goodsVideoTaskCurrentStep.value = 4
    }

    function onTranslateChinesePromptByTencent() {
        let params = {
            text: goodsVideoBuildOptions.prompt,
            source: 'zh',
            to: 'en',
        }
        translateByTencent(params).then((res) => {
            let translatedText = res.data.data[0]
            goodsVideoBuildOptions.prompt_en = translatedText
        }).catch((err) => {
            console.log(err)
        })
    }

    function onTranslateChinesePromptByAzure() {
        let params = {
            text: goodsVideoBuildOptions.prompt,
            source: 'zh',
            to: 'en',
        }
        translateByAzure(params).then((res) => {
            let translatedText = res.data.data[0]
            goodsVideoBuildOptions.prompt_en = translatedText
        }).catch((err) => {
            console.log(err)
        })
    }

    function onTranslateChinesePromptByAlibaba() {
        let params = {
            text: goodsVideoBuildOptions.prompt_cn,
            source: 'zh',
            to: 'en',
        }
        translateByAlibaba(params).then((res) => {
            let translatedText = res.data.data[0]
            goodsVideoBuildOptions.prompt_en = translatedText
        }).catch((err) => {
            console.log(err)
        })
    }

    function onSubmitGoodsVideoTask(type) {
            console.log('goodsVideoBuildOptions.subject_type_id=')
            console.log(goodsVideoBuildOptions.subject_type_id)
        let task_id = ''
        let subject_type_id = ''
        let subject_type_name = ''
        let subject_id = ''
        let subject_name = ''
        let subject_thumb_url = ''
        let model_id = ''
        let model_name = ''
        let model_value = ''
        let model_thumb_url = ''
        let prompt_is_svd = false
        let prompt_is_svd_freesize = false
        let prompt_is_schedule = false
        let prompt_is_image_prompt = false
        let prompt_schedule_mode = ''
        let pretrain_model_id = ''
        let pretrain_model_name = ''
        let pretrain_model_thumb_url = ''
        let pretrain_model_lora_name = ''
        let prompt_cn = ''
        let prompt_cn_negative = ''
        let promptCnObj = {}
        let prompt_en = ''
        let prompt_en_negative = ''
        let prompt_image = ''
        let prompt_image_name = ''
        let is_overweite_prompt = true
        let generat_number = 1
        let stencil_ids = ''
        let medium_style = ''
        let medium_style_id = ''
        let composition_style = ''
        let composition_style_id = ''
        let paint_style = ''
        let paint_style_id = ''
        let scene_style = ''
        let scene_style_id = ''
        let location_style = ''
        let location_style_id = ''
        let person_style = ''
        let person_style_id = ''
        let face_style = ''
        let face_style_id = ''
        let tags_style = ''
        let tags_style_id = ''
        let video_ref = ''
        let video_mask = ''
        let video_mask_type = ''
        let video_build_type = ''
        let video_duration = ''
        let video_fps = ''
        let validMessage = ''
        let workflow_schedule_prompts = ''
        let workflow_schedule_frames = ''
        let is_check_valid = true

        // 预先检查输入数据项
        let checkItems = {
            // 'prompt_cn': '中文视频文案还没有设置',
            // 'medium_style_id': '媒质输出类型还没有设置',
            // 'composition_style_id': '构图类型还没有设置',
            // 'paint_style_id': '画风类型还没有设置',
            // 'scene_style_id': '预设动作还没有设置',
            // 'location_style_id': '位置类型还没有设置',
            // 'person_style_id': '人物类型还没有设置',
            // 'face_style_id': '面部类型还没有设置',
            // 'tags_style_id': '标签还没有设置',
        }
        let invalidItems = []

        console.log('step ...')
        if (goodsVideoTaskCurrentId.value in goodsVideoTaskProgressingQueue.value) {
            let checkResultMessage = '当前任务正在处理中，请等待处理完毕后再提交任务'
            ElMessageBox.alert(checkResultMessage, '提示', {
                callback: () => {
                    return
                }
            })
            return
        }

        console.log('step 2...')
        for (let key in workflowCurrentScheduleFrames.value) {
            let frame_key = workflowCurrentScheduleFrames.value[key]['frame_key']
            let frame_value = workflowCurrentScheduleFrames.value[key]['frame_value']
            promptCnObj[frame_key] = frame_value
        }

        console.log('step 3...')
        if (!goodsVideoBuildOptions['subject_id']) {
            let checkResultMessage = '视频风格还没有选择'
            ElMessageBox.alert(checkResultMessage, '提示', {
                callback: () => {
                    // onSwitchMainOption2Subject()
                    return
                }
            })
        }
        
        if (goodsVideoBuildOptions['prompt_is_image_prompt']) {
            console.log('step 3.1...')
            if (!goodsVideoBuildOptions.prompt_image) {
                is_check_valid = false
                let checkResultMessage = '参考图还没有上传，请先上传或选择其他类型风格'
                ElMessageBox.alert(checkResultMessage, '提示', {
                    callback: () => {
                        return
                    }
                })
            }
        // } else if (!goodsVideoBuildOptions['pretrain_model_id']) {
        //     let checkResultMessage = '训练模型还没有选择'
        //     ElMessageBox.alert(checkResultMessage, '提示', {
        //         callback: () => {
        //             return
        //         }
        //     })
        } 
        if (is_check_valid) {
            console.log('step 3.2...')
            for (let itemKey in checkItems) {
                if (!goodsVideoBuildOptions[itemKey]) {
                    invalidItems.push(
                        checkItems[itemKey]
                    )
                }
            }

            // if (invalidItems.length == checkItems.keys.length) {
            // if (invalidItems.length == 5) {
            console.log('step 3.3...')
            if (invalidItems.length > 0 && (invalidItems.length == Object.keys(checkItems).length) ) {
                let checkResultMessage = '[' + invalidItems.join(',\n') + '];几项中需要选择其中一项!'
                ElMessageBox.alert(checkResultMessage, '提示', {
                    callback: () => {
                        onSwitchMainOption2Prompt()
                        return
                    }
                })
            } else {
                console.log('step 3.3.1...')
                console.log('goodsVideoTaskCurrentImaeSizeNeedValidate.value=')
                console.log(goodsVideoTaskCurrentImaeSizeNeedValidate.value)
                console.log('goodsVideoTaskCurrentImaeSizeValidated.value')
                console.log(goodsVideoTaskCurrentImaeSizeValidated.value)
                if (goodsVideoTaskCurrentImaeSizeNeedValidate.value && !goodsVideoTaskCurrentImaeSizeValidated.value) {
                    if (!goodsVideoTaskCurrentImaeSizeValidated.value) {
                        validMessage = `当前图片长宽需要符合上当前视屏风格的要求规格，请重新上传后再提交任务`
                        ElMessageBox.alert(validMessage, '提示', {
                            callback: () => {
                                return
                            }
                        })
                        return
                    }
                }
                if (true) {
                    subject_type_id = goodsVideoBuildOptions.subject_type_id
                    subject_type_name = goodsVideoBuildOptions.subject_type_name
                    subject_id = goodsVideoBuildOptions.subject_id
                    subject_name = goodsVideoBuildOptions.subject_name
                    subject_thumb_url = goodsVideoBuildOptions.subject_thumb_url
                    model_id = goodsVideoBuildOptions.model_id
                    model_name = goodsVideoBuildOptions.model_name
                    model_value = goodsVideoBuildOptions.model_value
                    model_thumb_url = goodsVideoBuildOptions.model_thumb_url
                    prompt_is_schedule = goodsVideoBuildOptions.prompt_is_schedule
                    prompt_is_image_prompt = goodsVideoBuildOptions.prompt_is_image_prompt
                    prompt_schedule_mode = goodsVideoBuildOptions.prompt_schedule_mode
                    pretrain_model_id = goodsVideoBuildOptions.pretrain_model_id
                    pretrain_model_name = goodsVideoBuildOptions.pretrain_model_name
                    pretrain_model_thumb_url = goodsVideoBuildOptions.pretrain_model_thumb_url
                    pretrain_model_lora_name = goodsVideoBuildOptions.pretrain_model_lora_name

                    if (prompt_is_schedule) {
                        // 分镜分解模式下的处理
                        prompt_cn = JSON.stringify(promptCnObj)
                        prompt_cn = prompt_cn.trim().replaceAll('{','').replaceAll('}','')
                        // prompt_cn = goodsVideoBuildOptions.prompt_cn
                    } else {
                        prompt_cn = goodsVideoBuildOptions.prompt_cn
                    }
                    console.log('prompt_is_schedule='+prompt_is_schedule)
                    console.log('prompt_cn='+prompt_cn)

                    prompt_image_name = goodsVideoBuildOptions.prompt_image_name
                    prompt_cn_negative = goodsVideoBuildOptions.prompt_cn_negative
                    prompt_en = goodsVideoBuildOptions.prompt_en
                    prompt_en_negative = goodsVideoBuildOptions.prompt_en_negative
                    is_overweite_prompt = goodsVideoBuildOptions.is_overweite_prompt
                    prompt_is_svd = goodsVideoBuildOptions.prompt_is_svd
                    prompt_is_svd_freesize = goodsVideoBuildOptions.prompt_is_svd_freesize
                    generat_number = goodsVideoBuildOptions.batch_size
                    medium_style_id = goodsVideoBuildOptions.medium_style_id
                    medium_style = goodsVideoBuildOptions.medium_style
                    composition_style_id = goodsVideoBuildOptions.composition_style_id
                    composition_style = goodsVideoBuildOptions.composition_style
                    paint_style_id = goodsVideoBuildOptions.paint_style_id
                    paint_style = goodsVideoBuildOptions.paint_style
                    scene_style_id = goodsVideoBuildOptions.scene_style_id
                    scene_style = goodsVideoBuildOptions.scene_style
                    location_style_id = goodsVideoBuildOptions.location_style_id
                    location_style = goodsVideoBuildOptions.location_style
                    person_style_id = goodsVideoBuildOptions.person_style_id
                    person_style = goodsVideoBuildOptions.person_style
                    face_style_id = goodsVideoBuildOptions.face_style_id
                    face_style = goodsVideoBuildOptions.face_style
                    video_duration = goodsVideoBuildOptions.video_duration
                    video_fps = goodsVideoBuildOptions.video_fps
                    workflow_schedule_prompts = goodsVideoBuildOptions.workflow_schedule_prompts
                    workflow_schedule_frames = goodsVideoBuildOptions.workflow_schedule_frames

                    video_build_type = type
                    stencil_ids = medium_style_id + ',' + composition_style_id + ',' + paint_style_id + ',' + scene_style_id + ',' + location_style_id + ',' + person_style_id + ',' + face_style_id

                    task_id = goodsVideoTaskCurrentId.value
                    let params = {
                        task_id: task_id,
                        subject_type_id: subject_type_id,
                        subject_type_name: subject_type_name,
                        subject_id: subject_id,
                        subject_name: subject_name,
                        subject_thumb_url: subject_thumb_url,
                        model_id: model_id,
                        model_name: model_name,
                        model_value: model_value,
                        model_thumb_url: model_thumb_url,
                        pretrain_model_id: pretrain_model_id,
                        pretrain_model_name: pretrain_model_name,
                        pretrain_model_thumb_url: pretrain_model_thumb_url,
                        pretrain_model_lora_name: pretrain_model_lora_name,
                        medium_style_id: medium_style_id,
                        medium_style: medium_style,
                        composition_style_id: composition_style_id,
                        composition_style: composition_style,
                        paint_style_id: paint_style_id,
                        paint_style: paint_style,
                        scene_style_id: scene_style_id,
                        scene_style: scene_style,
                        location_style_id: location_style_id,
                        location_style: location_style,
                        person_style_id: person_style_id,
                        person_style: person_style,
                        face_style_id: face_style_id,
                        face_style: face_style,
                        tags_style_id: tags_style_id,
                        tags_style: tags_style,
                        stencil_ids: stencil_ids,
                        batch_size: generat_number,
                        prompt_is_image_prompt: prompt_is_image_prompt,
                        prompt_image_name: prompt_image_name,
                        prompt_cn: prompt_cn,
                        prompt_cn_negative: prompt_cn_negative,
                        prompt_cn_obj: promptCnObj,
                        prompt_en: prompt_en,
                        prompt_en_negative: prompt_en_negative,
                        is_overweite_prompt: is_overweite_prompt,
                        video_build_type: video_build_type,
                        video_duration: video_duration,
                        video_fps: video_fps,
                        workflow_schedule_prompts: workflow_schedule_prompts,
                        workflow_schedule_frames: workflow_schedule_frames,

                        prompt_is_svd: prompt_is_svd,
                        prompt_is_svd_freesize: prompt_is_svd_freesize,
                        prompt_is_schedule: prompt_is_schedule,
                        prompt_schedule_mode: prompt_schedule_mode,

                    }


                    hasVideoTaskProgressPreview.value = false
                    goodsVideoTaskVideosPreview.value = []
                    goodsVideoTaskCurrentProgressing.value = false
                    goodsVideoTaskProgressValue.value = 0
                    goodsVideoTaskProgressInfo.value = ''
                    goodsVideoTaskCurrentGeneratedVideos.value = []
                    goodsVideoBuildTaskResult.value = []

                    submitVideoTask(params).then((res) => {
                        // goodsVideoTaskCurrentMainOption.value = 'status'

                        let queueInfo = {
                            "id": goodsVideoTaskCurrentId.value,
                            "task_id": "",
                            "task_progress": "",
                            "status": "STARTED",
                            "task_status": "STARTED",
                            "task_type": "goods-video",
                            "task_name": null,
                            "task_remote_agent_id": "",
                            "task_date_created": "",
                            "task_date_done": "",
                            "task_timer_id": "",
                            "status_desc": "进行中...",
                        }
                        // userTaskProgressQueue.value[goodsVideoTaskCurrentId.value] = queueInfo
                        console.log('before submitvideotask,')
                        console.log(goodsVideoTaskProgressingQueue.value)
                        console.log('goodsVideoTaskCurrentId='+goodsVideoTaskCurrentId.value)
                        goodsVideoTaskProgressingQueue.value[goodsVideoTaskCurrentId.value] = queueInfo
                        goodsVideoTaskCurrentProgressing.value = true
                        console.log('after... goodsVideoTaskProgressingQueue')
                        console.log(goodsVideoTaskProgressingQueue.value)
                        console.log('-----')

                        userTaskList.value.forEach((el) => {
                            let id = el.id
                            let currentCompareId = goodsVideoTaskCurrent.value.id
                            if (id == currentCompareId) {
                                el.status_desc = '进行中...'
                            }
                        })

                        goodsVideoTaskCurrentGeneratedVideos.value = []
                        let task_id = res.data.data.id
                        let task_task_id = res.data.data.task_id
                        let task_history_id = res.data.data.task_history_id
                        let task_asset_id = res.data.data.task_asset_id
                        let task_successful = res.data.data.task_successful
                        let task_media_site = res.data.data.task_media_site
                        let task_client_id = res.data.data.task_client_id
                        localStorage.setItem('current_task_id', task_id)
                        localStorage.setItem('current_task_task_id', task_task_id)
                        localStorage.setItem('current_task_history_id', task_history_id)
                        localStorage.setItem('current_task_asset_id', task_asset_id)
                        localStorage.setItem('current_task_client_id', task_client_id)
                        if (!task_successful) {
                            // 开始轮询任务进度
                            goodsVideoTaskTimerId.value = setInterval(() => {
                                let current_task_id = localStorage.getItem('current_task_id')
                                let current_task_task_id = localStorage.getItem('current_task_task_id')
                                let current_task_history_id = localStorage.getItem('current_task_history_id')
                                let current_task_asset_id = localStorage.getItem('current_task_asset_id')
                                let current_task_client_id = localStorage.getItem('current_task_client_id')
                                let taskParams = {
                                    id: current_task_id,
                                    history_id: current_task_history_id,
                                    asset_id: current_task_asset_id,
                                    client_id: current_task_client_id,
                                    task_id: current_task_task_id,
                                }
                                getVideoTaskProgress(taskParams).then((progressRes) => {   
                                    task_media_site = progressRes.data.data.progress_media_site
                                    // let progress_current_id = progressRes.data.data.progress_current_id
                                    // let progress_current_status = progressRes.data.data.progress_current_status
                                    // 建议用这个结果标志作为唯一判断标志
                                    let progress_successful = progressRes.data.data.progress_successful
                                    let progress_value = progressRes.data.data.progress_value
                                    let progress_info = progressRes.data.data.progress_info
                                    let progress_image = progressRes.data.data.progress_image
                                    let progress_message = progressRes.data.data.progress_message
                                    let progress_status = progressRes.data.data.progress_status

                                    // goodsVideoTaskProgressInfo.value = progress_info
                                    goodsVideoTaskProgressInfo.value = progress_info
                                    goodsVideoTaskProgressValue.value = progress_value
                                    if (progress_image) {
                                        hasVideoTaskProgressPreview.value = true
                                    }
                                    goodsVideoTaskVideosPreview.value = [progress_image]

                                    console.log('progress_successful='+progress_successful)
                                    if (progress_successful) {
                                        // 如果任务完成
                                        onDoneGoodsVideoTask(goodsVideoTaskCurrentId.value, true)
                                        // goodsVideoTaskCurrentProgressing.value =false
                                        // let current_task_id = localStorage.getItem('current_task_id')
                                        // let current_task_task_id = localStorage.getItem('current_task_task_id')
                                        // let taskParams = {
                                        //     task_id: current_task_id
                                        // }
                                        // getVideoTaskResult(taskParams).then((res) => {
                                        //     let task_videos_result = res.data.data.videos
                                        //     goodsVideoTaskCurrentProgressing.value = false
                                        //     goodsVideoTaskCurrentGeneratedVideos.value = task_videos_result
                                        // }).catch((err) => {
                                        //     console.log(err)
                                        // })
                                    } else {
                                        if (progress_status.toUpperCase() == 'FAILURE') {
                                            // 如果任务异常结束
                                            onDoneGoodsVideoTask(goodsVideoTaskCurrentId.value, false)
                                        } else {
                                            goodsVideoTaskCurrentGeneratedVideos.value = progress_image
                                        }
                                    }
                                }).catch((err) => {
                                    console.log(err)
                                })
                            }, 1000)
                            // 记录轮询id
                            goodsVideoTaskProgressingQueue.value[goodsVideoTaskCurrentId.value]['task_timer_id'] = goodsVideoTaskTimerId.value
                        } else {
                            goodsVideoTaskCurrentProgressing.value =false
                            let current_task_id = localStorage.getItem('current_task_id')
                            let taskParams = {
                                id: current_task_id
                            }
                            getVideoTaskResult(taskParams).then((res) => {
                                let task_result = res.data.data.result
                                let taskVideoUrls = task_result.video_urls
                                let taskVideoRealUrls = []
                                let media_site_base_url = task_current_media_site
                                taskVideoUrls.forEach((el) => {
                                    taskVideoRealUrls.push(media_site_base_url + el)
                                })
                                goodsVideoTaskCurrentGeneratedVideos.value = taskVideoRealUrls
                                onDoneGoodsVideoTask(goodsVideoTaskCurrentId.value, true)
                            }).catch((err) => {
                                console.log(err)
                            })
                        }
                    }).catch((err) => {
                        console.log(err)
                    })
                }
            }
        }
    }

    function onUpdateGoodsVideoTask() {
        let currentContext = {
            pretrainModelCurrentThumbUrl: pretrainModelCurrentThumbUrl.value,
            goodsVideoBuildOptions: goodsVideoBuildOptions,
        }
        let params = {
            id: goodsVideoTaskCurrentId.value,
            context: currentContext,
        }
        updateVideoTask(params).then(() => {
            ElMessage('保存完成')
        }).catch((err) => {
            console.log(err)
        })
    }

    function onQueryGoodsVideoTask() {
        let params = {
            task_id: goodsVideoTaskCurrent.value['task_id']
        }
        let videos = []
        goodsVideoBuildTaskResult.value = []
        queryVideoTask(params).then((res) => {
            videos = res.data.data.videos
            goodsVideoBuildTaskResult.value = videos
        }).catch((err) => {
            console.log(err)
        })
    }

    function onDoneGoodsVideoTask(taskId, taskStatus = true) {
        // goodsVideoTaskCurrentProgressing.value =false
        if (goodsVideoTaskProgressingQueue.value[taskId]) {
            clearInterval(goodsVideoTaskProgressingQueue.value[taskId]['task_timer_id'])
            delete goodsVideoTaskProgressingQueue.value[taskId]
        }
        
        let params = {
            id: taskId,
            status: 'SUCCESS',
            successful: 1,
        }
        if (!taskStatus) {
            params = {
                id: taskId,
                status: 'FAILURE',
                successful: 0,
            }
        }
        updateVideoTask(params).then((res) => {
            isCurrentGoodsVideoTaskTodo.value = false
            retriveVideoTask(params).then((res) => {
                goodsVideoTaskCurrent.value = reformatTask(res.data.data)
                nextTick(() => {
                    let newUserTaskList = []
                    userTaskList.value.forEach((el) => {
                        if (el.id == taskId) {
                            el = goodsVideoTaskCurrent.value
                        }
                        newUserTaskList.push(el)
                    })
                    userTaskList.value = newUserTaskList
                    onShowGoodsVideoCurrentTask(
                        taskId,
                        goodsVideoTaskCurrent.value
                    )
                })
            }).catch((err) => {
                console.log(err)
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    function onReDoingGoodsVideoTask(currentPromptCn, history) {
        let params = {
            id: goodsVideoTaskCurrentId.value,
            status: 'TODO',
            successful: -1,
        }
        updateVideoTask(params).then((res) => {
            isCurrentGoodsVideoTaskTodo.value = true
            goodsVideoTaskCurrent.value = reformatTask(res.data.data)
            let newUserTaskList = []
            userTaskList.value.forEach((el) => {
                if (el.id == goodsVideoTaskCurrentId.value) {
                    el = goodsVideoTaskCurrent.value
                }
                newUserTaskList.push(el)
            })
            userTaskList.value = newUserTaskList
            console.log('userTaskList')
            console.log(userTaskList.value)
            console.log('history')
            console.log(history)
            console.log('goodsVideoTaskCurrent')
            console.log(goodsVideoTaskCurrent.value)
            goodsVideoTaskCurrent.value['task_context'] = history['task_context']
            console.log(goodsVideoTaskCurrent.value)
            onShowGoodsVideoCurrentTask(
                goodsVideoTaskCurrentId.value,
                goodsVideoTaskCurrent.value
            )

            // pretrainModelCurrentThumbUrl.value = ''
            // pretrainModelCurrentName.value = ''
            // pretrainModelCurrentId.value = ''

            // goodsVideoTaskCurrentStep.value = 0
            // videoSubjectCurrentThumbUrl.value = ''
            // videoSubjectCurrentName.value = ''
            // goodsVideoBuildOptions.prompt_cn = currentPromptCn
            // // 恢复提示词历史设置现场
            // videoSubjectCurrentPromptCn.value = currentPromptCn
            // // videoSubjectCurrent.value.workflow_schedule_prompts = currentPromptCn
            // // videoSubjectCurrent.value.workflow_schedule_frames = []

            // goodsVideoBuildOptions.prompt_en = ''
            // goodsVideoBuildOptions.prompt_en_negative = ''
            // goodsVideoBuildOptions.medium_style = ''
            // goodsVideoBuildOptions.medium_style_id = ''
            // goodsVideoBuildOptions.composition_style = ''
            // goodsVideoBuildOptions.composition_style_id = ''
            // goodsVideoBuildOptions.paint_style = ''
            // goodsVideoBuildOptions.paint_style_id = ''
            // goodsVideoBuildOptions.scene_style = ''
            // goodsVideoBuildOptions.scene_style_id = ''
            // goodsVideoBuildOptions.location_style = ''
            // goodsVideoBuildOptions.person_style = ''
            // goodsVideoBuildOptions.person_style_id = ''
            // goodsVideoBuildOptions.face_style = ''
            // goodsVideoBuildOptions.face_style_id = ''
            // goodsVideoBuildOptions.tags_style = ''
            // goodsVideoBuildOptions.tags_style_id = ''

            // goodsVideoBuildOptions.subject_id = ''
            // goodsVideoBuildOptions.subject_name = ''
            // goodsVideoBuildOptions.subject_thumb_url = ''
            // goodsVideoBuildOptions.pretrain_model_id = ''
            // goodsVideoBuildOptions.pretrain_model_name = ''
            // goodsVideoBuildOptions.pretrain_model_thumb_url = ''
            // goodsVideoBuildOptions.pretrain_model_lora_name = ''

            // goodsVideoBuildOptions.batch_size = 4

            
        }).catch((err) => {
            console.log(err)
        })
    }

    function onDestroyGoodsVideoTask() {
        ElMessageBox.confirm('确认要删除[' + goodsVideoTaskCurrent.value.name + ']吗？','提示',
            {
                type: 'danger',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                callback: (action) => {
                    let params = {
                        id: goodsVideoTaskCurrentId.value
                    }
                    destroyVideoTask(params).then(() => {
                        getUserVideoTasks(1)
                        onShowGoodsVideoMain()
                    }).catch(() => {

                    })
                }
            }
        )
    }

    function onEditTaskName() {
        if (goodsVideoTaskCurrent.value.task_name) {
            taskNewName.value = goodsVideoTaskCurrent.value.task_name
        } else {
            taskNewName.value = goodsVideoTaskCurrent.value.name
        }
        isShowTaskEditDialog.value = true
    }

    function onCancelTaskEdit() {
        isShowTaskEditDialog.value = false
    }

    function onConfirmTaskEdit() {
        isShowTaskEditDialog.value = false
        let params = {
            id: goodsVideoTaskCurrentId.value,
            name: taskNewName.value
        }
        updateVideoTask(params).then((res) => {
            goodsVideoTaskCurrent.value = reformatTask(res.data.data)
            taskNewName.value = ''
            goodsVideoTaskCurrentName.value = res.data.data.task_name
            userTaskList.value.forEach((el) => {
                let id = el.id
                let currentCompareId = goodsVideoTaskCurrent.value.id
                if (id == currentCompareId) {
                    el.name = goodsVideoTaskCurrent.value.task_name
                }
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    function getStatusDesc(status) {
        let result = ''
        let statusDescDict = {
            'PENDING': '进行中...',
            'STARTED': '进行中...',
            'SUCCESS': '已完成',
            'RETRY': '',
            'FAILURE': '失败',
            'TODO': '',
        }
        if (statusDescDict.hasOwnProperty(status?.toUpperCase())) {
            result = statusDescDict[status?.toUpperCase()]
        } else {
            result = '未知'
        }
        return result
    }

    function onChangeVideoStencilScheduleStyle(stencil) {
        goodsVideoBuildOptions.prompt_is_schedule = stencil.id
        if (stencil.id == 1) {
            prompt_cn_tip.value = '多场景视频文案描述'
        } else {
            prompt_cn_tip.value = '单场景视频文案描述'
        }
    }
    
    function onChangeVideoStencilMediumStyle(stencil) {
        goodsVideoBuildOptions.medium_style = stencil.name
        goodsVideoBuildOptions.medium_style_id = stencil.id
    }

    function onChangeVideoStencilCompositionStyle(stencil) {
        goodsVideoBuildOptions.composition_style = stencil.name
        goodsVideoBuildOptions.composition_style_id = stencil.id
    }

    function onChangeVideoStencilPaintStyle(stencil) {
        goodsVideoBuildOptions.paint_style = stencil.name
        goodsVideoBuildOptions.paint_style_id = stencil.id
    }

    function onChangeVideoStencilSceneStyle(stencil) {
        goodsVideoBuildOptions.scene_style = stencil.name
        goodsVideoBuildOptions.scene_style_id = stencil.id
    }

    function onChangeVideoStencilLocationStyle(stencil) {
        goodsVideoBuildOptions.location_style = stencil.name
        goodsVideoBuildOptions.location_style_id = stencil.id
    }

    function onChangeVideoStencilPersonStyle(stencil) {
        goodsVideoBuildOptions.person_style = stencil.name
        goodsVideoBuildOptions.person_style_id = stencil.id
    }

    function onChangeVideoStencilFaceStyle(stencil) {
        goodsVideoBuildOptions.face_style = stencil.name
        goodsVideoBuildOptions.face_style_id = stencil.id
    }

    function onChangeVideoStencilTagsStyle(stencil) {
        goodsVideoBuildOptions.tags_style = stencil.name
        goodsVideoBuildOptions.tags_style_id = stencil.id
    }

    function onChangeVideoScheduleFrame(frameValue) {
        // 提取分镜文本内容
        let values = ''
        for( let frame in workflowCurrentScheduleFrames.value) {
            let key = frame['frame_key']
            let value = frame['frame_value']
            if (value) {
                values = values + ',' + value
            }
        }
        if (values) {
            values = values.substring(1)
        }
        // console.log('分镜文本:'+values+'workflowCurrentScheduleFrameValues')
        workflowCurrentScheduleFrameValues.value = values
        // console.log(workflowCurrentScheduleFrameValues.value)
    }

    function onShowVideo(videoObj, refType='detail') {
        console.log(videoObj)
        console.log(refType)
        // video.value.src = videoObj.asset_url
        // video.value.play()

        if (refType == 'detail') {
            // videoCurrentUrl.value = videoObj.asset_url
            // videoCurrentThumbUrl.value = videoObj.asset_thumb_medium_url
            videoCurrentUrl.value = videoObj.url
            videoCurrentThumbUrl.value = videoObj.thumb_url
        }

        if (refType == 'collect') {
            videoCurrentUrl.value = videoObj.object_asset_url
            videoCurrentThumbUrl.value = videoObj.object_asset_thumb_medium_url
        }

        isShowVideoDialog.value = true
    }

    function onCollectTaskVideo(video, is_collected, task_id, id, task_media_site, asset_url, asset_thumb_medium_url) {
        // console.log(video)
        let params = {
            id: id,
            task_id: task_id,
            task_media_site: task_media_site,
            asset_url: asset_url,
            asset_thumb_medium_url: asset_thumb_medium_url,
            task_type: 'goods-video',
        }

        if (!is_collected) {
            createUserVideoCollect(params).then((res) => {
                video.is_collected = true
                let message = res.msg
                ElMessage({
                    message: message,
                    type: 'success',
                })
            }).catch((err) => {
                let message = res.msg
                ElMessage({
                    message: message,
                    type: 'error',
                })
                console.log(err)
            })
        } else if (is_collected) {
            destroyUserVideoCollect(params).then((res) => {
                video.is_collected = false
                let message = res.msg
                ElMessage({
                    message: message,
                    type: 'warning'
                })
            }).catch((err) => {
                let message = res.msg
                ElMessage({
                    message: message,
                    type: 'error'
                })
                console.log(err)
            })
        }
    }

    function onDownloadTaskVideo(id, url) {
        let params = {
            id: id,
            url: url,
        }
        // download(params).then((res) => {
        //     console.log(res)
        //     let link = document.createElement('a')
        //     // let fileNameEncode = res.headers['content-disposition'].split("filename=")[1];
        //     // let fileName = decodeURIComponent(fileNameEncode)
        //     let fileName = ''
        //     let blob = new Blob([res.data], {
        //     })
        //     let url = window.URL.createObjectURL(blob)
        //     link.href = url
        //     link.download = fileName
        //     link.target = '_blank'
        //     link.click()
        //     window.URL.revokeObjectURL(link.href)
        // })
        const xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.responseType = 'blob'
        xhr.onload = e => {
          // 会创建一个 DOMString，其中包含一个表示参数中给出的对象的URL。这个 URL 的生命周期和创建它的窗口中的 document 绑定。这个新的URL 对象表示指定的 File 对象或 Blob 对象。
          console.log(e)
          console.log(xhr)
          const url = window.URL.createObjectURL(xhr.response)
          const a = document.createElement('a')
          a.href = url
          a.download = 'qiumi-video.mp4'
          a.click()
        }
        xhr.send()
    }

    function onDestroyTaskVideo(id, task_id) {
        ElMessageBox.confirm('确认要删除这张图吗？', '危险提示',{
            type: 'danger',
            callback: (action) => {
                let params = {
                    id: id,
                    task_id: task_id,
                }
                destroyGoodsVideo(params).then((res) => {
                    let taskParams = {
                        id: goodsVideoTaskCurrentId.value,
                    }
                    retriveVideoTask(taskParams).then((res) => {
                        goodsVideoTaskCurrent.value = reformatTask(res.data.data)
                        onShowGoodsVideoCurrentTask(
                            goodsVideoTaskCurrentId.value,
                            goodsVideoTaskCurrent.value)
                    }).catch((err) => {
                        console.log(err)
                    })
                    
                }).catch((err) => {
                    console.log(err)
                })
            }
        })
    }

    function onToggleTaskMoreDetails() {
        isShowTaskMoreDetails.value = !isShowTaskMoreDetails.value
    }

    function onPromptImageChange(file) {
        console.log('on change')
        let prompt_is_svd = goodsVideoBuildOptions.prompt_is_svd
        let prompt_is_svd_freesize = goodsVideoBuildOptions.prompt_is_svd_freesize
        let validResult = true
        let validMessage = ''
        goodsVideoTaskCurrentImaeSizeValidated.value = true
        if (prompt_is_svd && !prompt_is_svd_freesize) {
            // svd下需要判断尺码规格是否符合64倍数的要求，也有不需要尺码规格的工作流
            goodsVideoTaskCurrentImaeSizeNeedValidate.value = true
            let reader = new FileReader()
            reader.readAsDataURL(file.raw)
            reader.onload = (e) => {
                console.log('onload callback')
                console.log(e)
                const img = e.target.result;
                const image = new Image()
                image.src=img
                
                image.onload = () => {
                    console.log('image onload')
                    let width = image.width
                    let height= image.height
                    if (width % 64 == 0 && height % 64 == 0) {
                        validResult = true
                    } else {
                        validResult = false
                        goodsVideoTaskCurrentImaeSizeValidated.value = false
                        validMessage = `当前图片长宽：${width}x${height},svd视频需要上传图片的长宽为64的倍数`
                        ElMessageBox.alert(validMessage, '提示', {
                            callback: () => {
                                return
                            }
                        })
                    }
                }
            }
        }
    }

    function onPromptImageBeforeUpload(file) {
        console.log('on before upload')
        console.log(file)
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJPG) {
            this.$message.error('图片只能是 JPG/PNG 格式!');
            return false
        }
        return isJPG;
    }

    async function onPromptImageUploadRequest(params) {
        let uploadParams = params
        let prompt_is_svd = goodsVideoBuildOptions.prompt_is_svd
        console.log(`prompt_is_svd=${prompt_is_svd}`)
        uploadParams['image_name'] = params.file.name
        uploadParams['image_type'] = params.file.type
        console.log('uploadParams=')
        console.log(uploadParams)
        goodsVideoPromptImageList.value = []

        if (goodsVideoTaskCurrentImaeSizeValidated.value) {
            uploadPromptImage(uploadParams).then((res) => {
                if(res.code == 2000) {
                    // 列表上传图的单图模el式，感觉仍然容易会让新用户操作疑惑
                    // goodsVideoBuildOptions.prompt_image = res.data.data.image_url
                    // goodsVideoBuildOptions.prompt_image_name = res.data.data.prompt_image_name
                    // goodsVideoPromptImageList.value = [{
                    //     name: '',
                    //     url: res.data.data.image_url,
                    // }]
                    // console.log(goodsVideoBuildOptions.prompt_image)
                    // console.log(goodsVideoBuildOptions.prompt_image_name)
                    // 改成单图模式
                    goodsVideoBuildOptions.prompt_image = res.data.data.image_url
                    goodsVideoBuildOptions.prompt_image_name = res.data.data.prompt_image_name
                } else {
                    console.log(res)
                    // vm.$message.warning(res.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
        } else {
            ElMessageBox.alert('请先确保上传图片的尺码规格符合当前视频风格类型的规格要求', '提示', {
                callback: () => {
                }
            })
        }
        // if (prompt_is_svd) {
        //     // svd下需要判断尺码规格是否符合64位倍数的要求
        //     let reader = new FileReader()
        //     let validResult = true
        //     let validMessage = ''
        //     console.log(params.file)
        //     reader.readAsDataUrl(params.file)
        //     reader.onload = e=>{
        //         console.log(e)
        //         const img = e.target.result;
        //         const image = new Image()
        //         image.src=img
                
        //         image.onload = _ => {
        //             let width = image.width
        //             let height= image.height
        //             if (width % 64 == 0 && height % 64 == 0) {
        //                 validResult = true
        //                 uploadPromptImage(uploadParams).then((res) => {
        //                     if(res.code == 2000) {
        //                         goodsVideoBuildOptions.prompt_image = res.data.data.image_url
        //                         goodsVideoBuildOptions.prompt_image_name = res.data.data.prompt_image_name
        //                     } else {
        //                         console.log(res)
        //                     }
        //                 }).catch((err) => {
        //                     console.log(err)
        //                 })
        //             } else {
        //                 validResult = false
        //                 validMessage = `当前图片长宽：${width}x${height},svd视频需要上传图片的长宽为64的倍数`
        //                 ElMessageBox.alert(validMessage, '提示', {
        //                     callback: () => {
        //                         return
        //                     }
        //                 })
        //             }
        //         }
        //     }
        // } else {
        //     uploadPromptImage(uploadParams).then((res) => {
        //         if(res.code == 2000) {
        //             // 列表上传图的单图模式，感觉仍然容易会让新用户操作疑惑
        //             // goodsVideoBuildOptions.prompt_image = res.data.data.image_url
        //             // goodsVideoBuildOptions.prompt_image_name = res.data.data.prompt_image_name
        //             // goodsVideoPromptImageList.value = [{
        //             //     name: '',
        //             //     url: res.data.data.image_url,
        //             // }]
        //             // console.log(goodsVideoBuildOptions.prompt_image)
        //             // console.log(goodsVideoBuildOptions.prompt_image_name)
        //             // 改成单图模式
        //             goodsVideoBuildOptions.prompt_image = res.data.data.image_url
        //             goodsVideoBuildOptions.prompt_image_name = res.data.data.prompt_image_name
        //         } else {
        //             console.log(res)
        //             // vm.$message.warning(res.msg)
        //         }
        //     }).catch((err) => {
        //         console.log(err)
        //     })
        // }        
    }

    function onPromptImageUploadExceed(files, uploadFiles) {
        ElMessageBox.alert('请先删除之前的图片后再提交', '提示', {
            callback: () => {
            }
        })
        // console.log('on exceed')
        // goodsVideoPromptImageUpload.value.clearFiles()
        // console.log(files)
        // console.log(uploadFiles)
    }

    function onPromptImageUploadPreview(e) {
        console.log(e)
        console.log('preview')
    }

    function onPromptImageUploadRemove(file, files) {
        console.log(file)
        console.log(files)
        console.log('remove')
    }

    function onPromptImageUploadSuccess(e) {
        console.log('upload success')
        console.log(e)        
        goodsVideoPromptImageUpload.value.clearFiles()
    }

</script>

<style scoped lang="scss">
    ::v-deep(.el-tabs__content){
        // overflow:hidden 会导致 分页下拉框超出部分会被.el-tabs__content隐藏
        overflow: visible;
    }
    .page {
        position: relative;
        .aside {
            position: relative;
            .list {
                position: relative;
                display: flex;
                flex-direction: column;
                .list-item {
                    display: flex;
                    flex-direction: row;
                    background-color: #fff;
                    color: #424242;
                    margin-bottom: 4px;
                    &.active {
                        background-color: #eee;
                        color: #333;
                    }
                    .item-thumb {
                        width: 48px;
                        height: 48px;
                        border-radius: 50%;
                        margin-right: 4px;
                    }
                    .item-content {
                        display: flex;
                        flex-direction: row;
                    }
                    .item-actions {

                    }
                }
            }
        }
        .main {
            position: relative;
            .section--main {
                position: relative;
            }
            .section--new-goodsvideo {
                position: relative;
                .footer--task-create {
                    background-color: #fafafa;
                    opacity: 0.7;
                    color: #212121;
                }                
            }
            .section--current-goodsvideo {
                position: relative;
            }

            .main-gutter {
            }

            .gutter-switch {
                &.active {
                    background-color: #e0e0e0;
                    color: #212121;
                }
            }

            .flag-icon {
                color: #212121;
                &.flag-icon--fin {
                    color: #000;
                }
            }
            .flag-label {
                margin-left: 4px;
                margin-right: 4px;
                &.flag-label--fin {
                    color: #000;
                }
            }
        }
        .section-header {
            border-bottom: 1px dashed #eee;
        }
        .dialog--finetune {
            .finetune-pen-switch {
                background-color: #fff;
                color: #757575;
                &.active {
                    background-color: #eee;
                    color: #424242;
                }
            }
        }
        :deep(.desc-label) {
            padding-left: 16px;
            margin-left: 16px;
        }
        :deep(.desc-content) {
            padding-left: 16px;
        }

        .aside--task-nav {

        }
        .footer--task-actions {
        }

        :deep(.el-tabs__item.is-active .label) {
            color: #212121 !important;
            font-weight: 700;
        }

        .subject-nav-btn {
            background: #f5f5f5;
            color: #737373;
            &.active {
                background-color: #737373;
                color: #f5f5f5;
            }
        }

        .video-subject {
            &.active {
                border: 1px dashed #a3a3a3;
                border-radius: 8px;
                background-color: #a3a3a3;
                color: #404040;
                ::v-deep(.el-radio__input.is-checked + .el-radio__label) {
                    color: #f5f5f5;
                }
            }
        }
    }
</style>