import axios from 'axios';
import {reqExpost,ajaxGet,ajaxPost,ajaxDelete,ajaxPut,ajaxPatch,uploadImg,downloadRes, ajaxGetDetailByID} from './request';
import {url} from './url';
// const token = 'JWT  eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzAzNjgxODQ0LCJqdGkiOiJiNmZmZDAyNzJkNmQ0NTMyYjE5YWEyNmQxNzk5YTA5OSIsInVzZXJfaWQiOiJmMzZhMTE1YmQxOWE0MTJiOTdjMjFlMWEwNWIwZDgwMiJ9.L9_X86juN-cywt-nFlxhCUFB2b7X5ZgNaxQByQC4oZc'

/*lora训练风格参数列表(get方式)*/ 

export const getLora = params=> ajaxGet({url:`lora/lora_training_config_list`,params})
// export const getLora = params=> ajaxGet({url:`lora/lora_training_config_list?lora_train_config_id=`+params.lora_train_config_id+})
/*用户创建lora训练项目和lora项目文件夹目录(post方式)*/ 
export const getLoraTaskSubmit = params=> ajaxPost({url:`lora/lora_training_task_submit`,params})

/*用户上传图片(post方式)*/
export const getLoraImageUpload = (params)=> ajaxPost({url:`lora/lora_training_image_upload/`,params})
                                                    
/*用户获取上传图片(get方式)：*/
export const getLoraUseUploadImage = params=> ajaxGet({url:`lora/lora_user_upload_image`,params})
// export const getLoraUseUploadImage = params=> ajaxGet({url:`lora/lora_user_upload_image?lora_train_project_name=`+params.lora_train_project_name+'&lora_train_config_id='+params.lora_train_config_id+'&lora_train_machine_id='+params.lora_train_machine_id})

/*用户删除上传图片(delete方式)*/
export const getLoraUploadImageDelete = params=> ajaxDelete({url:`lora/lora_user_upload_image`,params})

/*图片自动打标签(post方式)*/
export const getLoraConfigListLabel = params=> ajaxPost({url:`lora/lora_train_image_caption`,params})
/*查看用户自动打标文件(get方式)*/
export const getLoraConfigCaptionList = (params)=> ajaxGet({url:`lora/lora_image_caption_list`,params})
// export const getLoraConfigCaptionList = (name,id,uid)=> ajaxGet({url:`lora/lora_image_caption_list/?lora_train_project_name=`+name+'&lora_train_config_id='+id+'&lora_train_machine_id='+uid})                                                                 

/*图片分桶潜在空间(post方式)*/
export const getLoraCofigListSpace = params=> ajaxPost({url:`lora/bucketing_latent_caching`,params})

/*图片分桶潜在空间(get方式)*/
export const    getLoraTrainingMachine  = params=> ajaxGet({url:`lora/lora_training_machine`,params})
// export const    getLoraTrainingMachines  = params=> ajaxGet({url:`/lora/lora_training_machine?lora_train_machine_id`+params})

/*lora训练配置参数文件创建(post方式*/ 
export const getLoraConfigListCreate = params=> ajaxPost({url:`lora/lora_training_config_create`,params})

/*lora训练开始(post方式)*/ 
export const getLoraConfigListTrain = params=> ajaxPost({url:`lora/lora_training_execute`,params})

//查询训练结束
export const getLoraModelList = params=> ajaxGet({url:`lora/lora_model_list`,params})
// export const getLoraModelList = params=> ajaxGet({url:`lora/lora_model_list`,params})



/**
 我的反馈
 **/
/*我的反馈(post方式)*/
export const getFeeckback = params=> ajaxPost({url:`app/feeckback/`,params})


/*审核管理*/
/*审核列表(get方式)*/
export const getShList = params=> ajaxGet({url:`noveltweet/sh_list`,params})
// export const getShList = params=> ajaxGet({url:`noveltweet/sh_list?page=${params.page}&pagesize=${params.pagesize}`})
// export const getShList = params=> ajaxGet({url:`noveltweet/sh_list?page=${params.page}&pagesize=${params.pagesize}`})

/*审核用户*/
export const getShState = params=> ajaxPost({url:`noveltweet/sh_state`,params})