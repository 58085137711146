<template>
    <div
        class="lycontainer" 
        :class="{'ly-is-full':isFull}"
    >
        <!-- <div>
            <el-empty
                description="敬请期待"
            >
            </el-empty>
        </div> -->
        <!-- 0 -->
        <div class="page">
        <div class="top">
            <div class="steps">

                <el-steps :active="curStepIndex" finish-status="success" simple style="margin-top: 20px">
                    <el-step v-for="(item, index) in stepsData" :title="item.label" :key="index" />
                </el-steps>
            </div>

            <el-button-group v-if="curStepIndex !== 3 && !bntsHide">
                <el-button v-if="curStepIndex !== 0 && curStepIndex !== 2" size="large" plain @click="prev"
                    :disabled="curStepIndex === 0">
                    <el-icon class="el-icon--right">
                        <ArrowLeft />
                    </el-icon>
                    上一步

                </el-button>
                <el-button type="primary" size="large" @click="next" :disabled="disabledNextBtn">
                    <span v-if="curStepIndex === 2">合成视频</span>
                    <span v-else>下一步</span>
                    <el-icon class="el-icon--right">
                        <ArrowRight />
                    </el-icon>
                </el-button>
            </el-button-group>

            <el-button style="margin-left: 12px;" type="primary" size="large" @click="fastSubmit" v-if="curStepIndex === 1">
                快速合成
            </el-button>


        </div>
        <div class="body">
            <KeepAlive>
                <component :is="stepsData[curStepIndex].component" :ref="componentRef" @hideBtns="hideBtns"
                    @setStep="setStep"></component>
            </KeepAlive>
        </div>
    </div>
    </div>
</template>

<script setup>
import { useRoute } from "vue-router"
import { ref, shallowRef, inject, onMounted, nextTick, computed } from "vue"
import { ElMessage, ElLoading } from 'element-plus'
import Step1 from "./Steps/Step1/index.vue"
import Step2 from "./Steps/Step2/index.vue"
import Step3 from "./Steps/Step3/index.vue"
import Step4 from "./Steps/Step4/index.vue"

// 0
let isFull = ref(false)
    function setFull(){
        isFull.value=!isFull.value
        window.dispatchEvent(new Event('resize'))
    }
const myFetch = inject('myFetch')
const routeInfo = useRoute();

const getUserId = inject('getUserId')

// 当前创作步骤
const curStepIndex = ref(0);
const curStepFormIns = ref();

// 上一步下一步按钮是否隐藏
const bntsHide = ref(false);
function hideBtns() {
    bntsHide.value = true;
}

// 创作步骤数据
const stepsData = ref([
    { label: "内容风格", component: shallowRef(Step1) },
    { label: "角色分镜", component: shallowRef(Step2) },
    { label: "分镜绘图", component: shallowRef(Step3) },
    // { label: "完成", component: shallowRef(Step4) },
])

// 每个步骤的数据
const stepsValue = ref({
    step1: {},
    step1_res: {},
    step2: {},
    step2_res: {},
    step3: {},
    step3_res: {},
    step4: {},
});

// 上一步点击
async function prev() {
    curStepIndex.value -= 1;
}

async function setStep(step) {
    curStepIndex.value = step;
}

const disabledNextBtn = computed(() => {
    if (curStepIndex === stepsData.length - 1) return true;
    if (curStepIndex.value === 0) {
        const values = curStepFormIns.value?.getValuesSync?.();
        return !values?.["value1"];
    }
    return false;
})


// 下一步点击
async function next() {
    let values = {};
    if (curStepIndex.value === 2) {
        // 步骤 3 没有 getValues 
    } else {
        values = await curStepFormIns.value.getValues();
    };

    // 第一步
    if (curStepIndex.value === 0) {

        const loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
        })
        // console.log("values:", values)
        stepsValue.value["step1"] = values;
        const { data, code, msg } = await myFetch("noveltweet/article_split", {
            text: values["value1"]
        }, {}, "POST");
        loading.close();
        if (code === 2000) {
            stepsValue.value["step1_res"] = data;
            curStepIndex.value += 1;
            await nextTick();
            curStepFormIns.value.setTableDataByTextList(data.list);
        } else {
            ElMessage.error(msg);
        }
        return;
    }


    // 第二步
    if (curStepIndex.value === 1) {
        // console.log("values:", values);
        let emptyDescIndex;
        values.forEach((item, index) => {
            if (!item.desc) {
                emptyDescIndex = index + 1;
            }
        });
        if (emptyDescIndex) {
            ElMessage.error(` 第 ${emptyDescIndex} 行，画面关键词必须填写`);
            return;
        }
        stepsValue.value["step2"] = values;
        const loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
        })
        const { data, code, msg } = await myFetch("noveltweet/task", {
            text_split: values.map(item => item.desc),
            text_split_role: values.map((item) => {
                return item.person.map((item) => {
                    return {
                        role_name: item.label,
                        role_id: item.value,
                    }
                })
            }),
            "dub": stepsValue.value["step1"]["value5"]?.value, // 角色
            "dub_speed": stepsValue.value["step1"]["value5"]?.speed || 1, // 语速
            "screen_style": stepsValue.value["step1"]["value4"]?.value,
            "videoscale": stepsValue.value["step1"]["value3"],
            "music": stepsValue.value["step1"]["value6"]?.value,
            "typeface": stepsValue.value["step1"]["value7"]?.value,
            "user_id": getUserId(),
            "is_automatic": 2, // 1快速合成 2下一步
            "dub_volume": "1",
            "show_captions": "1",
        }, {}, "POST");
        loading.close();
        if (code === 2000) {
            stepsValue.value["step2_res"] = data;
            curStepIndex.value += 1;
            await nextTick();
            curStepFormIns.value.refresh(data);
        } else {
            ElMessage.error(msg);
        }
        return;
    }

    // 第三步
    if (curStepIndex.value === 2) {
        // console.log("values:", values)
        stepsValue.value["step2"] = values;
        const loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
        })
        const { data, code, msg } = await myFetch("noveltweet/task_state", {
            "type": "2",
            "task_id": stepsValue.value["step2_res"]["task_id"],
        }, {}, "PUT");
        loading.close();
        if (code === 2000) {
            stepsValue.value["step3_res"] = data;
            curStepFormIns.value.endLoading();
            curStepFormIns.value.refresh(stepsValue.value["step2_res"]);
            // curStepIndex.value += 1;
            // await nextTick();
            // curStepFormIns.value.refresh(data);
        } else {
            ElMessage.error(msg);
        }
        return;
    }
}

// 快速合成
async function fastSubmit() {
    const values = await curStepFormIns.value.getValues();


    let emptyDescIndex;
    values.forEach((item, index) => {
        if (!item.desc) {
            emptyDescIndex = index + 1;
        }
    });
    if (emptyDescIndex) {
        ElMessage.error(` 第 ${emptyDescIndex} 行，画面关键词必须填写`);
        return;
    }

    stepsValue.value["step2"] = values;
    const { data, code, msg } = await myFetch("noveltweet/task", {
        text_split: values.map(item => item.desc),
        text_split_role: values.map((item) => {
            return item.person.map((item) => {
                return {
                    role_name: item.label,
                    role_id: item.value,
                }
            })
        }),
        "dub": stepsValue.value["step1"]["value5"]?.value, // 角色
        "dub_speed": stepsValue.value["step1"]["value5"]?.speed || 1, // 语速
        "screen_style": stepsValue.value["step1"]["value4"]?.value,
        "videoscale": stepsValue.value["step1"]["value3"],
        "music": stepsValue.value["step1"]["value6"]?.value,
        "typeface": stepsValue.value["step1"]["value7"]?.value,
        "user_id": getUserId(),
        "is_automatic": 1, // 1快速合成 2下一步
        "dub_volume": "1",
        "show_captions": "1",
    }, {}, "POST");
    if (code === 2000) {
        stepsValue.value["step2_res"] = data;
        curStepIndex.value = 2;
        hideBtns();
        await nextTick();
        curStepFormIns.value.refresh(data);
    } else {
        ElMessage.error(msg);
    }
    //     const { success, msg } = await myFetch("xxx", {}, { verifyToken: true });
    //     if (success) {
    //          curStepIndex.value = 3;
    //     } else {
    //           ElMessage.error(msg);
    //     }
}

function componentRef(ref) {
    curStepFormIns.value = ref;
}


// 刷新数据
async function refresh() {
    // 从路由参数传值
    const { step = 0, task_id } = routeInfo.query
    curStepIndex.value = +step;
    if (`${step}` === "2" || `${step}` === "3") {
        stepsValue.value["step2_res"]["task_id"] = task_id;
        await nextTick();
        curStepFormIns.value.refresh({ task_id });
    }

    // curStepIndex.value = 2;

    // 可能用不到了
    // const { success, msg, data } = await myFetch(apiName, {});
    // if (success) { 
    // } else {
    //     ElMessage.error(msg);
    // }
}

onMounted(() => {
    refresh();
})



</script>

<styule scoped  lang="scss">
.page{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.el-steps--simple{
    background-color: transparent;
}

.page > .top{ 
    display: flex;
    align-items: center;
}
.page > .top .steps{
    flex: 1;
    padding: 12px 0px;
}
.page > .top .steps .el-steps{
    margin-top: 0px !important;
}
.page > .body{
    flex: 1;
    /* border: 1px solid red; */
    box-sizing: border-box;
}
</styule>