<template>
    <div class="pageGen">
        <div class="actionBtns">
            <el-button link type="primary" @click.prevent="autoSetting" >
                自动配置角色
            </el-button>
            <el-button link type="primary" :icon="Plus" @click.prevent="addPerson">
                新增角色
            </el-button>
        </div>
        <el-table :data="tableData" style="width: 100%" :border="true">
            <el-table-column label="镜号" type="index" width="60" align="center" />
            <!-- <el-table-column prop="desc" label="原文描述" /> -->
            <el-table-column prop="desc" label="画面关键词">
                    <template #default="scope">
                        <el-input v-model="scope.row.desc" type="textarea" placeholder="请输入..."
                            :autosize="{ minRows: 3, maxRows: 4 }" />
                    </template>
                </el-table-column>
            <el-table-column prop="person" label="画面角色" width="200">
                <template #default="scope">
                    <div v-for="index in 4" :key="index">
                        <span>角色{{ index }}：</span>
                        <el-button link :icon="EditPen" size="small"
                            @click.prevent="settingPerson(scope.row, scope.$index, index - 1)"
                            v-if="!scope.row.person?.[index - 1]">
                            手动设置
                        </el-button>
                        <el-button link type="primary" :icon="EditPen" size="small"
                            @click.prevent="settingPerson(scope.row, scope.$index, index - 1)" v-else>
                            {{ scope.row.person[index - 1]?.label }}
                            <span style="margin-left: 14px;"
                                @click="cancelPerson(scope.row, scope.$index, index - 1)">取消</span>
                        </el-button>
                    </div>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="120" align="center">
                <template #default="scope">
                    <el-button link type="primary" :icon="Plus" size="small" @click.prevent="onAddItem(scope.$index)">
                        插入分镜
                    </el-button> 
                    <br/>
                    <el-button style="margin-top: 12px;" link type="danger" :icon="Delete" size="small" @click.prevent="deleteRow(scope.$index)">
                        删除分镜
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>


    <!-- 角色新增弹出 -->
    <el-dialog destroy-on-close v-model="addPersonModalShow" title="新增角色" align-center width="800px" class="my-modal"
        :close-on-click-modal="false">

        <div class="modal-content">
            <PersonAdd ref="addPersonIns" />
        </div>

        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="onOkByAddPerson">
                    确定
                </el-button>
            </span>
        </template>
    </el-dialog>

    <!-- 角色库弹出 -->
    <el-dialog destroy-on-close v-model="selectPersonModalShow" title="角色选择" align-center width="800px" class="my-modal"
        :close-on-click-modal="false">

        <div class="modal-content">

            <div style="text-align: right;background-color: #f5f7f9;padding-top: 18px;padding-right: 20px;">
                <el-button type="primary" :icon="Plus" @click.prevent="addPerson(true)">
                    新增角色
                </el-button>
            </div>

            <PersonSelect ref="selectPersonIns" @editPerson="editPerson" />
        </div>

        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="onOkBySetting">
                    确定
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<style src="./style.css" scoped></style>
<script setup>
import { ref, inject, onMounted, nextTick } from "vue"
import { ElMessage, ElLoading } from 'element-plus'
import { EditPen, Delete, Plus } from '@element-plus/icons-vue'
import PersonAdd from './PersonAdd/index.vue'
import PersonSelect from './PersonSelect/index.vue'
const emits = defineEmits(["hideBtns", 'setStep'])
const myFetch = inject('myFetch')
const getUserId = inject('getUserId')

const addPersonModalShow = ref(false);
const editPersonData = ref(''); // 当前编辑的角色数据
const selectPersonModalShow = ref(false);
const addPersonIns = ref();
const selectPersonIns = ref();

// 是否是在角色选择页面打开的新增角色页面、编辑角色页面
const isSelectPageOpen = ref();

// 当前操作的分镜数据
const curRowData = ref();

// 表格数据
const tableData = ref([])

// 当前要带入到角色选择回显的id
const curReShowPersonId = ref()

// 删除数据
const deleteRow = (index) => {
    tableData.value.splice(index, 1)
}

// 新增数据
const onAddItem = (index) => {
    tableData.value.splice(index + 1, 0, {
        person: [],
        desc: "",
    })
}
// 取消角色绑定
function cancelPerson(rowData, rowIndex, personIndex) {
    tableData.value[rowIndex].person[personIndex] = ''
    tableData.value = [...tableData.value]
}
// 角色设置按钮点击
async function settingPerson(rowData, rowIndex, personIndex) {
    curRowData.value = {
        ...rowData,
        rowIndex: rowIndex,
        personIndex: personIndex
    };
    selectPersonModalShow.value = true;
    await nextTick();
    // console.log(rowData)
    selectPersonIns.value.setValues(rowData?.["person"]?.[personIndex]?.value);
}
// 角色设置 确定
async function onOkBySetting() {
    const { rowIndex, personIndex } = curRowData.value
    const formValues = await selectPersonIns.value.getValues();
    tableData.value[rowIndex].person[personIndex] = {
        label: formValues.name,
        value: formValues.id,
    }
    tableData.value = [...tableData.value]
    selectPersonModalShow.value = false;
}

// 新增角色
// isSelectPageOpen 是否是角色选择页面打开的
async function addPerson(_isSelectPageOpen) {
    editPersonData.value = "";
    isSelectPageOpen.value = _isSelectPageOpen;
    addPersonModalShow.value = true;
}
// 新增角色 确定
async function onOkByAddPerson() {
    const formValues = await addPersonIns.value.getValues();
    if(!formValues) return; 
    
    // addPersonModalShow.value = false;
    // console.log(formValues)

    const _editPersonData = editPersonData.value;
    const isEdit = !!_editPersonData;
    const { code, msg, data } = await myFetch("noveltweet/role_edit", {
        ...formValues,
        "user_id": getUserId(),
        role_id: isEdit ? _editPersonData.role_id : undefined
    }, {}, "POST");
    if (code === 2000) {
        ElMessage.success("新增成功");
        addPersonModalShow.value = false;
        if (isSelectPageOpen.value) {
            // 刷新角色数据
            await selectPersonIns.value.refresh(data.role_id); 
        }
    } else {
        ElMessage.error(msg);
    }
}

// 编辑角色 
async function editPerson(data) {
    isSelectPageOpen.value = true;
    editPersonData.value = data;
    addPersonModalShow.value = true;

    console.log("---", { ...data })
    await nextTick()
    addPersonIns.value.setValues({
        alias_name: data.alias,
        ...data
    }); 
}

// 自动配置
async function autoSetting() {
    const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    const { code, msg, data } = await myFetch("noveltweet/role_automatic", {
        user_id: getUserId(),
        text_split: tableData.value.map(item => item.desc),
        text_split_role: tableData.value.map((item) => {
            return item.person.map((item) => {
                return {
                    role_name: item.label,
                    role_id: item.value,
                }
            })
        })
        // "text_split": [
        //     "李二狗一个人走在路上",
        //     "小明天空喜爱了大雪"
        // ],
        // "text_split_role": [
        //     [],
        //     [
        //         {
        //             "role_id": 1,
        //             "role_name": "李二狗"
        //         }
        //     ]
        // ]
    }, {}, "POST");
    loading.close();
    if (code === 2000) {
        // 设置表格数据
        tableData.value = tableData.value.map((item, index) => {
            const persons = data.text_split_role_list[index] || [];
            return {
                ...item,
                person: persons.map(({ role_id, role_name }) => {
                    return {
                        label: role_name,
                        value: role_id,
                    }
                }),
            }
        })
        // tableData.value = [...data];
    } else {
        ElMessage.error(msg);
    }
}

// 刷新列表数据
async function refresh() {
    tableData.value = [
        // {
        //     person: [
        //         // { label: "小美", value: "01" },
        //         // { label: "小帅", value: "02" }
        //     ],
        //     desc: "1-男人急促的呼吸声在耳边萦绕，安以甜知道自己又做梦了。",
        // },
        // {
        //     person: [],
        //     desc: "2-男人急促的呼吸声在耳边萦绕，安以甜知道自己又做梦了。",
        // },
    ];
}

onMounted(() => {
    refresh();
})

const getValues = async () => {
    return new Promise((resolve) => {
        resolve([...tableData.value])
    })
}
defineExpose({
    getValues,
    setTableDataByTextList: (data) => {
        tableData.value = data.map((text) => ({
            person: [],
            desc: text
        }))
    }
})

</script>