<template>
    <el-dialog title="提示" :show-close="false" v-model="drawIng" align-center width="750px" class="my-modal"
        :close-on-press-escape="false" :close-on-click-modal="false">
        <div class="pageGen">
            <div class="title">视频生成中…</div>
            <div class="desc">视频正在制作中，预计15分左右完成，可稍后到 个人中心 - 我的创作 - 全部 内容中查看您的视频创作</div>
            <div class="btns">
                <el-button style="margin-left: 12px;" type="primary" size="large" @click="toMyChuangZuo">
                    我的创作
                </el-button>
            </div>
        </div>
    </el-dialog>
</template>

<style src="./style.css" scoped></style>
<script setup>
import { ref, onUnmounted, inject } from "vue"
import { useRouter } from "vue-router"
import { ElMessage, ElLoading } from 'element-plus'
const emits = defineEmits(["hideBtns"])
const myFetch = inject('myFetch')
const router = useRouter()

const drawIng = ref(true);

const refreshTimer = ref('');
const paramsData = ref({})

function toMyChuangZuo() {
    router.push({
        path: '/MeChuanZhuo',
    });
}

// 刷新列表数据
async function refresh(params) {
    paramsData.value = params;
    const { code, msg, data } = await myFetch(`noveltweet/task_info?task_id=${params?.task_id}`);
    if (code === 2000) {
        if (data.is_automatic + '' === "1" && data.state + '' === "4") {
            // 跳转到“我的创作”页面
            toMyChuangZuo();
        } else {
            // 分镜绘图正在处理中
            drawIng.value = true;
            clearTimeout(refreshTimer.value);
            refreshTimer.value = setTimeout(() => {
                refresh(paramsData.value)
            }, 3000)
        }

    } else {
        ElMessage.error(msg);
    }
}


onUnmounted(() => {
    clearTimeout(refreshTimer.value)
})
defineExpose({ refresh })
</script>