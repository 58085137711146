
import moment from "moment";
//引入token
import {getStorage} from '@/utils/util'
const uploadToken = getStorage('logintoken')
let uploadHeaders = {Authorization: 'JWT ' + uploadToken}
// const { appInfo } = window.configs;
		// console.log(appInfo);
// const { closeTabClear } = storage;
// const storageFn = closeTabClear ? sessionStorage : localStorage;
const storageFn =  localStorage;


/**
 * 设置缓存项
 * @param {String} name 缓存项名称
 * @param {Object} data 缓存项数据
 * @param {Boolean} isUpdate 是否是更新缓存项
 */
const setItem = (name, data, isUpdate) => {
	return new Promise((resolve) => {
		if (data) {
			let _data = {};
			if (typeof data === "object") {
				_data = {
					t: "object",
					d: JSON.stringify(data),
					m: moment().valueOf(),
				};
			} else {
				_data = {
					t: "string",
					d: data.toString(),
					m: moment().valueOf(),
				};
			}
			// 更新的情况下不去删除
			if (isUpdate) {
				storageFn.setItem(`${uploadHeaders}_${name}`, JSON.stringify(_data));
				resolve(data);
			} else {
				removeItem(name).then(() => {
					storageFn.setItem(`${uploadHeaders}_${name}`, JSON.stringify(_data));
					let timer = setInterval(() => {
						if (!!storageFn.getItem(`${uploadHeaders}_${name}`)) {
							resolve(data);
							clearInterval(timer);
						}
					}, 10);
				});
			}
		} else {
			removeItem(name).then(() => {
				resolve(data);
			});
		}
	});
};
const getItem = (name) => {
	let _data = storageFn.getItem(`${uploadHeaders}_${name}`);

	if (_data) {
		let { t, d, m } = JSON.parse(_data);
		// let isTimeout = storage.timeout + m < moment().valueOf();
		let data = t === "object" ? JSON.parse(d) : d;
		return {
			// isTimeout,
			data,
		};
	} else {
		return null;
	}
};
/**
 * @sync 是否采用异步形式，需要使用 await 等待，否则定时器将会导致内存溢出
*/
const removeItem = (name, sync = true) => {
	return new Promise((resolve) => {
		storageFn.removeItem(`${uploadHeaders}_${name}`);
		if(!sync) return;
		let timer = setInterval(() => { 
			if (!storageFn.getItem(`${uploadHeaders}_${name}`)) {
				resolve();
				clearInterval(timer);
			}
		}, 10);
	});
};
const clear = storageFn.clear;
const MyStorage = {
	setItem,
	getItem,
	removeItem,
	clear,
};
export default MyStorage;
