<template>
  <div class="page page--video-manage lycontainer" :class="{'ly-is-full':isFull}">
    <div class="mode_content">
    <el-row class="model_step">
      <el-col :span="20">
      <!-- 步骤条 --> 
        <el-steps :active="activeModelNumber" finish-status="success"  >
            <el-step title="创建模型" class="el-step__arrow"/>
            <el-step title="添加训练数据"  class="el-step__arrow"/>
            <el-step title="模型训练" />
          </el-steps>
      </el-col>
      <el-col :span="4">
        <el-button style="margin-top: 12px;" :class="[ modelSingleStep === '上一步'?'active-button':'active-but']" @click="onmodelStepsNext" type="info" v-if="activeModelNumber<2">{{ modelSingleStep }}</el-button>
        <!-- v-if="trainBut" :disabled=modelDisable-->
        <el-tooltip class="box-item" effect="light"  content="必须5张才可以开始" placement="bottom"   >
    <el-button :class="['train_but',modelDisabled?'':'active-train_but']" type="info" @click="onstartedTraining" v-show="activeModelNumber >= 1"  :disabled="modelDisabled" >{{ startedText }}</el-button>
    </el-tooltip>
      </el-col>
    </el-row>
<!-- 0 -->
<el-form  label-width="120px"  v-show="activeModelNumber == 0" :hide-required-asterisk="true" label-position="top" ref="modelFormRef" :model="modeleruleForm" :rules="modelRules">
  <div style="margin-bottom: 20px;">
      <!-- <p>* 选择模型风格 <span style="color: red;" v-show="modeleruleForm.lora_train_config_id<=-1"> 请点击选择模型分类</span></p> -->
      <p>* 选择模型风格 </p>
    <div style="display: flex;justify-content: space-evenly;">
    <div  :class="['model_image',modeleruleForm.lora_train_config_id ===itemImg.id?'active':'']" v-for="itemImg in modeleActiveImage" :key="itemImg.id" @click="activeIMg(itemImg.id)">
      <!-- <el-image :src="itemImg.url"  class="model_img"  fit="fill" loading="eager"></el-image> -->
      <!-- <img :src="itemImg.url"  class="model_img"  /> -->
      <el-image :src="itemImg.url"  class="model_img"  fit="fill"></el-image>
      <div class="model_text">
        <h1 >{{ itemImg.training_type}}</h1>
      <p class="model_text_p">{{ itemImg.text }}</p>
      </div>  
    </div>
  </div>
  </div>
  <!-- <el-form-item label="* 请给你的模型起个名字" label-width="100px" prop="lora_train_project_name"> -->
  <el-form-item label="* 填写模型名字" label-width="100px" prop="lora_train_project_name">
    <el-input  size="large" placeholder="请给你的模型起个名字— 判定英文字符，并且唯一" v-model="modeleruleForm.lora_train_project_name" clearable/>
  </el-form-item>
</el-form>
<!-- 1 -->
<div v-show="activeModelNumber >= 1">
<!-- 上传图片区域 --> 
<div class="model_upload">
<el-row class="model_upload_row" >
<el-col :span="19">
  <!-- <span  style="color: #616161;"> * 图片上传（{{modelFileList.length}}/10-200）<span style="color: red;">6张图片上传失败</span></span> -->
  <!-- <span  style="color: #616161;"> * 图片上传（{{modelFileList.length}}/10-200）</span> -->
  <span  style="color: #616161;"> * 图片上传（{{modelFileList.length}}/10-100）</span>
</el-col>
<el-col :span="5">
  <!-- <div v-if="!modelFileList.length">
    <el-button class="upload_row_one">请标签图片</el-button>
  <el-button class="upload_row_tow">*打标签</el-button>
  </div> -->
</el-col>
</el-row>

<!-- 自动上传图片 action="#" :http-request="modelHttpReuest" :auto-upload="modeleAutoUpload" :disabled="disabledUpload" :limit="limitFile"-->
<el-upload
v-loading="loading"
element-loading-text="loading"
element-loading-svg=""
element-loading-background="rgba(0, 0, 0, 0.7)"
class="model_uploadis"
ref="uploadRef"
drag
multiple
:file-list = "modelFileList"
:action="actionUrl"
:headers="uploadHeaders"
:data="dataUpload"
accept="image/jpg,image/jpeg,image/png"
:show-file-list="false"
:before-upload="beforeAvatarUpload"
:on-exceed="handleExceed"
:on-success="handleSuccess"
:on-error="hanleError"
:on-progress="hanleProgress"
:disabled="disabledUpload"
>
<div v-if="modelFileList.length"  class="upload_ismy">
<div class="upload_url" v-for="(itemImg,index) in modelFileList" :key="index">
  <!-- <el-text class="upload_url_text" ><p>513*1100</p><span @click.stop="onUploadDelete(index,itemImg.name)">x</span></el-text> -->
  <el-text class="upload_url_text" ><span @click.stop="onUploadDelete(index,itemImg.name)">x</span></el-text>
  <el-image :src="(itemImg.url)"   fit="file" :lazy="true" ></el-image>
  <!-- <p class="upload_url_p">{{ modeleUploadruleForm.modelUplaodValue }}</p> -->
  <p class="upload_url_p">{{ modelUplaodValue }}</p>
</div>
</div>
<div v-else>
  <el-icon class="el-icon--upload"><upload-filled /></el-icon>
<div class="el-upload__text"  >
  <em>点击或拖拽上传图片</em>
</div>
  <div class="el-upload__tip">
    支持png/jpg/jpeg
  </div>
  <div class="el-upload__tip">
    图片最少10张，最多200张，体积小于500m
  </div>
  <div class="el-upload__tip">
      <div class="upload_img">
        <div v-for="itemImg in modelUploadUrl" class="upload_img_url" >
    <el-image style="width: 50px; height: 50px;border-radius: 5px;" :src="itemImg.url" />
          <div class="upload_p">{{ itemImg.name }}</div>
        </div>
      </div>
  </div>
</div>
<!-- 进度条 -->
<div class="demo-progress"  v-if="modeleProgressClose">
<el-progress :text-inside="true" :stroke-width="16" :percentage="modelProgressTrends" />
<span>图片上传中%：{{modelProgressTrends}}%</span>
</div>
<!-- 开始进度条 -->
<div class="demo-progress" v-if="endProgressClose">
<el-countdown title="​训练中 预计完成还需要" :value="endTimeValue" />
<el-progress :text-inside="true" :stroke-width="16" :percentage="endProgressTrends" />
</div>
<!-- 位置上传 -->
<!-- <div class="upload_button" v-if="modelFileList.length"> -->
<div class="upload_button"  v-if="modelDisableTrue">
      <!-- <el-button  @click.stop="submitUpload">重新上传</el-button> -->
      <el-button  @click.stop="submitUpload">删除全部图片</el-button>
      <el-button >+ 添加图片</el-button>
    </div>
</el-upload>
</div>
<!-- 模型触发词 区域 -->
<!-- <el-form  label-width="120px"  label-position="top" ref="modelUploadFormRef" :model="modeleUploadruleForm" :rules="modelUploadRules" :hide-required-asterisk="true">
  <el-form-item label="* 模型触发词" label-width="100px" prop="modelUplaodValue" v-if="true"> 
    <el-input   size="large" placeholder="请输入模型触发词（英文或数字，必须唯一且不可和标签重复)"  v-model="modeleUploadruleForm.modelUplaodValue" style="width: 90%;margin-right: 20px;" clearable/>
    <el-button type="info" @click="onAgainMarking"  size="large" :disabled="modelDisabled" :class="[modelDisabled?'':'active-train_but']" >打标</el-button>
  </el-form-item> -->
  <!-- <el-form-item label="模型触发词" label-width="100px" prop="modelUplaodValue" v-else>
    <el-input   size="large"  placeholder="请输入模型触发词（英文或数字，必须唯一且不可和标签重复)"  v-model="modeleUploadruleForm.modelUplaodValue" style="width: 90%;margin-right: 20px;" clearable/>
    <el-button type="info" @click="onAgainMarking">重新打标</el-button>
  </el-form-item> -->
<!-- </el-form> -->
</div>  
<!-- 开始训练 -->
<el-dialog
  v-model="startedTrainingDialogVisible" title="模型完成"  width="30%" center>
  <span>当前训练已经结束，可直接应用该模型到文生图模型测试。可到 <span style="color: #409EFF;">个人中心 - 我的创作 - 我的模型</span> 使用模型</span>
  <template #footer>
    <span class="dialog-footer">
      <el-button type="info" @click="onStartedTrainingDialogVisible" >模型生图测试</el-button>
    </span>
  </template>
</el-dialog>
<!-- 结束训练 -->
<el-dialog center v-model="finishTrainDialogVisible"  title="停止训练" width="50%">
  <span>确定要停止训练，如果停止，当前训练将不被保存</span>
  <template #footer>
    <span class="dialog-footer">
      <el-button type="primary" @click="onFinishTrainDialogVisible">确认</el-button>
    </span>
  </template>
</el-dialog>
<div class="modelBottom"></div>
</div>
</div>
</template>
<script setup>
import {ref,reactive,onMounted,computed, nextTick}from 'vue'
import { API_URL } from '../../../config/index'
let isFull = ref(false)
//引入api
import {getLora,getLoraTaskSubmit,getLoraImageUpload,getLoraUseUploadImage,getLoraUploadImageDelete,getLoraConfigListLabel,getLoraConfigCaptionList,getLoraCofigListSpace,getLoraConfigListCreate,getLoraConfigListTrain,getLoraModelList,getLoraTrainingMachine} from '@/api/train'
//element-puls引入
import { ElMessage,ElLoading } from 'element-plus'
//引入token
import {getStorage} from '@/utils/util'
const uploadToken = getStorage('logintoken')
const file = ref([])
//loging
let loading = ref(false)
//显示按钮
const modelDisableTrue = ref(true)
//禁用按钮按钮
const modelDisabled = ref(true)
//总数据
const modeleList = ref([])
//定时器
let modelMarkingTime = ref(null)
//定时器2
let modeleTime = ref(null)
let disabledUpload = ref(false)
//是否自动上传文件
const modeleAutoUpload = ref(true)
//打标查看文件
const modelTaskstatus = ref('captioning')
//请求路径
let actionUrl = ref(API_URL+'lora/lora_training_image_upload')

// console.log(actionUrl.value);
//请求头
let uploadHeaders = reactive({Authorization: 'JWT ' + uploadToken})
//上传图片的节点
const uploadRef = ref()
//下一步和上一步
let modelSingleStep = ref('下一步') 
//开始训练弹框
const startedTrainingDialogVisible = ref(false)
//结束训练弹框
const finishTrainDialogVisible = ref(false)
//禁用开始训练
const modelDisable = ref(true)
//开始动态显示条
const endProgressTrends = ref(80)
//进度条上传时间
const endTimeValue = ref(Date.now() + 1000 * 60 * 60 * 0.1)
//点击开始进度条显示
const endProgressClose = ref(false)
//开始训练文本
const startedText = ref('开始训练')
//控制上传图片数量
const limitFile = ref(100)
//动态显示进度条
const modelProgressTrends = ref(0)
//关闭进度条
const  modeleProgressClose = ref(false)
//图片上传中图集展示
const modelUploadUrl = ref([
{url:'https://liblibai-online.oss-cn-beijing.aliyuncs.com/train/%E4%BA%BA%E5%83%8F.png?x-oss-process=image%2Fresize%2Cw_200%2Fformat%2Cwebp',name:'画面清晰 '},
{url:'https://liblibai-online.oss-cn-beijing.aliyuncs.com/train/%E7%94%BB%E9%A3%8E.png?x-oss-process=image%2Fresize%2Cw_200%2Fformat%2Cwebp',name:'宽高>1024px'},
{url:'https://liblibai-online.oss-cn-beijing.aliyuncs.com/train/ACG.png?x-oss-process=image%2Fresize%2Cw_200%2Fformat%2Cwebp',name:'人物不要有遮挡'},
{url:'https://liblibai-online.oss-cn-beijing.aliyuncs.com/train/%E4%BA%BA%E5%83%8F.png?x-oss-process=image%2Fresize%2Cw_200%2Fformat%2Cwebp',name:'不要有水印文字'},
{url:'https://liblibai-online.oss-cn-beijing.aliyuncs.com/train/%E7%94%BB%E9%A3%8E.png?x-oss-process=image%2Fresize%2Cw_200%2Fformat%2Cwebp',name:'光线不要过度曝光'},
])
//图片列表对象
const modelFileList = ref([])
//表单对象
const modeleruleForm = reactive({
lora_train_project_name:'',
lora_train_config_id:'',
lora_train_machine_id:'',
})
const modeleUploadruleForm = reactive({
modelUplaodValue:''
})
//打标签
let modelUplaodValue = ref(modeleruleForm.lora_train_project_name)
//表单验证规则
const modelRules = reactive({
lora_train_project_name: [
  { required: true, message: '请添加模型名字', trigger: 'blur' },
  { pattern: /^[a-zA-Z0-9]+$/, message: '模型名字，必须只能填写英文字符', trigger: 'blur' }
  // { min: 3, max: 5, message: 'Length should be 3 to 5 ', trigger: 'blur' },
],
// lora_train_config_id: [
  // { required: true, message: '请选择模型分类', trigger: 'blur' },
  // { validator:lora_train_config_idPass, trigger: 'blur' },
// ],
})
const modelUploadRules = reactive({
modelUplaodValue:[
  { required: true, message: '请添加模型触发词', trigger: 'blur' },
  // { pattern: /^\w+$/, message: '模型触发词，必须是英文和数字，且不能与标签重复', trigger: 'blur' }
  { pattern: /^(?!_)(?!.*?_$)[a-zA-Z0-9_]+$/, message: '模型触发词，必须是英文和数字，且不能与标签重复', trigger: 'blur' }
  // { pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w@]{8,}$/, message: '模型触发词，且不能与标签重复，必须是英文和数字', trigger: 'blur' }
],
})
//form节点
const modelFormRef = ref()
const modelUploadFormRef = ref()
//步骤数值
const activeModelNumber = ref(0)
//点击图片值
const activenum = ref(0)
const dataUpload = ref()
//训练风格数据
const activeImage = ref([
{url:require('../../../assets/通用3.jpg'),text:'可用于任何用途，物品，人像，艺术，动漫和真实现实效果等'},
{url:require('../../../assets/真实3.jpg'),text:'可用于动漫ACG二次元艺术效果'},
{url:require('../../../assets/2d.jpg'),text:'可用于电商实物真实现实效果'},
])
const modeleActiveImage = ref([])
//点击下一步提交按钮显示
const trainBut = ref(false)
//步骤进行下一步事件
const onmodelStepsNext = () => {
if(activeModelNumber.value === 0){
  // if(modeleruleForm.lora_train_config_id === -1)return false
    onmodelFormInput()
  return false
}else if(activeModelNumber.value === 1){
  modelSingleStep.value = '下一步'
  activeModelNumber.value = 0
  return false
}

// activeModelNumber.value = 3
// if (activeNumber.value++ > 2) activeNumber.value = 0
// trainBut.value  = true
}
//处理表单数据1111111111111
const onmodelFormInput = ()=>{
modelFormRef.value.validate(async(valdid)=>{
      if(modeleruleForm.lora_train_config_id === '')return ElMessage.warning('请点击图片选择需要的模型分类')
      if(!valdid)return ElMessage.warning('请输入模型名字')
      const loraObj = {lora_train_project_name:modeleruleForm.lora_train_project_name,lora_train_config_id:modeleruleForm.lora_train_config_id}
        // let obj = {lora_train_project_name:'qwe',lora_train_config_id:2}
        const data = await getLoraTaskSubmit(loraObj)
            // console.log(data);console.log(data.data.data);
            console.log(data);
        if(data.code !== 2000)return ElMessage.error(data.msg)
        if(data.data.data === 0)return ElMessage.error("模型名字已经创建过！")
          // ElMessage.success("模型名字创建成功")
        modeleruleForm.lora_train_machine_id =  data.data.data 
        dataUpload.value = {...loraObj,...{lora_train_machine_id:modeleruleForm.lora_train_machine_id}}
        console.log(dataUpload.value);
        console.log('lora_train_machine_id',modeleruleForm.lora_train_machine_id)
        activeModelNumber.value = 1
        modelSingleStep.value = '上一步'
        // console.log(modelSingleStep.value === '上一步')
})
}
//处理点击选择模型风格图片事件
const activeIMg = (num)=>{
//1.点击那个图就给图片赋值
// activenum.value = num
modeleruleForm.lora_train_config_id = num
console.log('lora_train_config_id',modeleruleForm.lora_train_config_id);
// sessionstorage.setItem('lora_train_config_id',modeleruleForm.lora_train_config_id)
}
//上传图片之前的事件
const beforeAvatarUpload = (file)=>{ 
  if(modelFileList.value.length >= 100){
    ElMessage.warning('最多只能上传100张')
    return false
  }
  // console.log(file);
// let  myReg = /[~!@#$%^&*()/\|,.<>?"'();:_+-=\[\]{}]/;
let  myReg = /[~!@#$%^&*/\|,?]/;
  if(myReg.test(file)){
    ElMessage.warning('不允许上传图片带特殊字符')
    return false
  }
 let reg = /[\u4e00-\u9fa5]/;
 if (reg.test(file.name)){
  ElMessage.warning('不允许上传带文字图片')
  return  false
 }
  if(modelFileList.value.some((item)=>item.name === file.name)){
    ElMessage.warning('图片不允许重复上传')
    return false
  }
if(disabledUpload.value)return ElMessage.warning('禁止上传图片')
if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg') {
ElMessage.error('上传图片必须是 JPG PNG JPEG格式！')
return false
} else if (file.size / 1024 / 1024 > 2) {
ElMessage.error('上传图片必须小于2MB!')
return false
}
loading.value = true
return true
}
//上传图片时
const hanleProgress =  ()=>{}
//上传图片成功
const handleSuccess = (response,UploadFile,uploadFiles)=>{
    // console.log(response); console.log(UploadFile); console.log(uploadFiles);
    loading.value = false
    console.log(response);
    if(response.code !== 2000)return ElMessage.error(response.msg)
    if(response.msg  !== "上传成功")return ElMessage.warning(response.msg)
    modelFileList.value.push({name:UploadFile.name,url:response.data.data})
      if(modelFileList.value.length !== uploadFiles.length)return false
        modelDisabled.value = false 
        modelDisableTrue.value = true
        ElMessage.success('上传成功')
}
// 上传图片失败
const hanleError = (error)=>{
    console.log(error);
    ElMessage.error('上传图片失败')
// ElLoading.error(error)
loading.value = false
}
//删除上传图片中某个图片
const onUploadDelete = async(index,name)=>{
modelFileList.value.splice(index,1)
// if(modelFileList.value === null)return modelDisabled.value = true
let modelObj = {lora_train_project_name:modeleruleForm.lora_train_project_name,lora_train_config_id:modeleruleForm.lora_train_config_id,image_name:name,lora_train_machine_id:modeleruleForm.lora_train_machine_id}
const res = await getLoraUploadImageDelete(modelObj)
  // console.log(res);
if(res.code !== 2000)return ElMessage.error(res.msg)
  console.log(limitFile.value);
ElMessage.success(res.msg)
console.log(res);
}
//重新上传图片
const submitUpload = async(params) => {
  modelFileList.value = []
  let modelObj = {lora_train_project_name:modeleruleForm.lora_train_project_name,lora_train_config_id:modeleruleForm.lora_train_config_id,lora_train_machine_id:modeleruleForm.lora_train_machine_id}

const res = await getLoraUploadImageDelete(modelObj)
if(res.code !== 2000)return ElMessage.error("res.msg")
    ElMessage.success('删除全部图片成功')
  console.log(res)
// modeleAutoUpload.value = false
// uploadRef.value.submit()
// // console.log('重新上传图片');//   uploadRef.value.submit() // refs.upload.submit()
//       // 实例化一个formData，用来做文件上传
  //   let formData = new FormData()
  //  formData.append("lora_train_project_name",modeleruleForm.lora_train_project_name)
  //  formData.append("lora_train_config_id",modeleruleForm.lora_train_config_id)
  //  formData.append("lora_train_machine_id",modeleruleForm.lora_train_machine_id)

  // file.value.forEach((item)=>{
  //   formData.append('file', item,)
  // })
//      // //获取上传图片请求
//     try {
//       const res = await getLoraImageUpload(formData)
//     if(res.code !== 2000){
//       ElMessage.error(res.msg+"请从新上传图片")
//       uploadName.value = "重新上传"
//       return false
//     } 
//       modelDisabled.value = false
//       ElMessage.success(res.msg)
//       console.log(res);
//       uploadName.value = "上传图片"
//     } catch (error) {
//         ElMessage.error(error)
//         // uploadName.value = "重新上传"
//     }
//     //  let fileUrl = URL.createObjectURL(file.value)
//     //   modelFileList.value.push({url:fileUrl,name:params.file.name,id:params.file.uid})
}
//数量超出限制的事件
const handleExceed = ()=>{
ElMessage.warning("当前只允许选择 " + limitFile.value + " 个文件！");
}
//开始训练事件
const onstartedTraining = async()=>{
  if(modelFileList.value.length <4)return ElMessage.warning("必须5张以上才可以开始")
  // if(modelTaskstatus.value ==='captioning')return ElMessage.warning("打标还没有进行")
  if(activeModelNumber.value === 3)return ElMessage.warning('训练已经完成')
if(startedText.value !== "开始训练")return finishTrainDialogVisible.value = true
// clearInterval(modelMarkingTime.value)
// clearInterval(modeleTime.value)
  // startedText.value = '结束训练'
  // //禁止显示删除图片，添加按钮
  // modelDisableTrue.value = false
  // // 禁止上传图片
  // disabledUpload.value =true
  onAgainMarking()
  // onLatentCaching()
}
//训练开始
const onstartedTrainings = async()=>{
let modelObj = {lora_train_project_name:modeleruleForm.lora_train_project_name,lora_train_config_id:modeleruleForm.lora_train_config_id,lora_train_machine_id:modeleruleForm.lora_train_machine_id}
try{
const res = await getLoraConfigListTrain(modelObj)
    console.log(res);
if(res.code !== 2000)return ElMessage.error(res.msg)
console.log(res.data.data);
if(res.msg  !== "用户lora开始训练")return ElMessage.warning(res.msg)  
  ElMessage.success(res.msg)
  activeModelNumber.value =3
  startedText.value = '开始训练'
  onLoraModelList()
}catch(error){
ElMessage.error(error)
} 
}
//查询训练结构
const onLoraModelList = async()=>{
try {
  // modeleTime.value =   setInterval( async()=>{
  const res = await getLoraModelList()
  if(res.code !== 2000)return ElMessage.error(res.msg)
      console.log(res);
    setTimeout(()=>{
      ElMessage.success("训练完成")
    startedTrainingDialogVisible.value = true
    },3000)
    modelDisabled.value = true
    // ElMessage.success(res.msg)
    // startedTrainingDialogVisible.value = true
//     if(res.code !== 2000)return false
//        res.data.data.forEach(item => {
//             if(item.task_status === "training"){
//               ElMessage.warning('用户正在lora训练中')
//               return false
//             }else if(item.task_status === "failure"){
//               ElMessage.error('训练出错')
//               return false
//             }
//   clearInterval(modeleTime.value);
// ElMessage.success('lora训练完成')
// activeModelNumber.value = 3
//    console.log(res,33333333);
// startedTrainingDialogVisible.value = true
// startedText.value = '开始训练' 
// modeleList.value = res.data
//     console.log(modeleList.value);    
    // });
//  },10000) 
} catch (error) {
  console.log(error);
}
}
//开始训练弹框
// import router from "../../../router";
const onStartedTrainingDialogVisible =  ()=>{

startedTrainingDialogVisible.value = false
window.location.reload();
// router.push('/personalMyCreation')
}
//结束训练弹框
const onFinishTrainDialogVisible =()=>{
startedText.value = '开始训练'
finishTrainDialogVisible.value = false
clearInterval(modelMarkingTime.value)
clearInterval(modeleTime.value)
}

//图片自动打标签打标事件
const onAgainMarking = async()=>{
// if(activeModelNumber.value === 3)return ElMessage.warning('训练已经结束，不能再进行打标')
// modelUploadFormRef.value.validate(async(valdid)=>{
    // if(!valdid)return ElMessage.error('请标签图片')
    // let modelObj = {lora_train_project_name:modeleruleForm.lora_train_project_name,lora_train_config_id:modeleruleForm.lora_train_config_id,lora_train_machine_id:modeleruleForm.lora_train_machine_id,custom_tag:modeleUploadruleForm.modelUplaodValue}
      let modelObj = {lora_train_project_name:modeleruleForm.lora_train_project_name,lora_train_config_id:modeleruleForm.lora_train_config_id,lora_train_machine_id:modeleruleForm.lora_train_machine_id,custom_tag:modeleruleForm.lora_train_project_name,}
      modelUplaodValue.value = modeleruleForm.lora_train_project_name
      const res = await getLoraConfigListLabel(modelObj)
      if(res.code !== 2000)return ElMessage.error(res.msg)
    if(res.msg !== "用户上传图片自动打标开始，请调用查看接口读取生成打标文件")return ElMessage.warning(res.msg)
    //禁止显示删除图片，添加按钮
  modelDisableTrue.value = false
  // 禁止上传图片
  disabledUpload.value =true
      console.log(res);
     ElMessage.success("上传图片自动打标开始")
     onAgainMarkingList()
// })
}
//查看用户自动打标文件
const onAgainMarkingList = async()=>{
let modelObj = {lora_train_project_name:modeleruleForm.lora_train_project_name,lora_train_config_id:modeleruleForm.lora_train_config_id,lora_train_machine_id:modeleruleForm.lora_train_machine_id}
modelMarkingTime.value =   setInterval( async()=>{
   try{
    const res = await getLoraConfigCaptionList(modelObj)
          console.log(res);
      if(res.code!== 2000)return ElMessage.error(res.msg)
      if(res.data.data.task_status === 'captioning'){
        ElMessage.warning('图片打标进行中')
        modelTaskstatus.value = res.data.data.task_status
        console.log(res.data.data.task_status,11111111)
        return false
      }
      clearInterval(modelMarkingTime.value)
      ElMessage.success('打标成功')
      console.log(res.data.data.task_status,222222222)
      modelTaskstatus.value = res.data.data.task_status
      activeModelNumber.value = 2
      onLatentCaching()
   }catch(error){
      console.log(error)
   }
},10000)
}
//图片分桶潜在空间
const onLatentCaching = async()=>{
let modelObj = {lora_train_project_name:modeleruleForm.lora_train_project_name,lora_train_config_id:modeleruleForm.lora_train_config_id,lora_train_machine_id:modeleruleForm.lora_train_machine_id}
disabledUpload.value =true
const res = await getLoraCofigListSpace(modelObj)
if(res.code !== 2000)return ElMessage.error(res.msg)
if(res.msg !== "图片bucketing and latents caching成功")return ElMessage.error(res.msg)
console.log(res);
  // ElMessage.success(res.msg)
  ElMessage.success("进行图片分桶")
  onLatentCachings()
}
//图片分桶潜在空间查询
const onLatentCachings = async()=>{
let modelObj = {lora_train_machine_id:modeleruleForm.lora_train_machine_id}
    modeleTime.value = setInterval(async()=>{
  const res = await getLoraTrainingMachine(modelObj)
  console.log(res,1111111);
  if(res.code !== 2000)return ElMessage.error(res.msg)
  if(res.data.data.machine_status !=='pending')return ElMessage.warning("图片分桶未完成，请等待")
  clearInterval(modeleTime.value)
  console.log(res,2222222);
  ElMessage.success("图片分桶完成")
  setTimeout(()=>{
 onConfigCreate()
  },2000)
 
},10000)
}
//lora训练配置参数文件创建(
const onConfigCreate = async()=>{
let modelObj = {lora_train_project_name:modeleruleForm.lora_train_project_name,lora_train_config_id:modeleruleForm.lora_train_config_id,lora_train_machine_id:modeleruleForm.lora_train_machine_id}
// modeleTime.value = setInterval(async()=>{
    const res = await getLoraConfigListCreate(modelObj)
  if(res.code !== 2000)return ElMessage.error(res.msg)
  console.log(res,'00000000');
    // if(res.msg === "图片分桶未完成，请等待")return ElMessage.warning(res.msg)
    // console.log(111111);
    // clearInterval(modeleTime.value)
    ElMessage.success(res.msg)
    // 3.开始训练
    setTimeout(()=>{
      onstartedTrainings()
    },1000)
      // console.log(res);
  // },10000)
 
}
//请求数据事件
onMounted(()=>{
//训练风格参数列表
modelTrainingImg()
//保存数据
// modeleruleForm.lora_train_config_id = sessionstorage.getItem('lora_train_config_id') || ''
//   console.log(modeleruleForm.lora_train_config_id);
})
//展示数据事件
const modelTrainingImg = async()=>{
  let {code,data} = await getLora()
if(code !== 2000)return false
modeleActiveImage.value = activeImage.value.map((item,index)=>{
      const arrImgs =  data.data[index]
      return {...item,...arrImgs}
  })
  // console.log(modeleActiveImage.value);
}

</script>
<style lang="scss" scoped>
.mode_content{
width: 100%;
height: 100%;
}
.model_uploadis{
margin-bottom: 20px;
}
.model_step{
margin-bottom: 20px;
// margin-bottom: 10px; 
}

.model_image{
// width: 380px;
// height: 480px;
width: 260px;
height: 370px;
position: relative;
// margin-right: 100px;
.model_img{
  width: 100%;
  // height: 100%;
}

.model_text{
        width: 100%;
        height: 100px;
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: center;
        background: #00000033;
        color: #fff;
        h1{
          width: 80%;
          
          // background: red;
          margin: auto;
        }
        p{
          width: 80%;
          // background: #fff;
          margin: auto;
          margin: auto;
          // white-space: normal;
          word-wrap: break-word;
          text-align: left;
        }
}
}
::v-deep [data-v-51b63b10] .el-upload-dragger{
height: 500px;
overflow: auto;
}
.model_upload{
// overflow-y: auto;
// height: 500px;
.upload_ismy{
  display: flex;
  flex-wrap: wrap;
}
.upload_url{
text-align: left;
flex-wrap: wrap;
width:100px;
height:100px;
margin-right: 15px;
position: relative;
margin-bottom: 20px;
  .upload_url_text{
    z-index: 1;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    color: #fff;
  //   display: flex;
  // justify-content: space-around;
  // align-items: center;
  span{
    float: right;
    font-weight: bold;
    font-size: 16px;
    // background: red;
    width: 20px;
  }
  }

.el-image{
  width: 100%;
  height: 100%;
  border-radius: 5px;
  // width:100px;
  // height:100px;
  // margin-right: 20px;
}
.upload_url_p{
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #00000033;
  color: #fff;
}
}
.upload_img{
  width: 65%;
  height: 100px;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  // text-align: center;
  background: rgba(242, 242, 242, 1);
  .upload_img_url{
    width:160px;
    // height:100px;
    // align-items: center;
  }
  // .upload_p{
  //   background: #000;
  //   // padding: 2px;
  //   color: #fff;
  // }
}
}
.el-form-item__label:before{
color: '';
}
.demo-progress .el-progress--line {
// margin-bottom: 15px;
width: 650px;
border: #409EFF 1px solid;
border-radius: 15px;
padding: 2px 2px;
text-align: center;
}
.demo-progress{
position: absolute;
left: 10%;
top: 50%;
display: flex;
}

::v-deep .el-form.item_label:before{
color: #757575;
}
.train_but{
float: right;
margin-top: 12px;
// margin-right: 40px;
margin-right: 13px;
// background: #000;
}
.active-train_but{
background: #000;
}
.active{
border: #79bbff 3px solid;
border-radius: 5px;
// padding: 3px;
}
.el-upload-dragger .el-upload__text em{
text-decoration: underline;
}
::v-deep .el-upload-dragger{
border: 1px #000 solid;
// text-align: left;
}

.model_upload_row{
.el-button{
    background: none;
    border: none;
    --el-button-hover-text-color:none;
  }
.upload_row_one{
  color: red;
}
.upload_row_tow{
  text-decoration: underline;
}
}
// 步骤条
.el-steps{
width: 800px;
height: 56px;
}
::v-deep .el-step__head.is-process .el-step__icon.is-text{
background: #000;
color: #fff;
}
.el-step__head.is-success .el-step__icon.is-text{
  background: #fff;
}
::v-deep .el-step__head.is-success {
background: #fff;
  color: var(--el-color-success);
  border-color: var(--el-color-success);
}
::v-deep .el-step__head{
width: 20px;
margin-right: 20px;
}
.el-step__head is-wait{
width: 30px;
}

::v-deep  .el-step__head.is-process{
width: 30px;
}
.el-step.is-horizontal{
  display: flex;
  align-items: center;
}
/* 文本父级 */

::v-deep  .el-step__main{
position: relative;
  display: flex;
  align-items: stretch;
  flex-grow: 1; 
}
/* 文本 */

::v-deep  .el-step__title.is-wait{
margin-left: 10px;
}
.el-step__head is-wait{
width: 30px;
}

::v-deep .el-step__head.is-wait{
width: 20px;
}
/* 横线 */

::v-deep .el-step.is-horizontal .el-step__line{
height: 0;
top: 0;
overflow: hidden;
// border: none;
}
.el-step__arrow {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-step__arrow::before {
  transform: rotate(-45deg) translateY(-4px);
  transform-origin: 0 0;
}
.el-step__arrow::before {
  content: "";
  display: inline-block;
  position: absolute;
  height: 15px;
  width: 1px;
  background: var(--el-text-color-placeholder);
}
.el-step__arrow::after {
  transform: rotate(45deg) translateY(4px);
  transform-origin: 100% 100%;
}
.el-step__arrow::after {
  content: "";
  display: inline-block;
  position: absolute;
  height: 15px;
  width: 1px;
  background: var(--el-text-color-placeholder);
}

::v-deeep .el-step.is-simple .el-step__icon {
  background: 0 0;
  width: 16px; 
  height: 16px;
  font-size: 12px;
}
::v-deeep  .el-step__icon.is-text {
  border-radius: 50%;
  border: 2px solid;
  border-color: inherit;
}
.el-statistic{
width: 260px;
display: flex;
align-items: center;
}
::v-deep .el-statistic__head{
margin-right: 10px;color: #000;
font-size: 15px;
}
// .el-button{
//   background: #000;
// }
.active-button{
border: none;
background: none;
color: #000;
text-decoration: underline;
font-size: 18px;
}
.active-but{
background: #000;
}
.upload_button{
position: fixed;
right: 20px;
top: 180px;
.el-button{
    background: none;
    border: none;
    --el-button-hover-text-color:none;
    text-decoration: underline;
  }
}
::v-deep [data-v-51b63b10] .el-step__head.is-success{
background: none;
}
::v-deep .el-form-item__content{
width: 95%;
}
.modelBottom{
height: 60px;
}
</style>