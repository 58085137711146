<template>
    <div class="page page--image-manage lycontainer" :class="{'ly-is-full':isFull}">
        <div class="flex-1">
            <el-tabs 
                class="tabs tabs--top-nav"
                type="border-card" 
                v-model="activeTopNavTab" 
            >
                <el-tab-pane
                    class="tab-pane tab-pane--goods"
                    name="goods"
                    label="商品图"
                >
                    <template #label>
                    <span class="label flex items-center">
                        <el-icon><PriceTag /></el-icon>
                        <span>商品图</span>
                    </span>
                    </template>
                    <el-container
                        class="container container--goods -mt-2"
                    >
                        <el-aside
                            class="aside aside--task-nav flex flex-col pr-2"
                            width="320px">
                            <div
                                class="flex flex-col mt-2 mb-2"
                                @click="onShowGoodsImageMain"
                            >
                                <h5
                                    class="flex flex-row justify-between items-center"
                                >
                                    <span class="flex flex-row items-center justify-between">
                                        <el-icon size="16px">
                                            <Collection />
                                        </el-icon>
                                        <i class="ml-1 not-italic ">商品图管理-训练版</i>
                                    </span>
                                    <el-tooltip
                                        content="查看我的收藏列表"
                                        placement="left-start"
                                    >
                                        <el-icon>
                                            <CollectionTag size="32px"></CollectionTag>
                                        </el-icon>
                                    </el-tooltip>
                                </h5>
                                <p class="mt-2">
                                    <el-text type="info">
                                        简单几步，快速生成商业效果商品图
                                    </el-text>
                                </p>
                            </div>
                            <div class="border-t-dashed border-t-2 mt-1 pt-1">
                                <el-button
                                    round 
                                    plain
                                    type="primary"
                                    size="large"
                                    class="w-full"
                                    @click="onNewGoodsImageTask"
                                >
                                    <i class="not-italic text-large font-bold">新建任务</i>
                                </el-button>
                            </div>
                            <div class="mt-4 mb-1 pb-6">
                                <el-scrollbar
                                    style="height: calc(100vh - 300px)"
                                >
                                    <ul class="list">
                                        <li
                                            class="flex items-center list-item list-item--task h-14 mb-4 pl-2 pr-2 rounded"
                                            v-for="(task, index) in userTaskList"
                                            :key="index"
                                            :class="{'active': goodsImageTaskCurrentId == task.id}"
                                            @click="onShowGoodsImageCurrentTask(task.id, task)"
                                        >
                                            <div class="item-thumb">
                                                <el-image
                                                    :src="task.thumb_url"
                                                    loading="lazy"
                                                    fit="cover"
                                                    style="width: 40px;height:40px"
                                                    class="mr-2 rounded"
                                                >
                                                    <template #placeholder>
                                                        <el-icon><CameraFilled /></el-icon>
                                                    </template>
                                                </el-image>
                                            </div>
                                            <div class="item-content flex items-center">
                                                <div class="title text-sm">{{  task.name }}</div>
                                                <div class="subtitle text-sm">{{  task.status_text?'('+task.status_text+')':'' }}</div>
                                            </div>
                                            <div class="item-actions">

                                            </div>
                                        </li>
                                    </ul>
                                    <div
                                        class="more-director text-center mt-4"
                                    >
                                        <el-button
                                            round
                                            plain
                                            @click="onMoreUserTaskList"
                                            v-if="hasMoreUserTaskList"
                                        >更多历史任务</el-button>
                                        <el-button
                                            disabled
                                            round
                                            plain
                                            v-if="!hasMoreUserTaskList"
                                        >已经到底了</el-button>
                                    </div>
                                </el-scrollbar>
                            </div>
                        </el-aside>
                        <el-main
                            class="main bg-neutral-100 pt-0 pb-2 -mr-[15]"
                        >
                            <article
                                class="section--main mb-4"
                                v-if="isShowGoodsImageMain"
                            >
                                <el-tabs
                                    v-model="activeMainNavTab"
                                    class="tabs "
                                >
                                    <el-tab-pane
                                        name="collect"
                                        class="pb-6 flex flex-col"
                                    >
                                        <template #label>
                                            <b class="label text-base ">我收藏的商品图</b>
                                        </template>
                                        <div
                                            class="label flex justify-start items-center"
                                            v-if="userCollectList.length == 0"
                                        >
                                            <el-empty
                                                description="暂无收藏商品图"
                                            >
                                            </el-empty>
                                        </div>
                                        <div
                                            class="flex flex-row flex-wrap"
                                            v-if="userCollectList.length > 0"
                                        >
                                            <div
                                                class="flex flex-col task-video ml-2 mr-2 mb-2 shadow-2xl rounded-2xl p-2"
                                                v-for="(collect,index) in userCollectList"
                                                :key="index"
                                            >
                                                <figure
                                                    class="relative task-image shadow-none rounded p-1 ml-2 mr-2 mb-2"
                                                    v-if="collect.is_collected"
                                                >
                                                    <div class="image">
                                                        <el-image
                                                            fit="cover"
                                                            loading="lazy"
                                                            class="rounded-full"
                                                            style="width: 252px;"
                                                            :src="collect.object_asset_thumb_medium_url"
                                                            :preview-src-list="[collect.object_asset_url]"
                                                        >
                                                            <template #placeholder>
                                                                <el-icon><CameraFilled /></el-icon>
                                                            </template>
                                                        </el-image>
                                                    </div>
                                                </figure>
                                                <div
                                                    class="footer mt-1"
                                                    v-if="collect.is_collected"
                                                >
                                                    <div class="flex flex-row justify-between">
                                                        <div class="action"
                                                            @click="onCollectTaskImage(
                                                                collect, 
                                                                collect.is_collected, 
                                                                collect.task_id, 
                                                                collect.object_id, 
                                                                collect.task_media_site, 
                                                                collect.object_asset_url,
                                                                collect.object_asset_thumb_medium_url,
                                                            )"
                                                        >
                                                            <el-icon>
                                                                <StarFilled size="24px"></StarFilled>
                                                            </el-icon>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <footer
                                            class="more-director text-center"
                                            v-if="userCollectList.length > 0"
                                        >
                                            <el-button
                                                round
                                                plain
                                                @click="onMoreUserCollects"
                                                v-if="hasMoreUserCollectList"
                                            >更多收藏</el-button>
                                            <el-text
                                                class="info"
                                                v-if="!hasMoreUserCollectList"
                                            >
                                                没有更多了
                                            </el-text>
                                        </footer>
                                    </el-tab-pane>
                                </el-tabs>
                            </article>

                            <article
                                class="section--current-task-doing bg-neutral-100 pb-16"
                                v-if="isShowCurrentGoodsImageTask && isCurrentGoodsImageTaskTodo"
                            >
                                <nav class="mb-2 mt-2">
                                    <el-steps :align-center="true"
                                        :active="goodsImageTaskCurrentStep"
                                    >
                                        <el-step title="选择模型">
                                            <template #description>
                                                <div
                                                    class="flex flex-col items-center justify-center"
                                                >
                                                    <el-image
                                                        :src="pretrainModelCurrentThumbUrl"
                                                        style="width: 96px;"
                                                        loading="lazy"
                                                        fit="contain"
                                                        :preview-src-list="[pretrainModelCurrentThumbUrl]"
                                                        v-if="pretrainModelCurrentThumbUrl"
                                                    >
                                                        <template #placeholder>
                                                            <el-icon><CameraFilled /></el-icon>
                                                        </template>
                                                    </el-image>
                                                    <el-text
                                                        type="info"
                                                        v-if="pretrainModelCurrentName"
                                                    >{{ pretrainModelCurrentName }}</el-text>
                                                    <el-text
                                                        type="info"
                                                        v-if="!pretrainModelCurrentName"
                                                    >等待选取</el-text>
                                                </div>
                                            </template>
                                        </el-step>
                                        <el-step title="选择场景">
                                            <template #description>
                                                <el-space wrap>
                                                    <el-text
                                                        type="info"
                                                        line-clamp="2"
                                                        class="p-1 border border-dashed rounded"
                                                        v-if="goodsImageBuildOptions.prompt_cn"
                                                    >
                                                        {{ goodsImageBuildOptions.prompt_cn }}
                                                    </el-text>
                                                    <el-text
                                                        type="info"
                                                        line-clamp="2"
                                                        class="p-1 border border-dashed rounded"
                                                        v-if="goodsImageBuildOptions.prompt_en"
                                                    >
                                                        {{ goodsImageBuildOptions.prompt_en }}
                                                    </el-text>
                                                    <el-tag size="default" v-if="goodsImageBuildOptions.medium_style">
                                                        {{ goodsImageBuildOptions.medium_style }}
                                                    </el-tag>
                                                    <el-tag size="default" v-if="goodsImageBuildOptions.composition_style">
                                                        {{ goodsImageBuildOptions.composition_style }}
                                                    </el-tag>
                                                    <el-tag size="default" v-if="goodsImageBuildOptions.paint_style">
                                                        {{ goodsImageBuildOptions.paint_style }}
                                                    </el-tag>
                                                    <el-tag size="default" v-if="goodsImageBuildOptions.scene_style">
                                                        {{ goodsImageBuildOptions.scene_style }}
                                                    </el-tag>
                                                    <el-tag size="default" v-if="goodsImageBuildOptions.location_style">
                                                        {{ goodsImageBuildOptions.location_style }}
                                                    </el-tag>
                                                    <el-tag size="default" v-if="goodsImageBuildOptions.person_style">
                                                        {{ goodsImageBuildOptions.person_style }}
                                                    </el-tag>
                                                    <el-tag size="default" v-if="goodsImageBuildOptions.face_style">
                                                        {{ goodsImageBuildOptions.face_style }}
                                                    </el-tag>
                                                    <el-tag size="default" v-if="goodsImageBuildOptions.tag_style">
                                                        {{ goodsImageBuildOptions.tag_style }}
                                                    </el-tag>

                                                </el-space>
                                            </template>
                                        </el-step>
                                        <el-step title="选择数量">
                                            <template #description>
                                                <el-tag 
                                                    type="danger"
                                                    size="default"
                                                    v-if="goodsImageBuildOptions.batch_size"
                                                >
                                                    {{ goodsImageBuildOptions.batch_size }}
                                                </el-tag>
                                        </template>
                                        </el-step>
                                        <el-step title="提交任务">
                                            <template #description>
                                            </template>
                                        </el-step>
                                    </el-steps>
                                </nav>
                                <div class="flex pb-6 mt-4">
                                    <aside class="flex flex-col justify-start items-center pl-1 pr-2 pt-2 pb-8 main-gutter border-0 border-dashed border-neutral-700">
                                        <div
                                            class="gutter-switch p-2 rounded border-r border border-dashed  border-neutral-500 mb-2 flex flex-col justify-center items-center"
                                            :class="{'active': goodsImageTaskCurrentMainOption == 'model'}"
                                            @click="onSwitchMainOption2Model"
                                        >
                                            <h5 class="text-large font-bold">选择模型</h5>
                                            <el-icon><Place /></el-icon>
                                        </div>
                                        <i class="not-italic flex flex-col justify-center items-center w-full mt-1 mb-2">
                                            <el-icon color="#737373"><Bottom /></el-icon>
                                        </i>
                                        <div
                                            class="gutter-switch p-2 rounded border-r border border-dashed  border-neutral-500 mb-2 flex flex-col justify-center items-center"
                                            :class="{'active': goodsImageTaskCurrentMainOption == 'scene'}"
                                            @click="onSwitchMainOption2Scene"
                                        >
                                            <h5 class="text-large font-bold">选择场景</h5>
                                            <el-icon><Place /></el-icon>
                                        </div>
                                        <i class="not-italic flex flex-col justify-center items-center w-full mt-1 mb-2">
                                            <el-icon color="#737373"><Bottom /></el-icon>
                                        </i>
                                        <div
                                            class="gutter-switch p-2 rounded border-r border border-dashed  border-neutral-500 mb-2 flex flex-col justify-center items-center"
                                            :class="{'active': goodsImageTaskCurrentMainOption == 'number'}"
                                            @click="onSwitchMainOption2Number"
                                        >
                                            <h5 class="text-large font-bold">选择数量</h5>
                                            <el-icon>
                                                <DCaret></DCaret>
                                            </el-icon>
                                        </div>
                                        <i class="not-italic flex flex-col justify-center items-center w-full mt-1 mb-2">
                                            <el-icon color="#737373"><Bottom /></el-icon>
                                        </i>
                                        <div
                                            class="gutter-switch p-2 rounded border-r border border-dashed  border-neutral-500 mb-2 flex flex-col justify-center items-center"
                                            :class="{'active': goodsImageTaskCurrentMainOption == 'status'}"
                                            @click="onSwitchMainOption2Status"
                                        >
                                            <h5 class="text-large font-bold">生成状态</h5>
                                            <el-icon>
                                                <DCaret></DCaret>
                                            </el-icon>
                                        </div>
                                    </aside>
                                    <div class="flex flex-1 pl-2 pb-6 main-options">
                                        <div
                                            class="option--number flex flex-col pt-2"
                                            v-if="goodsImageTaskCurrentMainOption == 'model'"
                                        >
                                            <h5
                                                class="section-header text-large font-bold mb-4"
                                            >
                                                预训练模型列表
                                            </h5>
                                            <div
                                                class="flex flex-wrap"
                                            >
                                                <el-radio-group
                                                    v-model="pretrainModelCurrentThumbUrl"
                                                >
                                                <figure
                                                    class="mr-1 mt-1 flex flex-col"
                                                    v-for="(model,index) in imagePretrainModelList"
                                                    :key="index"
                                                >
                                                    <el-image
                                                        :src="model.thumb_url"
                                                        style="width: 136px;height:136px"
                                                        class="rounded"
                                                        fit="cover"
                                                        loading="lazy"
                                                        @click="onChangePretrainModel({
                                                            'thumb_url': model.thumb_url, 
                                                            'id': model.id, 
                                                            'name': model.name,
                                                            'lora_name': model.lora_name,
                                                            'model': model
                                                        })"
                                                    >
                                                        <template #placeholder>
                                                            <el-icon><CameraFilled /></el-icon>
                                                        </template>
                                                    </el-image>

                                                    <el-text
                                                        type="info"
                                                        class="mt-1"
                                                        @click="onChangePretrainModel({
                                                            'thumb_url': model.thumb_url, 
                                                            'id': model.id, 
                                                            'name': model.name,
                                                            'lora_name': model.lora_name,
                                                            'model': model
                                                        })"
                                                    >
                                                        {{ model.name }}
                                                    </el-text>
                                                    <figcaption class="ml-1 flex items-center justify-between">
                                                        <el-radio 
                                                            :label="model.thumb_url"
                                                            data-id="model.id"
                                                            @change="onChangePretrainModel({
                                                                'thumb_url': model.thumb_url, 
                                                                'id': model.id, 
                                                                'name': model.name,
                                                                'lora_name': model.lora_name,
                                                                'model': model
                                                            })"
                                                        >{{ model.name }}模型</el-radio>
                                                    </figcaption>
                                                </figure>
                                                </el-radio-group>
                                            </div>
                                        </div>
                                        <div
                                            class="option--scene flex flex-col pt-2"
                                            v-if="goodsImageTaskCurrentMainOption == 'scene'"
                                        >
                                            <el-tabs
                                                v-model="activeNewNavTab"
                                                class="mt-0 w-auto"
                                            >
                                                <el-tab-pane
                                                    name="stencil">
                                                    <template #label>
                                                        <b class="label text-base font-medium">场景模板</b>
                                                    </template>
                                                    <el-form
                                                        label-position="top"
                                                        label-width="120px"
                                                        :model="goodsImageBuildOptions"
                                                    >
                                                        <el-form-item label="">
                                                            <template #label>
                                                                <el-text type="info" size="default" class="font-medium text-base neutral-700">中文场景/动作说明</el-text>
                                                            </template>
                                                            <el-input
                                                                v-model="goodsImageBuildOptions.prompt_cn"
                                                                type="textarea"
                                                                :autosize="{minRows: 3, maxRows: 5}"
                                                                placeholder="文字描述"
                                                            ></el-input>
                                                        </el-form-item>
                                                        <el-divider boder-style="dashed" class="my-2">更多快速模板</el-divider>
                                                        <el-form-item label="">
                                                            <template #label>
                                                                <el-text 
                                                                    type="info" 
                                                                    size="default" 
                                                                    class="font-medium text-base neutral-700"
                                                                >
                                                                    <span class="border-l-2 pl-2 border-neutral-700 border-dotted mr-2 font-bold text-neutral-900">
                                                                        输出媒质类型
                                                                    </span>
                                                                </el-text>
                                                            </template>
                                                            <div
                                                                class="flex flex-row flex-wrap pl-6"
                                                            >
                                                                <el-radio-group
                                                                    v-model="goodsImageBuildOptions.medium_style"
                                                                >
                                                                <div
                                                                    class="flex flex-row flex-wrap"
                                                                    v-for="(stencil,index) in imageStencilMediumStyleList"
                                                                    :key="index"
                                                                >
                                                                    <figure
                                                                        class="flex flex-col justify-center items-center mr-1 mb-1 rounded-md p-1 border border-dashed border-neutral-600"
                                                                        @click="onChangeImageStencilMediumStyle(stencil)"
                                                                    >
                                                                        <!-- <el-image
                                                                            :src="stencil.thumb_url"
                                                                            style="width: 40px;height:40px"
                                                                            :preview-src-list="[stencil.thumb_url]"
                                                                            fit="cover"
                                                                            loading="lazy"
                                                                        >
                                                                            <template #placeholder>
                                                                                <el-icon><CameraFilled /></el-icon>
                                                                            </template>
                                                                        </el-image> -->
                                                                        <el-radio
                                                                            class="mt-1"
                                                                            :label="stencil.name"
                                                                        >
                                                                        {{  stencil.name }}
                                                                        </el-radio>
                                                                    </figure>
                                                                </div>
                                                                </el-radio-group>
                                                            </div>
                                                        </el-form-item>
                                                        <el-form-item label="">
                                                            <template #label>
                                                                <el-text type="info" size="default" class="font-medium text-base neutral-700">
                                                                    <span class="border-l-2 pl-2 border-neutral-700 border-dotted mr-2 font-bold text-neutral-900">场景/动作</span>                                                                    
                                                                </el-text>
                                                            </template>
                                                            <div
                                                                class="flex flex-row flex-wrap pl-6"
                                                            >
                                                                <el-radio-group
                                                                    v-model="goodsImageBuildOptions.scene_style"
                                                                >
                                                                <div
                                                                    class="flex flex-row flex-wrap"
                                                                    v-for="(stencil,index) in imageStencilSceneStyleList"
                                                                    :key="index"
                                                                >
                                                                    <figure
                                                                        class="flex flex-col justify-center items-center mr-1 mb-1 rounded-md p-1 border border-dashed border-neutral-600"
                                                                        @click="onChangeImageStencilSceneStyle(stencil)"
                                                                    >
                                                                        <!-- <el-image
                                                                            :src="stencil.thumb_url"
                                                                            style="width: 40px;height:40px"
                                                                            fit="cover"
                                                                            loading="lazy"
                                                                        >
                                                                            <template #placeholder>
                                                                                <el-icon><CameraFilled /></el-icon>
                                                                            </template>
                                                                        </el-image> -->
                                                                        <el-radio
                                                                            class="mt-1"
                                                                            :label="stencil.name"
                                                                        >
                                                                        {{  stencil.name }}
                                                                        </el-radio>
                                                                    </figure>
                                                                </div>
                                                                </el-radio-group>
                                                            </div>
                                                        </el-form-item>
                                                        <!-- <el-form-item label="">
                                                            <template #label>
                                                                <el-text type="info" size="default" class="font-medium text-base neutral-700">
                                                                    <span class="border-l-2 pl-2 border-neutral-700 border-dotted mr-2 font-bold text-neutral-900">
                                                                        构图类型
                                                                    </span>
                                                                </el-text>
                                                            </template>
                                                            <div
                                                                class="flex flex-row flex-wrap pl-6"
                                                            >
                                                                <el-radio-group
                                                                    v-model="goodsImageBuildOptions.composition_style"
                                                                >
                                                                <div
                                                                    class="flex flex-row flex-wrap"
                                                                    v-for="(stencil,index) in imageStencilCompositionStyleList"
                                                                    :key="index"
                                                                >
                                                                    <figure
                                                                        class="flex flex-col justify-center items-center mr-1 mb-1 rounded-md p-1 border border-dashed border-neutral-600"
                                                                        @click="onChangeImageStencilCompositionStyle(stencil)"
                                                                    >
                                                                        <el-image
                                                                            :src="stencil.thumb_url"
                                                                            style="width: 40px;height:40px"
                                                                            fit="cover"
                                                                            loading="lazy"
                                                                        >
                                                                            <template #placeholder>
                                                                                <el-icon><CameraFilled /></el-icon>
                                                                            </template>
                                                                        </el-image>
                                                                        <el-radio
                                                                            class="mt-1"
                                                                            :label="stencil.name"
                                                                        >
                                                                        {{  stencil.name }}
                                                                        </el-radio>
                                                                    </figure>
                                                                </div>
                                                                </el-radio-group>
                                                            </div>
                                                        </el-form-item> -->
                                                        <!--
                                                        <el-form-item label="">
                                                            <template #label>
                                                                <el-text type="info" size="default" class="font-medium text-base neutral-700">画风类型</el-text>
                                                            </template>
                                                            <div
                                                                class="flex flex-row flex-wrap pl-6"
                                                            >
                                                                <el-radio-group
                                                                    v-model="goodsImageBuildOptions.paint_style"
                                                                >
                                                                <div
                                                                    class="flex flex-row flex-wrap"
                                                                    v-for="(stencil,index) in imageStencilPaintStyleList"
                                                                    :key="index"
                                                                >
                                                                    <figure
                                                                        class="flex flex-col justify-center items-center mr-1 mb-1 rounded-md p-1 border border-dashed border-neutral-600"
                                                                        @click="onChangeImageStencilPaintStyle(stencil)"
                                                                    >
                                                                        <el-image
                                                                            :src="stencil.thumb_url"
                                                                            style="width: 40px;height:40px"
                                                                            fit="cover"
                                                                            loading="lazy"
                                                                        >
                                                                            <template #placeholder>
                                                                                <el-icon><CameraFilled /></el-icon>
                                                                            </template>
                                                                        </el-image>
                                                                        <el-radio
                                                                            class="mt-1"
                                                                            :label="stencil.name"
                                                                        >
                                                                        {{  stencil.name }}
                                                                        </el-radio>
                                                                    </figure>
                                                                </div>
                                                                </el-radio-group>
                                                            </div>
                                                        </el-form-item> -->
                                                        <!-- <el-form-item label="">
                                                            <template #label>
                                                                <el-text type="info" size="default" class="font-medium text-base neutral-700">
                                                                    <span class="border-l-2 pl-2 border-neutral-700 border-dotted mr-2 font-bold text-neutral-900">位置</span>
                                                                </el-text>
                                                            </template>
                                                            <div
                                                                class="flex flex-row flex-wrap pl-6"
                                                            >
                                                                <el-radio-group
                                                                    v-model="goodsImageBuildOptions.location_style"
                                                                >
                                                                <div
                                                                    class="flex flex-row flex-wrap"
                                                                    v-for="(stencil,index) in imageStencilLocationStyleList"
                                                                    :key="index"
                                                                >
                                                                    <figure
                                                                        class="flex flex-col justify-center items-center mr-1 mb-1 rounded-md p-1 border border-dashed border-neutral-600"
                                                                        @click="onChangeImageStencilLocationStyle(stencil)"
                                                                    >
                                                                        <el-image
                                                                            :src="stencil.thumb_url"
                                                                            style="width: 40px;height:40px"
                                                                            fit="cover"
                                                                            loading="lazy"
                                                                        >
                                                                            <template #placeholder>
                                                                                <el-icon><CameraFilled /></el-icon>
                                                                            </template>
                                                                        </el-image>
                                                                        <el-radio
                                                                            class="mt-1"
                                                                            :label="stencil.name"
                                                                        >
                                                                        {{  stencil.name }}
                                                                        </el-radio>
                                                                    </figure>
                                                                </div>
                                                                </el-radio-group>
                                                            </div>
                                                        </el-form-item> -->
                                                        <!--
                                                        <el-form-item label="">
                                                            <template #label>
                                                                <el-text type="info" size="default" class="font-medium text-base neutral-700">人物类型</el-text>
                                                            </template>
                                                            <div
                                                                class="flex flex-row flex-wrap pl-6"
                                                            >
                                                                <el-radio-group
                                                                    v-model="goodsImageBuildOptions.person_style"
                                                                >
                                                                <div
                                                                    class="flex flex-row flex-wrap"
                                                                    v-for="(stencil,index) in imageStencilPersonStyleList"
                                                                    :key="index"
                                                                >
                                                                    <figure
                                                                        class="flex flex-col justify-center items-center mr-1 mb-1 rounded-md p-1 border border-dashed border-neutral-600"
                                                                        @click="onChangeImageStencilPersonStyle(stencil)"
                                                                    >
                                                                        <el-image
                                                                            :src="stencil.thumb_url"
                                                                            style="width: 40px;height:40px"
                                                                            fit="cover"
                                                                            loading="lazy"
                                                                        >
                                                                            <template #placeholder>
                                                                                <el-icon><CameraFilled /></el-icon>
                                                                            </template>
                                                                        </el-image>
                                                                        <el-radio
                                                                            class="mt-1"
                                                                            :label="stencil.name"
                                                                        >
                                                                        {{  stencil.name }}
                                                                        </el-radio>
                                                                    </figure>
                                                                </div>
                                                                </el-radio-group>
                                                            </div>
                                                        </el-form-item> -->
                                                        <!--
                                                        <el-form-item label="">
                                                            <template #label>
                                                                <el-text type="info" size="default" class="font-medium text-base neutral-700">面部风格</el-text>
                                                            </template>
                                                            <div
                                                                class="flex flex-row flex-wrap pl-6"
                                                            >
                                                                <el-radio-group
                                                                    v-model="goodsImageBuildOptions.face_style"
                                                                >
                                                                <div
                                                                    class="flex flex-row flex-wrap"
                                                                    v-for="(stencil,index) in imageStencilFaceStyleList"
                                                                    :key="index"
                                                                >
                                                                    <figure
                                                                        class="flex flex-col justify-center items-center mr-1 mb-1 rounded-md p-1 border border-dashed border-neutral-600"
                                                                        @click="onChangeImageStencilFaceStyle(stencil)"
                                                                    >
                                                                        <el-image
                                                                            :src="stencil.thumb_url"
                                                                            style="width: 40px;height:40px"
                                                                            fit="cover"
                                                                            loading="lazy"
                                                                        >
                                                                            <template #placeholder>
                                                                                <el-icon><CameraFilled /></el-icon>
                                                                            </template>
                                                                        </el-image>
                                                                        <el-radio
                                                                            class="mt-1"
                                                                            :label="stencil.name"
                                                                        >
                                                                        {{  stencil.name }}
                                                                        </el-radio>
                                                                    </figure>
                                                                </div>
                                                                </el-radio-group>
                                                            </div>
                                                        </el-form-item> -->
                                                        <!--
                                                        <el-form-item label="">
                                                            <template #label>
                                                                <el-text type="info" size="default" class="font-medium text-base neutral-700">标签</el-text>
                                                            </template>
                                                        </el-form-item> -->
                                                    </el-form>
                                                </el-tab-pane>
                                                <!-- <el-tab-pane
                                                    name="custom"
                                                >
                                                    <template #label>
                                                        <el-text type="info" size="default" class="font-medium text-base neutral-700">场景/动作自定义</el-text>
                                                    </template>
                                                    <el-form
                                                        label-position="left"
                                                        label-width="120px"
                                                    >
                                                        <el-form-item label="">
                                                            <template #label>
                                                                <el-text type="info" size="default" class="font-medium text-base neutral-700">中文场景/动作说明</el-text>
                                                            </template>
                                                            <el-input
                                                                v-model="goodsImageBuildOptions.prompt_cn"
                                                                type="textarea"
                                                                :autosize="{minRows: 3, maxRows: 5}"
                                                                placeholder="文字描述"
                                                            ></el-input>
                                                        </el-form-item>
                                                        <el-form-item label="">
                                                            <el-button
                                                                plain
                                                                round
                                                                class="btn--translate"
                                                                @click="onTranslateChinesePromptByAlibaba"
                                                            >中译英</el-button>
                                                        </el-form-item>
                                                        <el-form-item label="">
                                                            <template #label>
                                                                <el-text type="info" size="default" class="font-medium text-base neutral-700">英文提示词（生成需要）</el-text>
                                                            </template>
                                                            <el-input
                                                                v-model="goodsImageBuildOptions.prompt_en"
                                                                type="textarea"
                                                                :autosize="{minRows: 3, maxRows: 5}"
                                                                placeholder="文字描述"
                                                            ></el-input>
                                                        </el-form-item>
                                                    </el-form>
                                                    <blockquote class="help-block mt-1">
                                                        <el-text
                                                            type="info"
                                                            size="default"
                                                        >
                                                            {{ goodsImageBuildHelp }}
                                                        </el-text>
                                                    </blockquote>
                                                </el-tab-pane> -->
                                            </el-tabs>
                                        </div>
                                        <div
                                            class="option--number flex flex-col pt-2"
                                            v-if="goodsImageTaskCurrentMainOption == 'number'"
                                        >
                                            <el-form label-position="top">
                                                <h5 class="text-large font-bold mb-4">
                                                    生成图片数量
                                                </h5>
                                                <el-form-item
                                                    label=""
                                                >
                                                    <el-input-number
                                                        :min="1"
                                                        :max="4"
                                                        placeholder="最多4张"
                                                        v-model="goodsImageBuildOptions.batch_size"
                                                    ></el-input-number>
                                                </el-form-item>
                                            </el-form>
                                        </div>
                                        <div
                                            class="option--status flex flex-1 flex-col pt-2"
                                            v-if="goodsImageTaskCurrentMainOption == 'status'"
                                        >
                                            <h5 class="text-large font-bold mb-4">
                                                生成状态
                                            </h5>
                                            <p class="ml-2 flex-1">
                                                <blockquote
                                                    class="px-1 py-1 flex flex-1 flex-col"
                                                    v-if="goodsImageTaskProgressing"
                                                >
                                                    <el-space
                                                        wrap
                                                        v-if="hasImageTaskProgressPreview && goodsImageTaskImagesPreview.length > 0"
                                                    >
                                                        <el-image
                                                            :src="image"
                                                            style="width: 96px;"
                                                            loading="lazy"
                                                            fit="contain"
                                                            :preview-src-list="[image]"
                                                            v-for="(image,index) in goodsImageTaskImagesPreview"
                                                            :key="index"
                                                        >
                                                            <template #placeholder>
                                                                <el-icon><CameraFilled /></el-icon>
                                                            </template>
                                                        </el-image>
                                                    </el-space>
                                                    <el-progress
                                                        class=""
                                                        :percentage="goodsImageTaskProgressValue"
                                                    >
                                                    </el-progress>
                                                    <div class="progress-help-info">
                                                        {{ goodsImageTaskProgressInfo }}
                                                    </div>
                                                </blockquote>
                                                <blockquote
                                                    class="px-1 py-1 flex flex-1 flex-col"
                                                    v-if="!goodsImageTaskProgressing && goodsImageTaskCurrentGeneratedImages"
                                                >
                                                    <el-space>
                                                        <el-image
                                                            :src="image.url"
                                                            :preview-src-list="[image.url]"
                                                            style="width: 96px;"
                                                            loading="lazy"
                                                            fit="contain"
                                                            v-for="(image,index) in goodsImageTaskCurrentGeneratedImages"
                                                            :key="index"
                                                        >
                                                            <template #placeholder>
                                                                <el-icon><CameraFilled /></el-icon>
                                                            </template>
                                                        </el-image>
                                                    </el-space>
                                                </blockquote>
                                                <blockquote
                                                    class="px-1 py-1 flex flex-1 flex-col"
                                                    v-if="goodsImageBuildTaskResult"
                                                >
                                                    <el-space>
                                                        <div class=""
                                                            v-for="(image,index) in goodsImageBuildTaskResult"
                                                            :key="index"
                                                        >
                                                            <el-image
                                                                :src="image.url"
                                                                :preview-src-list="[image.url]"
                                                                loading="lazy"
                                                                style="width: 96px;"
                                                                fit="contain"
                                                            >
                                                                <template #placeholder>
                                                                    <el-icon><CameraFilled /></el-icon>
                                                                </template>
                                                            </el-image>
                                                        </div>
                                                    </el-space>
                                                </blockquote>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <footer
                                    class="footer--task-actions fixed bottom-2 p-2 mt-0 mb-0"
                                >
                                    <div class="flex justify-start items-center bg-transparent w-[560px] py-1 px-4 mt-0 mb-0 rounded bg-neutral-200 text-neutral-200">
                                        <div
                                            class="flex flex-row flex-nowrap items-center mb-1"
                                        >
                                            <el-button 
                                                round
                                                plain
                                                type="danger"
                                                @click="onSubmitGoodsImageTask('goods-image-train')"
                                            >
                                                提交任务
                                                <el-icon><Promotion /></el-icon>
                                            </el-button>
                                            <el-divider direction="vertical" border-style="dotted"></el-divider>
                                            <el-button 
                                                round
                                                plain
                                                @click="onEditTaskName"
                                            >
                                                <el-icon
                                                    color="#9e9e9e"
                                                >
                                                    <EditPen />
                                                </el-icon>
                                               {{ goodsImageTaskCurrentName }}
                                            </el-button>
                                        </div>
                                        <i class="not-italic flex-1"></i>
                                        <div
                                            class="flex flex-row flex-nowrap items-center"
                                        >
                                            <el-button
                                                round
                                                plain
                                                type="info"
                                                @click="onDoneGoodsImageTask"
                                                v-if="isCurrentGoodsImageTaskTodo"
                                            >
                                                <el-icon><Select /></el-icon>
                                                确认任务完成
                                            </el-button>
                                            <el-divider direction="vertical" border-style="dotted"></el-divider>
                                            <el-button
                                                round
                                                plain
                                                type="danger"
                                                @click="onDestroyGoodsImageTask"
                                            >
                                                <el-icon><DeleteFilled /></el-icon>
                                                删除任务
                                            </el-button>
                                        </div>
                                    </div>
                                </footer>
                            </article>

                            <article
                                class="section--current-task-done bg-neutral-100"
                                v-if="isShowCurrentGoodsImageTask && !isCurrentGoodsImageTaskTodo"
                            >
                                <div class="top">
                                    <div class="flex flex-row mt-2 items-center">
                                        <el-space :size="4">
                                            <div
                                                class="flex flex-row items-center mb-1"
                                                @click="onEditTaskName"
                                            >
                                                <el-text
                                                    class="mr-2 text-lg font-medium text-neutral-700"
                                                    type="primary"
                                                >
                                                    {{ goodsImageTaskCurrentName ? goodsImageTaskCurrentName : goodsImageTaskCurrentTaskName }}
                                                </el-text>
                                                <el-icon
                                                    color="#9e9e9e"
                                                >
                                                    <EditPen />
                                                </el-icon>
                                            </div>
                                            <el-divider direction="vertical"></el-divider>
                                            <p class="flex mx-2 items-center"
                                                @click="onToggleTaskMoreDetails"
                                            >
                                                <i class="not-italic mr-1 text-sm font-normal">详情</i>
                                                <el-icon size="14">
                                                    <ArrowDown v-if="!isShowTaskMoreDetails"></ArrowDown>
                                                    <ArrowUp v-if="isShowTaskMoreDetails"></ArrowUp>
                                                </el-icon>
                                            </p>
                                            <el-divider direction="vertical"></el-divider>
                                            <div
                                                class="flex flex-row items-center text-sm"
                                            >
                                                <span class="mr-1">任务ID: </span>
                                                <span>{{ goodsImageTaskCurrent.task_id }}</span>
                                            </div>
                                        </el-space>
                                        <div class="flex-1"></div>
                                    </div>
                                </div>
                                <el-divider border-style="dashed"></el-divider>
                                <div class="flex flex-row mb-4 justify-between items-center">
                                    <h5 class="">
                                        <span class=""></span>
                                        <div class="">
                                            <i class="not-italic"></i>
                                            <i class="not-italic"></i>
                                        </div>
                                    </h5>
                                    <p class="">
                                        <el-button
                                            plain
                                            round
                                            @click="onReDoingGoodsImageTask"
                                            v-if="!isCurrentGoodsImageTaskTodo"
                                        >
                                            再次执行任务
                                        </el-button>
                                        <el-divider
                                            direction="vertical"
                                            border-type="dotted"
                                        ></el-divider>
                                        <el-tooltip
                                            content="删除任务"
                                        >
                                            <el-icon
                                                @click="onDestroyGoodsImageTask"
                                            >
                                                <DeleteFilled />
                                            </el-icon>
                                        </el-tooltip>
                                    </p>
                                </div>
                                <div class="main mt-1 pb-6">
                                    <div class="main-header"
                                        v-if="isShowTaskMoreDetails"
                                    >
                                        <div class="header-left">
                                            <el-descriptions
                                                title="任务详情"
                                                direction="vertical"
                                                :column="2"
                                                size="large"
                                            >
                                                <el-descriptions-item
                                                    label="创建时间"
                                                    label-class-name="desc-label"
                                                    class-name="desc-content"
                                                >
                                                    {{  goodsImageTaskCurrent.task_date_created }}
                                                </el-descriptions-item>
                                                <el-descriptions-item
                                                    label="完成时间"
                                                    label-class-name="desc-label"
                                                    class-name="desc-content"
                                                >
                                                    {{  goodsImageTaskCurrent.task_date_done }}
                                                </el-descriptions-item>
                                                <el-descriptions-item
                                                    label="任务提交最近设置"
                                                    label-class-name="desc-label"
                                                    class-name="desc-content"
                                                >
                                                    <el-space
                                                        wrap
                                                        v-if="goodsImageTaskCurrentContextDescList"
                                                    >
                                                        <el-tag
                                                            v-for="(desc,index) in goodsImageTaskCurrentContextDescList"
                                                            :key="index"
                                                        >
                                                            {{  desc }}
                                                        </el-tag>
                                                    </el-space>
                                                </el-descriptions-item>
                                            </el-descriptions>
                                        </div>
                                        <el-divider border-style="dashed"></el-divider>
                                    </div>
                                    <div class="main-body">
                                        <!-- <h5
                                            class="mt-2 mb-2 text-large font-bold"
                                            type="info"
                                        >
                                            任务产出图一览
                                        </h5> -->
                                        <el-empty
                                            description="暂无完成商品图"
                                            v-if="goodsImageTaskCurrentTotalImageList.length == 0"
                                        >
                                        </el-empty>
                                        <div 
                                            class="flex flex-row flex-wrap pl-4"
                                            v-if="goodsImageTaskCurrentTotalImageList.length > 0"
                                        >
                                            <figure
                                                class="flex flex-col task-image shadow-none rounded p-1 ml-2 mr-2 mb-2"
                                                v-for="(image,index) in goodsImageTaskCurrentTotalImageList"
                                                :key="index"
                                            >
                                                <div class="image">
                                                    <el-image
                                                        :src="image.asset_thumb_medium_url"
                                                        class="rounded-xl"
                                                        style="width:252px;"
                                                        :preview-src-list="[image.asset_url]"
                                                        fit="cover"
                                                        loading="lazy"
                                                    >
                                                        <template #placeholder>
                                                            <el-icon><CameraFilled /></el-icon>
                                                        </template>
                                                    </el-image>
                                                </div>
                                                <div class="footer mt-1">
                                                    <div class="flex flex-row justify-between">
                                                        <div class="action"
                                                            @click="onCollectTaskImage(
                                                                image,
                                                                image.is_collected,
                                                                image.task_id,
                                                                image.id,
                                                                image.task_media_site,
                                                                image.asset_url,
                                                                image.asset_thumb_medium_url,
                                                            )"
                                                        >
                                                            <el-icon v-if="image.is_collected">
                                                                <StarFilled size="24px"></StarFilled>
                                                            </el-icon>
                                                            <el-icon v-if="!image.is_collected">
                                                                <Star size="16px"></Star>
                                                            </el-icon>
                                                        </div>
                                                        <div class="action"
                                                            @click="onDestroyTaskImage(image.id, image.task_id)"
                                                        >
                                                            <el-icon>
                                                                <Delete></Delete>
                                                            </el-icon>
                                                        </div>
                                                    </div>
                                                </div>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </el-main>
                    </el-container>
                </el-tab-pane>
            </el-tabs>
        </div>

        <el-dialog
            v-model="isShowTaskEditDialog"
            title="任务名称编辑"
        >
            <el-form
                label-position="top"
            >
                <el-form-item label="">
                    <template #label>
                        <el-text type="info" size="default" class="font-medium text-base neutral-700">任务名字</el-text>
                    </template>
                    <el-input v-model="taskNewName"></el-input>
                </el-form-item>
            </el-form>

            <template #footer>
                <el-button
                    plain
                    @click="onCancelTaskEdit"
                >
                    取消
                </el-button>
                <el-button
                    plain
                    @click="onConfirmTaskEdit"
                >
                    确定
                </el-button>
            </template>
        </el-dialog>

    </div>
</template>

<script setup>
    import { ref, reactive, nextTick, onMounted,onBeforeUnmount,getCurrentInstance} from 'vue'
    import { useRoute } from "vue-router";
    import { ElMessage, ElMessageBox, genFileId } from 'element-plus'
    // import Konva from 'konva'
    import { 
        getUserImageCollectList,createUserImageCollect,destroyUserImageCollect,
        getUserImageTaskList, 
        createImageTask, retriveImageTask, updateImageTask, destroyImageTask, queryImageTask,
        submitImageTask, getImageTaskProgress, getImageTaskResult, 
        getUserImageRefList, uploadGoodsImageRef,destroyGoodsImageRef,
        destroyGoodsImage,
        getGoodsImageMaskAuto, uploadGoodsImageMask, 
        getUserImagePretrainModelList, getImageStencilCategoryList, getImageStencilList,
        sendSegmentReq,
        translateByTencent, translateByAzure, translateByAlibaba } from '@/api/api'
    import {hasPermission,getTableHeight} from "@/utils/util";
    import { MEDIA_SITE_ROOT } from '@/config'
    
    const { proxy } = getCurrentInstance()

    const route = useRoute();

    let isFull = ref(false)
    let isShowTaskMoreDetails = ref(false)
    let footerNav = ref()

    function setFull(){
        isFull.value=!isFull.value
        window.dispatchEvent(new Event('resize'))
    }

    let scrowHight = ref(0)

    let activeTopNavTab = ref("goods")
    let activeMainNavTab = ref("collect")
    let activeNewNavTab = ref("stencil")
    let editableTopNavTabs = ref([])

    let userTaskList = ref([
    ])
    let userTaskListCurrentPage = ref(1)
    let userTaskListScrollHeight = ref('100%')
    let hasMoreUserTaskList = ref(false)

    let userCollectList = ref([])
    let userCollectListCurrentPage = ref(1)
    let hasMoreUserCollectList = ref(false)
    let galleryList = ref([])

    let isShowGoodsImageMain = ref(true)
    let isCurrentGoodsImageTaskTodo = ref(true)
    let isShowCurrentGoodsImageTask = ref(false)
    let isShowUploadRef = ref(false)
    let isShowUploadMask = ref(false)

    let taskNewName = ref('')
    let isShowTaskEditDialog = ref(false)
    let isShowFinetuneDialog = ref(false)

    let pretrainModelType = ref('upload')
    let pretrainModelCurrentThumbUrl = ref('')
    let pretrainModelCurrentName = ref('')
    let pretrainModelCurrentId = ref('')
    let goodsImageRefGalleryCurrent = ref('')
    let goodsImageRefList = ref([])
    let goodsImageRefUploaded = ref('')
    let goodsImageRefUploaderRef = ref()

    let imagePretrainModelList = ref([])

    let imageStencilMediumStyleList = ref([])
    let imageStencilPaintStyleList = ref([])
    let imageStencilCompositionStyleList = ref([])
    let imageStencilSceneStyleList = ref([])
    let imageStencilLocationStyleList = ref([])
    let imageStencilPersonStyleList = ref([])
    let imageStencilFaceStyleList = ref([])
    let imageStencilTagList = ref([])

    let goodsImageTaskCurrentMainOption = ref('model')
    let goodsImageBuildTaskResult = ref([])
    let goodsImageTaskCurrent = ref()
    let goodsImageTaskCurrentId = ref()
    let goodsImageTaskCurrentTaskId = ref()
    let goodsImageTaskCurrentContextDescList = ref([])
    let goodsImageTaskCurrentTotalImageList = ref([])
    let goodsImageTaskCurrentName = ref()
    let goodsImageTaskCurrentTaskName = ref()
    let goodsImageTaskCurrentStep = ref(0)

    let goodsImageMaskType = ref('auto')
    let goodsImageMaskAuto = ref()
    let goodsImageMaskAutoGetType = ref('extract')
    let goodsImageMaskIsNeedFinetune = ref(true)
    let goodsImageMaskUploaded = ref()
    let goodsImageMaskUploaderRef = ref()

    let finetuneStageContainer = ref()
    let finetuneStage = ref()
    let finetuneStageConfig= ref({
        width: 300,
        height: 300,
    })
    let finetuneStageLayer = ref()
    let finetuneStageLayerConfig= ref({
    })
    let finetuneStageLayerBgImage = ref()
    let finetuneStageLayerBgImageConfig= ref({
        image: '',
        x: 0,
        y: 0,
        width: 300,
        height: 300,
    })

    let finetuneExpectedContainer = ref()
    let finetuneExpected = ref()
    let finetuneExpectedConfig= ref({
        width: 300,
        height: 300,
    })
    let finetuneExpectedLayerConfig= ref({
    })
    let finetuneExpectedImage= ref()
    let finetuneExpectedImageConfig= ref({
    })
    let finetuneExpectedInfo = ref('')
    let finetuneExpectedResult = ref('')

    let finetunePenMode = ref('include')
    let finetunePenMudgeWidth = ref(4)
    let finetunePositivePoints = ref([])
    let finetuneNegativePoints = ref([])

    let goodsImageFinetuneType = ref('auto-extract')
    let goodsImageFinetune = ref('')
    let goodsImageFinetuneWidth = ref(256)
    let goodsImageFinetuneHeight = ref(256)
    let goodsImageFinetuneWidthOrig = ref(256)
    let goodsImageFinetuneHeightOrig = ref(256)
    let goodsImageFinetuneScale = ref()
    let goodsImageFinetunePreview = ref('')

    let goodsImageBuildOptions = reactive({
        pretrain_model_id: '',
        pretrain_model_name: '',
        pretrain_model_thumb_url: '',
        pretrain_model_lora_name: '',
        prompt_cn: '',
        prompt_cn_negative: '',
        prompt_en: '',
        prompt_en_negative: '',
        batch_size: 4,
        medium_style: '',
        medium_style_id: '',
        paint_style: '',
        paint_style_id: '',
        scene_style: '',
        scene_style_id: '',
        location_style: '',
        location_style_id: '',
        composition_style: '',
        composition_style_id: '',
        person_style: '',
        person_style_id: '',
        face_style: '',
        face_style_id: '',
        tags_style: '',
        tags_style_id: '',
    })
    let goodsImageBuildHelp = ref(
        `
        描述你想要生成的图片内容，支持中英文，但是最终都要生成英文才能提交。
        `
    )

    let goodsImageMaskProgressing = ref(false)

    let goodsImageTaskProgressing = ref(false)
    let goodsImageTaskProgressValue = ref(0)
    let goodsImageTaskProgressInfo = ref('')
    let hasImageTaskProgressPreview = ref(false)
    let goodsImageTaskImagesPreview = ref([])
    let goodsImageTaskCurrentGeneratedImages = ref([])
    let goodsImageTaskTimerId = ref(-1)

    let goodsImageRefCanvas = ref()
    let goodsImageMaskCanvas = ref()

    let goodsImageRefStageConfig= ref({
        width: 300,
        height: 300,
    })
    
    let goodsImageRefLayerConfig= ref()
    let goodsImageMaskLayerConfig= ref()
    let goodsImageMaskImageConfig= ref({
        image: ''
    })


    function getTopNavTabs() {
        // platformsettingsSysconfig({limit:999,parent__isnull:true}).then(res=>{
        //     if(res.code == 2000){
        //         editableTopNavTabs.value = res.data.data
        //     }
        // })
        editableTopNavTabs.value = [
            {
                title: '商品图',
                key: 'goods',
            },
        ]
    }
    function handleResize(){
        nextTick(() => {
            let tabSelectHeight = proxy.$refs.tableSelect?proxy.$refs.tableSelect.offsetHeight:0
            tabSelectHeight = isFull.value?tabSelectHeight + 90 :tabSelectHeight + 200
            scrowHight.value = getTableHeight(tabSelectHeight,false)
        })
    }

    onMounted(() => {
        getTopNavTabs()
        handleResize()
        window.addEventListener('resize',handleResize)

        getUserTasks()
        getUserCollects(1)
        getUserGoodsImageRefs()
        getUserImagePretainModels()
        getImageStencils('medium')
        getImageStencils('composition')
        getImageStencils('paint')
        getImageStencils('scene')
        getImageStencils('location')
        getImageStencils('person')
        getImageStencils('face')
        // getImageStencils('tag')

        // if (finetuneStageContainer.value) {
        //     app.mount(finetuneStageContainer.value);
        // }
    })
    onBeforeUnmount(()=>{
        window.removeEventListener('resize',handleResize)
    })

    defineExpose({
        setFull
    })

    function getUserCollects(page = userCollectListCurrentPage.value) {
        let params = {
            page: page,
            task_type: 'goods-image-train',
        }
        getUserImageCollectList(params).then((res) => {
            let remList = res.data?.data
            remList && remList.forEach((el) => {
                el.is_collected = true
                el.task_id = el.task
            })
            let hasNext = res.data?.has_next
            if (hasNext) {
                hasMoreUserCollectList.value = true
            } else {
                hasMoreUserCollectList.value = false
            }
            if (page == 1) {
                userCollectList.value = remList
            } else {
                userCollectList.value = userCollectList.value.concat(remList)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    function onMoreUserCollects() {
        userCollectListCurrentPage.value = parseInt(userCollectListCurrentPage.value) + 1
        getUserCollects(userCollectListCurrentPage.value)
    }

    function getTaskContextDescList(context) {
        let result = []
        if (context?.goodsImageBuildOptions?.pretrain_model_name) {
            result.push('预训练模型:' + context?.goodsImageBuildOptions?.pretrain_model_name)
        }
        if (context?.goodsImageBuildOptions?.prompt_cn) {
            result.push('中文提示词:' + context?.goodsImageBuildOptions?.prompt_cn)
        }
        if (context?.goodsImageBuildOptions?.prompt_en) {
            result.push('英文提示词:' + context?.goodsImageBuildOptions?.prompt_en)
        }
        if (context?.goodsImageBuildOptions?.medium_style) {
            result.push('输出媒质类型:' + context?.goodsImageBuildOptions?.medium_style)
        }
        if (context?.goodsImageBuildOptions?.composition_style) {
            result.push('构图类型:' + context?.goodsImageBuildOptions?.composition_style)
        }
        // if (context?.goodsImageBuildOptions?.paint_style) {
        //     result.push('画风类型:' + context?.goodsImageBuildOptions?.paint_style)
        // }
        if (context?.goodsImageBuildOptions?.scene_style) {
            result.push('动作/场景类型:' + context?.goodsImageBuildOptions?.scene_style)
        }
        if (context?.goodsImageBuildOptions?.location_style) {
            result.push('位置类型:' + context?.goodsImageBuildOptions?.location_style)
        }
        // if (context?.goodsImageBuildOptions?.person_style) {
        //     result.push('人物类型:' + context?.goodsImageBuildOptions?.person_style)
        // }
        // if (context?.goodsImageBuildOptions?.face_style_id) {
        //     result.push('面部类型:' + context?.goodsImageBuildOptions?.face_style_id)
        // }
        if (context?.goodsImageBuildOptions?.tags_style) {
            result.push('标签:' + context?.goodsImageBuildOptions?.tags_style)
        }
        return result
    }

    function reformatTask(dataOrig) {
        let result = {}
        let thumb_url = ''
        let taskImages = []
        let taskCurrentResult = []
        let taskContext = {}

        if (dataOrig?.assets && dataOrig?.assets.length > 0) {
            thumb_url = dataOrig?.assets[0]?.asset_thumb_medium_url
        }
        if (dataOrig) {
            let name = '任务-'+dataOrig.id
            if (dataOrig.task_name) {
                name = dataOrig.task_name
            }
            if (dataOrig.assets) {
                dataOrig.assets.sort((a,b) => {
                    if (a.create_datetime > b.create_datetime) {
                        return -1
                    } else if (a.create_datetime < b.create_datetime) {
                        return 1
                    } else {
                        return 0
                    }
                })
                dataOrig.assets.forEach((el) => {
                    if (el.asset_url) {
                        el.asset_url = el.asset_url
                        taskImages.push({
                            'id': el.id, 
                            'asset_url': el.asset_url, 
                            'asset_thumb_medium_url': el.asset_thumb_medium_url, 
                            'asset_thumb_url': el.asset_thumb_url, 
                            'is_collected': el.is_collected,
                            'task_id': dataOrig.id,
                            'task_media_site': dataOrig.task_media_site,
                            'asset_url': el.asset_url,
                        })
                    } else if (el?.fields?.asset_url) {
                        el.fields.asset_url = el.fields.asset_url
                        taskImages.push({
                            'id': el.fields.id, 
                            'asset_url': el.fields.asset_url, 
                            'asset_thumb_medium_url': el.fields.asset_thumb_medium_url, 
                            'asset_thumb_url': el.fields.asset_thumb_url, 
                            'is_collected': el.fields.is_collected,
                            'task_id': dataOrig.id,
                            'task_media_site': dataOrig.task_media_site,
                            'asset_url': el.fiels.asset_url,
                        })
                    }
                })
            }
            if (dataOrig.task_context) {
                if (typeof(dataOrig.task_context) == 'string') {
                    taskContext = JSON.parse(dataOrig.task_context)
                } else {
                    taskContext = dataOrig.task_context
                }
            }
            if (dataOrig.task_result) {
                if (typeof(dataOrig.task_context) == 'string') {
                    taskCurrentResult = JSON.parse(dataOrig.task_result)
                } else {
                    taskCurrentResult = dataOrig.task_result
                }
            }
            result = {
                id: dataOrig.id,
                name: name,
                thumb_url: thumb_url,
                task_id: dataOrig.task_id,
                task_name: dataOrig.task_name,
                status: dataOrig.status,
                status_text: getStatusText(dataOrig.status),
                task_status: dataOrig.task_status,
                task_context: taskContext,
                task_date_created: dataOrig.task_date_created,
                task_date_done: dataOrig.task_date_done,
                task_args: dataOrig.task_args,
                task_successful: dataOrig.task_successful,
                task_media_site: dataOrig.task_media_site,
                task_images: taskImages,
                task_result: taskCurrentResult,
            }
        }
        return result
    }

    function getUserTasks(page = userTaskListCurrentPage.value, currentTaskId = null, currentTask = null) {
        let params = {
            page: page,
            task_type: 'goods-image-train',
        }
        getUserImageTaskList(params).then((res) => {
            let remList = res.data.data
            let hasNext = res.data.has_next
            if (hasNext) {
                hasMoreUserTaskList.value = true
            } else {
                hasMoreUserTaskList.value = false
            }
            let transList = []
            transList = remList.map((el) => {
                return reformatTask(el)
            })
            console.log('transList')
            console.log(transList)
            if (page == 1) {
                userTaskList.value = transList
            } else {
                userTaskList.value = userTaskList.value.concat(transList)
            }
            if (currentTaskId && currentTask) {
                onShowGoodsImageCurrentTask(currentTaskId, currentTask)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    function onMoreUserTaskList() {
        userTaskListCurrentPage.value = parseInt(userTaskListCurrentPage.value) + 1
        let page = userTaskListCurrentPage.value
        getUserTasks(page)
    }

    function onChangePretrainModel(values) {
        pretrainModelCurrentThumbUrl.value = values.thumb_url
        pretrainModelCurrentName.value = values.name
        pretrainModelCurrentId.value = values.id
        goodsImageBuildOptions.pretrain_model_id = values.id
        goodsImageBuildOptions.pretrain_model_name = values.name
        goodsImageBuildOptions.pretrain_model_thumb_url = values.thumb_url
        goodsImageBuildOptions.pretrain_model_lora_name = values.lora_name
        onSwitchMainOption2Scene()
    }

    function onProcessGoodsImageRefActions(command) {
        if (command == 'drop') {
            ElMessageBox.confirm('确认要删除这项参考图吗？', '提示',{}).then(() => {
                let params = {
                    id: goodsImageRefGalleryCurrentId.value
                }
                destroyGoodsImageRef(params).then((res) => {
                    getUserGoodsImageRefs()
                }).catch((err) => {
                    console.log(err)
                })
            }).catch(() => {

            })
        }
    }

    function getUserGoodsImageRefs() {
        let params = {
            page: 1,
        }
        getUserImageRefList(params).then((res) => {
            goodsImageRefList.value = res.data.data
        }).catch((err) => {
            console.log(err)
        })
    }

    function getUserImagePretainModels() {
        let params = {
            page: 1,
        }
        getUserImagePretrainModelList(params).then((res) => {
            imagePretrainModelList.value = res.data.data
        }).catch((err) => {
            console.log(err)
        })
    }

    function getImageStencils(styleName) {
        let params = {
            page: 1,
        }
        if (styleName == 'medium') {
            params['stencil_category_id'] = 1
            getImageStencilList(params).then((res) => {
                imageStencilMediumStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'composition') {
            params['stencil_category_id'] = 2
            getImageStencilList(params).then((res) => {
                imageStencilCompositionStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'paint') {
            params['stencil_category_id'] = 3
            getImageStencilList(params).then((res) => {
                imageStencilPaintStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'scene') {
            params['stencil_category_id'] = 4
            getImageStencilList(params).then((res) => {
                imageStencilSceneStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'location') {
            params['stencil_category_id'] = 5
            getImageStencilList(params).then((res) => {
                imageStencilLocationStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'person') {
            params['stencil_category_id'] = 8
            getImageStencilList(params).then((res) => {
                imageStencilPersonStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'face') {
            params['stencil_category_id'] = 9
            getImageStencilList(params).then((res) => {
                imageStencilFaceStyleList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        } else if (styleName == 'tag') {
            params['stencil_category_id'] = 10
            getImageStencilList(params).then((res) => {
                imageStencilTagList.value = res.data.data
            }).catch((err) => {
                console.log(err)
            })
        }
    }

    function onShowGoodsImageMain() {
        isShowGoodsImageMain.value = true
        isShowCurrentGoodsImageTask.value = false
        isCurrentGoodsImageTaskTodo.value = false
        userCollectListCurrentPage.value = 1
        getUserCollects(userCollectListCurrentPage.value)
        goodsImageTaskCurrentId.value = null
        goodsImageTaskCurrentTaskId.value = null
    }

    function onNewGoodsImageTask() {
        isShowGoodsImageMain.value = false
        isShowCurrentGoodsImageTask.value = true
        isCurrentGoodsImageTaskTodo.value = true

        goodsImageTaskProgressing.value = false
        hasImageTaskProgressPreview.value = false
        goodsImageTaskImagesPreview.value = []

        goodsImageBuildOptions.pretrain_model_id = ''
        goodsImageBuildOptions.pretrain_model_name = ''
        goodsImageBuildOptions.pretrain_model_thumb_url = ''
        goodsImageBuildOptions.pretrain_model_lora_name = ''
        goodsImageBuildOptions.prompt_cn = ''
        goodsImageBuildOptions.prompt_cn_negative = ''
        goodsImageBuildOptions.prompt_en = ''
        goodsImageBuildOptions.prompt_en_negative = ''
        goodsImageBuildOptions.batch_size = 4
        goodsImageBuildOptions.medium_style = ''
        goodsImageBuildOptions.medium_style_id = ''
        goodsImageBuildOptions.paint_style = ''
        goodsImageBuildOptions.paint_style_id = ''
        goodsImageBuildOptions.scene_style = ''
        goodsImageBuildOptions.scene_style_id = ''
        goodsImageBuildOptions.location_style = ''
        goodsImageBuildOptions.location_style_id = ''
        goodsImageBuildOptions.composition_style = ''
        goodsImageBuildOptions.composition_style_id = ''
        goodsImageBuildOptions.person_style = ''
        goodsImageBuildOptions.person_style_id = ''
        goodsImageBuildOptions.face_style = ''
        goodsImageBuildOptions.face_style_id = ''
        goodsImageBuildOptions.tags_style = ''
        goodsImageBuildOptions.tags_style_id = ''

        let params = {
            task_type: 'goods-image-train',
        }
        createImageTask(params).then((res) => {
            let newTaskId = res.data.data.id
            let newTask = res.data.data
            newTask = reformatTask(newTask)
            userTaskList.value.push(newTask)
            onShowGoodsImageCurrentTask(newTaskId, newTask)
        }).catch((err) => {
            console.log(err)
        })
    }

    function onShowGoodsImageCurrentTask(taskId, task) {
        isShowTaskMoreDetails.value = false
        goodsImageTaskCurrentId.value = taskId
        goodsImageTaskCurrentTaskId.value = task.task_id
        goodsImageTaskCurrent.value = task
        goodsImageTaskCurrentName.value = task.name
        goodsImageTaskCurrentTaskName.value = task.task_name
        if (task.task_images) {
            goodsImageTaskCurrentTotalImageList.value = task.task_images
        }
        goodsImageTaskCurrentGeneratedImages.value = task.task_result
        goodsImageTaskCurrentMainOption.value = 'model'
        goodsImageTaskCurrentStep.value = 0

        isShowGoodsImageMain.value = false
        isShowCurrentGoodsImageTask.value = true
        isCurrentGoodsImageTaskTodo.value = task.task_successful != 1

        
        let ctxPretrainModelCurrentThumbUrl = ''
        let ctxPretrainModelCurrentName = ''
        let ctxPretrainModelCurrentLoraName = ''
        let ctxPretrainModelCurrentId = ''
        let ctxPretrainModelCurrent = {}
        let ctxGoodsImageBuildOptions = {}
        let context = {}
        if (task.task_context) {
            if (typeof(context) == 'string') {
                context = JSON.parse(context)
            } else {
                context = task.task_context
            }
        }
        if (context.goodsImageBuildOptions) {
            ctxPretrainModelCurrentThumbUrl = context.goodsImageBuildOptions.pretrain_model_thumb_url
            ctxPretrainModelCurrentName = context.goodsImageBuildOptions.pretrain_model_name
            ctxPretrainModelCurrentId = context.goodsImageBuildOptions.pretrain_model_id
            ctxPretrainModelCurrentLoraName = context.goodsImageBuildOptions.pretrain_model_lora_name
            ctxGoodsImageBuildOptions = context.goodsImageBuildOptions

            pretrainModelCurrentThumbUrl.value = ctxPretrainModelCurrentThumbUrl
            pretrainModelCurrentName.value = ctxPretrainModelCurrentName
            pretrainModelCurrentId.value = ctxPretrainModelCurrentId
            goodsImageBuildOptions.pretrain_model_id = ctxGoodsImageBuildOptions.pretrain_model_id
            goodsImageBuildOptions.pretrain_model_name = ctxGoodsImageBuildOptions.pretrain_model_name
            goodsImageBuildOptions.pretrain_model_thumb_url = ctxGoodsImageBuildOptions.pretrain_model_thumb_url
            goodsImageBuildOptions.pretrain_model_lora_name = ctxGoodsImageBuildOptions.pretrain_model_lora_name
            goodsImageBuildOptions.batch_size = ctxGoodsImageBuildOptions.batch_size
            goodsImageBuildOptions.prompt_cn = ctxGoodsImageBuildOptions.prompt_cn
            goodsImageBuildOptions.prompt_en = ctxGoodsImageBuildOptions.prompt_en
            goodsImageBuildOptions.prompt_en_negative = ctxGoodsImageBuildOptions.prompt_en_negative
            goodsImageBuildOptions.medium_style = ctxGoodsImageBuildOptions.medium_style
            goodsImageBuildOptions.medium_style_id = ctxGoodsImageBuildOptions.medium_style_id
            goodsImageBuildOptions.composition_style = ctxGoodsImageBuildOptions.composition_style
            goodsImageBuildOptions.composition_style_id = ctxGoodsImageBuildOptions.composition_style_id
            goodsImageBuildOptions.paint_style = ctxGoodsImageBuildOptions.paint_style
            goodsImageBuildOptions.paint_style_id = ctxGoodsImageBuildOptions.paint_style_id
            goodsImageBuildOptions.scene_style = ctxGoodsImageBuildOptions.scene_style
            goodsImageBuildOptions.scene_style_id = ctxGoodsImageBuildOptions.scene_style_id
            goodsImageBuildOptions.person_style = ctxGoodsImageBuildOptions.person_style
            goodsImageBuildOptions.person_style_id = ctxGoodsImageBuildOptions.person_style_id
            goodsImageBuildOptions.face_style = ctxGoodsImageBuildOptions.face_style
            goodsImageBuildOptions.face_style_id = ctxGoodsImageBuildOptions.face_style_id
            goodsImageBuildOptions.tags_style = ctxGoodsImageBuildOptions.tags_style
            goodsImageBuildOptions.tags_style_id = ctxGoodsImageBuildOptions.tags_style_id

            goodsImageTaskCurrentContextDescList.value = getTaskContextDescList(task.task_context)
        } else {
            pretrainModelCurrentThumbUrl.value = ''
            pretrainModelCurrentName.value = ''
            pretrainModelCurrentId.value = ''

            goodsImageBuildOptions.pretrain_model_id = ''
            goodsImageBuildOptions.pretrain_model_name = ''
            goodsImageBuildOptions.pretrain_model_thumb_url = ''
            goodsImageBuildOptions.pretrain_model_lora_name = ''
            goodsImageBuildOptions.batch_size = 4
            goodsImageBuildOptions.prompt_cn = ''
            goodsImageBuildOptions.prompt_en = ''
            goodsImageBuildOptions.prompt_en_negative = ''
            goodsImageBuildOptions.medium_style = ''
            goodsImageBuildOptions.medium_style_id = ''
            goodsImageBuildOptions.composition_style = ''
            goodsImageBuildOptions.composition_style_id = ''
            goodsImageBuildOptions.paint_style = ''
            goodsImageBuildOptions.paint_style_id = ''
            goodsImageBuildOptions.scene_style = ''
            goodsImageBuildOptions.scene_style_id = ''
            goodsImageBuildOptions.person_style = ''
            goodsImageBuildOptions.person_style_id = ''
            goodsImageBuildOptions.face_style = ''
            goodsImageBuildOptions.face_style_id = ''
            goodsImageBuildOptions.tags_style = ''
            goodsImageBuildOptions.tags_style_id = ''
        }
    }

    function onSwitchMainOption2Mask() {
        goodsImageTaskCurrentMainOption.value = 'mask'
    }
    function onSwitchMainOption2Model() {
        goodsImageTaskCurrentMainOption.value = 'model'
        goodsImageTaskCurrentStep.value = 0
    }
    function onSwitchMainOption2Scene() {
        goodsImageTaskCurrentMainOption.value = 'scene'
        goodsImageTaskCurrentStep.value = 1
    }
    function onSwitchMainOption2Number() {
        goodsImageTaskCurrentMainOption.value = 'number'
        goodsImageTaskCurrentStep.value = 2
    }
    function onSwitchMainOption2Status() {
        goodsImageTaskCurrentMainOption.value = 'status'
        goodsImageTaskCurrentStep.value = 3
    }

    function onTranslateChinesePromptByTencent() {
        let params = {
            text: goodsImageBuildOptions.prompt,
            source: 'zh',
            to: 'en',
        }
        translateByTencent(params).then((res) => {
            let translatedText = res.data.data[0]
            goodsImageBuildOptions.prompt_en = translatedText
        }).catch((err) => {
            console.log(err)
        })
    }

    function onTranslateChinesePromptByAzure() {
        let params = {
            text: goodsImageBuildOptions.prompt,
            source: 'zh',
            to: 'en',
        }
        translateByAzure(params).then((res) => {
            let translatedText = res.data.data[0]
            goodsImageBuildOptions.prompt_en = translatedText
        }).catch((err) => {
            console.log(err)
        })
    }

    function onTranslateChinesePromptByAlibaba() {
        let params = {
            text: goodsImageBuildOptions.prompt_cn,
            source: 'zh',
            to: 'en',
        }
        translateByAlibaba(params).then((res) => {
            let translatedText = res.data.data[0]
            goodsImageBuildOptions.prompt_en = translatedText
        }).catch((err) => {
            console.log(err)
        })
    }

    function onSubmitGoodsImageTask(type) {
        let task_id = ''
        let pretrain_model_id = ''
        let pretrain_model_name = ''
        let pretrain_model_thumb_url = ''
        let pretrain_model_lora_name = ''
        let prompt_cn = ''
        let prompt_cn_negative = ''
        let prompt_en = ''
        let prompt_en_negative = ''
        let generat_number = 1
        let stencil_ids = ''
        let medium_style = ''
        let medium_style_id = ''
        let composition_style = ''
        let composition_style_id = ''
        let paint_style = ''
        let paint_style_id = ''
        let scene_style = ''
        let scene_style_id = ''
        let location_style = ''
        let location_style_id = ''
        let person_style = ''
        let person_style_id = ''
        let face_style = ''
        let face_style_id = ''
        let tags_style = ''
        let tags_style_id = ''
        let image_ref = ''
        let image_mask = ''
        let image_mask_type = ''
        let image_build_type = ''

        let checkItems = {
            'prompt_cn': '提示词还没有设置',
            // 'prompt_en': '英文提示词还没有设置',
            'medium_style_id': '输出媒质类型还没有设置',
            'scene_style_id': '场景/动作类型还没有设置',
            // 'composition_style_id': '构图类型还没有设置',
            // 'paint_style_id': '画风类型还没有设置',
            // 'location_style_id': '位置类型还没有设置',
            // 'person_style_id': '人物类型还没有设置',
            // 'face_style_id': '面部类型还没有设置',
            // 'tags_style_id': '标签还没有设置',
        }
        let invalidItems = []

        if (!goodsImageBuildOptions['pretrain_model_id']) {
            let checkResultMessage = '预训练模型还没有选择'
            ElMessageBox.alert(checkResultMessage, '提示', {
                callback: () => {
                    return
                }
            })
        } else {
            for (let itemKey in checkItems) {
                if (!goodsImageBuildOptions[itemKey]) {
                    invalidItems.push(
                        checkItems[itemKey]
                    )
                }
            }
            if (invalidItems.length == Object.keys(checkItems).length) {
            // if (invalidItems.length == 5) {
                let checkResultMessage = '[' + invalidItems.join(',\n') + '];几项中需要选择其中一项!'
                ElMessageBox.alert(checkResultMessage, '提示', {
                    callback: () => {
                        return
                    }
                })
            } else {
                pretrain_model_id = goodsImageBuildOptions.pretrain_model_id
                pretrain_model_name = goodsImageBuildOptions.pretrain_model_name
                pretrain_model_thumb_url = goodsImageBuildOptions.pretrain_model_thumb_url
                pretrain_model_lora_name = goodsImageBuildOptions.pretrain_model_lora_name
                prompt_cn = goodsImageBuildOptions.prompt_cn
                prompt_cn_negative = goodsImageBuildOptions.prompt_cn_negative
                prompt_en = goodsImageBuildOptions.prompt_en
                prompt_en_negative = goodsImageBuildOptions.prompt_en_negative
                generat_number = goodsImageBuildOptions.batch_size
                medium_style_id = goodsImageBuildOptions.medium_style_id
                medium_style = goodsImageBuildOptions.medium_style
                composition_style_id = goodsImageBuildOptions.composition_style_id
                composition_style = goodsImageBuildOptions.composition_style
                paint_style_id = goodsImageBuildOptions.paint_style_id
                paint_style = goodsImageBuildOptions.paint_style
                scene_style_id = goodsImageBuildOptions.scene_style_id
                scene_style = goodsImageBuildOptions.scene_style
                location_style_id = goodsImageBuildOptions.location_style_id
                location_style = goodsImageBuildOptions.location_style
                person_style_id = goodsImageBuildOptions.person_style_id
                person_style = goodsImageBuildOptions.person_style
                face_style_id = goodsImageBuildOptions.face_style_id
                face_style = goodsImageBuildOptions.face_style
                image_build_type = type
                stencil_ids = medium_style_id + ',' + composition_style_id + ',' + paint_style_id + ',' + scene_style_id + ',' + location_style_id + ',' + person_style_id + ',' + face_style_id

                task_id = goodsImageTaskCurrentId.value
                let params = {
                    task_id: task_id,
                    pretrain_model_id: pretrain_model_id,
                    pretrain_model_name: pretrain_model_name,
                    pretrain_model_thumb_url: pretrain_model_thumb_url,
                    pretrain_model_lora_name: pretrain_model_lora_name,
                    medium_style_id: medium_style_id,
                    medium_style: medium_style,
                    composition_style_id: composition_style_id,
                    composition_style: composition_style,
                    paint_style_id: paint_style_id,
                    paint_style: paint_style,
                    scene_style_id: scene_style_id,
                    scene_style: scene_style,
                    location_style_id: location_style_id,
                    location_style: location_style,
                    person_style_id: person_style_id,
                    person_style: person_style,
                    face_style_id: face_style_id,
                    face_style: face_style,
                    tags_style_id: tags_style_id,
                    tags_style: tags_style,
                    stencil_ids: stencil_ids,
                    batch_size: generat_number,
                    prompt_cn: prompt_cn,
                    prompt_cn_negative: prompt_cn_negative,
                    prompt_en: prompt_en,
                    prompt_en_negative: prompt_en_negative,
                    image_build_type: image_build_type,
                }


                hasImageTaskProgressPreview.value = false
                goodsImageTaskImagesPreview.value = []
                goodsImageTaskProgressing.value = false
                goodsImageTaskProgressValue.value = 0
                goodsImageTaskProgressInfo.value = ''
                goodsImageTaskCurrentGeneratedImages.value = []
                goodsImageBuildTaskResult.value = []

                submitImageTask(params).then((res) => {
                    goodsImageTaskCurrentMainOption.value = 'status'

                    goodsImageTaskProgressing.value = true
                    goodsImageTaskCurrentGeneratedImages.value = []
                    let task_id = res.data.data.id
                    let task_task_id = res.data.data.task_id
                    let task_successful = res.data.data.task_successful
                    let task_current_media_site = res.data.data.task_media_site
                    localStorage.setItem('current_task_id', task_id)
                    localStorage.setItem('current_task_task_id', task_task_id)
                    if (task_successful != 1) {
                        goodsImageTaskTimerId.value = setInterval(() => {
                            let current_task_id = localStorage.getItem('current_task_id')
                            let current_task_task_id = localStorage.getItem('current_task_task_id')
                            let taskParams = {
                                id: current_task_id,
                                task_id: current_task_task_id,
                            }
                            getImageTaskProgress(taskParams).then((progressRes) => {   
                                task_current_media_site = progressRes.data.data.progress_current_media_site
                                // let progress_current_id = progressRes.data.data.progress_current_id
                                // let progress_current_status = progressRes.data.data.progress_current_status
                                let progress_current_successful = progressRes.data.data.progress_current_successful
                                let progress_current_status = progressRes.data.data.progress_current_status
                                let progress_current_result = progressRes.data.data.progress_current_result
                                let progress_current_value = progressRes.data.data.progress_current_value
                                let progress_current_info = progressRes.data.data.progress_current_info
                                let progress_current_images = progressRes.data.data.progress_current_images

                                goodsImageTaskProgressInfo.value = progress_current_info
                                goodsImageTaskProgressValue.value = progress_current_value
                                if (progress_current_images.length > 0) {
                                    hasImageTaskProgressPreview.value = true
                                }
                                goodsImageTaskImagesPreview.value = progress_current_images

                                if (progress_current_successful) {
                                    onDoneGoodsImageTask()
                                    // goodsImageTaskProgressing.value =false
                                    // let current_task_id = localStorage.getItem('current_task_id')
                                    // let current_task_task_id = localStorage.getItem('current_task_task_id')
                                    // let taskParams = {
                                    //     id: current_task_id,
                                    //     task_id: current_task_task_id,
                                    // }
                                    // getImageTaskResult(taskParams).then((res) => {
                                    //     let task_images_result = res.data.data.images
                                    //     goodsImageTaskProgressing.value = false
                                    //     goodsImageTaskCurrentGeneratedImages.value = task_images_result
                                    // }).catch((err) => {
                                    //     console.log(err)
                                    // })
                                    // clearInterval(goodsImageTaskTimerId.value)
                                } else {
                                    goodsImageTaskCurrentGeneratedImages.value = progress_current_images
                                }
                            }).catch((err) => {
                                console.log(err)
                            })
                        }, 1000)
                    } else {
                        goodsImageTaskProgressing.value =false
                        let current_task_id = localStorage.getItem('current_task_id')
                        let current_task_task_id = localStorage.getItem('current_task_task_id')
                        let taskParams = {
                            id: current_task_id,
                            task_id: current_task_task_id,
                        }
                        getImageTaskResult(taskParams).then((res) => {
                            let task_result = res.data.data.result
                            let taskImageUrls = task_result
                            // let taskImageRealUrls = []
                            // let media_site_base_url = task_current_media_site
                            // taskImageUrls.forEach((el) => {
                            //     // taskImageRealUrls.push(media_site_base_url + el)
                            // })
                            goodsImageTaskCurrentGeneratedImages.value = taskImageUrls
                        }).catch((err) => {
                            console.log(err)
                        })
                    }
                }).catch((err) => {
                    console.log(err)
                })
            }
        }
    }

    function onUpdateGoodsImageTask() {
        let currentContext = {
            pretrainModelCurrentThumbUrl: pretrainModelCurrentThumbUrl.value,
            goodsImageBuildOptions: goodsImageBuildOptions,
        }
        let params = {
            id: goodsImageTaskCurrentId.value,
            task_id: goodsImageTaskCurrentTaskId.value,
            context: currentContext,
        }
        updateImageTask(params).then(() => {
            ElMessage('保存完成')
        }).catch((err) => {
            console.log(err)
        })
    }

    function onQueryGoodsImageTask() {
        let params = {
            task_id: goodsImageTaskCurrent.value['task_id']
        }
        let images = []
        goodsImageBuildTaskResult.value = []
        queryImageTask(params).then((res) => {
            images = res.data.data.images
            goodsImageBuildTaskResult.value = images
        }).catch((err) => {
            console.log(err)
        })
    }

    function onDoneGoodsImageTask() {
        console.log(goodsImageTaskTimerId.value)
        clearInterval(goodsImageTaskTimerId.value)
        let params = {
            id: goodsImageTaskCurrentId.value,
            task_id: goodsImageTaskCurrentTaskId.value,
            status: 'SUCCESS',
            successful: 1,
        }
        updateImageTask(params).then((res) => {
            isCurrentGoodsImageTaskTodo.value = false
            retriveImageTask(params).then((res) => {
                goodsImageTaskCurrent.value = reformatTask(res.data.data)
                nextTick(() => {
                    let newUserTaskList = []
                    userTaskList.value.forEach((el) => {
                        if (el.id == goodsImageTaskCurrentId.value) {
                            el = goodsImageTaskCurrent.value
                        }
                        newUserTaskList.push(el)
                    })
                    userTaskList.value = newUserTaskList
                    onShowGoodsImageCurrentTask(
                        goodsImageTaskCurrentId.value,
                        goodsImageTaskCurrent.value
                    )
                })
            }).catch((err) => {
                console.log(err)
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    function onReDoingGoodsImageTask() {
        let params = {
            id: goodsImageTaskCurrentId.value,
            status: 'STARTED',
            successful: -1,
        }
        updateImageTask(params).then((res) => {
            isCurrentGoodsImageTaskTodo.value = true
            goodsImageTaskCurrent.value = reformatTask(res.data.data)
            userTaskList.value.forEach((el) => {
                nextTick(() => {
                    let newUserTaskList = []
                    userTaskList.value.forEach((el) => {
                        if (el.id == goodsImageTaskCurrentId.value) {
                            el = goodsImageTaskCurrent.value
                        }
                        newUserTaskList.push(el)
                    })
                    userTaskList.value = newUserTaskList
                    onShowGoodsImageCurrentTask(
                        goodsImageTaskCurrentId.value,
                        goodsImageTaskCurrent.value
                    )
                })
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    function onDestroyGoodsImageTask() {
        ElMessageBox.confirm('确认要删除[' + goodsImageTaskCurrent.value.name + ']吗？','提示',
            {
                type: 'danger',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                callback: (action) => {
                    let params = {
                        id: goodsImageTaskCurrentId.value
                    }
                    destroyImageTask(params).then(() => {
                        getUserTasks(1)
                        onShowGoodsImageMain()
                    }).catch(() => {

                    })
                }
            }
        )
    }

    function onEditTaskName() {
        if (goodsImageTaskCurrent.value.task_name) {
            taskNewName.value = goodsImageTaskCurrent.value.task_name
        } else {
            taskNewName.value = goodsImageTaskCurrent.value.name
        }
        isShowTaskEditDialog.value = true
    }

    function onCancelTaskEdit() {
        isShowTaskEditDialog.value = false
    }

    function onConfirmTaskEdit() {
        isShowTaskEditDialog.value = false
        let params = {
            id: goodsImageTaskCurrentId.value,
            name: taskNewName.value
        }
        updateImageTask(params).then((res) => {
            goodsImageTaskCurrent.value = reformatTask(res.data.data)
            taskNewName.value = ''
            userTaskList.value.forEach((el) => {
                let id = el.id
                let currentCompareId = goodsImageTaskCurrent.value.id
                if (id == currentCompareId) {
                    el.name = goodsImageTaskCurrent.value.task_name
                }
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    function getStatusText(status) {
        let result = ''
        let statusTextDict = {
            'PENDING': '',
            'STARTED': '',
            'SUCCESS': '已完成',
            'RETRY': '',
            'FAILURE': '',
        }
        if (statusTextDict.hasOwnProperty(status?.toUpperCase())) {
            result = statusTextDict[status?.toUpperCase()]
        } else {
            result = ''
        }
        return result
    }

    function onChangeImageStencilMediumStyle(stencil) {
        goodsImageBuildOptions.medium_style = stencil.name
        goodsImageBuildOptions.medium_style_id = stencil.id
    }

    function onChangeImageStencilCompositionStyle(stencil) {
        goodsImageBuildOptions.composition_style = stencil.name
        goodsImageBuildOptions.composition_style_id = stencil.id
    }

    function onChangeImageStencilPaintStyle(stencil) {
        goodsImageBuildOptions.paint_style = stencil.name
        goodsImageBuildOptions.paint_style_id = stencil.id
    }

    function onChangeImageStencilSceneStyle(stencil) {
        goodsImageBuildOptions.scene_style = stencil.name
        goodsImageBuildOptions.scene_style_id = stencil.id
    }

    function onChangeImageStencilLocationStyle(stencil) {
        goodsImageBuildOptions.location_style = stencil.name
        goodsImageBuildOptions.location_style_id = stencil.id
    }

    function onChangeImageStencilPersonStyle(stencil) {
        goodsImageBuildOptions.person_style = stencil.name
        goodsImageBuildOptions.person_style_id = stencil.id
    }

    function onChangeImageStencilFaceStyle(stencil) {
        goodsImageBuildOptions.face_style = stencil.name
        goodsImageBuildOptions.face_style_id = stencil.id
    }

    function onChangeImageStencilTagsStyle(stencil) {
        goodsImageBuildOptions.tag_style = stencil.name
        goodsImageBuildOptions.tag_style_id = stencil.id
    }

    function onCollectTaskImage(image, is_collected, task_id, id, task_media_site, asset_url, asset_thumb_medium_url) {
        let params = {
            id: id,
            task_id: task_id,
            task_media_site: task_media_site,
            asset_url: asset_url,
            asset_thumb_medium_url: asset_thumb_medium_url,
            task_type: 'goods-image-train',
        }

        if (!is_collected) {
            createUserImageCollect(params).then((res) => {
                image.is_collected = true
                let message = res.msg
                ElMessage({
                    message: message,
                    type: 'success',
                })
            }).catch((err) => {
                let message = res.msg
                ElMessage({
                    message: message,
                    type: 'error',
                })
                console.log(err)
            })
        } else if (is_collected) {
            destroyUserImageCollect(params).then((res) => {
                image.is_collected = false
                let message = res.msg
                ElMessage({
                    message: message,
                    type: 'warning'
                })
            }).catch((err) => {
                let message = res.msg
                ElMessage({
                    message: message,
                    type: 'error'
                })
                console.log(err)
            })
        }
    }

    function onDestroyTaskImage(id, task_id) {
        ElMessageBox.confirm('确认要删除这张图吗？', '危险提示',{
            type: 'danger',
            callback: (action) => {
                let params = {
                    id: id,
                    task_id: task_id,
                }
                destroyGoodsImage(params).then((res) => {
                    let taskParams = {
                        id: goodsImageTaskCurrentId.value,
                    }
                    retriveImageTask(taskParams).then((res) => {
                        goodsImageTaskCurrent.value = reformatTask(res.data.data)
                        onShowGoodsImageCurrentTask(
                            goodsImageTaskCurrentId.value,
                            goodsImageTaskCurrent.value)
                    }).catch((err) => {
                        console.log(err)
                    })
                    
                }).catch((err) => {
                    console.log(err)
                })
            }
        })
    }

    function onToggleTaskMoreDetails() {
        isShowTaskMoreDetails.value = !isShowTaskMoreDetails.value
    }

</script>

<style scoped lang="scss">
    ::v-deep(.el-tabs__content){
        // overflow:hidden 会导致 分页下拉框超出部分会被.el-tabs__content隐藏
        overflow: visible;
    }
    .page {
        position: relative;
        .aside {
            position: relative;
            .list {
                position: relative;
                display: flex;
                flex-direction: column;
                .list-item {
                    display: flex;
                    flex-direction: row;
                    background-color: #fff;
                    color: #424242;
                    margin-bottom: 4px;
                    &.active {
                        background-color: #eee;
                        color: #333;
                    }
                    .item-thumb {
                        width: 48px;
                        height: 48px;
                        border-radius: 50%;
                        margin-right: 4px;
                    }
                    .item-content {
                        display: flex;
                        flex-direction: row;
                    }
                    .item-actions {

                    }
                }
            }
        }
        .main {
            position: relative;
            .section--main {
                position: relative;
            }
            .section--new-goodsimage {
                position: relative;
                .footer--task-create {
                    background-color: #fafafa;
                    opacity: 0.7;
                    color: #212121;
                }                
            }
            .section--current-goodsimage {
                position: relative;
            }

            .main-gutter {
            }

            .gutter-switch {
                &.active {
                    background-color: #e0e0e0;
                    color: #212121;
                }
            }

            .flag-icon {
                color: #212121;
                &.flag-icon--fin {
                    color: #000;
                }
            }
            .flag-label {
                margin-left: 4px;
                margin-right: 4px;
                &.flag-label--fin {
                    color: #000;
                }
            }
        }
        .section-header {
            border-bottom: 1px dashed #eee;
        }
        .dialog--finetune {
            .finetune-pen-switch {
                background-color: #fff;
                color: #757575;
                &.active {
                    background-color: #eee;
                    color: #424242;
                }
            }
        }
        :deep(.desc-label) {
            padding-left: 16px;
            margin-left: 16px;
        }
        :deep(.desc-content) {
            padding-left: 16px;
        }

        .aside--task-nav {

        }
        .footer--task-actions {
        }

        :deep(.el-tabs__item.is-active .label) {
            color: #212121 !important;
            font-weight: 700;
        }
    }
</style>