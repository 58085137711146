export default {
	login: {
		loginInTitle: 'AI中台',
		loginInSubTitle: '用户登录',
		loginAccount: '请输入账号',
		loginPWD: '请输入密码',
		code: '验证码',
		rememberMe: '记住密码',
		AccountError: '请输入用户名',
		PWError: '请输入密码',
		codeError: '请输入验证码',
		login: '登录',

		pending: '等待',
		received: '受理',
		started: '开始执行',
		success: '成功',
		failure: '失败',
		retry: '重新执行',
		revoked: '回收',
		rejected: '被拒绝',		
	}
}