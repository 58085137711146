<template>
    <el-input v-model="inputValue.label" class="my-input" @click="inputOnClick" readonly placeholder="请选择"
        :suffix-icon="EditPen" v-bind="props.selectAttr" />

    <!-- 弹出选择 -->
    <el-dialog v-model="modalShow" align-center width="800px" class="my-modal" :close-on-click-modal="false">
        <template #header>
            <div style="display: flex;align-items: center;font-size: 18px;color: #222;">
                <span>{{ props.modalTitle }}</span>
            </div>
        </template>
        <slot></slot>
        <div class="modal-content">
            <div :class="['item', modalSelectData?.value === item.value ? 'active' : '']" :key="index"
                @click="modalSelectData = item" v-for="(item, index) in optionData"
                :style="props.itemStyle">
                <template v-if="props.type === 'mp3'">
                    <div class="playBtn" @click="playToggle(item.img)">
                        <el-icon>
                            <VideoPlay v-if="playIngUrl !== item.img" />
                            <VideoPause v-else />
                        </el-icon>
                    </div>
                    <audio :src="playIngUrl" autoplay ref="audio" @ended="ended"></audio>
                </template>
                <template v-else>
                    <div class="img">
                        <img :src="item.img" alt="img">
                    </div>
                </template>
                <div class="title">
                    {{ item.label }}
                </div>
            </div>
        </div>

        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="onOk">
                    确定
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>
<style src="./style.css" scoped></style>
<script setup>
import { ref, inject, onMounted, watch } from "vue"
import { ElMessage } from 'element-plus'
import { EditPen } from '@element-plus/icons-vue'

const myFetch = inject('myFetch')
/**
 * props.type: img | mp3   
 * props.selectAttr: {}  el-select 的配置 
 * props.fetchConfig: {} 请求接口配置的配置
 * props.defaultSelectOne 默认选中第一个选项
 * props.modalTitle 弹出层的标题
 * props.valueTextFormat 格式化输入框中显示的文字
 * 
 * 
 * ins.refresh()  刷新接口
*/
const props = defineProps(['type', 'selectAttr', 'modalTitle', 'fetchConfig', 'optionConfig', 'defaultSelectOne', 'valueTextFormat', 'modelValue', 'itemStyle']);
const emit = defineEmits(['update:modelValue'])

const optionConfig = {
    label: "label",
    value: "value",
    img: "img",
    ...props.optionConfig
}
const optionData = ref([])
// 对象形式： { label:xxx, value:xxx }
const inputValue = ref(props.modelValue)
const modalShow = ref(false)

// modal 中选择的数据
const modalSelectData = ref()

function valueChange(value) {
    emit('update:modelValue', value);
}

async function refresh() {
    // optionData.value = [
    //     {
    //         value: '1',
    //         label: '1',
    //     }, 
    // ]; 
    const { label: lKey, value: vKey, img: iKey } = optionConfig;
    const { apiName, otherParams } = props.fetchConfig || {};
    const { code, msg, data } = await myFetch(apiName, otherParams);
    if (code === 2000) {
        optionData.value = data.list.map(item => ({
            label: item[lKey],
            value: item[vKey],
            img: item[iKey] || "https://p3-passport.byteacctimg.com/img/user-avatar/326d15e2c3edcf6de0c83c77d8fc4adb~90x90.awebp"
        }))
        if (props.defaultSelectOne && optionData.value[0]&& !inputValue.value) {
            const oneValue = optionData.value[0];
            valueChange(oneValue);
            inputValue.value = oneValue;
        }
    } else {
        ElMessage.error(msg);
    }
}

function inputOnClick() {
    modalShow.value = true;
    modalSelectData.value = { ...inputValue.value };
}
function onOk() {
    inputValue.value = { ...modalSelectData.value };
    modalShow.value = false;
    valueChange(inputValue.value);
}


// 正在播放的音频
const audio = ref();
const playIngUrl = ref('');
async function playToggle(url) {
    if (playIngUrl.value === url) {
        // 暂停 
        playIngUrl.value = '';
        return;
    }
    playIngUrl.value = url; 
}
function ended() {
    playIngUrl.value = '';
} 
watch(modalShow, (val) => {
    if(!val){ 
        playIngUrl.value = '';
    }
})

onMounted(() => {
    refresh();
})
defineExpose({
    refresh
})

</script> 
<style>
.my-modal .el-dialog__body {
    padding: 0px;
}
</style>