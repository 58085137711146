<template>
    <div class="feedback-tonntent">
        <el-form  :model="feedbackForm" label-width="120px" class="feedback-form" ref="formRef" :rules="formRules">
    <el-form-item label="意见反馈:   " prop="textareaValue"  class="textareaItem">
      <el-input maxlength="1000"  show-word-limit input-style="height: 100%" type="textarea" placeholder="请输入意见反馈消息" v-model="feedbackForm.textareaValue" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmitForm">提交消息</el-button>
    </el-form-item>
  </el-form>
    </div>
</template>
<script setup>
import {ref,reactive,}from 'vue'
import {getFeeckback,getShList}from '@/api/train'
import { ElMessage } from 'element-plus'
//form节点
const formRef = ref()
//表单数据
const feedbackForm = reactive({textareaValue:''})
//表单验证规则
const formRules = reactive({
    textareaValue: [
        { required: true, message: '必填项' },
    ],
})
//提交意见事件
const onSubmitForm = async() => {
    let formObj = {"message":feedbackForm.textareaValue}
    formRef.value.validate(async(valid)=>{
        if(!valid)return ElMessage.warning('请输入反馈信息')
        const res = await getFeeckback(formObj)
        if(res.code !== 2000)return ElMessage.error(res.msg)
        ElMessage.success('提交成功')
        formRef.value.resetFields()
        console.log(res);
    })
}

</script>
<style scoped lang="scss">
.feedback-tonntent{
    width: 100%;
    height: 100%;
    overflow: hidden;
    background:radial-gradient(black, transparent);
    .feedback-form{
        margin-top: 200px;
    }
}
.el-form-item--default:first-child{
    height: 200px;
}
</style>